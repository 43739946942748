import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { ProjectManagersType } from '../types/projectUser'
import { appActions } from '../../appSlice'
import { projectUserActions } from '../slice/projectUserSlice'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'

type RequestType = {
  params?: PaginationParamsType
  projectId: number
}

export const fetchProjectManagers = createAsyncThunk<ProjectManagersType[],
  RequestType,
  ThunkConfig<any>>(
    'projectUser/fetch_project_managers',
    async (data, { dispatch, rejectWithValue, extra }) => {

      dispatch(appActions.setIsLoad(true))
      dispatch(projectUserActions.setProjectId(data.projectId))
      try {
        const res = await extra.api().get<ProjectManagersType[]>(`project/${data.projectId}/project-managers`)

        return res.data

      } catch (e) {
        handleServerNetworkError(e, dispatch, 'Не удалось получить список менеджеров заказа')
        return rejectWithValue({})
      } finally {
        dispatch(appActions.setIsLoad(false))
      }
    }
  )