import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { ProjectManagersType } from "../types/projectUser";


export const switchIsGetNewClients = createAsyncThunk<ProjectManagersType[],
    number,
    ThunkConfig<any>>(
    'projectUser/switch_is_get_new_clients',
    async (client_id, { dispatch, rejectWithValue, extra }) => {
        try {
            const res = await extra.api().patch(`project-user/${client_id}/switch-is-get-new-clients`)
            return res.data
        } catch (e) {
            handleServerNetworkError(e, dispatch, 'Произошла ошибка')
            return rejectWithValue({})
        }
    }
)