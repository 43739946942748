import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { clientThunks } from '../../clientsSlice'
import { tracksActions } from '../slice/tracksSlice'


type RequestProps = {
  params: any,  // todo types
  clientId?: number
}

export const editClientProduct = createAsyncThunk<
  {},
  RequestProps,
  ThunkConfig<any>>(
  'tracks/edit_client_product',
  async (dto, { dispatch, rejectWithValue, extra }) => {

    const { clientId, params } = dto

    dispatch(tracksActions.setIsLoad(true))
    try {
      await extra.api().patch(`client-product/${params.productId}`, params)

      clientId && dispatch(clientThunks.fetchClientData({ clientId }))
      dispatch(snackActions.setSnack({
        label: 'Продукт был успешно обновлен',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось обновить продукт')
      return rejectWithValue({})
    } finally {
      dispatch(tracksActions.setIsLoad(false))
    }
  }
)