import React, { FC, useEffect, useState } from 'react'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import { FormControl, MenuItem, Select } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { letterSelectors, letterThunks } from '../../providers/StoreProvider/slices/lettersSlice'
import { clientSelectors } from '../../providers/StoreProvider/slices/clientsSlice'
import { useAppDispatch } from '../../providers/StoreProvider/hooks/useAppDispatch'
import { useLeadControl } from '../../Pages/LeadsManagePage/LeadsContext/lib/useLeadControl'

type SelectPropsType = {
  clientId?: number
};

export type FilteredValueType = 'DEFAULT'
  | 'ACTION'
  | 'LETTER'
  | 'NOTE'
  | 'ALL'
  | 'CLS'
  | 'SMS'

export const CustomSelect: FC<SelectPropsType> = ({ clientId }) => {

  const {
    isOpenPlannedLetters,
    isOpenFailedLetters,
    isOpenAllLetters
  } = useSelector(letterSelectors.getOpenLettersSettings)
  const isClientChanged = useSelector(clientSelectors.getIsClientChanged)

  const [filter, setFilter] = useState<FilteredValueType>('DEFAULT')
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const { isNotFoundClient } = useLeadControl()

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as FilteredValueType
    setFilter(value)
    clientId && dispatch(letterThunks.fetchClientMessageFeed({
      clientId,
      params: {
        page: 1,
        per_page: 20,
        filter: value
      },
      isFilter: true
    }))
  }

  useEffect(() => {
    if (
      isOpenFailedLetters
      || isOpenPlannedLetters
      || isClientChanged
    ) setFilter('DEFAULT')
  }, [isOpenFailedLetters, isOpenPlannedLetters, isClientChanged])


  return (
    <FormControl>
      <Select
        value={filter}
        onChange={handleChange}
        disabled={!isOpenAllLetters || isNotFoundClient }
        classes={{
          root: classes.root,
          icon: classes.icon

        }}
        disableUnderline
      >
        <MenuItem value='' disabled>
          Фильтр
        </MenuItem>
        <MenuItem
          value='ALL'>
          Вся лента
        </MenuItem>
        <MenuItem
          value='DEFAULT'>
          Сообщения, заметки, смс
        </MenuItem>
        <MenuItem
          value='LETTER'>
          Сообщения
        </MenuItem>
        <MenuItem
          value='NOTE'>
          Заметки
        </MenuItem>
        <MenuItem
          value='ACTION'>
          Информационные сообщения
        </MenuItem>
        {/*TODO убрано, так как не реализованы звонки*/}
        {/*<MenuItem*/}
        {/*  value='CLS'>*/}
        {/*  Все звонки*/}
        {/*</MenuItem>*/}
        <MenuItem
          value='SMS'>
          СМС
        </MenuItem>
      </Select>
    </FormControl>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 100,
      border: '1px solid rgba(0,0,0,.1)',
      borderRadius: 3,
      backgroundColor: 'white',
      padding: 3,
      transition: 'all .3s',
      '&:hover': {
        borderColor: 'blue',
        borderRadius: '3px',
        color: 'blue'
      },
      '&.MuiSelect-select.Mui-disabled': {
        color: 'rgba(0,0,0,.1)',
        '&:hover': {
          borderColor: 'rgba(0,0,0,.1)',
          color: 'rgba(0,0,0,.1)'
        }
      }
    },
    icon: {
      fill: 'rgba(0,0,0,.1)'
    }
  })
)