import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { CommonChatListResponse } from '../types/chats'
import { prepareFilterParams } from '../../../../../Api/API'
import { chatsActions } from '../slice/chatsSlice'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'


export const fetchGlobalChats = createAsyncThunk<CommonChatListResponse,
    PaginationParamsType | undefined,
    ThunkConfig<any>>(
    'chats/fetch_global_chats',
    async (params, thunkApi) => {
        const { dispatch, getState, extra, rejectWithValue } = thunkApi
        const globalChatList = getState().chats.globalChatList
        !globalChatList && dispatch(chatsActions.setIsFetchData(true))
        try {
            const response = await extra.api().get('chats/index?' + prepareFilterParams(params))
            params && dispatch(chatsActions.setParams(params))
            return response.data
        } catch (e) {
            handleServerNetworkError(e, dispatch, 'Не удалось получить список чатов')
            return rejectWithValue(e)
        } finally {
            dispatch(chatsActions.setIsFetchData(false))
        }
    }
)