export const emailStatusMapValues = {
  NEW: 'Новый',
  WORKING: 'Рабочий',
  NOT_WORKING: 'Не рабочий',
  BAD_PROXY_NEW: 'Не рабочий прокси',
  BAD_PROXY_WORKING: 'Не рабочий прокси',
  BAD_PROXY_NOT_WORKING: 'Не рабочий прокси',
};


export type StatusEmailKeyType = 'NEW' | 'WORKING' | 'NOT_WORKING' | 'BAD_PROXY_NEW' | 'BAD_PROXY_WORKING' | 'BAD_PROXY_NOT_WORKING'
