import { Dispatch, SetStateAction, useEffect, useState } from 'react'

export type PointsType = {
  x: number
  y: number
}

type ReturnType = {
  clicked: boolean
  setClicked: Dispatch<SetStateAction<boolean>>
  points: PointsType
  setPoints: Dispatch<SetStateAction<PointsType>>
  //checkActualMessageId: (currentId?: number, activeId?:number) => boolean
  // setId: Dispatch<SetStateAction<number | undefined>>
}

const defaultPoints: PointsType = {
  x: 0,
  y: 0
}

export const useContextMenu = ():ReturnType => {

  const [clicked, setClicked] = useState<boolean>(false)
  const [points, setPoints] = useState<PointsType>(defaultPoints)
  useEffect(() => {
    const handleClick = () => setClicked(false)
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])


  return {
    clicked,
    points,
    setClicked,
    setPoints,
  }
}