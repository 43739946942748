import { FC, memo, MouseEventHandler, ReactNode, useCallback } from 'react'
import { Box, IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useStyles } from '../styles'
import { useSelector } from 'react-redux'
import { ToastVariant } from '../../../../Redux/notificationsReducer'
import { useAppDispatch } from '../../../StoreProvider/hooks/useAppDispatch'
import { Action } from 'redux'
import { notificationsSelectors } from '../../../StoreProvider/slices/notitficationsSlice'

interface ToastNoTitleProps {
    className: string
    onToggle: MouseEventHandler<HTMLButtonElement>
    children?: ReactNode
    variant: ToastVariant
    actionCallback?: Action

}

export const ToastNoTitle: FC<ToastNoTitleProps> = memo(({className, onToggle, variant, actionCallback,children}) => {
    const disableActionCallback = useSelector(notificationsSelectors.getNotificationDisableActionCallback)

    const cls = useStyles({variant, disableActionCallback})
    const dispatch = useAppDispatch()

    const actionCallbackHandler = useCallback((): void => {
       actionCallback &&  dispatch(actionCallback)
    }, [actionCallback, dispatch])

    return (
        <Box className={className}>
            <Box className={cls.toastBody}>
                <Typography variant="caption">{children}</Typography>
                <IconButton onClick={onToggle} className={cls.closeIconButton}>
                    <CloseIcon className={cls.closeIcon}/>
                </IconButton>
            </Box>
            {actionCallback && (
                <Box className={cls.toastActions}>
                    <Box className={cls.toastAction}>
                        <Typography onClick={actionCallbackHandler}>Перейти</Typography>
                    </Box>
                </Box>
            )}
        </Box>
    )
})