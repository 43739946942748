import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@material-ui/core'
import { useStyles } from '../styles'
import { ToastNotification } from '../ToastNotification'
import { notificationsSelectors } from '../../../StoreProvider/slices/notitficationsSlice'

export const Notifications:FC = () => {

    const notifications = useSelector(notificationsSelectors.getNotifications)

    const cls = useStyles({})
    return (
        <Box className={cls.root}>
            {notifications.map(({title, message, variant, id, expirable, actionCallback})=> (
                <ToastNotification
                    key={id}
                    variant={variant}
                    title={title}
                    id={id}
                    expirable={expirable}
                    actionCallback={actionCallback}

                >
                    {message}
                </ToastNotification>
            ))}
        </Box>
    );
};