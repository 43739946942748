import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import * as queryString from 'query-string'
import { IFullClientData } from '../../../../../types'

type RequestPropsType = {
    ids: string[]
    project_ids: string[]
    isReplaceByMacros?: boolean
}

export const fetchClientByProjectForCopy = createAsyncThunk<
    IFullClientData[],
    any,
    ThunkConfig<any>>(
    'client/fetch_clients_by_projects_for_copy',
    async (params, {dispatch, rejectWithValue, extra}) => {
        params.ids = params.project_ids.join(',');
        delete params.project_ids;
        const query = queryString.stringify(params);
        try {
           const res = await extra.api().get<IFullClientData[]>(`clients/projects?${query}`)
            return res.data
        } catch (e) {
            handleServerNetworkError(e, dispatch, 'Не удалось получить клиентов')
            return rejectWithValue({})
        }
    }
)