import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { clientsActions } from '../slice/clientsSlice'

export const unSnoozeClient = createAsyncThunk<
  {},
  number | undefined,
  ThunkConfig<any>>(
  'client/unSnooze_client',
  async (clientId, thunkApi) => {
    const { dispatch, rejectWithValue, extra } = thunkApi

    if (clientId) {
      try {
        await extra.api().patch(`client/${clientId}/unsnooze`)
        // dispatch(snackActions.setSnack({
        //   label: 'Клиент успешно добавлен в активные',
        //   typeLabel: 'success'
        // }))
        //dispatch(clientsActions.unSnoozeClient(clientId))
      } catch (e) {
        handleServerNetworkError(e, dispatch, 'Не удалось добавить клиента в активные')
        return rejectWithValue({})
      }
    }
  }
)