import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { proxyActions } from '../slice/proxySlice'


export const checkProxy = createAsyncThunk<{},
  string,
  ThunkConfig<any>>(
  'proxy/check_proxy',
  async (proxy, { dispatch, rejectWithValue, extra }) => {

    dispatch(proxyActions.setCheckResult(true))
    try {
      const response = await extra.api().post(`proxy/check`, { proxy })
      dispatch(proxyActions.setProxyErrors(response.data))
      dispatch(snackActions.setSnack({
        label: 'Прокси рабочий',
        typeLabel: 'success'
      }))
    } catch (e: any) {
      if (e.response.data) {
        dispatch(proxyActions.setProxyErrors(e.response.data[0]))
      }
      handleServerNetworkError(e, dispatch, 'Прокси не рабочий')
      return rejectWithValue({})
    } finally {
      dispatch(proxyActions.setCheckResult(false))
    }
  }
)