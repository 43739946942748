import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { ProjectUserDeleteDataDto } from '../types/projectUser'
import { projectUserActions } from '../slice/projectUserSlice'
import {
  fetchOrderManagers,
  fetchClientManagers,
  fetchProjectManagers,
  fetchProjectUsersWithoutRole
} from '../services'
import { snackActions } from '../../snackSlice'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'


type RequestProps = {
  managerId: number,
  data: ProjectUserDeleteDataDto
}

export const deleteManager = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'projectUser/delete_manager',
  async (dto, { dispatch, rejectWithValue, getState, extra }) => {

    const { managerId, data } = dto

    const projectId = getState().projectUser.project_id
    dispatch(projectUserActions.setIsDelete(true))
    try {
      await extra.api().delete(`project-user/${managerId}`, { data: { ...data } })

      if (data.role === 'PROJECT_MANAGER') {
        await dispatch(fetchProjectManagers({projectId: data.projectId}))
      } else if (data.role === 'CLIENT_MANAGER') {
        await dispatch(fetchClientManagers({projectId: data.projectId}))
      } else if (data.role === 'ASSISTANT_OPERATOR') {
        console.log('ASSISTANT_OPERATOR', data.projectId)
        await dispatch(fetchClientManagers({projectId: data.projectId}))
      } else {
        projectId && await dispatch(fetchOrderManagers({projectId}))
      }

      /** Список свободных пользователей должен обновляться после каждого изменения */
      await dispatch(fetchProjectUsersWithoutRole(data.projectId))

      data.role === 'ASSISTANT_OPERATOR'
        ? dispatch(snackActions.setSnack({
          label: 'Помощник был успешно удален',
          typeLabel: 'success'
        }))
        : dispatch(snackActions.setSnack({
          label: 'Менеджер был успешно удален',
          typeLabel: 'success'
        }))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось удалить пользователя')
      return rejectWithValue({})
    } finally {
      dispatch(projectUserActions.setIsDelete(false))
    }
  }
)