import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { MailBoxForSelectType } from '../types/mailboxForDeal'

export const fetchMailBoxForDealForSelect = createAsyncThunk<MailBoxForSelectType[],
  number,
  ThunkConfig<any>>(
  'mailboxForDeal/fetch_mailbox_for_deal_for_select',
  async (projectId, { dispatch, rejectWithValue, extra }) => {
    try {
      const response = await extra.api().get<MailBoxForSelectType[]>(`me-mailboxes-for-deal-for-select/project/${projectId}`)
      return response.data
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить список емэйлов')
      return rejectWithValue({})
    }
  }
)