import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { prepareFilterParams } from '../../../../../Api/API'
import { callsActions } from '../slice/callSlice'
import { smsActions } from '../../smsSlice'

type ResponseType = {
    
}

type ApiResponseType = {
    clientId: number,
    lastCall: string | null,
    lastManagerSms: string | null,
    lastClientSms: string | null
}

type RequestPropsType = {
    cli: number
    params?: PaginationParamsType
}

export const getLastCallNumbers = createAsyncThunk<
    void,
    number,
    ThunkConfig<any>
>(
    'call/get_last_call_numbers',
    async (clientId, { extra, dispatch }) => {
        try {
            const res = await extra.api().get<ApiResponseType>(`last_call/${clientId}`)
            dispatch(callsActions.setLastCallNumber({
                clientId: res.data.clientId,
                lastCallNumber: res.data.lastCall
            }))
            dispatch(smsActions.setLastSmsNumbers({
                clientId: res.data.clientId,
                lastClientSmsNumber: res.data.lastClientSms,
                lastManagerSmsNumber: res.data.lastManagerSms
            }))
        } catch (e) {
            handleServerNetworkError(e, dispatch, 'Не удалось получить список последних звонков и смс')
        } finally {
            
        }
    }
    
)