import { FC, useRef, useState } from 'react'
import ScrollBar from 'react-perfect-scrollbar'

import { Box, createStyles, Fab, Typography } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

import { LettersHistory } from './LettersHistory/LettersHistory'
import { LetterSubject } from './LetterSubject/LetterSubject'
import { LetterInfoTargetType } from '../../../../../../../providers/StoreProvider/slices/lettersSlice'


type ExpandedRowLetterPropsType = {
  clientLetter: any
  target: LetterInfoTargetType | string
  letterId: number
};

export const ExpandedRowLetter: FC<ExpandedRowLetterPropsType> = ({ target, clientLetter, letterId }) => {

  const [showButton, setShowButton] = useState<boolean>(false)

  const classes = useStyles()

  let scrollBarRef = useRef<any>(null)

  const scrollToTop = () => {
    const curr = scrollBarRef.current
    if (curr) {
      curr.scrollTop = 0
    }
  }
  const scrollY = (container: HTMLElement) => {
    container.scrollTop > 400 ? setShowButton(true) : setShowButton(false)
  }

  const createData = (recipient: string, name: JSX.Element | string) => {
    return { recipient, name }
  }

  const rows = [
    createData('Кому:', target === 'client' ? clientLetter && clientLetter.to : clientLetter && clientLetter.recipient),
    createData('От:', target === 'client' ? clientLetter && clientLetter.from : clientLetter && clientLetter.sender),
    createData('Тема:', target === 'client' ? clientLetter && clientLetter.subject : clientLetter && clientLetter.header),
    createData('Дата:', target === 'client' ? clientLetter && clientLetter.date : clientLetter && clientLetter.postDate),
    createData('Текст сообщения:', <LetterSubject 
                                      message={clientLetter && clientLetter.message}
                                      attachedFiles={clientLetter && clientLetter.attachedFiles}
                                    />),
    createData('',
      <LettersHistory 
        clientLetterId={clientLetter && clientLetter.clientId} 
        clientLetterHeader={clientLetter && clientLetter.header} 
      />)
  ]

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.header}>
        <Typography style={{ fontSize: '22px', fontWeight: 500 }}>Детализация письма</Typography>
      </Box>
      {showButton &&
      <Fab className={classes.scrollTop_button}
           size='small'
           onClick={scrollToTop}
      >
        <KeyboardArrowUpIcon />
      </Fab>
      }

      <StyledTableContainer>
        <ScrollBar containerRef={(el) => scrollBarRef.current = el}
                   onScrollY={(container) => scrollY(container)}>
          <Table className={classes.table} aria-label='simple table'>
            <TableBody>
              {rows.map((row, i) => (
                <StyledTableRow key={row.recipient + '-' + i}>
                  <StyledTableCell className={classes.cell}>
                    {row.recipient}
                  </StyledTableCell>
                  <StyledTableCell align='left' className={classes.content_cell}>
                    {row.name}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </ScrollBar>
      </StyledTableContainer>
    </Box>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      maxWidth: 900,
      position: 'relative'
    },
    header: {
      height: '57px',
      padding: '15px',

      display: 'flex',
      justifyContent: 'center'
    },
    table: {
      minWidth: 900,
      margin: '15px 0',
      padding: 5,
      borderRadius: '0 !important',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    cell: {
      fontWeight: 500,
      minWidth: 215
    },
    content_cell: {
      minWidth: 675
    },
    link: {
      color: '#334bfa',
      textDecoration: 'none',
      cursor: 'pointer',
      fontSize: '14px'
    },
    scrollTop_button: {
      position: 'absolute',
      top: 80,
      left: '94%',
      zIndex: 9999
    }
  })
)

const StyledTableCell = withStyles(() =>
  createStyles({
    root: {
      padding: '9px 15px',
      fontSize: 14,
      borderBottom: '0.5px solid rgba(0,0,0,.1)',
    }
  })
)(TableCell)

const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      minHeight: '30px !important',
      verticalAlign: 'top',
      '&:last-child td, &:last-child th': {
        borderBottom: 'none',
      },
    }
  })
)(TableRow)

const StyledTableContainer = withStyles(() =>
  createStyles({
    root: {
      height: 'calc(100vh - 110px)',
      borderRadius: 0,
      backgroundColor: 'white'
    }
  })
)(TableContainer)

