import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { WigetType } from '../../../../../Redux/widgetReducer'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'


//Todo не юзается логика
export const updateWidgets = createAsyncThunk<WigetType[],
  any, // todo types
  ThunkConfig<any>>(
  'widgets/update_widgets',
  async (widgetsData, { dispatch, rejectWithValue, extra }) => {

    try {
      const res = await extra.api().patch('user-widgets', widgetsData)

      return  res.data
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось обновить виджеты')
      return rejectWithValue({})
    } finally {
    }
  }
)