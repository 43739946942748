import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { EditUserDataDto } from '../types/projectUser'
import { projectUserActions } from '../slice/projectUserSlice'


type RequestProps = {
  projectUserId: number,
  data: EditUserDataDto
}

export const editUser = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'projectUser/edit_user',
  async (dto, { dispatch, rejectWithValue, extra }) => {

    const { projectUserId, data } = dto
    dispatch(projectUserActions.setIsEdit(true))
    try {
      await extra.api().patch(`project-user/${projectUserId}`, data)

        dispatch(snackActions.setSnack({
          label: 'Менеджер был успешно изменен',
          typeLabel: 'success'
        }))


    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось изменить данные пользователя')
      return rejectWithValue({})
    } finally {
      dispatch(projectUserActions.setIsEdit(false))
    }
  }
)