import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import * as queryString from 'query-string'
import { MyClientsDataResponse } from '../types/clients'


interface RequestProps {
  projectId: number,
  params: any // todo types
}

type ReturnFullFilledResponse = {
  data: MyClientsDataResponse
  lastProjectId: number
}

export const fetchMyClients = createAsyncThunk<
  ReturnFullFilledResponse,
  RequestProps,
  ThunkConfig<any>>(
  'client/fetch_my_clients',
  async (data, thunkApi) => {
    const { dispatch, rejectWithValue, extra } = thunkApi
    const { projectId, params } = data
    delete params.project_id // todo убрать project_id из реквеста
    const query = queryString.stringify(params);
    try {
      const response = await extra.api().get(`my-clients/project/${projectId}?${query}`)
      return { data: response.data, lastProjectId: projectId }
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить клиентов')
      return rejectWithValue({})
    }
  }
)