import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { ManagerLetterDataType } from '../types/managerLetter'
import { CommonLetterType, lettersActions } from '../../lettersSlice'
import { clientsActions } from '../../clientsSlice'
import { AxiosError } from 'axios'


type RequestProps = {
  data: ManagerLetterDataType,
  clientId?: number
  //project_id?: number
}

export const createManagerReply = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'managerLetter/create_manager_reply',
  async (request, { dispatch, rejectWithValue, extra }) => {

    const {
      data,
      //project_id,
    } = request

    try {
      const response = await extra.api().post<CommonLetterType[]>('manager-letter', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      if (data.snooze) {
        dispatch(snackActions.setSnack({
          label: 'Клиент отправлен в "Отложенные"',
          typeLabel: 'success'
        }))
        // project_id && await dispatch(fetchClientsByProject({ projectId: project_id }))
      } else {
        dispatch(lettersActions.setLetter({ letter: response.data }))
        const hasEmptyMacrosInClientMessage = response.data.find(letter => letter.hasEmptyMacrosInMessages)
        if (hasEmptyMacrosInClientMessage && hasEmptyMacrosInClientMessage.clientId) {
          dispatch(
            clientsActions.setEmptyMacros({ clientId: hasEmptyMacrosInClientMessage.clientId, status: true })
          )
        }
      }
      dispatch(snackActions.setSnack({
        label: 'Письмо принято в отправку',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Произошла ошибка при отправлении письма')
      return rejectWithValue((e as AxiosError)?.response?.data)
    }
  }
)