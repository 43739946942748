import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { snackActions } from '../../snackSlice'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { clientsActions } from '../slice/clientsSlice'


export const acceptClient = createAsyncThunk<{},
  number,
  ThunkConfig<any>>(
  'client/accept_client',
  async (id, thunkApi) => {
    const { dispatch, extra } = thunkApi
    try {
      await extra.api().post(`client/${id}/accept`)
      dispatch(clientsActions.setIsAccepted(true)) //Todo wtf ?
      dispatch(clientsActions.setIsAccepted(false)) //Todo wtf ?
      dispatch(snackActions.setSnack({
        label: 'Клиент успешно добавлен в активные',
        typeLabel: 'success'
      }))
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось добавить клиента в активные')
    }
  }
)