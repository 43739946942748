import { FC, ReactNode } from 'react'
import { Notifications } from './Notifications/Notifications'


interface NotificationsProviderProps {
  children: ReactNode
}

export const NotificationsProvider: FC<NotificationsProviderProps> = ({ children }) => {

  return (
    <>
      <Notifications />
      {children}
    </>
  )
}