import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { SpreadSheetDataType } from '../types/projectSpreedSheet'


type RequestProps = {
  projectId: number,
  source: number
}

type FullFilledResponse = {
  data: SpreadSheetDataType
  source: number
}


export const fetchSpreadSheetData = createAsyncThunk<FullFilledResponse,
  RequestProps,
  ThunkConfig<any>>(
  'spreadSheets/fetch_spread_sheet_data',
  async (dto, { dispatch, rejectWithValue, extra }) => {

    const { projectId, source } = dto

    try {
      const res = await extra.api().get<SpreadSheetDataType>(`/project/${projectId}/spreadsheets/${source}/show`)
      return { data: res.data, source }

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить данные. Проверьте адрес таблицы и прокси')
      return rejectWithValue({})
    }
  }
)