import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { FetchChainsResponse } from '../types/chains'
import { prepareFilterParams } from '../../../../../Api/API'
import { chainsActions } from '../slice/chainsSlice'
import { appActions } from '../../appSlice'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'


export const fetchPublicChains = createAsyncThunk<FetchChainsResponse, // Todo typing response
  PaginationParamsType | undefined,
  ThunkConfig<any>>(
  'chains/fetch_public_chains',
  async (params, { dispatch, getState, rejectWithValue, extra }) => {

    const publicChains = getState().chains.publicChainList
    !publicChains && dispatch(chainsActions.setIsFetchPublicChainsData(true))

    try {
      const response = await extra.api().get('chains-letters-public?' + prepareFilterParams(params))
      params && dispatch(chainsActions.setPublicChainsParams(params))

      return response.data
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Невозможно загрузить публичные цепочки')
      return rejectWithValue({})
    } finally {
      dispatch(appActions.setIsLoad(false))
      dispatch(chainsActions.setIsFetchPublicChainsData(false))
    }
  }
)