import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationsSchema, ToastVariant } from '../types/notifications'
import { uuid } from "uuidv4";


const initialState: NotificationsSchema = {
  notifications: [],
  renderedIds: [],
  noTimeout: [],
  disableActionCallback: false
}


export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    disableNotificationActionCallback: (state, action: PayloadAction<boolean>) => {
      state.disableActionCallback = action.payload
    },
    deleteNotification: (state, action: PayloadAction<string>) => {
     state.notifications = state.notifications.filter(n => n.id !== action.payload)
      state.renderedIds = state.renderedIds.filter((index) => index !== action.payload)
    },
    stopTimeout: (state, action: PayloadAction<string>) => {
      state.noTimeout = [...state.noTimeout, action.payload]
    },
    setRenderedIds: (state, action: PayloadAction<string>) => {
      state.renderedIds = [...state.renderedIds, action.payload]
    },
    addNotification: (state, action: PayloadAction<{
                        message: string | JSX.Element
                        title: string,
                        variant: ToastVariant,
                        actionCallback?: any
                      }>
    ) => {
      const id = uuid().slice(0, 8)
      state.notifications = [...state.notifications, { id, ...action.payload }]
    }
  }
})

export const { actions: notificationsActions } = notificationsSlice
export const { reducer: notificationsReducer } = notificationsSlice
