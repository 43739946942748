export type StatusLeadMapType = {
    NEW: string
    ACCEPTED: string
    DECLINED: string
    WAITING_FOR_APPROVAL_DECLINE: string
    TRUSTED: string

}

export type StatusLeadKeyType =
    'NEW'
    | 'ACCEPTED'
    | 'DECLINED' /**| 'ARCHIVED' */
    | 'WAITING_FOR_APPROVAL_DECLINE'
    | 'TRUSTED'

export const statusLeadMap: StatusLeadMapType = {
    NEW: 'Новый',
    ACCEPTED: 'Принятый',
    DECLINED: 'Отказ',
    WAITING_FOR_APPROVAL_DECLINE: 'Ожидает подтверждения отказа', //'Ждёт подтверждения удаления'
    TRUSTED: 'Входящий'
}

export const statusLeadMapForGlobalLeads = {
    NEW: 'Новый',
    TRUSTED: 'Входящий',
    ACCEPTED: 'Принятый',
    WAITING_FOR_APPROVAL_DECLINE: 'Ожидает подтверждения отказа', //'Ждёт подтверждения удаления'
    DECLINED: 'В архиве', // Удален'
}

export const declineReasons: Record<string, string> = {
    '': 'Своя',
    'Другое': 'Другое',
    'Молчит': 'Молчит',
    'Неизвестно': 'Неизвестно',
    'Отказ': 'Отказ',
    'Отказ лида': 'Отказ лида',
    'Отказ оператором': 'Отказ оператором',
}