import { makeStyles } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      cursor: 'pointer',
      fill: 'grey',
    },

  })
)
