import {Box, Typography} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Draggable from 'react-draggable'
import CloseIcon from '@material-ui/icons/Close'
import {useSelector} from 'react-redux'
import {Call} from '@twilio/voice-sdk'
import {callsSelectors, callsThunks} from '../../../providers/StoreProvider/slices/callSlice'
import {makeStyles} from "@material-ui/core/styles";
import {Icon} from "../../../ui-kit";
import { useAppDispatch } from '../../../providers/StoreProvider/hooks/useAppDispatch'


type IncomingCallPropsType = {
    setIncoming: React.Dispatch<React.SetStateAction<boolean>>
    setCallParams: React.Dispatch<Call>
    call?: Call
}

const IncomingCall: React.FC<IncomingCallPropsType> = (props) => {
    const dispatch = useAppDispatch()
    const device = useSelector(callsSelectors.getDevice)
    const incomingCallClientData = useSelector(callsSelectors.getIncomingCallClientData)
    const activeCallData = useSelector(callsSelectors.getActiveCallData)

    const [isAnswered, setIsAnswered] = useState<boolean>(false)
    const [callTime, setCallTime] = useState<number>(0)
    useEffect(() => {
        if (callTime !== 0) {
            const interval = setInterval(() => {
                setCallTime(callTime+1);
              }, 1000);
          
              return () => clearInterval(interval);
        }
    }, [callTime])

    const cls = useStyles()
    
    const incomingExample = {
        AccountSid: "AC4bd0a2a45b7dcf57d4a9a49ee2fbb9e0",
        CallSid: "CA217992158f486804e6e37d465a2eb58e",
        From: "+375447383125",
        To: "client:+12543544790"
    }

    const onAnswer = () => {
        if (device && props.call) {
            console.log('Twilio.Device onAnswer', props.call)

            props.call.accept();

            activeCallData && dispatch(callsThunks.updateCall({
                callId: activeCallData.id,
                status: 'INITIATED',
                sid: props.call.parameters.CallSid
            }))

            setIsAnswered(true)
            setCallTime(1)
        }
    }

    const onCancel = () => {
        if (device && props.call) {
            console.log('CALL onCancel', props.call.disconnect)
            console.log('CALL activeCallData', activeCallData)

            // props.call.disconnect()
            props.call.ignore()
            // props.call.mute()
            
            props.setIncoming(false)

            activeCallData && dispatch(callsThunks.updateCall({
                callId: activeCallData.id,
                status: 'CANCELED',
                sid: props.call.parameters.CallSid
            }))
        }
    }

    const onSkip = () => {
        console.log('onSkip')
        setCallTime(0)
        if (device && props.call) {
            props.call.disconnect()
            props.setIncoming(false)
            activeCallData && dispatch(callsThunks.updateCall({
                callId: activeCallData.id,
                status: 'COMPLETED',
                sid: props.call.parameters.CallSid
            }))
        }
    }

    return (
        <Draggable
            handle="#handle"
            bounds="parent"
        >
            <Box className={cls.incoming_call}>
                <Box className={cls.header} id="handle">
                    <Typography className={cls.typography}>Входящий звонок</Typography>
                    <CloseIcon
                        className={cls.icon}
                        onClick={() => props.setIncoming(false)}/>
                </Box>
                <Box>
                    <Box className={cls.incomingCallDescription}>
                        <Typography>
                            {props.call?.parameters.From}
                        </Typography>
                        {incomingCallClientData
                            ?   <>
                                    <p>{`${incomingCallClientData.firstName} ${incomingCallClientData.lastName}`}</p>
                                    <p>Проект: {incomingCallClientData.projectId}</p>
                                </>
                            : <Typography component='span'>звонит вам...</Typography>
                        }
                        
                    </Box>
                    {!isAnswered ?
                        <Box className={cls.actions}>
                            <Box className={`${cls.callBox} ${cls.cancel_call}`} onClick={onCancel} >
                                <Icon name='phone_filled' size={16}/>
                            </Box>
                            <Box className={`${cls.callBox} ${cls.apply_call}`}
                                onClick={onAnswer}>
                                <Icon name='phone_filled' size={16}/>
                            </Box>
                        </Box>
                        :
                        <Box className={cls.actions}>
                            <Box className={`${cls.callBox} ${cls.cancel_call}`} onClick={onSkip} >
                                <Icon name='phone_filled' size={16}/>
                            </Box>
                            {callTime}
                        </Box>
                    }
                    
                </Box>
            </Box>
        </Draggable>
    )
}

export default IncomingCall


const useStyles = makeStyles({
    incoming_call: {
        position: 'fixed',
        right: '36%',
        top: '35px',
        maxHeight: 260,
        width: 360,
        zIndex: 1110,
        display: 'grid',
        gridTemplateColumns: '1fr',
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        gridTemplateRows: '52px  minmax(260px, 1fr) auto',
        transitionProperty: 'opacity, height, width',
        transitionDuration: '0.4s',
        transitionTimingFunction: 'ease',
        overflow: 'auto'
    },
    header: {
        position: 'relative',
        padding: '0 10px',
        borderBottom: '0.5px solid rgba(0,0,0,.1)',
        display: 'grid',
        gridTemplateColumns: '1fr 20px',
        gap: '8px',
        alignItems: 'center',
        justifyItems: 'center',
        cursor: 'move'
    },
    incomingCallDescription: {
        width: '100%',
        textAlign: 'center',
        marginBottom: 10,
        '& > p': {
            fontSize: 16,
            fontWeight: 500
        },
        '& > span': {
            fontSize: 12
        }
    },
    typography: {
        fontSize: '17px',
        lineHeight: '22px',
        fontWeight: 600,
        cursor: 'pointer'
    },
    icon: {
        padding: 3,
        color: 'gray',
        cursor: 'pointer',
        '&:hover': {
            color: '#5383ff',
            transition: 'all .3s',
            transform: 'scale(0.9)'
        }
    },
    actions: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        gap: 10
    },
    callBox: {
        width: '40px',
        height: '40px',
        borderRadius: '100%',
        cursor: 'pointer',
        position: 'relative',
        border: 'solid 1px rgb(252, 241, 241)',
        '& > svg': {
            position: 'absolute',
            top: '11px',
            left: '11px',
            fill: '#fff'
        }
    },
    apply_call: {
        background: '#2aea15',
        animation: '$call 1.5s ease infinite',
    },
    cancel_call: {
        background: '#ff353a',
        '& > svg': {
            transform: 'rotate(135deg)',
        }
    },
    '@keyframes call': {
        '15%': {
            boxShadow: '0 0 0 2px rgba(166,165,165, 0.5)'
        },
        '25%': {
            boxShadow: '0 0 0 4px rgba(166,165,165, 0.5), 0 0 0 8px rgba(166,165,165, 0.3)',
        },
        '30%': {
            boxShadow: '0 0 0 8px rgba(166,165,165, 0.5), 0 0 0 16px rgba(166,165,165, 0.3))'
        }
    },
})

