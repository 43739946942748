import {createAsyncThunk} from '@reduxjs/toolkit'
import {ThunkConfig} from '../../../config/StateSchema'
import {handleServerNetworkError} from '../../../../../helpers/error-helpers'
import {snackActions} from '../../snackSlice'
import {IClientForProject} from "../../../../../types";

type RequestProps = {
  projectId: number,
  clients: any[] // types
}

export const insertClients = createAsyncThunk<IClientForProject[],
  RequestProps,
  ThunkConfig<any>>(
  'client/insert_clients',
  async (data, thunkApi) => {
    const { dispatch, rejectWithValue, extra } = thunkApi
    const { clients, projectId } = data
    try {
      const { data } = await extra.api().post(`project/${projectId}/new-clients`, { clients })
      if (data.notices.length === 0) {
        dispatch(snackActions.setSnack({
          label: 'Клиенты успешно добавлены',
          typeLabel: 'success'
        }))
        return data.newClients
      } else {
        data.notices.forEach((el: string) => (
          dispatch(snackActions.setSnack({
            label: el,
            typeLabel: 'error'
          }))
        ))
      }
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось загрузить клиентов')
      return rejectWithValue({})
    }
  }
)