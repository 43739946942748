import { ReactNode } from 'react'
import { createReduxStore } from '../config/store'
import { Provider } from 'react-redux'
import { StateSchema } from "../config/StateSchema";

interface StoreProviderProps {
    children: ReactNode
    initialState?: DeepPartial<StateSchema>
}

export const StoreProvider = ({ children, initialState }: StoreProviderProps) => {

    //const navigate = useNavigate()

    const store = createReduxStore(initialState as StateSchema)

    return (
        <Provider store={store}>
            {children}
        </Provider>
    )
}