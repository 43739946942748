import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProxyErrorType, ProxySchema } from '../types/proxy'


const initialState: ProxySchema = {
  checkStatus: false,
}

export const proxySlice = createSlice({
  name: 'proxy',
  initialState,
  reducers: {
    setCheckResult: (state, action: PayloadAction<boolean | undefined>) => {
      state.checkStatus = action.payload
    },
    setProxyErrors: (state, action: PayloadAction<ProxyErrorType | undefined>) => {
      state.proxyErrors = action.payload
    }
  },
  extraReducers: (builder) => {

  }

})

export const { actions: proxyActions } = proxySlice
export const { reducer: proxyReducer } = proxySlice
