import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { ProjectForCurrentItemType } from '../../../../../Api/fragmentApi' // todo change import
import { appActions } from '../../appSlice'

type RequestProps = {
  id: number,
  name?: string
}

type FullFilledResponse = {
  items: ProjectForCurrentItemType[]
}

export const fetchProjectListForCurrentFragment = createAsyncThunk<FullFilledResponse,
  RequestProps,
  ThunkConfig<any>>(
  'fragments/fetch_project_list_for_current_fragment',
  async (data, thunkApi) => {
    const { dispatch, rejectWithValue, extra } = thunkApi
    const { id, name = '' } = data
    dispatch(appActions.setIsLoad(true))
    try {
      const response = await extra.api().get(`letter-fragment/${id}/projects?filter[name]=${name}`)
      console.log('response', response)
      return response.data
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось загрузить список проектов')
      return rejectWithValue({})

    } finally {
      dispatch(appActions.setIsLoadTable(false))
      dispatch(appActions.setIsLoad(false))
    }
  }
)