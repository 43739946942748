import { createAsyncThunk } from "@reduxjs/toolkit";
import { PaginationParamsType } from "../../../../../ui-kit/Table/types/query";
import { ThunkConfig } from "../../../config/StateSchema";
import { handleServerNetworkError } from "../../../../../helpers/error-helpers";
import { prepareFilterParams } from "../../../../../Api/API";
import { OperatorsStatistic, ProjectSalesFunnelStatistic } from "../types/operators";


export const fetchStatisticBySalesFunnel = createAsyncThunk<OperatorsStatistic<ProjectSalesFunnelStatistic[]>[],
    PaginationParamsType | undefined,
    ThunkConfig<any>>(
    'operators/fetch_statistic_by_sales_funnel',
    async (params, { dispatch, rejectWithValue, extra }) => {

        const query = params ? prepareFilterParams(params) : ''
        try {
           const response = await extra.api().get(`clients/statistics-by-sales-funnel?${query}`)
            return response.data
        } catch (e) {
            handleServerNetworkError(e, dispatch, 'Не удалось загрузить список операторов')
            return rejectWithValue({})
        }
    }
)

