import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { ChatType } from '../../../../../types'
import { MessageRequest } from '../../../../../Containers/UsersChat/Chat'

interface RequestProps {
  chatType: ChatType,
  message: MessageRequest,
  chatId?: number,
  clientId?: number
}

const config = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}

export const sendMessageInChat = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'chats/send_message_in_chat',
  async (data, thunkApi) => {
    const { chatType, message, chatId, clientId } = data
    const { dispatch, extra } = thunkApi
    const formData = getFormData(message)
    try {
      if (chatType === ChatType.CLIENT && clientId) await extra.api().post(`client-chat/${clientId}/message/create`, formData, config)
      if (chatType === ChatType.PRIVATE && chatId) await extra.api().post(`private-chat/${chatId}/message/create`, formData, config)
      if (chatType === ChatType.GENERAL) await extra.api().post(`general-chat/message/create`, formData, config)
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Произошла ошибка')
    }
  }
)



const getFormData = (data: MessageRequest) => {
  const formData = new FormData()
  for (let i = 0; i < data.files.length; i++) {
    formData.append('files[]', data.files[i])
  }
  formData.append('message', data.message)
  return formData
}