import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { PrivateChat } from '../../../../../types'
import { notificationsActions } from '../../notitficationsSlice'
import { chatsActions } from '../slice/chatsSlice'

interface RequestProps {
  chatId: number,
  isWsEvent: boolean
}


export const fetchPrivateChat = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'chats/fetch_private_chat',
  async (data, thunkApi) => {
    const { chatId, isWsEvent = false } = data
    const { dispatch, extra } = thunkApi
    dispatch(notificationsActions.disableNotificationActionCallback(true))
    try {
      const response = await extra.api().get<PrivateChat>(`private-chat/${chatId}/info`)
      if (isWsEvent) {
        dispatch(chatsActions.wsChatAddedAfterSend(response.data))
      } else {
        dispatch(chatsActions.openGroupChat(response.data))
      }
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Произошла ошибка')
    } finally {
      dispatch(notificationsActions.disableNotificationActionCallback(false))
    }
  }
)