import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { fetchChains } from './fetchChains'
import { fetchPublicChains } from './fetchPublicChains'
import { snackActions } from '../../snackSlice'
import { ChainType } from '../types/chains'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'

interface RequestProps {
  params: PaginationParamsType
  chain: ChainType
}

export const createChainLetter = createAsyncThunk<{},
  ChainType,
  // RequestProps,
  ThunkConfig<any>>(
  'chains/create_chain_letter',
  async (data, thunkApi) => {
    // const { params } = data
    const { dispatch, getState, extra } = thunkApi
    const myChainsParams = getState().chains.chainPagination
    const publicChainsParams = getState().chains.publicChainPagination
    try {
      await extra.api().post(`chain-letters`, data)
      dispatch(fetchChains(myChainsParams))
      dispatch(fetchPublicChains(publicChainsParams))

      dispatch(snackActions.setSnack({
        label: 'Цепочка успешно создана',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'При создании цепочки произошла ошибка')
    } finally {
    }
  }
)