import {createAsyncThunk} from '@reduxjs/toolkit'
import {ThunkConfig} from '../../../config/StateSchema'
import {handleServerNetworkError} from "../../../../../helpers/error-helpers";
import {AxiosError, AxiosResponse} from "axios";
import {RequestType} from "../../../../../Components/Inputs/FilesAttach/FilesAttach";


type ResponseType = [
    {
        0: string | URL | undefined
    }
]

export const fetchPdfFiles = createAsyncThunk<ResponseType,
    RequestType,
    ThunkConfig<AxiosError | string>>(
    'letters/fetch_pdf_files',
    async (files, {dispatch, rejectWithValue, extra}) => {
        try {
            const res = await extra.api().post<
                ResponseType,
                AxiosResponse<ResponseType>,
                RequestType>('files/preview',  files )
            return res.data
        } catch (e) {
            if (e instanceof AxiosError) {
                handleServerNetworkError(e, dispatch, 'Что-то пошло не так')
                return rejectWithValue(e)
            } else {
                return  rejectWithValue('')
            }
        } finally {
        }
    }
)