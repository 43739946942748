import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { EditTemplateData } from '../types/templates'
import { fetchTemplates } from './fetchTemplates'


export const createTemplate = createAsyncThunk<{},
  EditTemplateData,
  ThunkConfig<any>>(
  'templates/create_template',
  async (data, { dispatch, rejectWithValue, getState, extra }) => {

    const params = getState().templates.templatePagination

    try {
      await extra.api().post(`letter-template`, data)
      dispatch(fetchTemplates(params))

      dispatch(snackActions.setSnack({
        label: 'Шаблон успешно создан',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Произошла ошибка при создании шаблона')
      return rejectWithValue({})
    }
  }
)