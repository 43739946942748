import { createSlice } from '@reduxjs/toolkit'
import { ProjectLettersSettingsSchema } from '../types/projectLettersSettings'
import { fetchProjectFooter, fetchProjectHeader } from '../services'


const initialState: ProjectLettersSettingsSchema = {
  defaultValue: {}
}


export const projectLettersSettingsSlice = createSlice({
  name: 'project_letters_settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    //Todo пофиксить дублтрование
    builder.addCase(fetchProjectFooter.fulfilled, (state, { payload }) => {
      const defaultValue = state.defaultValue
      if (defaultValue[payload.projectId]) {
        defaultValue[payload.projectId][payload.name] = payload.data[payload.projectId][payload.name]
      } else {
        defaultValue[payload.projectId] = {...defaultValue[payload.projectId],
          footer: payload.data[payload.projectId][payload.name]
        }
      }
      state.defaultValue = defaultValue
    })
    builder.addCase(fetchProjectHeader.fulfilled, (state, { payload }) => {
      const defaultValue = state.defaultValue
      if (defaultValue[payload.projectId]) {
        defaultValue[payload.projectId][payload.name] = payload.data[payload.projectId][payload.name]
      } else {
        defaultValue[payload.projectId] = {...defaultValue[payload.projectId],
          header: payload.data[payload.projectId][payload.name]
        }
      }
      state.defaultValue = defaultValue
    })
  }
})

export const { actions: projectLettersSettingsActions } = projectLettersSettingsSlice
export const { reducer: projectLettersSettingsReducer } = projectLettersSettingsSlice
