import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProjectPhonesSchema } from '../types/projectPhones'
import { checkProviderStatusForCurrentPhone, fetchGlobalPhones, fetchProjectPhonesForSelect } from '../services'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'

const initialState: ProjectPhonesSchema = {
  // projectPhonesForSelect: [],
  isLoad: false,
  isAddPhoneToProjectUser: false,
  globalPhonesPagination: { per_page: 15, page: 1 }
}


export const projectPhonesSlice = createSlice({
  name: 'project_phones',
  initialState,
  reducers: {
    isAddPhoneToProjectUser: (state, action: PayloadAction<{ isAddPhoneToProjectUser: boolean, phoneId?: number }>) => {
      state.isAddPhoneToProjectUser = action.payload.isAddPhoneToProjectUser
      state.projectPhonesForSelect && state.projectPhonesForSelect.filter(phone => phone.id !== action.payload.phoneId)
    },
    setIsLoad: (state, action: PayloadAction<boolean>) => {
      state.isLoad = action.payload
    },
    setParams: (state, action: PayloadAction<PaginationParamsType>) => {
      state.globalPhonesPagination = action.payload
    },
    clearProjectPhones: (state) => {
      state.projectPhonesForSelect = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkProviderStatusForCurrentPhone.fulfilled, (state, { payload }) => {
      state.globalPhonesList?.map(phone => phone.id === payload.phoneId ? {
        ...phone,
        providerStatus: payload.status
      } : phone)
    })
    builder.addCase(fetchGlobalPhones.fulfilled, (state, { payload }) => {
      state.globalPhonesList = payload.items
      state.totalCount = payload.totalCount
    })
    builder.addCase(fetchProjectPhonesForSelect.fulfilled, (state, { payload }) => {
      state.projectPhonesForSelect = payload
    })
  }

})

export const { actions: projectPhonesActions } = projectPhonesSlice
export const { reducer: projectPhonesReducer } = projectPhonesSlice
