import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { fetchTemplates } from './fetchTemplates'
import { defaultPaginationParams } from '../slice/templatesSlice'


export const editTemplate = createAsyncThunk<{},
  any, // todo types
  ThunkConfig<any>>(
  'templates/edit_template',
  async (data, { dispatch, rejectWithValue, getState, extra }) => {

    const params = getState().templates.templatePagination

    data.newFiles = data.attachedFiles
    const { id, ...body } = data

    try {
      await extra.api().patch(`letter-template/${id}`, body)
      dispatch(fetchTemplates(!params.page ? defaultPaginationParams : params))

      dispatch(snackActions.setSnack({
        label: 'Шаблон успешно изменен',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Произошла ошибка при редактировании шаблона')
      return rejectWithValue({})
    }
  }
)