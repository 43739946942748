import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { fetchProjectMailFooter } from './fetchProjectMailFooter'


type FullFilledResponse = {
  projectId: number
  header: string
}

export const fetchProjectMailHeader = createAsyncThunk<FullFilledResponse,
  number,
  ThunkConfig<any>>(
  'projectLetterSetting/fetch_project_mail_header',
  async (projectId, { dispatch, rejectWithValue, extra }) => {
    try {
      const response = await extra.api().get<string>(`project/${projectId}/letters-setting/header`)
      await fetchProjectMailFooter()
      return { projectId, header: response.data }
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось загрузить верхнюю часть письма')
      return rejectWithValue({})
    }
  }
)