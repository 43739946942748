import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'

export const declineManagerLetter = createAsyncThunk<number,
  number,
  ThunkConfig<any>>(
  'letters/decline_manager_letter',
  async (letterId, { dispatch, rejectWithValue, extra }) => {

    try {
       await extra.api().post(`manager-letter/${letterId}/decline`)
        return letterId

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Произошла ошибка')
      return rejectWithValue({})
    } finally {}
  }
)