import { CircularProgress, IconButton, ListItem, ListItemIcon } from '@material-ui/core'
import React, { MouseEvent, useEffect } from 'react'
import Menu from '@material-ui/core/Menu'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Folder, FolderOpen, KeyboardArrowDown } from '@material-ui/icons'
import { useSelector } from 'react-redux'
import { getProjectsForSelect } from '../../providers/StoreProvider/slices/projectsSlice/selectors'
import { useAppDispatch } from '../../providers/StoreProvider/hooks/useAppDispatch'
import { projectThunks } from '../../providers/StoreProvider/slices/projectsSlice'
import { makeStaticOrLink } from './BreadCrumps'

const ProjectShowBreadcrumbItem: React.FC<{ isLink: boolean }> = (props) => {
    const { id_project } = useParams<{ id_project: string }>()
    const dispatch = useAppDispatch()
    const projectsForSelect = useSelector(getProjectsForSelect)

    const location = useLocation()

    useEffect(() => {
        !projectsForSelect && dispatch(projectThunks.fetchProjectsForSelect())
    }, [dispatch])

    const [anchorEl, setAnchorEl] = React.useState<Element | null | undefined>(null)

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <div style={{ display: 'flex' }}>
            {makeStaticOrLink(
                <span>
                    Просмотр проекта{' '}
                    {projectsForSelect && projectsForSelect.find(i => i.id === parseInt(id_project as string)) ? (
                        <b>
                            {'  ' + projectsForSelect.find(i => i.id === parseInt(id_project as string))
                                ? projectsForSelect.find(i => i.id === parseInt(id_project as string))?.name
                                : ''
                            }
                        </b>
                    ) : (
                        <CircularProgress disableShrink size={12}/>
                    )}
                </span>,
                <FolderOpen fontSize='small' className='icon'/>,
                !!props.isLink,
                '/project/' + id_project + '/leads_manage',
                'ProjectShowBreadcrumbItem',
                { from: `${location.pathname}` }
            )}

            <IconButton
                aria-label='more'
                aria-controls='long-menu'
                aria-haspopup='true'
                className='button_more'
                disabled={!projectsForSelect}
                size='small'
                onClick={handleClick}>
                <KeyboardArrowDown fontSize='small' className='button_icon'/>
            </IconButton>
            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
                {projectsForSelect &&
                projectsForSelect.map((project, index) => (
                    <ListItem
                        selected={project.id === parseInt(id_project as string)}
                        disabled={project.id === parseInt(id_project as string)}
                        key={project.id + 'a' + index}
                        onClick={handleClose}
                        component={Link}
                        state={ { from: `${location.pathname}` }}
                        to={'/project/' + project.id + '/leads_manage'}>
                        <ListItemIcon>
                            <Folder fontSize='small'/>
                        </ListItemIcon>
                        {project.name}
                    </ListItem>
                ))}
            </Menu>
        </div>
    )
}

export default ProjectShowBreadcrumbItem