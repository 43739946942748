import { makeStyles } from '@material-ui/core/styles'
import { PointsType } from '../hooks/useContextMenu'

export const useStyles = makeStyles<{}, PointsType>({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex:1200,
  },
  content: {
    position: 'absolute',
    width: '120px',
    backgroundColor: '#fff',
    borderRadius: '5px',
    boxSizing: 'border-box',
    zIndex: 100,
    top: points => points?.y,
    left: points => points?.x,
    boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)'
  },
  menuItem: {
    width: '100%',
    height: '30px',
    padding: '5px 10px',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(241,241,241,1)',
      borderRadius: '5px',
    }
  }
})