import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TemplatesSchema } from '../types/templates'
import {
  fetchGlobalLetterTemplatesForSelect,
  fetchProjectListForCurrentTemplate,
  fetchTemplateInfo,
  fetchTemplates, linkTemplateToCurrentProject
} from '../services'
import { getItemList } from '../../../helpers/helpers'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'

export const defaultPaginationParams: PaginationParamsType = { page: 1, per_page: 15 }

const initialState: TemplatesSchema = {
  isTemplateGet: false,
  targetTemplateId: null,
  templatePagination: defaultPaginationParams,
  globalTemplatesForSelect: [],
  template: null,
  isFetchData: false
}

export const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setIsFetch: (state, action: PayloadAction<boolean>) => {
      state.isFetchData = action.payload
    },
    setParams: (state, action: PayloadAction<PaginationParamsType>) => {
      state.templatePagination = action.payload
    },
    setTargetTemplateId: (state, action: PayloadAction<number | null>) => {
      state.targetTemplateId = action.payload
    },
    setIsTemplatesGet: (state, action: PayloadAction<boolean>) => {
      state.isTemplateGet = action.payload
    },
    setTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload
    },
    clearProjectListForTemplate: (state) => {
      state.projectListForCurrentTemplate = undefined
    },

  },
  extraReducers: (builder) => {
    builder.addCase(fetchGlobalLetterTemplatesForSelect.fulfilled, (state, action) => {
      state.globalTemplatesForSelect = action.payload
    })
    builder.addCase(fetchProjectListForCurrentTemplate.fulfilled, (state, action) => {
      state.projectListForCurrentTemplate = action.payload.items
    })
    builder.addCase(fetchTemplateInfo.fulfilled, (state, action) => {
      state.template = action.payload
    })
    builder.addCase(fetchTemplates.fulfilled, (state, action) => {
      state.templateList = action.payload.items
      state.totalCount = action.payload.totalCount
    })
    builder.addCase(linkTemplateToCurrentProject.fulfilled, (state, action) => {
      state.templateList = getItemList(state.templateList, action.payload)
    })
  }

})

export const { actions: templatesActions } = templatesSlice
export const { reducer: templatesReducer } = templatesSlice
