import React, { FC, MouseEvent, useState } from 'react'
import {
    Box,
    Button,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Popover,
    Select,
    TextField,
    Tooltip
} from '@material-ui/core'
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded'
import { makeStyles } from '@material-ui/core/styles'
import { steps } from './constants'
import { Alert, AlertTitle } from '@material-ui/lab'
import { SelectFilterType } from '../../Pages/LeadsManagePage/MainContentBlock/Leads/LeadHeader/HeaderBottom'
import { useAppDispatch } from '../../providers/StoreProvider/hooks/useAppDispatch'
import { clientThunks } from '../../providers/StoreProvider/slices/clientsSlice'
import { useLeadControl } from '../../Pages/LeadsManagePage/LeadsContext/lib/useLeadControl'

type FilterPropsType = {
    filterValue: string
    setFilterValue: (filterValue: string) => void
    selectFilter: SelectFilterType | string
    setSelectFilter: (selectFilter: SelectFilterType | string) => void
    project_id: number
};

export const Filter: FC<FilterPropsType> = ({
                                                project_id,
                                                filterValue,
                                                setFilterValue,
                                                selectFilter,
                                                setSelectFilter
                                            }) => {


    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

    const dispatch = useAppDispatch()

    const open = Boolean(anchorEl)
    const popoverId = open ? 'filter-popover' : undefined

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)

    const handleClose = () => setAnchorEl(null)
    const { resetState, updateSearch, currentClient } = useLeadControl()

    const disabled = currentClient && currentClient.status !== 'ACCEPTED'
    const classes = useStyles({ disabled })

    const updateFilterParams = (isUpdate: boolean) => {
        if (isUpdate) {
            resetState?.()
            updateSearch?.(undefined, undefined, selectFilter, filterValue)
            dispatch(clientThunks.fetchClientsByProject({
                projectId: project_id,
                filterName: selectFilter,
                filterValue
            }))
        } else {
            setFilterValue('')
            setSelectFilter('')
            resetState?.()
            updateSearch?.()
            dispatch(clientThunks.fetchClientsByProject({
                projectId: project_id,
                filterName: '',
                filterValue: ''
            }))
        }
        handleClose()
    }

    const getValuesForSelect = (data: any) => {
        const response: JSX.Element[] = []
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                if (key !== 'NEW') response.push(
                    <MenuItem value={key} key={key}>
                        {data[key]}
                    </MenuItem>
                )
            }
        }
        return response
    }

    const renderFilterInput = (): JSX.Element | undefined => {
        if (selectFilter === '') return
        /** Убрали статусы, потому что в работе с лидами по умолчанию показывает только ACCEPTED */
        // if (selectFilter === 'status')
        //     return (
        //         <FormControl size={'small'} fullWidth style={{ padding: 4 }}>
        //             <InputLabel id='filter-label' shrink>Статус</InputLabel>
        //             <Select
        //                 margin='dense'
        //                 labelId='filter-label'
        //                 id='filter-select'
        //                 displayEmpty
        //                 value={filterValue}
        //                 onChange={(ev) => {
        //                     setFilterValue(ev.target.value as string)
        //                 }}
        //             >
        //                 {getValuesForSelect(statusesClient)}
        //             </Select>
        //         </FormControl>
        //     )

        if (selectFilter === 'salesFunnelStep')
            return (
                <FormControl size={'small'} fullWidth style={{ padding: 4 }}>
                    <InputLabel id='filter-label' shrink/>
                    <Select
                        margin='dense'
                        labelId='filter-label'
                        id='filter-select'
                        displayEmpty
                        value={filterValue}
                        onChange={(ev) => {
                            setFilterValue(ev.target.value as string)
                        }}
                    >
                        {getValuesForSelect(steps)}
                    </Select>
                </FormControl>
            )
        if (selectFilter === 'commonField') {
            return (
                <>
                    <Alert severity='info'>
                        <AlertTitle>Доступные поля</AlertTitle>
                        • Имя <br/>
                        • Фамилия <br/>
                        • Имэйл <br/>
                        • Телефон <br/>
                        • Трек-номер
                    </Alert>
                    <TextField fullWidth style={{ padding: 4 }}
                               onKeyPress={event => {
                                   if (event && event.key === 'Enter') {
                                       event.preventDefault()
                                       updateFilterParams(true)
                                   }
                               }}
                               label={' '}
                               value={filterValue}
                               margin='dense'
                               onChange={(ev) => {
                                   setFilterValue(ev.target.value as string)
                               }}
                    />
                </>
            )
        }
        return (
            <TextField fullWidth style={{ padding: 4 }}
                       onKeyPress={event => {
                           if (event && event.key === 'Enter') {
                               event.preventDefault()
                               updateFilterParams(true)
                           }
                       }}
                       label={' '}
                       value={filterValue}
                       margin='dense'
                       onChange={(ev) => {
                           setFilterValue(ev.target.value as string)
                       }}
            />
        )
    }

    return (
        <div>
            <Tooltip title='Фильтр'>
                <IconButton size='small'
                            onClick={handleClick}
                            disabled={disabled}
                            className={classes.filter_button}
                >
                    <FilterListRoundedIcon className={classes.filter_icon}/>
                </IconButton>
            </Tooltip>
            <Popover id={popoverId}
                     open={open}
                     anchorEl={anchorEl}
                     onClose={handleClose}
                     anchorOrigin={{
                         vertical: 'center',
                         horizontal: 'right'
                     }}
                     transformOrigin={{
                         vertical: 'top',
                         horizontal: 'left'
                     }}>
                <Box className={classes.filter_wrapper}>
                    <Box className={classes.select_block}>
                        <FormControl size={'small'} fullWidth style={{ padding: 4 }}>
                            <InputLabel id='filter-label' shrink>
                                Фильтр
                            </InputLabel>
                            <Select
                                labelId='filter-label'
                                id='filter-select'
                                displayEmpty
                                margin='dense'
                                value={selectFilter}
                                onChange={(ev) => {
                                    setSelectFilter(ev.target.value as string)
                                    setFilterValue('')
                                }}
                            >
                                <MenuItem value={''}>Не выбран</MenuItem>
                                <MenuItem value={'commonField'}>
                                    Общий поиск
                                </MenuItem>
                                {/*<MenuItem value={'status'}>*/}
                                {/*    Статус клиента*/}
                                {/*</MenuItem>*/}
                                {/* <MenuItem value={'salesFunnelStatus'}>Статус продажи</MenuItem> */}
                                <MenuItem value={'salesFunnelStep'}>
                                    Этап продажи
                                </MenuItem>
                                <MenuItem value={'name'}>Имя + Фамилия</MenuItem>
                                <MenuItem value={'firstName'}>Имя</MenuItem>
                                <MenuItem value={'lastName'}>Фамилия</MenuItem>
                                <MenuItem value={'email'}>Email</MenuItem>
                                <MenuItem value={'phone'}>Телефон</MenuItem>
                                <MenuItem value={'track'}>Трек номер</MenuItem>
                            </Select>
                        </FormControl>

                        {renderFilterInput()}
                    </Box>
                    <Box className={classes.buttons_block}>
                        <Button
                            size={'small'}
                            onClick={() => updateFilterParams(false)}
                        >
                            Сбросить
                        </Button>
                        <Button
                            size='small'
                            onClick={() => updateFilterParams(true)}
                        >
                            Применить
                        </Button>
                    </Box>
                </Box>
            </Popover>
        </div>
    )
}

const useStyles = makeStyles<{}, { disabled?: boolean }>({
    filter_wrapper: {
        width: 400,
        minHeight: 200,
        padding: 10,
        display: 'flex',
        flexDirection: 'column'
    },
    filter_button: {
        width: 30,
        height: 30,
        '&.MuiIconButton-root.Mui-disabled': {
            color: 'rgba(0,0,0,.1)'
        }
    },
    filter_icon: {
        padding: 5,
        color: ({ disabled }) => disabled ? 'rgba(0,0,0,.1)' : 'grey',
        height: 30,
        width: 30,
        cursor: 'pointer',
        '&:hover': {
            fill: '#5383ff'
        }
    },
    select_block: {
        minHeight: 110
    },
    buttons_block: {
        minHeight: 70,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
})
