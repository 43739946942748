import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { snackActions } from '../../snackSlice'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'


export const approveTrustedClient = createAsyncThunk<
  {},
  number,
  ThunkConfig<any>>(
  'client/approve_trusted_client',
  async (id, thunkApi) => {
    const { dispatch, extra } = thunkApi
    try {
      await extra.api().post(`client/${id}/force-accept`)

      dispatch(snackActions.setSnack({
        label: 'Клиент успешно добавлен',
        typeLabel: 'success'
      }))
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось добавить клиента')
    }
  }
)