import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { snackActions } from '../../snackSlice'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { fetchLetterFragments } from './fetchLetterFragments'
import { TemplateFragmentFieldType } from '../../../../../types'



export const editFragment = createAsyncThunk<{},
  TemplateFragmentFieldType,
  ThunkConfig<any>>(
  'fragments/edit_fragment',
  async (data, thunkApi) => {
    const { dispatch, rejectWithValue, getState, extra } = thunkApi

    const { id, ...body} = data

    const params = getState().fragments.fragmentPagination
    try {
      await extra.api().patch(`letter-fragment/${id}`, body)
      dispatch(fetchLetterFragments(params))

      dispatch(snackActions.setSnack({
        label: 'Фрагмент успешно изменен',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'При редактировании фрагмента произошла ошибка')
      return rejectWithValue({})

    } finally {
    }
  }
)