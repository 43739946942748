import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProjectSpreedSheetSchema } from '../types/projectSpreedSheet'
import {
  deleteSpreadSheetFromProject,
  editSpreadSheetForProject,
  fetchSpreadSheetData,
  fetchSpreedSheetsListForProject
} from '../services'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'

const initialState: ProjectSpreedSheetSchema = {
  spreadSheetData: null,
  spreadSheetId: null,
  //isConfigureFieldOrder: null,
  paginationParams: { per_page: 15, page: 1 },
  isProcessSpreadSheet: false,
  isFetchData: false
}


export const projectSpreedSheetSlice = createSlice({
  name: 'project_spreed_sheet',
  initialState,
  reducers: {
    setIsFetchData: (state, action: PayloadAction<boolean>) => {
      state.isFetchData = action.payload
    },
    setParams: (state, action: PayloadAction<PaginationParamsType>) => {
      state.paginationParams = action.payload
    },
    setIsProcessSpreadSheet: (state, action: PayloadAction<boolean>) => {
      state.isProcessSpreadSheet = action.payload
    },
    clearSpreadSheetData: (state) => {
      state.spreadSheetData = null
    },
    clearTableId: (state) => {
      state.spreadSheetId = null
      state.spreadSheetData = null
    },
    clearSpreadSheetState: (state) => {
      state.spreadSheetsList = []
      state.totalCount = undefined
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSpreedSheetsListForProject.fulfilled, (state, { payload }) => {
      state.spreadSheetsList = payload.items
      state.totalCount = payload.totalCount
    })
    builder.addCase(deleteSpreadSheetFromProject.fulfilled, (state, { payload }) => {
      state.spreadSheetsList?.filter(el => el.id !== payload)
      state.totalCount = state.totalCount && state.totalCount - 1
    })
    builder.addCase(editSpreadSheetForProject.fulfilled, (state, { payload }) => {
      const { data, source } = payload
      state.spreadSheetsList = state.spreadSheetsList?.map(item => {
        if (item.id === source) {
          return {
            ...item,
            ...data,
            proxy: data.proxy
          }
        } else {
          return {...item}
        }
      })
    })
    builder.addCase(fetchSpreadSheetData.fulfilled, (state, { payload }) => {
      state.spreadSheetData = payload.data
      state.spreadSheetId = payload.source
    })
  }

})

export const { actions: projectSpreedSheetActions } = projectSpreedSheetSlice
export const { reducer: projectSpreedSheetReducer } = projectSpreedSheetSlice
