import React, { ErrorInfo, ReactNode } from 'react'
import { PageError } from '../../../ui-kit'


interface ErrorBoundaryProps {
  children: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log('Unhandled errors: ', error, errorInfo)
  }

  public render():ReactNode {
    const { hasError } = this.state
    const { children } = this.props

    if (hasError) {
      return <PageError />
    }

    return children
  }
}

export default ErrorBoundary