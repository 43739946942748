import React, { MouseEvent, useState } from 'react'
import { Box, CircularProgress, IconButton, ListItemIcon, Menu, MenuItem } from '@material-ui/core'
import { Contacts, Face, HighlightOff, KeyboardArrowDown, Settings } from '@material-ui/icons'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { projectSelectors } from '../../providers/StoreProvider/slices/projectsSlice'
import { makeStaticOrLink } from './BreadCrumps'

type TypeType =
    'settings'
    // | 'my_leads'
    | 'raw_leads'
    | 'archive_clients'
    | 'leads_manage'
    | 'leads_manage_as_manager'
//| 'tasks'
// | 'archive_tasks'

type ProjectShowPagesBreadcrumbItemPropsType = {
    isLink: boolean,
    type: TypeType,
}


const ProjectShowPagesBreadcrumbItem: React.FC<ProjectShowPagesBreadcrumbItemPropsType> = (props) => {
    const { id_project } = useParams<{ id_project: string }>()
    const projectsList = useSelector(projectSelectors.getProjectsList)
    const targetProject = projectsList?.filter(item => item.id === parseInt(id_project as string))

    console.log('projectsList', projectsList)
    console.log('targetProject', targetProject)
    const projectShowPagesItems = {
        settings: {
            label: 'Настройка проекта',
            access: targetProject?.[0] && targetProject[0].rights.canEdit,
            icon: <Settings fontSize={'small'} className={'icon'}/>,
            link: '/settings'
        },
        // my_leads: {
        //   label: 'Мои лиды',
        //   access: targetProject?.[0] && targetProject[0].rights.canManage,
        //   icon: <RecentActors fontSize={'small'} className={'icon'} />,
        //   link: '/my_leads'
        // },
        raw_leads: {
            label: 'Обработка новых лидов',
            access: targetProject?.[0] && targetProject[0].rights.canViewRawClients,
            icon: <Face fontSize={'small'} className={'icon'}/>,
            link: '/raw_leads'
        },
        archive_clients: {
            label: 'Архивные клиенты',
            access: targetProject?.[0] && targetProject[0].rights.canRestore,
            icon: <HighlightOff fontSize={'small'} className={'icon'}/>,
            link: '/archive_clients'
        },
        leads_manage: {
            label: 'Оператор: Работа с лидами',
            access: targetProject?.[0] && targetProject[0].rights.canManage,
            icon: <Contacts fontSize={'small'} className={'icon'}/>,
            link: '/leads_manage'
        },

        leads_manage_as_support: {
            label: 'Все клиенты (саппорт)',
            access: targetProject?.[0] && targetProject[0].rights.canManageClientsAsProjectManager,
            icon: <Contacts fontSize={'small'} className={'icon'}/>,
            link: '/archive_clients?tab_tab=all'
        },

        leads_manage_as_manager: {
            label: 'Все клиенты (менеджер)',
            access: targetProject?.[0] && targetProject[0].rights.canManageClientsAsOrderManager,
            icon: <Contacts fontSize={'small'} className={'icon'}/>,
            link: '/product_notes?tab_tab=clients'
        },
        // TODO(обсудить что будем делать с этим)
        // tasks: {
        //   label: 'Просмотр задач',
        //   access: targetProject?.[0] && targetProject[0].rights.canView,
        //   icon: <File fontSize={'small'} className={'icon'} />,
        //   link: '/'
        // }
    }

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const location = useLocation()
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null)

    const checkRawLeadsPermissions = (isSame: boolean) => {
        if (isSame) {
            return true
        }
        const targetProject = projectsList?.filter(item => item.id === parseInt(id_project as string))

        return !(targetProject?.[0] && targetProject[0].hasLetterTemplates && targetProject[0].hasMailboxesForDeal && targetProject[0].hasMailboxesForNewClients)

    }

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>

            {makeStaticOrLink(
                <span>{projectShowPagesItems[props.type].label}</span>,
                projectShowPagesItems[props.type].icon,
                props.isLink,
                // '/project/' + id_project,
                `/project/${id_project}${projectShowPagesItems[props.type].link}`,
                'ProjectShowPagesBreadcrumbItem',
            )}
            {/*{targetProject?.length === 0 ?*/}
            {/*  <CircularProgress size={12} style={{ marginLeft: 10 }} /> :*/}
            <>
                {!targetProject ?
                    <CircularProgress size={12} style={{ marginLeft: 10 }}/> :
                    <IconButton
                        id={'x_pro_show'}
                        className={'button_more'}
                        size={'small'}
                        disabled={!targetProject}
                        onClick={handleClick}>
                        <KeyboardArrowDown fontSize={'small'} className={'button_icon'}/>
                    </IconButton>}
                {/*<IconButton*/}
                {/*  id={'x_pro_show'}*/}
                {/*  className={'button_more'}*/}
                {/*  size={'small'}*/}
                {/*  disabled={!targetProject}*/}
                {/*  onClick={handleClick}>*/}
                {/*  <KeyboardArrowDown fontSize={'small'} className={'button_icon'} />*/}
                {/*</IconButton>*/}
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    keepMounted
                    onClose={handleClose}>
                    {Object.keys(projectShowPagesItems).map((typeItem: string, index: number) => {
                        return (
                            <Box key={index + '-' + typeItem}>
                                {targetProject && projectShowPagesItems[typeItem as TypeType].access &&
                                    <MenuItem
                                        key={'x' + index}
                                        disabled={
                                            typeItem !== 'leads_manage' && typeItem !== 'raw_leads'
                                                ? typeItem === props.type
                                                :
                                                checkRawLeadsPermissions(typeItem === props.type)
                                        }
                                        selected={typeItem === props.type}
                                        onClick={() => {
                                            handleClose()
                                        }}
                                        component={Link}
                                        to={
                                            '/project/' +
                                            id_project +
                                            projectShowPagesItems[typeItem as TypeType].link
                                        }
                                        state={{ from: `${location?.pathname}${location?.search}` }}
                                    >
                                        <ListItemIcon>
                                            {projectShowPagesItems[typeItem as TypeType].icon}
                                        </ListItemIcon>
                                        {projectShowPagesItems[typeItem as TypeType].label}
                                    </MenuItem>
                                }
                            </Box>
                        )
                    })}
                </Menu>
            </>
            {/*}*/}
        </div>
    )
}

export default ProjectShowPagesBreadcrumbItem