import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { appActions } from '../../appSlice'
import { fetchProjectNotes } from './fetchProjectNotes'
import { FilesType } from '../../../../../Components/Inputs/FilesAttach/FilesAttach'

type RequestType = {
  id: number
  name: string
  values: string[]
  attachedFiles: FilesType
  existingFiles: FilesType
  defaultAttach: string
}

export const fetchEditProjectNote = createAsyncThunk<{},
  RequestType,
  ThunkConfig<any>>(
  'notes/fetch_edit_project_note',
  async (data, { dispatch, rejectWithValue, getState, extra }) => {

    const { id } = data

    const projectId = getState().notes.projectId
    dispatch(appActions.setIsLoad(true))
    try {
      await extra.api().patch(`project-note/${id}`, data)
      dispatch(fetchProjectNotes(projectId))

      dispatch(snackActions.setSnack({
        label: 'Заметка была успешно изменена',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Произошла ошибка при сохранении изменений заметки')
      return rejectWithValue({})
    } finally {
      dispatch(appActions.setIsLoad(false))
    }
  }
)