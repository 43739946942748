import { FC, ReactNode } from 'react'

import { Link, useLocation } from 'react-router-dom'

import { createStyles, ListItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

type CustomLinkListItemPropsType = {
  to: string
  classname?: string
  children: ReactNode
};

export const CustomLinkListItem: FC<CustomLinkListItemPropsType> = ({
                                                                      to,
                                                                      classname,
                                                                      children
                                                                    }) => {

  const location = useLocation()
  const classes = useStyles()

  const listItemStyle = to === location.pathname ? classes.active : classes.list_item

  return (
    <ListItem
      button
      component={Link}
      to={to}
      selected={to === location.pathname}
      className={`${listItemStyle} ${classname}`}
    >
      {children}
    </ListItem>
  )
}


const useStyles = makeStyles(() =>
  createStyles({
    list_item: {
      fontSize: 12
    },
    active: {
      color: '#40a9ff',
      fontSize: 12,
      '&.MuiListItem-root.Mui-selected': {
        backgroundColor: '#3d4977'
      }
    }
  })
)