import React from 'react'
import { makeStaticOrLink } from './BreadCrumps';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core';
import { RoutePath } from '../../providers/router/routeConfig/routeConfig';

type PathKeys = keyof typeof RoutePath;
type PathValues = typeof RoutePath[PathKeys]
export type BreadCrumbsPropsType = {
    isLink?: boolean
    key: number | string
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>
    label: string
    link: PathValues
}

const BaseBreadcrumbItem:React.FC<BreadCrumbsPropsType> = (props) => {

    return (
        makeStaticOrLink(
            props.label,
            <props.icon fontSize='small' className='icon' />,
            !!props.isLink,
            props.link,
            props.key
          )
    )
}

export default  BaseBreadcrumbItem