import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'
import { fetchSpreedSheetsListForProject } from './fetchSpreedSheetsListForProject'


type RequestProps = {
  projectId: number,
  source: number,
  params?: PaginationParamsType
}


export const deleteSpreadSheetFromProject = createAsyncThunk<number,
  RequestProps,
  ThunkConfig<any>>(
  'spreadSheets/delete_spread_sheet_for_project',
  async (dto, { dispatch, rejectWithValue, extra }) => {

    const { projectId, source } = dto

    try {
     await extra.api().delete(`/project/${projectId}/spreadsheets/${source}/delete`)

     if (dto.params) {
      dispatch(fetchSpreedSheetsListForProject({
        projectId,
        params: dto.params
      }))
     }

      dispatch(snackActions.setSnack({
        label: 'Таблица успешно удалена',
        typeLabel: 'success'
      }))
      return source

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось удалить таблицу')
      return rejectWithValue({})
    }
  }
)