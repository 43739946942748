import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { PhoneType } from '../types/projectPhones'
import { snackActions } from '../../snackSlice'
import { projectPhonesActions } from '../slice/projectPhonesSlice'
import { projectUserThunks } from '../../projectUserSlice'


type RequestProps = {
  managerId: number,
  phoneId: number,
  projectId: number
}


export const addPhoneToProjectUser = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'projectPhones/add_phone_to_project_user',
  async (data, { dispatch, rejectWithValue, getState, extra }) => {

    const { projectId, phoneId, managerId } = data

    dispatch(projectPhonesActions.isAddPhoneToProjectUser({ isAddPhoneToProjectUser: true }))
    try {
      await extra.api().patch<PhoneType[]>(`project-user/${managerId}/add-phone`, {
        phone: phoneId,
        project: projectId
      })
      dispatch(projectUserThunks.fetchClientManagers({projectId}))
      dispatch(projectPhonesActions.isAddPhoneToProjectUser({ isAddPhoneToProjectUser: false, phoneId }))
      dispatch(snackActions.setSnack({
        label: 'Телефон успешно добавлен',
        typeLabel: 'success'
      }))


    } catch (e) {
      dispatch(projectPhonesActions.isAddPhoneToProjectUser({ isAddPhoneToProjectUser: false }))
      handleServerNetworkError(e, dispatch, 'Телефон не был добавлен')
      return rejectWithValue({})
    }

  }
)