import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles<{}, { isEditMode: boolean }>({

  editBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 36.63
  },
  typography: {
    margin: '4px 0',
    wordBreak: 'break-all'
  },
  errorTypography: {
    paddingLeft: 24
  },
  editInput: {
    '& .MuiOutlinedInput-input': {
      padding: '7px 10px'
    }
  },
  iconButton: {
    width: 25,
    height: 25,
    padding: 6,
    '&.MuiIconButton-root:hover': {
      backgroundColor: '#fff',
      '& .MuiIconButton-label': {
        '& > svg' : {
          fill: 'rgba(83,131,255, 1)'
        }
      }
    }
  },
  icon: {
    fill: 'rgba(0, 0, 0, 0.54)'
  },

  phonePreviewBox: {
    border: '0.5px solid rgba(0,0,0,.1)',
    marginLeft: 24,
    padding: '5px ',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px'
  },
  phonePreviewHeader: {
    display: 'flex',
    alignItems: 'center',
    // gap: '10px'
    justifyContent: 'space-between'
  },
  phonePreviewContent: {
    display: 'flex',
    alignItems: 'center',
    // gap: '10px'
    justifyContent: 'space-between'
  }
})