import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { FetchSpreadSheetsResponse } from '../types/projectSpreedSheet'
import { prepareFilterParams } from '../../../../../Api/API'
import { projectSpreedSheetActions } from '../slice/projectSpreedSeetSlice'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'


type RequestProps = {
  projectId: number,
  params?: PaginationParamsType
}


export const fetchSpreedSheetsListForProject = createAsyncThunk<FetchSpreadSheetsResponse,
  RequestProps,
  ThunkConfig<any>>(
  'spreadSheets/fetch_spread_sheet_list_for_project',
  async (dto, { dispatch, rejectWithValue, getState, extra }) => {

    const { projectId, params } = dto
    // const spreedSheetList = getState().projectSpreedSheet.spreadSheetsList
    //  !spreedSheetList && dispatch(projectSpreedSheetActions.setIsFetchData(true))
    try {
      const res = await extra.api().get<FetchSpreadSheetsResponse>(`project/${projectId}/spreadsheets?${prepareFilterParams(params)}`)
      // params && dispatch(projectSpreedSheetActions.setParams(params))
      
      return res.data

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить данные')
      return rejectWithValue({})
    } finally {
      // dispatch(projectSpreedSheetActions.setIsFetchData(true))
    }
  }
)