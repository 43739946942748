import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { CallDirectionsType, CallType } from '../types/calls'
import { callsActions } from '../slice/callSlice'
import { lettersActions } from '../../lettersSlice'


type RequestProps = {
  direction: CallDirectionsType,
  projectId: number,
  clientId: number,
  projectPhoneId: number,
  clientPhone?: string
}


export const createCall = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'call/create_call',
  async (data, { dispatch, extra, rejectWithValue }) => {

    const { clientId, clientPhone, projectPhoneId, projectId, direction } = data

    const status = 'QUEUED'
    try {
     const res = await extra.api().post<CallType>(`call`, {
        type: direction,
        projectId: projectId,
        status: status,
        clientId: clientId,
        projectPhoneId: projectPhoneId,
        clientPhone: clientPhone
      })
      dispatch(callsActions.setActiveCallData(res.data))
      /** Добавляем звонок в список сообщений */
      dispatch(lettersActions.addNewCallToGeneralMessageFeed(res.data))
    } catch (e) {
      return rejectWithValue({})
    }


  }
)