import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'

import { IClientForProject } from '../../../../../types'
import { snackActions } from '../../snackSlice'

type RequestProps = {
  clientId: number,
  message: string
}

export const sendReshipmentReasonForClient = createAsyncThunk<
  IClientForProject[],
  RequestProps,
  ThunkConfig<any>>(
  'client/send_reshipment_reason_for_client',
  async (data, thunkApi) => {
    const { dispatch, rejectWithValue, extra } = thunkApi
    const { clientId, message } = data
    try {
      const response = await extra.api().post<IClientForProject[]>(`client/${clientId}/track-problem`, { message })
      dispatch(snackActions.setSnack({
        label: 'Клиент помечен флагом "Повторная отправка"',
        typeLabel: 'success'
      }))
      return response.data
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось осуществить повторную отправку')
      return rejectWithValue({})
    }
  }
)