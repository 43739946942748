import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { clientsActions } from '../slice/clientsSlice'


export const clearEmptyMacros = createAsyncThunk<
  {},
  number,
  ThunkConfig<any>>(
  'client/approve_trusted_client',
  async (id, thunkApi) => {
    const { dispatch, extra } = thunkApi
    try {
      await extra.api().get(`client/${id}/clear-empty-macros`)
      dispatch(clientsActions.setEmptyMacros({ clientId: id }))
    } catch (e) {
    }
  }
)