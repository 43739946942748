import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { LeadFromUploadTracks } from '../types/tracks'
import { RequestData } from '../../../../../Pages/ProjectsPage/ImportTrackNumbers'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'


export const checkTracksUniqueFromLeads = createAsyncThunk<
    LeadFromUploadTracks[],
    RequestData,
    ThunkConfig<any>
>(
    'tracks/check_tracks_unique_from_leads',
    async (data, { dispatch, extra, rejectWithValue }) => {
        try {
            const response = await extra.api().post('tracks/check', data)
            return response.data
        } catch (e) {
            handleServerNetworkError(e, dispatch, 'Не удалось проверить информацию')
            return rejectWithValue({})
        }
    }
)


