import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { PhoneType } from '../types/projectPhones'
import { snackActions } from '../../snackSlice'
import { projectPhonesActions } from '../slice/projectPhonesSlice'
import { fetchProjectPhonesForSelect } from './fetchProjectPhonesForSelect'


type RequestProps = {
  projectId: number,
  externalServiceId: number,
  phone: string
}

export const createPhone = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'projectPhones/create_phone',
  async (data, { dispatch, rejectWithValue, extra }) => {

    const { projectId, phone } = data
    dispatch(projectPhonesActions.setIsLoad(true))
    try {
      await extra.api().post<PhoneType[]>(`project/${projectId}/phones-for-select`, {
        number: phone,
        project: projectId
      })
      dispatch(fetchProjectPhonesForSelect(projectId))

      dispatch(snackActions.setSnack({
        label: 'Телефон успешно добавлен',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Телефон не был добавлен')
      return rejectWithValue({})
    } finally {
      dispatch(projectPhonesActions.setIsLoad(false))
    }

  }
)