import { ChangeEvent, FC, MouseEvent, useState } from 'react'
import { MuiPopover } from '../../Popover/MuiPopover'
import { Box, Button, createStyles, IconButton, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { List } from '../../List/List'
import { getSendMessageDescription, getSendTime, getSnoozeTime } from '../../../default-values'

import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { getTimeZoneOffsetInSeconds } from '../../../Utils/getTimeZoneOffsetInSeconds'
import { CustomTooltip } from '../../CustomToolTip/CustomTooltip'
// import { CustomCalendar } from '../../CustomCalendar/CustomCalendar'
import { utcToZonedTime } from 'date-fns-tz'
import { addDays, addHours, addMinutes, getUnixTime, isWeekend, setHours, setMinutes } from 'date-fns'
import { SendMessageType } from '../../../Pages/LeadsManagePage/MainContentBlock/ManageLeadsLetters/ManageLeadsDialog'

type ButtonWithSelectPropsType = {
  disabled?: boolean,
  isDefaultSnoozed: boolean
  setMessageInTime: (sendMethod: SendMethodType, sendType: SendMessageType, newDate: number, sendTime?: number) => void
  className?: string
}


export type SelectTitleType =
  'Через 3 часа'
  | 'Завтра'
  | 'Через 3 дня'
  | 'Через N часов'
  | 'В указанное время'

export type SendMethodType = 'letter' | 'delayed_letter'


export type SelectType = {
  name: SelectNameType
  title: SelectTitleType
  description: string | JSX.Element
  value: number,
  snoozeTime: number

}

export type SelectNameType = 'IN_3_HOURS' | 'NEXT_DAY' | 'IN_N_HOURS'

export const ButtonWithSelect: FC<ButtonWithSelectPropsType> = ({
  disabled,
  isDefaultSnoozed,
  setMessageInTime,
    className
}) => {

  const classes = useStyles()

  const zonedDate = utcToZonedTime(new Date(), 'America/Chicago')


  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [anchorHandleChooseEl, setAnchorHandleChooseEl] = useState<HTMLElement | null>(null)

  const [chooseHoursInterval, setChooseHoursInterval] = useState<number>(0)
  const [chooseMinutesInterval, setChooseMinutesInterval] = useState<number>(0)
  type ErrorType = {
    hours: boolean
    minutes: boolean
  }
  const [error, setError] = useState<ErrorType | undefined>()


  const options: SelectType[] = [
    {
      name: 'IN_3_HOURS',
      title: 'Через 3 часа',

      description: getSendMessageDescription('IN_3_HOURS'),
      value: getUnixTime(addHours(zonedDate, 3)),
      snoozeTime: getSnoozeTime('IN_3_HOURS', isDefaultSnoozed)
    },
    {
      name: 'NEXT_DAY',
      title: 'Завтра',
      description: getSendMessageDescription('NEXT_DAY'),
      value: getUnixTime(addDays(setHours(setMinutes(new Date(), 0), 8), 0)),
      snoozeTime: getSnoozeTime('NEXT_DAY', isDefaultSnoozed)
    },
    // {
    //   title: 'Через 3 дня',
    //   description: sendMessageValues.sendInThreeDays,
    //   value: isDefaultSnoozed
    //     ? moment.tz('America/Chicago').add(4, 'day').hour(8).minutes(0).unix()
    //     : moment.tz('America/Chicago').add(3, 'day').hour(8).minutes(0).unix()
    // },
    {
      name: 'IN_N_HOURS',
      title: 'Через N часов',
      description: chooseHoursInterval
        ? getSendMessageDescription('IN_N_HOURS', chooseHoursInterval)
        : <NavigateNextIcon style={{ padding: 3 }} />,
      value: getUnixTime(addHours(zonedDate, chooseHoursInterval)),
      // ? getUnixTime(addDays(setHours(setMinutes(zonedDate, 0), 8), 1))
      // : getUnixTime(addHours(zonedDate, chooseHoursInterval)),
      snoozeTime: getSnoozeTime('IN_N_HOURS', isDefaultSnoozed, chooseHoursInterval)
    }
  ]


  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>): void => setAnchorEl(event.currentTarget)
  const handleCloseMenu = () => setAnchorEl(null)


  const handleOpenDatePickerOnClick = (e: MouseEvent<HTMLElement>, name: SelectNameType): void => {
    const sendTime = getSendTime(name, isDefaultSnoozed, options)
    const snoozeTime = options.filter(el => el.name === name)[0].snoozeTime
    if (name === 'IN_N_HOURS') {
      setAnchorHandleChooseEl(e.currentTarget)
    } else {
      if (isDefaultSnoozed) {
        setMessageInTime('delayed_letter', 'SEND_AND_SNOOZE', snoozeTime, sendTime)
      } else {
        setMessageInTime('delayed_letter', 'SEND_NOW', sendTime)
      }
      handleCloseMenu()
    }
  }

  const handleCloseChooseHourPicker = (): void => {
    setAnchorHandleChooseEl(null)
    setChooseHoursInterval(0)
  }

  // отправить сообщение  при выбранном элементе "Через N часов"
  const handleSendByHoursInterval = (): void => {
    let sendTime
    const date = addMinutes(addHours(zonedDate, chooseHoursInterval), chooseMinutesInterval)
    if (isWeekend(date)) {
      sendTime = getUnixTime(addDays(setHours(setMinutes(zonedDate, 0), 8), 3)) + getTimeZoneOffsetInSeconds()
    } else {
      sendTime = getUnixTime(date) + getTimeZoneOffsetInSeconds()
    }

    const snoozeTime = options.filter(el => el.name === 'IN_N_HOURS')[0].snoozeTime
    if (isDefaultSnoozed) {
      setMessageInTime('delayed_letter', 'SEND_AND_SNOOZE', snoozeTime, sendTime)
    } else
      setMessageInTime('delayed_letter', 'SEND_NOW', sendTime)
    handleCloseChooseHourPicker()
    setAnchorEl(null)
  }

  // изменение значения в инпуте выбора интервала времени
  const handleChangeHourInterval = (e: ChangeEvent<HTMLInputElement>): void => {
    setError(undefined)
    const value = parseInt(e.currentTarget.value)
    if (value > 24) setError({
      hours: true,
      minutes: error ? error.minutes : false
    })
    setChooseHoursInterval(value)
  }

  const handleChangeMinuteInterval = (e: ChangeEvent<HTMLInputElement>): void => {
    setError(undefined)
    const value = parseInt(e.currentTarget.value)
    if (value > 60) setError({
      hours: error ? error.hours : false,
      minutes: true
    })
    setChooseMinutesInterval(value)
  }

  // // закрыть datePicker меню
  // const handleCloseDatePicker = (): void => setAnchorDatePickerEl(null)

  // //Отправить в указанное время
  // const sendAtSpecifiedTime = (): void => {
  //   const sendTime = moment(dateTimePickerValue).unix() + getTimeZoneOffsetInSeconds()
  //   const fieldValue = options.filter(el => el.title === 'В указанное время')[0].value
  //   if (isDefaultSnoozed) {
  //     setMessageInTime('delayed_letter', fieldValue, sendTime)
  //   } else {
  //     setMessageInTime('delayed_letter', fieldValue)
  //   }
  //   setAnchorDatePickerEl(null)
  //   setAnchorEl(null)
  // }

  return (
    <>
      <MuiPopover anchorEl={anchorEl}
                  onClose={handleCloseMenu}
                  popoverId='buttonWithSelect-popover'
                  name='BUTTON_WITH_SELECT'
                  actionIcon={
                    <IconButton size='small'
                                className={`${classes.selectButton} ${className ? className : ''}`}
                                onClick={handleOpenMenu}
                                disabled={disabled}
                    >
                      <ArrowDropDownIcon className={classes.actionIcon}
                      />
                    </IconButton>
                  }
      >
        <Box className={classes.timeSelect_container}>
          <Box className={classes.timeSelectHeader}>
            <Typography className={classes.typography}>
              Отправить
            </Typography>
          </Box>
          <List items={options} renderItem={(option, index) => (
            <CustomTooltip
              arrow
              placement='left'
              title={'Если заданный интервал попадает на выходной день, отправка осуществится в понедельник в 8:00 утра'}>
              <Box key={option.title + '-' + index}
                   onClick={(e) => handleOpenDatePickerOnClick(e, option.name)}
                   className={classes.timeSelectItem}>
                <Typography className={classes.sendTimeDescription}>{option.title}</Typography>
                <Typography className={'send_time_value'}>{option.description}</Typography>
              </Box>
            </CustomTooltip>
          )} />
        </Box>
      </MuiPopover>

      {anchorHandleChooseEl &&
      <MuiPopover anchorEl={anchorHandleChooseEl}
                  onClose={handleCloseChooseHourPicker}
                  popoverId='choose-hour-popover'
                  name='CHOOSE_HOUR'>
        <Box className={classes.nHoursBox}>
          <Typography className={classes.typography}>
            Выберите время
          </Typography>
          <TextField
            label='Часы'
            fullWidth
            margin='dense'
            type='number'
            value={chooseHoursInterval}
            onChange={handleChangeHourInterval}
            error={error ? error.hours : false}
            helperText={error && 'Не более 24-х часов'}
            inputProps={{ min: 0, max: 24 }}
          />
          <TextField
            label='Минуты'
            fullWidth
            margin='dense'
            type='number'
            value={chooseMinutesInterval}
            onChange={handleChangeMinuteInterval}
            error={error ? error.minutes : false}
            helperText={error && 'Не более 60-х минут'}
            inputProps={{ min: 0, max: 60 }}
          />
          <Box className={classes.sendNHoursButtonBox}>
            <Button color='primary'
                    onClick={handleSendByHoursInterval}
                    variant='contained'
                    className={classes.sendButton}
                    disabled={!chooseHoursInterval && !chooseMinutesInterval || !!error}
                    size='small'
            >
              Отправить
            </Button>
          </Box>
        </Box>
      </MuiPopover>
      }

      {/*{anchorDatePickerEl &&*/}
      {/*<MuiPopover anchorEl={anchorDatePickerEl}*/}
      {/*            onClose={handleCloseDatePicker}*/}
      {/*            popoverId='datePicker-popover'*/}
      {/*            name='DATE_PICKER'>*/}
      {/*  <CustomCalendar setDateTimePickerValue={setDateTimePickerValue} />*/}
      {/*  <Box className={classes.sendDatePicker}>*/}
      {/*    <Button color='primary'*/}
      {/*            onClick={sendAtSpecifiedTime}*/}
      {/*            variant='contained'*/}
      {/*            className={classes.sendDatePickerButton}*/}
      {/*            disabled={dateTimePickerValue < zonedDate}*/}
      {/*            size='small'*/}
      {/*    >*/}
      {/*      Отправить*/}
      {/*    </Button>*/}
      {/*  </Box>*/}
      {/*</MuiPopover>*/}
      {/*}*/}
    </>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    timeSelect_container: {
      width: '225px',
      height: 'auto',
      padding: '10px 0',
      backgroundColor: '#fff'
    },
    timeSelectHeader: {
      height: '29px',
      padding: '3px 12px 6px',
      color: '#737376'
    },
    selectButton: {
      padding: 2,
      borderRadius: '0 8px 8px 0',
      backgroundColor: '#5383ff',
      borderLeft: '1px solid #fff',
      '&:hover': {
        backgroundColor: 'rgb(70, 111, 216)'
      },
      '&:disabled': {
        backgroundColor: '#aba8a8'
      }
    },
    actionIcon: {
      fill: '#fff'
    },
    timeSelectItem: {
      height: '36px',
      padding: '6px 12px',

      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(241,241,241,1)',
        borderRadius: '4px'
      },
      '& .send_time_value': {
        color: '#737376',
        fontSize: '13px',
        fontWeight: 500
      }
    },
    sendTimeDescription: {
      padding: '2px 4px 1px',
      fontSize: '13px',
      fontWeight: 500
    },
    typography: {
      fontSize: '13px',
      fontWeight: 500
    },
    nHoursBox: {
      width: 185,
      height: 170,
      padding: 10,
      color: 'gray',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    sendNHoursButtonBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 5
    },
    sendButton: {
      height: '20px',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none'
      },
      '&:disabled': {
        backgroundColor: '#aba8a8'
      },
      '&.MuiButton-containedSizeSmall': {
        fontSize: 10,
        padding: 5
      }
    },
    sendDatePicker: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '0px 10px 10px 10px'
    },
    sendDatePickerButton: {
      boxShadow: 'none',
      '&:disabled': {
        backgroundColor: '#aba8a8'
      },
      '&:hover': {
        boxShadow: 'none'
      },
      '&.MuiButton-containedSizeSmall': {
        fontSize: 10,
        padding: 5
      }
    }
  })
)