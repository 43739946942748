import React, {Suspense, useCallback, useEffect, useState} from 'react'
import {Route, Routes, useLocation} from 'react-router-dom'


import {authActions, authThunks} from '../providers/StoreProvider/slices/authSlice'
import {useAppDispatch} from '../providers/StoreProvider/hooks/useAppDispatch'
import {makeStyles, ThemeProvider} from '@material-ui/core/styles'
import {CRMChatsProvider} from '../providers/CRMChatsProvider'
import {AnimatePresence, motion} from 'framer-motion'
//import { UserActivityProvider } from '../providers/UserActivityProvider'
import {InfoSnack} from './Snackbar/InfoSnack'
import {ForceLogin} from '../Components/Login/ForceLogin'
import {AppRouter} from '../providers/router'
import MuiTheme from '../z_old_site/theme'
import {NoteNotificationSnack} from './Snackbar/NoteNotificationSnack'
import {NotificationsProvider} from '../providers/NotificationsProvider'
import {Login} from '../Components/Login/Login'
import {useSelector, useStore} from 'react-redux'
import {StateSchema} from '../providers/StoreProvider'
import {connectSocketIo} from '../Redux/socket-io-handler'
import VoiceProvider from '../providers/VoiceProvider'
import {Box} from '@material-ui/core'
import {SyncLoader} from 'react-spinners'


export const Main = () => {

  const dispatch = useAppDispatch()

  const name = useSelector<StateSchema, string | null>(state => state.auth.name)
  const id = useSelector<StateSchema, number | null>(state => state.auth.id)
  const isAuth = useSelector<StateSchema, boolean>(state => state.auth.isAuth)
  const isTryAuth = useSelector<StateSchema, boolean>(state => state.auth.isTryAuth)

  const store = useStore<StateSchema>()
  const cls = useStyles()

  const [isLoad, setIsLoad] = useState(true)

  let retryCount = 0
  const maxReconnects = 3

  const checkApikey = useCallback(() => {
    const apikey = localStorage.getItem('apikey')
    if (!apikey) {
      if (isAuth) {
        dispatch(authActions.setAuthStatus(false))
        if (isLoad) {
          setIsLoad(false)
        }
      } else {
        setIsLoad(false)
      }
      dispatch(authActions.setIsTryAuth(true))
    } else {
      console.log('tokenCheck()', isAuth, isLoad)
      !isAuth && dispatch(authThunks.tokenCheck())
    }
  }, [dispatch, isAuth, isLoad])

  useEffect(() => {
    checkApikey()
  }, [checkApikey])


  useEffect(() => {
    name && id && connectSocketIo(name, id, retryCount, maxReconnects, dispatch, store)
  }, [dispatch, id, name, retryCount])

  if (!isAuth && !isTryAuth) {
    return (
      <Box className={cls.loaderBox}>
        <SyncLoader color={'var(--primary)'} loading/>
      </Box>
    )
  }
  console.log('auth', isAuth, isTryAuth)

  return (
    <Suspense fallback=''>
      <ThemeProvider theme={MuiTheme}>
        <AnimatePresence>
          {isAuth && isTryAuth ?
            // <UserActivityProvider>
            <NotificationsProvider>
              <CRMChatsProvider>
                <VoiceProvider>
                    <AppRouter />
                  <InfoSnack />
                  <NoteNotificationSnack />
                </VoiceProvider>
              </CRMChatsProvider>
            </NotificationsProvider>
            // </UserActivityProvider>
            :
            <>
              <motion.div
                initial='initial'
                animate='in'
                exit='out'
                variants={pageVariants}
                transition={pageTransition}
              >
                <Routes>
                  <Route path='/force_login' element={<ForceLogin />} />
                  <Route path='*' element={<Login />} />
                </Routes>
              </motion.div>
            </>
          }
        </AnimatePresence>
      </ThemeProvider>
    </Suspense>
  )
}

const pageVariants = {
  initial: {
    opacity: 0,
    scale: 0.99
  },
  in: {
    opacity: 1,
    scale: 1
  },
  out: {
    opacity: 0,
    scale: 1.01
  }
}

const pageTransition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.4
}

const useStyles = makeStyles({
  loaderBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '24%'
  }
})