import { ReactElement, ReactNode } from 'react'
import { Box } from '@material-ui/core'

type ListProps<T> = {
  items: T[]
  renderItem: (item: T, index: number) => ReactNode
  className?: string
}

export const List: <T>(props: ListProps<T>) => ReactElement<ListProps<T>> = ({
  items,
  renderItem,
  className
}) => <Box className={className}>{items.map(renderItem)}</Box>


