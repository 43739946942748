import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { IClientForProject } from '../../../../../types'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'

export const cancelReshipment = createAsyncThunk<
    IClientForProject[],
    number,
    ThunkConfig<any>
>(
    'client/cancel_reshipment',
    async (clientId, { dispatch, rejectWithValue, extra }) => {

        try {
            const response = await extra.api().post(`client/${clientId}/track-problem-solved`)
            dispatch(snackActions.setSnack({
                label: 'Повторная отправка отменена',
                typeLabel: 'success'
            }))
            return response.data
        } catch (e) {
            handleServerNetworkError(e, dispatch, 'Произошла ошибка')
            return rejectWithValue(e)
        }
    }
)