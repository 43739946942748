import React, { CSSProperties, FC, memo, MouseEventHandler } from 'react'

import { Box } from '@material-ui/core'

import { Icon } from '../Icon/Icon'
import { CustomTooltip } from '../CustomToolTip/CustomTooltip'
import { ContentPlacement } from './types'
import { IconName } from '../Icon/types'
import { useStyles } from './styles'

export type PropsType = {
  title: string
  name: IconName
  size?: number
  placement?: ContentPlacement
  arrow?: boolean
  interactive?: boolean
  className?: string
  iconClassName?: string
  style?: CSSProperties
  onClick?: MouseEventHandler<SVGElement>
  disabled?: boolean
}

const ToolTipIcon: FC<PropsType> = ({
                                      title,
                                      name,
                                      size = 18,
                                      placement,
                                      arrow,
                                      interactive,
                                      className,
                                      style,
                                      iconClassName,
                                      disabled,
                                      onClick
                                    }) => {
  const classes = useStyles()

  const finalIconClassName = `${classes.root} ${iconClassName ? iconClassName : ''}`

  return (
    <Box className={className}>
      <CustomTooltip title={!disabled ? title : ''}
                     arrow={arrow}
                     placement={placement}
                     interactive={interactive}
      >
        <div>
          <Icon name={name}
                size={size}
                onClick={onClick}
                style={style}
                className={finalIconClassName}
          />
        </div>
      </CustomTooltip>
    </Box>
  )
}

export default memo(ToolTipIcon)