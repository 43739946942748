import { IClientForProject } from '../../../../../types'

const stringToNumber = (str: string): number => {
  const strParts = str.split(' ')
  const parts = strParts[0].split(':').concat(strParts[1].split('.'))
  return Date.parse(parts[4] + '-' + parts[3] + '-' + parts[2] + 'T' + parts[0] + ':' + parts[1] + ':00.000')
}


export const filterLeadList = (leadList: IClientForProject[]): IClientForProject[] => {
  const filteredLeadList = [...leadList]
  filteredLeadList.sort((a, b) => {
    return stringToNumber(b.lastLetter ? b.lastLetter.postDate : b.updatedAt) - stringToNumber(a.lastLetter ? a.lastLetter.postDate : a.updatedAt)
  })
  filteredLeadList.sort((a, b) => {
    if (a.status === 'TRUSTED') {
      return -1
    }
    if (a.hasUnreadLetter) {
      return -1
    }
    return 1
  })
  return filteredLeadList
}