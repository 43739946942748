import { createAsyncThunk } from "@reduxjs/toolkit"
import { ThunkConfig } from "../../.."
import { UserMailboxForDealDataDto } from "../types/projectUser"
import { handleServerNetworkError } from "../../../../../helpers/error-helpers"
import { fetchMailboxForDealForSelect } from "../../clientsSlice/services"
import { fetchClientManagers } from "./fetchClientManagers"

type RequestProps = {
    managerId: number,
    data: UserMailboxForDealDataDto
}

export const deleteUserMailboxForDeal = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'projectUser/delete_manager',
  async (request, { dispatch, rejectWithValue, getState, extra }) => {
    const { managerId, data } = request
    try {
      await extra.api().delete(`project-user/${managerId}/delete-mailbox-for-deal`, {data})

      await dispatch(fetchMailboxForDealForSelect(data.projectId))
      await dispatch(fetchClientManagers({projectId: data.projectId}))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось удалить email для спама')
      return rejectWithValue({})
    } finally {
    //   dispatch(projectUserActions.setIsDelete(false))
    }
  }
)