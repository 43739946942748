import {Action, AnyAction, Dispatch, Store} from 'redux'
import {ThunkDispatch} from 'redux-thunk'

import {CommonLetterType, ISMSLetter, NoteWithType} from '../Api/lettersApi'
import {createConnection} from '../Api/API'
// import {MailBoxType} from '../Api/emailApi'
import {EventNames} from '../enums/enums'
import {ChatType, HistoriesType, IArchiveClient, IClientForProject, IFullClientData, IShortClientData} from '../types'
import {clientsActions} from '../providers/StoreProvider/slices/clientsSlice'
import {lettersActions} from '../providers/StoreProvider/slices/lettersSlice'
import {appActions} from '../providers/StoreProvider/slices/appSlice'
import {snackActions} from '../providers/StoreProvider/slices/snackSlice'
import {emailsActions, EmailType} from '../providers/StoreProvider/slices/emailSlice'
import {archiveClientsActions} from '../providers/StoreProvider/slices/archiveClientsSlice'
import {chatsActions, chatThunks} from '../providers/StoreProvider/slices/chatsSlice'
import {notificationsThunks} from '../providers/StoreProvider/slices/notitficationsSlice'
import {StateSchema, ThunkExtraArg} from '../providers/StoreProvider'
import {IChatMessage} from '../types/chat'
import {TrackType} from "../Pages/ProjectsPage/ClientManage/ManageTracknumbersClient/ManageTracknumbersClient";
import { MailBoxType } from '../providers/StoreProvider/slices/emailSlice/types/emails'


export type LetterDelayed = {
    letters: CommonLetterType[]
    messages: string[]
}

export type RestoreClientType = {
    client: IClientForProject,
    projectId: number
    restorer: string
}
export type DeclineClientType = {
    client: IArchiveClient,
    projectId: number
    decliner: string
}

export type NoteAdded = {
    client: IFullClientData
    note: NoteWithType[]
    projectId: number
}

export type TrackAdded = {
    clientId: number
    tracks: TrackType[]
}

export type ClientLogUpdated = {
    client: {
        id: number
    }
    item: HistoriesType
}

export type SMSHalted = {
    letters: ISMSLetter[]
    messages: string[]
}

export type ChatDelete = {
    chatId: number
    chatName: string
    username: string
}

type SocketDataType =
    & IClientForProject
    & IClientForProject[]
    & CommonLetterType[]
    & CommonLetterType
    & IFullClientData
    & ClientLogUpdated
    & LetterDelayed
    & MailBoxType
    & RestoreClientType
    & DeclineClientType
    & IShortClientData
    & NoteAdded
    & ISMSLetter[]
    & IChatMessage
    & EmailType[]
    & ChatDelete
    & TrackAdded


type SocketResponseType<T> = {
    event: EventNames,
    data: T
}

export const connectSocketIo = (
    name: string,
    id: number,
    retryCount: number,
    maxReconnects: number,
    dispatch: ThunkDispatch<StateSchema, ThunkExtraArg, AnyAction> & Dispatch<Action>,
    store?: Store<StateSchema>
) => {
    createConnection(name, id, retryCount, maxReconnects, dispatch, store)
}

export const socketReducer = (
    response: string,
    dispatch: ThunkDispatch<StateSchema, ThunkExtraArg, AnyAction> & Dispatch<Action>,
    store?: Store<StateSchema>
): void => {
    const objRes: SocketResponseType<SocketDataType> = JSON.parse(response)
    switch (objRes.event) {
        case EventNames.TRACK_UPDATED:
            dispatch(clientsActions.wsUpdateTrack(objRes.data))
            break
        case EventNames.CLIENT_SNOOZED:
            dispatch(clientsActions.wsSnoozeClient(objRes.data))
            break
        case EventNames.CLIENT_UNSNOOZED:
            dispatch(clientsActions.wsUnSnoozeClient(objRes.data))
            break
        case EventNames.MAIL_ARRIVED:
            dispatch(lettersActions.wsMailArrived(objRes.data))
            dispatch(clientsActions.wsMailArrived(objRes.data))
            dispatch(appActions.setClientDataForNotification({data: objRes.data, target: 'mail_arrived'}))
            dispatch(snackActions.setSnack({label: `У вас новое сообщение от`, typeLabel: 'info'}))
            break
        case EventNames.SMS_ARRIVED:
            dispatch(lettersActions.wsSMSMailArrived(objRes.data))
            dispatch(clientsActions.wsSMSMailArrived(objRes.data))
            dispatch(appActions.setClientDataForNotification({data: objRes.data, target: 'mail_arrived'}))
            dispatch(snackActions.setSnack({label: `У вас новое сообщение от`, typeLabel: 'info'}))
            break
        case EventNames.MAIL_SENT:
            dispatch(lettersActions.wsMailSent(objRes.data))
            dispatch(clientsActions.setLeadListItem(objRes.data))
            //@ts-ignore
            objRes.data[0].clientId && dispatch(clientsActions.clearUnreadMessages(objRes.data[0].clientId))
            break
        case EventNames.NEW_CLIENT_ADDED:
            break
        case EventNames.TRUSTED_CLIENT_ADDED:
            dispatch(clientsActions.wsAddTrustedClient(objRes.data))
            break
        case EventNames.NEW_CLIENT_ACCEPTED:
            dispatch(clientsActions.wsAddNewClientToAccepted(objRes.data))
            break
        case EventNames.TRUSTED_CLIENT_ACCEPTED:
            dispatch(clientsActions.wsAddTrustedClientToAccepted(objRes.data))
            break
        case EventNames.MAIL_SENDING_FAILED:
            dispatch(lettersActions.wsMailSendFailed(objRes.data))
            break
        case EventNames.LETTERS_DELAYED:
            dispatch(lettersActions.wsLettersDelayed(objRes.data))
            dispatch(appActions.setWsDelayedLetterInfo(objRes.data.messages))
            break
        case EventNames.LETTERS_HALTED:
            dispatch(lettersActions.wsLettersHalted(objRes.data))
            dispatch(appActions.setWsDelayedLetterInfo(objRes.data.messages))
            break
        case EventNames.MAILBOX_HAS_PROBLEMS:
            dispatch(emailsActions.setIsWSEmailProblematic(objRes.data))
            dispatch(snackActions.setSnack({label: `Проблема с почтой`, typeLabel: 'error'}))
            break
        case EventNames.CLIENT_RESTORED:
            dispatch(clientsActions.wsRestoreClient(objRes.data))
            dispatch(appActions.setClientDataForNotification({data: objRes.data, target: 'client_restored'}))
            dispatch(snackActions.setSnack({label: `Восстановление клиента успешно. `, typeLabel: 'success'}))
            break
        case EventNames.CLIENT_DECLINED:
            dispatch(archiveClientsActions.wsDeclineClient(objRes.data))
            dispatch(appActions.setClientDataForNotification({data: objRes.data, target: 'client_declined'}))
            dispatch(snackActions.setSnack({label: `Добавление в архив успешно. `, typeLabel: 'success'}))
            break
        case EventNames.CLIENT_SALES_FUNNEL_STEP_UPDATED:
            dispatch(clientsActions.wsChangeClientSalesFunnelStep(objRes.data))
            break
        case EventNames.CLIENT_NOTE_ADDED:
            if (objRes.data.client) dispatch(clientsActions.setLeadListItem(objRes.data.client))
            if (store?.getState().auth.name !== objRes.data.note[0].author) {
                dispatch(appActions.wsNoteAdded(objRes.data))
            }
            dispatch(lettersActions.wsNoteAdded(objRes.data.note))
            break
        case EventNames.TRACK_ADDED:
            dispatch(clientsActions.wsTrackAdded(objRes.data))
            break
        case EventNames.CLIENT_LOG_UPDATED:
            dispatch(lettersActions.wsClientLogUpdated(objRes.data))
            break
        case EventNames.SMS_SENT:
            dispatch(lettersActions.wsSMSMailSent(objRes.data))
            dispatch(clientsActions.setLeadListItem(objRes.data))
            //@ts-ignore
            objRes.data[0].clientId && dispatch(clientsActions.clearUnreadMessages(objRes.data[0].clientId))
            break

        case EventNames.SMS_HALTED:
            dispatch(lettersActions.wsSMSLetterDelayed(objRes.data))
            dispatch(appActions.setWsDelayedLetterInfo(objRes.data.messages))
            break
        case EventNames.MESSAGE_ADDED:
            if (store?.getState().auth.name !== objRes.data.author.username) {
                dispatch(chatsActions.incrementUnreadMessagesCount(objRes.data.chatId))
            }
            dispatch(chatsActions.wsChatMessageAdded(objRes.data))
            const {chatParameters} = objRes.data
            const currentActiveChat = store?.getState().chats.activeChat
            if (currentActiveChat?.id !== chatParameters.chatId) {
                objRes.data.needNotification && dispatch(notificationsThunks.showNotification({
                    message: <span>Вас упомянули в чате {getChatName(objRes.data)}</span>,
                    title: `Уведомление`,
                    variant: 'info',
                    actionCallback: chatsActions.openCollapsedChat(chatParameters.chatId)
                }))
            }
            break
        case EventNames.MESSAGE_PINNED:
            dispatch(chatsActions.wsChatMessagePinned(objRes.data))
            break
        case EventNames.CHAT_CREATED:
            dispatch(chatThunks.fetchGlobalChats(store?.getState().chats.paginationParams))
            break
        case EventNames.CHAT_DELETED:
            dispatch(chatsActions.deleteActiveChat({id: objRes.data.chatId, isOpen: false}))
            dispatch(chatThunks.fetchGlobalChats(store?.getState().chats.paginationParams))
            if (store?.getState().auth.name !== objRes.data.username) {
                dispatch(notificationsThunks.showNotification({
                    message:
                        <span>Чат <b>{objRes.data.chatName}</b> был удален пользователем <b>{objRes.data.username}</b></span>,
                    title: `Уведомление`,
                    variant: 'warning'
                }))
            }
            break
        case EventNames.MAILBOXES_FOR_NEW_CLIENTS_UPDATED:
            dispatch(emailsActions.wsMailboxForNewClientsUpdated(objRes.data))
            break
        case EventNames.MAILBOXES_FOR_DEAL_UPDATED:
            dispatch(emailsActions.wsMailboxForDealUpdated(objRes.data))
            break
        default:
            break
    }
}

export const checkConnectionStatus = (type: string, dispatch: ThunkDispatch<StateSchema, ThunkExtraArg, AnyAction> & Dispatch<Action>) => {
    dispatch(appActions.checkWsConnectionStatus(type))
}

const getChatName = (message: IChatMessage): JSX.Element => {
    const {chatParameters} = message
    const {type, clientFirstName, clientLastName, chatName} = chatParameters
    if (type === ChatType.CLIENT) return <em><b>{clientFirstName} {clientLastName}</b></em>
    if (type === ChatType.PRIVATE) return <em><b>{chatName}</b></em>
    return <em><b>Общий</b></em>
}