import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { ClientChat } from '../../../../../types'
import { notificationsActions } from '../../notitficationsSlice'
import { chatsActions } from '../slice/chatsSlice'

export interface RequestProps {
    clientId: number,
    isWsEvent: boolean
}


export const fetchClientChat = createAsyncThunk<
    ClientChat,
    RequestProps,
    ThunkConfig<any>>(
    'chats/fetch_client_chat',
    async (data, thunkApi) => {
        const { clientId, isWsEvent = false } = data
        const { dispatch, extra, rejectWithValue } = thunkApi
        dispatch(notificationsActions.disableNotificationActionCallback(true))

        try {
            const response = await extra.api().get<ClientChat>(`client-chat/${clientId}`)
            if (isWsEvent) {
                dispatch(chatsActions.wsChatAddedAfterSend(response.data))
            } else {
                dispatch(chatsActions.openGroupChat(response.data))
            }
            return response.data
        } catch (e) {
            handleServerNetworkError(e, dispatch, 'Произошла ошибка')
            return rejectWithValue(e)
        } finally {
            dispatch(notificationsActions.disableNotificationActionCallback(false))
        }
    }
)