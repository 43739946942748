import {FC, memo, useCallback} from 'react'

import {Box, createStyles} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

import {AttachFile} from './AttachFile/AttachFile'

type AttachedFilesPropsType = {
    attachedFiles: any[]
    removeAttachFile?: (index: number) => void
    isMessage?: boolean
    isClientLetter?: boolean
    clientId?: number
};

export const AttachedFiles: FC<AttachedFilesPropsType> = memo(({
                                                                   isMessage,
                                                                   attachedFiles,
                                                                   removeAttachFile,
                                                                   isClientLetter,
                                                                   clientId
                                                               }) => {
    const classes = useStyles()

    const deleteAttachFileClb = useCallback((index: number) => {
        removeAttachFile && removeAttachFile(index)
    }, [removeAttachFile])

    const containerStyle = isMessage ? classes.message_container : classes.attach_files_container

    return (
        <Box className={containerStyle} style={{alignItems: isClientLetter ? 'start' : 'end'}}>
            {attachedFiles && attachedFiles.map((file, i) => (
                <AttachFile key={file.name + '-' + i}
                            file={file}
                            index={i}
                            removeAttachFile={deleteAttachFileClb}
                            clientId={clientId}
                />
            ))}
        </Box>
    )
})

const useStyles = makeStyles(() =>
    createStyles({
        attach_files_container: {
            minHeight: 5,
            padding: '0 15px 5px 15px',
            marginBottom: 10,

            display: 'flex',
            flexWrap: 'wrap'
        },
        message_container: {
            padding: '0 5px 5px 20px',
            display: 'flex',
            flexDirection: 'column'
        }
    })
)