import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { snackActions } from '../../snackSlice'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { fetchLetterFragments } from './fetchLetterFragments'


export const deleteFragment = createAsyncThunk<{},
  number,
  ThunkConfig<any>>(
  'fragments/delete_fragment',
  async (id, thunkApi) => {
    const { dispatch, rejectWithValue, getState, extra } = thunkApi
    const params = getState().fragments.fragmentPagination
    try {
      await extra.api().delete(`letter-fragment/${id}`)
      dispatch(fetchLetterFragments(params))

      dispatch(snackActions.setSnack({
        label: 'Фрагмент успешно удален',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'При удалении фрагмента произошла ошибка')
      return rejectWithValue({})

    } finally {
    }
  }
)