import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { appActions } from '../../appSlice'
import { fetchProjectNotes } from './fetchProjectNotes'
import { FilesType } from '../../../../../Components/Inputs/FilesAttach/FilesAttach'

type RequestProps = {
  name: string,
  project: number,
  values: string[]
  attachedFiles: FilesType
  defaultAttach: string
}

export const createNoteForProject = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'notes/create_note_for_project',
  async (data, { dispatch, rejectWithValue, getState, extra }) => {

    const id = getState().notes.projectId
    dispatch(appActions.setIsLoad(true))
    try {
      await extra.api().post(`project-note`,data)
      dispatch(fetchProjectNotes(id))

      dispatch(snackActions.setSnack({
        label: 'Заметка была успешно создана',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch,'Произошла ошибка при создании заметки')
      return rejectWithValue({})
    } finally {
      dispatch(appActions.setIsLoad(false))
    }
  }
)