import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { ResponseTemplateType } from '../types/templates'
import { appActions } from '../../appSlice'


export const fetchTemplateInfo = createAsyncThunk<ResponseTemplateType,
  number,
  ThunkConfig<any>>(
  'templates/fetch_template_info',
  async (templateId, { dispatch, rejectWithValue, extra }) => {

    dispatch(appActions.setStatus('loading'))
    try {
      const res = await extra.api().get<ResponseTemplateType>(`letter-template/${templateId}`)

      return res.data

    } catch (e) {
      dispatch(appActions.setError('Не удалось получить информацию о шаблоне'))
      handleServerNetworkError(e, dispatch, 'Не удалось получить информацию о шаблоне')
      return rejectWithValue({})
    } finally {
      dispatch(appActions.setStatus('success'))
    }
  }
)