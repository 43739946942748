import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { appActions } from '../../appSlice'
import { fetchChains } from './fetchChains'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'


interface RequestProps {
  id: number,
  projectId: number,
  checked: boolean,
  projectName: string
  params?: PaginationParamsType
}

type FullFilledResponse = {
  id: number,
  checked: boolean,
  projectName: string
}

export const linkChainToCurrentProject = createAsyncThunk<FullFilledResponse,
  RequestProps,
  ThunkConfig<any>>(
  'chains/link_chain_to_current_project',
  async (data, { dispatch, getState, rejectWithValue, extra }) => {
    const { id, projectId, checked } = data

    dispatch(appActions.setIsLoad(true))

    try {
      const response = await extra.api().patch(`chain-letters/${id}/project/${projectId}/edit`, { checked })

      console.log('linkChainToCurrentProject', data)
      data.params && dispatch(fetchChains(data.params))

      return response.data
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось обновить данные')
      return  rejectWithValue({})
    } finally {
      dispatch(appActions.setIsLoad(false))

    }
  }
)