import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { appActions } from '../../appSlice'
import { archiveProjectsActions } from '../slice/archiveProjectsSlice'
import { CommonResponseArchiveProjectsType } from '../types/archiveProjects'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'
import { prepareFilterParams } from "../../../../../Api/API";

export const fetchArchiveProjects = createAsyncThunk<CommonResponseArchiveProjectsType,
  PaginationParamsType,
  ThunkConfig<any>>(
  'archiveProjects/getArchiveProjects',
  async (params, thunkApi) => {
    
    const { dispatch, getState, rejectWithValue, extra } = thunkApi
    const archiveProjects = getState().archiveProjects.projectsList
    !archiveProjects && dispatch(appActions.setIsFetchClientData(true))
    const query = params ? prepareFilterParams(params): ''
    try {
      const response = await extra.api().get(`archived-projects?${query}`)
      params && dispatch(archiveProjectsActions.setParams(params))
      return response.data
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось загрузить архивные проекты')
      return rejectWithValue(e)
    } finally {
      dispatch(appActions.setIsFetchClientData(false))
    }
  }
)