import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { prepareFilterParams } from '../../../../../Api/API'

type ResponseType = {
    isUnique: boolean
    duplicates: string[]
} | undefined

type ApiResponseType = {
    isUnique: boolean
    duplicates: string[]
}

type RequestPropsType = {
    projectId: number
    notes?: string[]
}

export const checkProductNotesByDuplicates = createAsyncThunk<
    ResponseType,
    RequestPropsType,
    ThunkConfig<any>
>(
    'product_notes/check_product_notes_by_duplicates',
    async (data, thunkApi) => {
        try {
            const res = await thunkApi.extra.api().post<ApiResponseType>(`project/${data.projectId}/product-notes/check-unique`, {
                notes: data.notes
            })
            console.log('checkProductNotesByDuplicates', res)
            
            return res.data
        } catch (e) {
            handleServerNetworkError(e, thunkApi.dispatch, 'Не удалось получить список менеджеров клиента')
        } finally {
            
        }
    }
    
)