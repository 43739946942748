import {createAsyncThunk} from '@reduxjs/toolkit'
import {ThunkConfig} from '../../../config/StateSchema'
import {snackActions} from '../../snackSlice'
import {handleServerNetworkError} from '../../../../../helpers/error-helpers'
import {clientsActions} from '../slice/clientsSlice'
import {AxiosResponse} from "axios";

type ResponseType = {
        key: string
        name: string
}


interface RequestProps {
    clientId?: number,
    editFields: any //todo types
}

export const editClientSalesFunnel = createAsyncThunk<ResponseType | null,
    RequestProps, // todo types
    ThunkConfig<any>>(
    'client/edit_client_sales_funnel',
    async (data, {dispatch, rejectWithValue, extra}) => {
        const {clientId, editFields} = data
        try {
            if (clientId) {
                const res = await extra.api().patch<ResponseType, AxiosResponse<ResponseType>>(`client/${clientId}/sales-funnel`, editFields)
                // if (editFields.step) {
                    clientId && dispatch(clientsActions.updateLeadListForSalesFunnelStep({clientId, editFields}))
                // } else {
                //     clientId && dispatch(clientsActions.updateLeadListForSalesFunnelStatus({clientId, editFields}))
                // }
                dispatch(snackActions.setSnack({
                    label: 'Данные успешно обновлены',
                    typeLabel: 'success'
                }))
                return res.data
            } else return null
        } catch (e) {
            handleServerNetworkError(e, dispatch, 'Не удалось обновить данные')
            return rejectWithValue({})
        }
    }
)