import { SalesFunnelStepType } from '../../types'
import { StatusLeadMap } from '../../types/client'

type StatusesType = {
  [K in SalesFunnelStepType]: string
}

export const statuses: StatusesType = {
  // NEW: 'Новый',
  // PAUSED: 'На паузе',
  // DECLINED: 'Отказ'
  NEW: 'Новый лид',
  CLIENT_ACCEPTED: 'Принят',
  CONTACT_ATTEMPT: 'Написали',
  CLIENT_ANSWERED: 'Ответил',
  ADDRESS_ENTERED: 'Заполнены данные',
  READY_TO_SEND: 'Готов к отправке',
  ORDER_PREPARED: 'Оформляем заказ',
  ORDER_SENT: 'Отправляем заказ',
  ORDER_RECEIVED: 'Заказ получен',
  DEAL_CLOSED: 'Закрыли продажу'
}

export const statusesClient = {
  ACCEPTED: 'Принятый',
  DECLINED: 'Отказ',
}


export type SalesFunnelStepsType = {
  [K in SalesFunnelStepType]: string
}
export type StatusLeadMapForGlobalLeads = {
  [K in StatusLeadMap]: string
}

export const steps: SalesFunnelStepsType = {
  NEW: 'Новый лид',
  CLIENT_ACCEPTED: 'Принят',
  CONTACT_ATTEMPT: 'Написали',
  CLIENT_ANSWERED: 'Ответил',
  ADDRESS_ENTERED: 'Заполнены данные',
  READY_TO_SEND: 'Готов к отправке',
  ORDER_PREPARED: 'Оформляем заказ',
  ORDER_SENT: 'Отправляем заказ',
  ORDER_RECEIVED: 'Заказ получен',
  DEAL_CLOSED: 'Закрыли продажу'
}