import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { notificationsActions } from '../slice/notificationsSlice'


type RequestProps = {
  id: string,
  delay?: number
}

export const deleteNotificationDelayed = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'notifications/delete_notification_delayed',
  async (data, { dispatch, getState }) => {

    const { id, delay = 8500 } = data
    const noTimeout = getState().notifications.noTimeout

    dispatch(notificationsActions.setRenderedIds(id))
    setTimeout(() => {
      if (!noTimeout.includes(id)) dispatch(notificationsActions.deleteNotification(id))
    }, delay)

  }
)