import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { FetchMacrosParams } from '../types/macros'
import { snackActions } from '../../snackSlice'
import { macrosActions } from '../slice/macrosSlice'
import { projectLetterSettingActions } from '../../projectLetterSettingSlice'

type RequestProps = {
  macrosParams: FetchMacrosParams,
  target?: 'header' | 'footer'
}

export const fetchMacrosPreview = createAsyncThunk<string | null,
  RequestProps,
  ThunkConfig<string>>(
  'macros/fetch_macros_preview',
  async (data, { dispatch, rejectWithValue, extra }) => {
    const { macrosParams, target } = data
    dispatch(macrosActions.setMacrosIsLoad(true))
    try {
      const response = await extra.api().post<string>(`macros/replace-content`, macrosParams, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      if (target) {
        if (target === 'footer') {
          dispatch(projectLetterSettingActions.setProjectFooterForClient(response.data))
        } else {
          dispatch(projectLetterSettingActions.setProjectHeaderForClient(response.data))
        }
        return null
      } else {
        // dispatch(snackActions.setSnack({
        //   label: 'Превью успешно сгенерированно',
        //   typeLabel: 'success'
        // }))
        return response.data
      }

      // dispatch(addSnack(response.data[0].content, 'error'))  todo было в старой санке (проверить)
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось сгенерировать превью макросов')
      return rejectWithValue('')
    }
  }
)