import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { projectSpreedSheetActions } from '../slice/projectSpreedSeetSlice'



type RequestProps = {
  projectId: number,
  source: number,
  data: { fieldOrder: string[] }
}

export const configureSpreadSheetFieldOrder = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'spreadSheets/configure_spread_sheet_field_order',
  async (dto, { dispatch, rejectWithValue, extra }) => {

    const { data, source, projectId } = dto

    try {
      await extra.api().post(`project/${projectId}/spreadsheets/${source}/configure`, data)
      dispatch(projectSpreedSheetActions.clearSpreadSheetData())
      dispatch(snackActions.setSnack({
        label: 'Таблица сконфигурирована',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось применить настройки')
      return rejectWithValue({})
    }
  }
)