import { FC, useEffect } from 'react'
import { Box, createStyles, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { List, SvgSelector } from '../../ui-kit'
import { appActions, appSelectors } from '../../providers/StoreProvider/slices/appSlice'
import { useAppDispatch } from '../../providers/StoreProvider/hooks/useAppDispatch'

type InfoSnackPropsType = {}

export const InfoSnack: FC<InfoSnackPropsType> = () => {

 const info = useSelector(appSelectors.getDelayedLetterInfo)
  const dispatch = useAppDispatch()
  const classes = useStyles()

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(appActions.setWsDelayedLetterInfo(null))
    }, 20000)
    return () => clearTimeout(timer)
  }, [info, dispatch])

  const onHideInfoHandler = (): void => {
    dispatch(appActions.setWsDelayedLetterInfo(null))
  }

  return (
    <Box className={`${classes.infoContainer} ${info && classes.active}`}>
      <Alert severity='info'>
        <AlertTitle>Info</AlertTitle>
        {info && info.length > 1 ?
          <List items={info} renderItem={(item, index) => (
            <Typography style={{ fontWeight: 700 }}
                        key={item + '-' + index}>
              • {item}
            </Typography>
          )} />
          :
          <strong>{info && info[0]}</strong>
        }

        <Typography component='span'
                    role='presentation'
                    onClick={onHideInfoHandler}
                    className={classes.close}>
          <SvgSelector id='close-icon' />
        </Typography>
      </Alert>
    </Box>
  )
}


const useStyles = makeStyles(() =>
  createStyles({
    infoContainer: {
      position: 'fixed',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      right: '-200px',
      overflow: 'hidden',
      bottom: 20,
      minWidth: '200px',
      maxWidth: '460px',
      padding: '10px',
      borderRadius: '5px',
      transition: 'all 0.3s ease-out'
    },
    active: {
      zIndex: 500,
      right: 20
    },
    close: {
      position: 'absolute',
      cursor: 'pointer',
      right: 4,
      top: 20,
      transform: 'translate(0, -50%)',
      marginRight: '3px',
      border: '2px solid rgba(255, 0, 13, 0)',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat'
    }
  })
)