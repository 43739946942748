import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { ChainForSelect } from '../types/chains'


export const fetchChainsForSelect = createAsyncThunk<ChainForSelect[],
  number | undefined,
  ThunkConfig<any>>(
  'chains/fetch_chains_for_select',
  async (projectId, thunkApi) => {

    const { dispatch, rejectWithValue, extra } = thunkApi

    try {
      const response = await extra.api().get<ChainForSelect[]>(`chains-letters-for-select?filter[project]=${projectId}`)
      return response.data
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось загрузить список цепочек для проекта')
      return rejectWithValue({})
    } finally {}
  }
)