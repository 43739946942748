import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { AddUserDataDto } from '../types/projectUser'
import {
  fetchClientManagers,
  fetchOrderManagers,
  fetchProjectManagers, 
  fetchProjectUsersWithoutRole} from '../services'
import { projectUserActions } from '../slice/projectUserSlice'
import { snackActions } from '../../snackSlice'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'


type RequestProps = {
  userId: number,
  data: AddUserDataDto
}

export const addProjectUser = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'projectUser/add_project_user',
  async (dto, { dispatch, rejectWithValue, getState, extra }) => {

    const { data, userId } = dto

    // const projectId = getState().projectUser.project_id
    dispatch(projectUserActions.setIsAdd(true))
    try {
      await extra.api().post(`project-user/user/${userId}`, data)
      if (data.role === 'PROJECT_MANAGER') {
        await dispatch(fetchProjectManagers({projectId: data.project}))
      } else if (data.role === 'CLIENT_MANAGER' || data.role === 'ASSISTANT_OPERATOR') {
        await dispatch(fetchClientManagers({projectId: data.project}))
      } else {
        await dispatch(fetchOrderManagers({projectId: data.project}))
      }

      /** Список свободных пользователей должен обновляться после каждого изменения */
      await dispatch(fetchProjectUsersWithoutRole(data.project))

      data.role === 'ASSISTANT_OPERATOR'
        ? dispatch(snackActions.setSnack({
          label: 'Помощник был успешно добавлен',
          typeLabel: 'success'
        }))
        : dispatch(snackActions.setSnack({
          label: 'Менеджер был успешно добавлен',
          typeLabel: 'success'
        }))
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось добавить пользователя')
      return rejectWithValue({})
    } finally {
      dispatch(projectUserActions.setIsAdd(false))
    }
  }
)