import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { ClientForSelect } from '../types/macros'
import { fetchMacrosPreview } from './fetchMacrosPreview'

type RequestProps = {
  projectId: number,
  content: string
}


export const fetchFirstClientForSelect = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'macros/fetch_first_client_for_select',
  async (data, { dispatch, rejectWithValue, extra }) => {
    const { content, projectId } = data

    try {
      const response = await extra.api().get<ClientForSelect[]>(`clients-for-select/project/${projectId}`)
      if (response.data.length > 0) {
        dispatch(fetchMacrosPreview({
          macrosParams: {
            content,
            clientId: response.data[0].id
          }
        }))

      } else {
        dispatch(fetchMacrosPreview({
          macrosParams: {
            content,
            clientId: 2 // todo хардкод (нужна ли вообще эта санка ?? )
          }
        }))
      }
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить клиента')
      return rejectWithValue({})
    }
  }
)