import { makeStyles } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'
import img from '../../z_old_site/assets/images/illustrations/500.svg'

export const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      height: '100vh',
      position: 'relative',
      backgroundImage: `url(${img})`,
      backgroundRepeat: 'no-repeat'
    },
    description: {
      position: 'absolute',
      top: '30%',
      left: '50%',
      padding: '10px'
    },
    typography: {
      textAlign: 'center',
      '& a:not(.MuiButtonBase-root)': {
        fontSize: '1rem',
        color: '#5383ff',
        textDecoration: 'underLine'
      }
    },
  })
)
