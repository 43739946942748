import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { fetchWaitingForApproveDeclineClients } from './fetchWaitingForApproveDeclineClients'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'

interface RequestProps {
  projectId: number,
  client_id: number
}

export const deleteApprovalClient = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'archiveClients/deleteApprovalClient',
  async (data, thunkApi) => {
    const { dispatch, getState,  rejectWithValue, extra } = thunkApi
    const { paginationParams } = getState().archiveClients
    try {
      await extra.api().delete(`client/${data.client_id}/delete`)
      await dispatch(fetchWaitingForApproveDeclineClients({
        projectId: data.projectId,
        params: paginationParams
      }))
      dispatch(snackActions.setSnack({
        label: 'Клиент был успешно удален',
        typeLabel: 'success'
      }))
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось удалить клиента')
        return rejectWithValue(e)
    } finally {
    }
  }
)