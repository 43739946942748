import { Box, IconButton, Typography } from '@material-ui/core'
import WifiOffIcon from '@material-ui/icons/WifiOff';
import ReplayIcon from '@material-ui/icons/Replay';
import { makeStyles } from '@material-ui/core/styles'

export const NoInternetPage = () => {
    const cls = useStyles()

    const handleReload = () => {
        window.location.reload()
    }
    return (
        <Box className={cls.pageWrapper}>
            <Box className={cls.content}>
                <WifiOffIcon className={cls.icon}/>
                <Typography className={cls.title}>Нет подключения к интернету</Typography>
                <Typography className={cls.text}>Проверьте соединение или попробуйте обновить страницу</Typography>
               <IconButton
                   onClick={handleReload}
                   className={cls.iconButton}
               >
                   <ReplayIcon/>
               </IconButton>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles({
    pageWrapper: {
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    content : {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        fontWeight: 500,
        fontSize: '29px',
        lineHeight: '32px'
    },
    text: {
        fontSize: '14px'
    },
    icon: {
        color: '#5383ff',
        width: 60,
        height: 60
    },
    iconButton: {
        '&:hover': {
            color: '#5383ff',
        }
    }
})