export type PopoverNameType =
  'EMAILS_FOR_DEALING'
  | 'MESSAGE_ITEM'
  | 'SMS_MESSAGE_ITEM'
  | 'SELECT_TIME'
  | 'CHOOSE_HOUR'
  | 'DATE_PICKER'
  | 'SEND_TEMPLATE'
  | 'SEND_CHAIN'
  | 'CREATE_USER_CHAT'
  | 'WS_CONNECTION_STATUS'
  | 'BUTTON_WITH_SELECT'
  | 'SNOOZE_ON_N_HOURS'
  | 'SNOOZE_DATE_PICKER'
  | 'SHOW_PRODUCT_NOTES_HISTORY'
  | 'SHOW_GROUP_USER_CHAT_MEMBERS'
  | 'CANCEL_SEND_CHAIN'
  | 'HOT_KEY_CONTROL'
  | 'ARCHIVE_PROJECT_MANAGERS'
  | 'HEADER_USER_NOTIFICATIONS'
  | 'SHOW_DETAILED_STATISTIC'
  | 'UPLOAD_TRACKS_FORMAT'
  | 'PRODUCT_INFO_UPLOADED_TRACKS'
  | 'LAST_FETCHING_HISTORY'


type PopoverOriginSettings = {
  vertical: number | 'center' | 'bottom' | 'top'
  horizontal: number | 'left' | 'right' | 'center'
}

export type PopoverOriginType = {
  [key in PopoverNameType]: PopoverOriginSettings
}

export const transformOrigin: PopoverOriginType = {
  EMAILS_FOR_DEALING: { vertical: 'center', horizontal: 'right' },
  MESSAGE_ITEM: { vertical: 'top', horizontal: 'center' },
  SMS_MESSAGE_ITEM: { vertical: 'top', horizontal: 'center' },
  SELECT_TIME: { vertical: 'bottom', horizontal: 'center' },
  CHOOSE_HOUR: { vertical: 'bottom', horizontal: 'right' },
  DATE_PICKER: { vertical: 'bottom', horizontal: 'right' },
  SEND_TEMPLATE: { vertical: 'center', horizontal: 'center' },
  SEND_CHAIN: { vertical: 'center', horizontal: 'center' },
  CREATE_USER_CHAT: { vertical: 'top', horizontal: 'center' },
  WS_CONNECTION_STATUS: { vertical: 'top', horizontal: 'center' },
  BUTTON_WITH_SELECT: { vertical: 'bottom', horizontal: 'left' },
  SNOOZE_ON_N_HOURS: { vertical: 'center', horizontal: 'left' },
  SNOOZE_DATE_PICKER: { vertical: 'bottom', horizontal: 'center' },
  SHOW_PRODUCT_NOTES_HISTORY: { vertical: 'center', horizontal: 'center' },
  SHOW_GROUP_USER_CHAT_MEMBERS: { vertical: 'top', horizontal: 'center' },
  CANCEL_SEND_CHAIN: { vertical: 'center', horizontal: 'center' },
  HOT_KEY_CONTROL: { vertical: 'center', horizontal: 'left' },
  ARCHIVE_PROJECT_MANAGERS: { vertical: 'top', horizontal: 'right' },
  HEADER_USER_NOTIFICATIONS: { vertical: 'top', horizontal: 'left' },
  SHOW_DETAILED_STATISTIC: { vertical: 'center', horizontal: 'center' },
  UPLOAD_TRACKS_FORMAT: { vertical: 'bottom', horizontal: 'center' },
  PRODUCT_INFO_UPLOADED_TRACKS: { vertical: 'center', horizontal: 'right' },
  LAST_FETCHING_HISTORY: { vertical: 'bottom', horizontal: 'center' },
}

export const anchorOrigin: PopoverOriginType = {
  EMAILS_FOR_DEALING: { vertical: 'center', horizontal: 'left' },
  MESSAGE_ITEM: { vertical: 'bottom', horizontal: 'center' },
  SMS_MESSAGE_ITEM: { vertical: 'bottom', horizontal: 'center' },
  SELECT_TIME: { vertical: 'top', horizontal: 'center' },
  CHOOSE_HOUR: { vertical: 'bottom', horizontal: 'left' },
  DATE_PICKER: { vertical: 'bottom', horizontal: 'left' },
  SEND_TEMPLATE: { vertical: 'center', horizontal: 'center' },
  SEND_CHAIN: { vertical: 'center', horizontal: 'center' },
  CREATE_USER_CHAT: { vertical: 'bottom', horizontal: 'center' },
  WS_CONNECTION_STATUS: { vertical: 'bottom', horizontal: 'center' },
  BUTTON_WITH_SELECT: { vertical: 'top', horizontal: 'left' },
  SNOOZE_ON_N_HOURS: { vertical: 'center', horizontal: 'right' },
  SNOOZE_DATE_PICKER: { vertical: 'center', horizontal: 'right'},
  SHOW_PRODUCT_NOTES_HISTORY: { vertical: 'center', horizontal: 'center'},
  SHOW_GROUP_USER_CHAT_MEMBERS: { vertical: 'bottom', horizontal: 'center'},
  CANCEL_SEND_CHAIN: { vertical: 'center', horizontal: 'center'},
  HOT_KEY_CONTROL: { vertical: 'center', horizontal: 'right'},
  ARCHIVE_PROJECT_MANAGERS: { vertical: 'bottom', horizontal: 'left'},
  HEADER_USER_NOTIFICATIONS: { vertical: 'bottom', horizontal: 'right'},
  SHOW_DETAILED_STATISTIC: { vertical: 'center', horizontal: 'center'},
  UPLOAD_TRACKS_FORMAT: { vertical: 'center', horizontal: 'center'},
  PRODUCT_INFO_UPLOADED_TRACKS: { vertical: 'center', horizontal: 'center'},
  LAST_FETCHING_HISTORY: { vertical: 'top', horizontal: 'center'},
}