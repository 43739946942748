import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { WigetType } from '../../../../../Redux/widgetReducer'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { instance } from '../../../../../Api/API'

export const fetchAllWidget = createAsyncThunk<WigetType[],
  void,
  ThunkConfig<any>>(
  'widgets/fetch_all_widget',
  async (_, { dispatch, rejectWithValue, extra }) => {

    try {
      console.log('extra', extra)
      const res = await extra.api().get<WigetType[]>(`user-widgets`)

      return  res.data
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить список виджетов')
      return rejectWithValue({})
    } finally {
    }
  }
)