import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { prepareFilterParams } from '../../../../../Api/API'
import { AssignAssistantOperatorType } from '../types/projectUser'
import { fetchProjectUsersWithoutRole } from './fetchProjectUsersWithoutRole'
import { fetchClientManagers } from './fetchClientManagers'
import { snackActions } from '../../snackSlice'

type ResponseType = {}

type ApiResponseType = {
    
}

type RequestPropsType = {
    projectId: number
    params?: PaginationParamsType
    data: AssignAssistantOperatorType
}

export const assignAssistantOperator = createAsyncThunk<
    ResponseType,
    RequestPropsType,
    ThunkConfig<any>
>(
    'slice/assign_assistant_operator',
    async (data, thunkApi) => {
        const query = data.params ? prepareFilterParams(data.params) : ''
        try {
            console.log('assignAssistantOperator data', data)
            // /project/{id}/assign_assistant_operators
            const res = await thunkApi.extra.api()
                .patch<ApiResponseType>(`project-user/${data.projectId}/assign_assistant_operators`, data.data)
            
            /** Список свободных пользователей должен обновляться после каждого изменения */
            await thunkApi.dispatch(fetchProjectUsersWithoutRole(data.projectId))
            await thunkApi.dispatch(fetchClientManagers({projectId: data.projectId}))

            thunkApi.dispatch(snackActions.setSnack({
                label: 'Ассистент был успешно добавлен',
                typeLabel: 'success'
            }))

            return res.data
        } catch (e) {
            handleServerNetworkError(e, thunkApi.dispatch, 'Не удалось получить список менеджеров клиента')
        } finally {
            
        }
    }
    
)