import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { MacrosPreviewListType } from '../../../../../Redux/macrosReducer'

export const fetchMacrosPreviewList = createAsyncThunk<MacrosPreviewListType,
  number,
  ThunkConfig<any>>(
  'macros/fetch_macros_preview_list',
  async (clientId, { dispatch, rejectWithValue, extra }) => {

    try {
      const response = await extra.api().get<MacrosPreviewListType>(`macros/client/${clientId}/list`)

      return response.data

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить превью макросов')
      return rejectWithValue({})
    }
  }
)