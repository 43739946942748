import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { ThunkConfig } from '../../../config/StateSchema'
import { ISMSLetter } from '../../../../../Api/lettersApi'
import { lettersActions } from '../../lettersSlice'
import { snackActions } from '../../snackSlice'
import { SMSRequestForEdit } from '../types/sms'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'

type RequestProps = {
  smsId: number,
  data: SMSRequestForEdit
}

export const editPlannedSMSLetter = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'sms/edit_planned_sms_letter',
  async (dto, { dispatch, rejectWithValue, extra }) => {

    const { smsId, data } = dto

    try {
      const res = await extra.api().patch<ISMSLetter,
        AxiosResponse<ISMSLetter>>(`manager-sms/${smsId}/edit`, data)
      dispatch(lettersActions.editPlannedSmsLetter(res.data))

      dispatch(snackActions.setSnack({
        label: 'сообщение успешно изменено',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Произошла ошибка при редактирвании письма')
      return rejectWithValue({})
    }
  }
)