import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ClientsSchema } from '../types/clients'
import { IClientForProject, IFullClientData, IShortClientData, ProjectNote, Track } from '../../../../../types'
import {
    editClientData,
    fetchClientData,
    fetchClientsByProject,
    fetchClientsByProjectForOrderManager,
    fetchClientsByProjectForProjectManager,
    fetchGlobalClientsList,
    fetchMailboxForDealForSelect,
    fetchMyClients,
    fetchNewClients,
    fetchNewClientsIds,
    fetchSalesFunnelDescribeForClient,
    insertClients,
    sendReshipmentReasonForClient,
    fetchMyClientsWidget, fetchProjectSettingsReplacedByClient, editClientSalesFunnel,
    cancelReshipment
} from '../services'
import { filterLeadList } from '../utils/filterLeadList'
import { CommonLetterType, ISMSLetter } from '../../../../../Api/lettersApi'
import { NoteAdded, RestoreClientType, TrackAdded } from '../../../../../Redux/socket-io-handler'
import {
    checkWhereIsClient,
    getClientsListAfterChangeNoteData,
    getClientsListAfterClearUnreadMessages,
    getClientsListAfterClientEdit,
    getClientsListAfterClientFunnelStepEdit,
    getClientsListAfterSetEmptyMacros,
    getClientsListAfterUpdateSalesFunnelStep,
    getClientsStateAfterMailArrived
} from '../../../helpers/helpers'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'
import { ProductNotesType } from '../../../../../types/client'


const initialState: ClientsSchema = {
    isAccepted: false,
    lastProjectId: null,
    isUpdate: false,
    updateErrors: [],
    clientId: null,
    clientTracks: [],
    clientsWithStatusNew: [],
    hasClientsInProject: true,
    isClientChanged: false,
    isFetchGlobalClientsList: false,
    globalClientsPaginationParams: { per_page: 15, page: 1 },
    searchError: false
}


export const clientsSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        isClientChanged: (state, action: PayloadAction<boolean>) => {
            state.isClientChanged = action.payload
        },
        setIsUpdateClient: (state, action: PayloadAction<boolean>) => {
            state.isUpdate = action.payload
        },
        setClientId: (state, action: PayloadAction<string>) => {
            state.clientId = +action.payload
        },
        setIsFetchData: (state, action: PayloadAction<boolean>) => {
            state.isFetchGlobalClientsList = action.payload
        },
        setTrackItem: (state, action: PayloadAction<Track>) => {
            if (state.fullClientData) {
                state.fullClientData.tracks = state.fullClientData.tracks.map(t => t.id === action.payload.id ? action.payload : t)
            }
        },
        setLeadListItem: (state, action: PayloadAction<IClientForProject>) => {
            //state.leadsList?.map(l => l.id === action.payload.id ? action.payload : l)
            state.activeClients = state.activeClients?.map(l => l.id === action.payload.id ? action.payload : l)
            state.snoozedClients = state.snoozedClients?.map(l => l.id === action.payload.id ? action.payload : l)
        },
        setEmptyMacros: (state, action: PayloadAction<{ clientId: number, status?: boolean }>) => {
            const clientStatus = checkWhereIsClient(state, action.payload.clientId)
            if (clientStatus === 'active') {
                state.activeClients = getClientsListAfterSetEmptyMacros(state, action.payload, 'activeClients')
            }
            if (clientStatus === 'snooze') {
                state.snoozedClients = getClientsListAfterSetEmptyMacros(state, action.payload, 'snoozedClients')
            }
            // const { clientId, status } = action.payload
            // state.leadsList && state.leadsList.map(l => l.id === clientId ?
            //   { ...l, hasEmptyMacrosInMessages: status ? status : false } : l)
        },
        setIsAccepted: (state, action: PayloadAction<boolean>) => {
            state.isAccepted = action.payload
        },
        updateLeadListForSalesFunnelStep: (state, action: PayloadAction<{ clientId: number, editFields: { step: string } }>) => {
            const clientStatus = checkWhereIsClient(state, action.payload.clientId)
            if (clientStatus === 'active') {
                state.activeClients = getClientsListAfterUpdateSalesFunnelStep(state, action.payload, 'activeClients')
            }
            if (clientStatus === 'snooze') {
                state.snoozedClients = getClientsListAfterUpdateSalesFunnelStep(state, action.payload, 'snoozedClients')
            }
            //  state.fullClientData = state.fullClientData && {...state.fullClientData}
            // state.leadsList?.map(l => l.id === action.payload.clientId ? {
            //   ...l,
            //   salesFunnelStep: action.payload.editFields.step
            // } : l)
        },
        setProjectNoteForClient: (state, action: PayloadAction<ProjectNote>) => {
            state.fullClientData = state.fullClientData && { ...state.fullClientData, projectNote: action.payload }
        },
        setProductNotesForClient: (state, action: PayloadAction<{
            productNotes: ProductNotesType | null
        }>) => {
            state.fullClientData = state.fullClientData && {
                ...state.fullClientData,
                productNotes: action.payload.productNotes
            }
        },
        addClientToArchive: (state, { payload }: { payload: number }) => {
            const clientStatus = checkWhereIsClient(state, payload)
            if (clientStatus === 'snooze') state.snoozedClients = state.snoozedClients?.filter(cl => cl.id !== payload)
            if (clientStatus === 'active') state.activeClients = state.activeClients?.filter(cl => cl.id !== payload)
        },

        //Todo Зачем этот экшен (не используется)
        // updateLeadListForSalesFunnelStatus: (state, action: PayloadAction<{ clientId: number, editFields: { step: string } }>) => {
        //     const clientStatus = checkWhereIsClient(state, action.payload.clientId)
        //     if (clientStatus === 'snooze') state.snoozedClients = state.snoozedClients?.map(cl => cl.id !== action.payload.clientId ? {...cl} : cl)
        //     if (clientStatus === 'active') state.activeClients = state.activeClients?.map(cl => cl.id !== action.payload.clientId ? {...cl} : cl)
        //     // state.leadsList?.map(l => l.id === action.payload.clientId ? { ...l } : l)
        // },
        clearLeadManagePage: (state) => {
            state.leadsList = undefined
            state.activeClients = undefined
            state.snoozedClients = undefined
            state.fullClientData = undefined
            state.clientId = null
            state.clientRights = undefined
            state.searchError = false
            state.letterSettings = undefined
        },
        clearUnreadMessages: (state, action: PayloadAction<number>) => {
            const clientStatus = checkWhereIsClient(state, action.payload)
            if (clientStatus === 'active') {
                state.activeClients = getClientsListAfterClearUnreadMessages(state, action.payload, 'activeClients')
            }
            if (clientStatus === 'snooze') {
                state.snoozedClients = getClientsListAfterClearUnreadMessages(state, action.payload, 'snoozedClients')
            }
            //state.leadsList?.map(l => l.id === action.payload ? { ...l, hasUnreadLetter: false } : l)
        },
        clearClientData: (state) => {
            state.fullClientData = undefined
            state.letterSettings = undefined
            state.clientId = null
            state.salesFunnelDescribe = undefined
        },
        setErrors: (state, action: PayloadAction<string[]>) => {
            state.updateErrors = action.payload
        },
        setParams: (state, action: PayloadAction<PaginationParamsType>) => {
            state.globalClientsPaginationParams = action.payload
        },
        setNewClientsIds: (state, action: PayloadAction<number[] | undefined>) => {
            state.newClientsIds = action.payload
        },
        setHasClientsInProject: (state, action: PayloadAction<boolean>) => {
            state.hasClientsInProject = action.payload
        },
        clearRowLeadsPage: (state) => {
            state.newClients = undefined
            state.clientId = null
        },


        // socket-events
        wsSnoozeClient: (state, action: PayloadAction<IFullClientData>) => {
            // state.leadsList?.map(l => l.id === action.payload.id ? { ...l, snooze: true } : l)
            const snoozedClient = state.activeClients?.find(cl => cl.id === action.payload.id)
            state.activeClients = state.activeClients?.filter(cl => cl.id !== action.payload.id)
            if (snoozedClient) state.snoozedClients = state.snoozedClients && [snoozedClient, ...state.snoozedClients]
        },

        wsUnSnoozeClient: (state, action: PayloadAction<IFullClientData>) => {
            //state.leadsList?.map(l => l.id === action.payload.id ? { ...l, snooze: false } : l)
            const unSnoozedClient = state.snoozedClients?.find(cl => cl.id === action.payload.id)
            state.snoozedClients = state.snoozedClients?.filter(cl => cl.id !== action.payload.id)
            if (unSnoozedClient) state.activeClients = state.activeClients && [unSnoozedClient, ...state.activeClients]
        },
        wsMailArrived: (state, action: PayloadAction<CommonLetterType[]>) => {
            const clientStatus = checkWhereIsClient(state, action.payload[0].clientId)
            getClientsStateAfterMailArrived(state, action.payload[0], clientStatus)
            // if (clientStatus === 'snooze') state.snoozedClients = getClientsListAfterMailArrived(state, action.payload, 'snoozedClients')
            // if (clientStatus === 'active') state.activeClients = getClientsListAfterMailArrived(state, action.payload, 'activeClients')
            //state.leadsList?.map(l => l.id === action.payload[0].clientId ? { ...l, hasUnreadLetter: true } : l)
            // .sort((a, b) => a.id === action.payload[0].clientId ? -1 : b.id === action.payload[0].clientId ? 1 : 0)
        },
        wsSMSMailArrived: (state, action: PayloadAction<ISMSLetter[]>) => {
            const clientStatus = checkWhereIsClient(state, action.payload[0].clientId)
            getClientsStateAfterMailArrived(state, action.payload[0], clientStatus)
            // if (clientStatus === 'snooze') state.snoozedClients = getClientsListAfterMailArrived(state, action.payload, 'snoozedClients')
            // if (clientStatus === 'active') state.snoozedClients = getClientsListAfterMailArrived(state, action.payload, 'activeClients')
            // state.leadsList?.map(l => l.id === action.payload[0].clientId ? { ...l, hasUnreadLetter: true } : l)
            // .sort((a, b) => a.id === action.payload[0].clientId ? -1 : b.id === action.payload[0].clientId ? 1 : 0)
        },
        wsUpdateTrack: (state, action: PayloadAction<any>) => {
            state.clientTracks = state.clientTracks.map(t => t.id === action.payload.id ? {
                ...t,
                statusColor: action.payload.status.color
            } : t)
        },
        wsAddTrustedClient: (state, action: PayloadAction<IClientForProject>) => {
            state.activeClients = state.activeClients ? [action.payload, ...state.activeClients] : [{ ...action.payload }]
        },
        wsAddNewClientToAccepted: (state, action: PayloadAction<IClientForProject>) => {
            state.snoozedClients = state.snoozedClients ? [action.payload, ...state.snoozedClients] : [{ ...action.payload }]
        },
        wsAddTrustedClientToAccepted: (state, action: PayloadAction<IClientForProject>) => {
            state.activeClients = state.activeClients ? state.activeClients.map(l => l.id === action.payload.id ? { ...action.payload } : l) : [{ ...action.payload }]
        },
        wsRestoreClient: (state, action: PayloadAction<RestoreClientType>) => {
            const trustedClients = state.activeClients ? state.activeClients.filter(cl => cl.status === 'TRUSTED') : []
            state.activeClients = state.activeClients
                ? [...trustedClients, action.payload.client, ...state.activeClients.filter(cl => cl.status !== 'TRUSTED')]
                : [action.payload.client]
        },
        wsChangeClientSalesFunnelStep: (state, action: PayloadAction<IShortClientData>) => {
            const clientStatus = checkWhereIsClient(state, action.payload.id)
            if (clientStatus === 'snooze') state.snoozedClients = getClientsListAfterClientFunnelStepEdit(state, action.payload, 'snoozedClients')
            if (clientStatus === 'active') state.activeClients = getClientsListAfterClientFunnelStepEdit(state, action.payload, 'activeClients')
        },
        wsSetNoteData: (state, action: PayloadAction<NoteAdded>) => {
            const clientStatus = checkWhereIsClient(state, action.payload.client.id)
            if (clientStatus === 'snooze') state.snoozedClients = getClientsListAfterChangeNoteData(state, action.payload, 'snoozedClients')
            if (clientStatus === 'active') state.activeClients = getClientsListAfterChangeNoteData(state, action.payload, 'activeClients')
            //state.leadsList?.map(l => l.id === action.payload.client.id ? { ...l, notes: action.payload.client.notes } : l)
        },

        wsTrackAdded: (state, action: PayloadAction<TrackAdded>) => {
            const clientStatus = checkWhereIsClient(state, action.payload.clientId)
            if (clientStatus === 'snooze') {
                state.snoozedClients = state.snoozedClients?.map(el => el.id === action.payload.clientId
                    ? {
                        ...el,
                        tracks: action.payload.tracks
                    }
                    : el
                )
            }
            if (clientStatus === 'active') {
                state.activeClients = state.activeClients?.map(el => el.id === action.payload.clientId
                    ? {
                        ...el,
                        tracks: action.payload.tracks
                    }
                    : el
                )
            }

            if (state.clientId === action.payload.clientId) {
                state.clientTracks = action.payload.tracks
            }
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(editClientData.fulfilled, (state, { payload }) => {
                const clientStatus = checkWhereIsClient(state, payload.clientId)
                if (clientStatus === 'active') {
                    state.activeClients = getClientsListAfterClientEdit(state, payload, 'activeClients')
                }
                if (clientStatus === 'snooze') {
                    state.snoozedClients = getClientsListAfterClientEdit(state, payload, 'snoozedClients')
                }
                // state.leadsList?.map(l => l.id === payload.clientId ? {
                //   ...l, ...payload.params,
                //   address: { ...payload.params }
                // } : l)

                state.fullClientData = { ...state.fullClientData, ...payload.params }
            })
            .addCase(fetchClientData.fulfilled, (state, { payload }) => {
                state.fullClientData = payload
                state.clientTracks = payload.tracks
                state.clientRights = payload.rights
            })
            .addCase(fetchClientsByProject.fulfilled, (state, { payload }) => {
                state.lastProjectId = payload.lastProjectId
                //state.leadsList = filterLeadList(payload.leadList)
                const snoozedLeads = payload.leadList.filter(l => l.snooze)
                const activeLeads = payload.leadList.filter(l => !l.snooze)
                state.activeClients = filterLeadList(activeLeads)
                state.snoozedClients = filterLeadList(snoozedLeads)
                state.searchError = false
            })
            .addCase(fetchClientsByProject.rejected, (state, { payload }) => {
                state.searchError = payload
            })
            .addCase(fetchClientsByProjectForOrderManager.fulfilled, (state, { payload }) => {
                state.clientsForOrderManager = payload.items
                state.clientsForOrderManagerCount = payload.totalCount
            })
            .addCase(fetchClientsByProjectForProjectManager.fulfilled, (state, { payload }) => {
                state.clientsForProjectManager = payload.items
                state.clientsForProjectManagerCount = payload.totalCount
            })
            .addCase(fetchGlobalClientsList.fulfilled, (state, { payload }) => {
                state.globalClientsList = payload.items
                state.totalCount = payload.totalCount
            })
            .addCase(fetchMailboxForDealForSelect.fulfilled, (state, { payload }) => {
                state.mailboxForDealForSelect = payload
            })
            .addCase(fetchMyClients.fulfilled, (state, { payload }) => {
                state.lastProjectId = payload.lastProjectId
                state.leadsList = filterLeadList(payload.data.items)
            })
            .addCase(fetchNewClients.fulfilled, (state, { payload }) => {
                state.newClients = payload
            })
            .addCase(fetchNewClientsIds.fulfilled, (state, { payload }) => {
                state.clientsWithStatusNew = payload
            })
            .addCase(fetchSalesFunnelDescribeForClient.fulfilled, (state, { payload }) => {
                state.salesFunnelDescribe = payload
            })
            .addCase(insertClients.fulfilled, (state, { payload }) => {
                const trustedClients = state.activeClients ? state.activeClients.filter(cl => cl.status === 'TRUSTED') : []
                state.activeClients = state.activeClients
                    ? [...trustedClients, ...payload, ...state.activeClients.filter(cl => cl.status !== 'TRUSTED')]
                    : [...payload]

                //  state.activeClients = state.activeClients ? [...payload.newClients, ...state.activeClients] : [...payload.newClients]
            })
            .addCase(sendReshipmentReasonForClient.fulfilled, (state, { payload }) => {
                state.activeClients = state.activeClients?.map(l => l.id === payload[0].id ? { ...payload[0] } : l)
                state.snoozedClients = state.snoozedClients?.map(l => l.id === payload[0].id ? { ...payload[0] } : l)
            })
            .addCase(fetchMyClientsWidget.fulfilled, (state, { payload }) => {
                state.acceptedClients = payload
            })
            .addCase(fetchProjectSettingsReplacedByClient.fulfilled, (state, { payload }) => {
                state.letterSettings = payload
            })
            .addCase(editClientSalesFunnel.fulfilled, (state, { payload }) => {
                if (payload) {
                    state.fullClientData = state.fullClientData && {
                        ...state.fullClientData,
                        salesFunnel: { ...state.fullClientData.salesFunnel, step: { ...payload } }
                    }
                }
            })
            .addCase(cancelReshipment.fulfilled, (state, { payload }) => {
                const clientStatus = checkWhereIsClient(state, payload[0].id)
                if (clientStatus === 'active') state.activeClients = state.activeClients?.map(cl => cl.id === payload[0].id ? { ...payload[0] } : cl)
                if (clientStatus === 'snooze') state.snoozedClients = state.snoozedClients?.map(cl => cl.id === payload[0].id ? { ...payload[0] } : cl)
            })
    }
})

export const { actions: clientsActions } = clientsSlice
export const { reducer: clientsReducer } = clientsSlice
