import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { ProjectUserWithoutRoleType } from '../types/projectUser'


export const fetchProjectUsersWithoutRole = createAsyncThunk<ProjectUserWithoutRoleType,
  number,
  ThunkConfig<any>>(
  'projectUser/fetch_project_users_without_role',
  async (projectId, { dispatch, rejectWithValue, extra }) => {

    try {
      const res = await extra.api().get<ProjectUserWithoutRoleType>(`project/${projectId}/users-without-role`)

      return res.data

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить список юзеров без роли')
      return rejectWithValue({})
    }
  }
)