import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProjectManagersType, ProjectUserSchema } from '../types/projectUser'
import {
    fetchClientManagers,
    fetchOrderManagers,
    fetchProjectManagers,
    fetchProjectUsersWithoutRole,
    fetchUsersByProjects,
    switchIsGetNewClients
} from '../services'


const initialState: ProjectUserSchema = {
    projectManagers: [],
    clientManagers: [],
    orderManagers: [],
    isEdit: false,
    isAdd: false,
    isDelete: false,
    project_id: null,
    usersListByProjects: []
}


export const projectUserSlice = createSlice({
    name: 'project_user',
    initialState,
    reducers: {
        setIsAdd: (state, action: PayloadAction<boolean>) => {
            state.isAdd = action.payload
        },
        setIsDelete: (state, action: PayloadAction<boolean>) => {
            state.isDelete = action.payload
        },
        setIsEdit: (state, action: PayloadAction<boolean>) => {
            state.isEdit = action.payload
        },
        setProjectId: (state, action: PayloadAction<number>) => {
            state.project_id = action.payload
        },
        clearManagersState: (state) => {
            state.clientManagers = []
            state.orderManagers = []
            state.projectManagers = []
        },
        updateClientManager: (state, action: PayloadAction<ProjectManagersType>) => {
            state.clientManagers && state.clientManagers?.map(cl => {
                if (cl.id === action.payload.id) {
                    return action.payload
                } else {
                    return cl
                }
            })
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchClientManagers.fulfilled, (state, action) => {
            state.clientManagers = action.payload
        })
        builder.addCase(fetchOrderManagers.fulfilled, (state, action) => {
            state.orderManagers = action.payload
        })
        builder.addCase(fetchProjectManagers.fulfilled, (state, action) => {
            state.projectManagers = action.payload
        })
        builder.addCase(fetchProjectUsersWithoutRole.fulfilled, (state, action) => {
            state.projectUsersWithoutRole = action.payload
        })
        builder.addCase(fetchUsersByProjects.fulfilled, (state, action) => {
            state.usersListByProjects = action.payload
        })
        builder.addCase(switchIsGetNewClients.fulfilled, (state, { payload }) => {
            state.clientManagers = state.clientManagers?.map(cl => cl.id === payload[0].id ? { ...payload[0] } : cl)
        })
    }

})

export const { actions: projectUserActions } = projectUserSlice
export const { reducer: projectUserReducer } = projectUserSlice
