import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { prepareFilterParams } from '../../../../../Api/API'
import { GlobalClientsResponse } from '../types/clients'
import { clientsActions } from '../slice/clientsSlice'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'

export const fetchGlobalClientsList = createAsyncThunk<
  GlobalClientsResponse,
  PaginationParamsType | undefined,
  ThunkConfig<any>>(
  'client/fetch_global_clients_list',
  async (params, thunkApi) => {
    const { dispatch, rejectWithValue, getState, extra } = thunkApi

    const globalClientsList = getState().clients.globalClientsList
     !globalClientsList && dispatch(clientsActions.setIsFetchData(true))
    try {
      const response = await extra.api().get(`clients/global-list?${prepareFilterParams(params)}`)
     params && dispatch(clientsActions.setParams(params))

      return response.data
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось загрузить список клиентов')
      return rejectWithValue({})
    } finally {
       dispatch(clientsActions.setIsFetchData(false))
    }
  }
)