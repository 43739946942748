import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CallsSchema, CallType, LastCallNumberType, ProjectsUserPhonesType } from '../types/calls'
import { fetchTwilioToken } from '../services'
import { Device } from '@twilio/voice-sdk'
import { DeviceStatusType } from '../types/calls'
import { PhoneCreatingStatus } from '../../../../../Redux/twilioReducer'
import { fetchActiveNumbers } from '../services/fetchActiveNumbers'
import { fetchAvailableNumbers } from '../services/fetchAvailableNumbers'
import { IBaseClient } from '../../../../../types'


const initialState: CallsSchema = {
  isTwilioAvailable: true,
  availableNumbers: [],
  isFetching: false,
  isCreating: 'waiting',
  deviceStatus: {},
  // activeNumbers: []
  callsByProjectPhone: undefined,
  callsByProjectPhoneTotalCount: undefined,
  lastCallNumber: {
    clientId: undefined,
    lastCallNumber: undefined
  }
}


export const callsSlice = createSlice({
  name: 'calls',
  initialState,
  reducers: {
    setLastCallNumber: (state, action: PayloadAction<LastCallNumberType>) => {
      state.lastCallNumber = action.payload
    },
    callsByProjectPhone: (state, action: PayloadAction<{
      items?: CallType[],
      totalCount?: number
    }>) => {
      state.callsByProjectPhone = action.payload.items
      state.callsByProjectPhoneTotalCount = action.payload.totalCount
    },
    setIncomingCallClientData: (state, action: PayloadAction<IBaseClient | undefined>) => {
      state.incomingCallClientData = action.payload
    },
    setProjectsUserPhones: (state, action: PayloadAction<ProjectsUserPhonesType>) => {
      state.projectsUserPhones = action.payload
    },
    setDevice: (state, action: PayloadAction<{ device: Device, identity: string }>) => {
      // state.device = action.payload.device
      if (!state.device) {
        state.device = {}
        state.device[action.payload.identity] = action.payload.device
      }
      state.device[action.payload.identity] = action.payload.device
    },
    setDeviceStatus: (state, action: PayloadAction<{status: DeviceStatusType, key: string}>) => {
      state.deviceStatus[action.payload.key] = action.payload.status
    },
    setActiveCallData: (state, action: PayloadAction<CallType | undefined>) => {
      state.activeCallData = action.payload
    },
    setIsCreating: (state, action: PayloadAction<PhoneCreatingStatus>) => {
      state.isCreating = action.payload
    },
    setAvailableNumbers: (state, action: PayloadAction<string[]>) => {
      state.availableNumbers = action.payload
    },
    setActiveNumbers: (state, action: PayloadAction<string[]>) => {
      state.activeNumbers = action.payload
    },
    setIsFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload
    },
    setIsTwilioAvailable: (state, action: PayloadAction<boolean>) => {
      state.isTwilioAvailable = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTwilioToken.fulfilled, (state, { payload }) => {
      if (!state.token) {
        state.token = {}
        state.token[payload.identity] = payload.token
      }
      state.token[payload.identity] = payload.token
      state.clientName = payload.identity
    })
    builder.addCase(fetchActiveNumbers.fulfilled, (state, { payload }) => {
      state.activeNumbers = payload
    })
    builder.addCase(fetchAvailableNumbers.fulfilled, (state, { payload }) => {
      state.availableNumbers = payload
    })

  }

})

export const { actions: callsActions } = callsSlice
export const { reducer: callsReducer } = callsSlice
