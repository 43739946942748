import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { FieldsDataType } from '../../../../../Containers/Modal-content' // todo перенести тип
import { defaultPaginationParams } from '../slice/projectsSlice'
import { fetchProjects } from './fetchProjects'


type RequestProps = {
  data: FieldsDataType,
  projectId: number
}

export const copyProject = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'projects/copy_project',
  async (dto, { dispatch, rejectWithValue, getState, extra }) => {

    const { data, projectId } = dto

    const params = getState().projects.projectPagination
    try {
      await extra.api().post(`project/${projectId}/copy`, data)
      await dispatch(fetchProjects({params: !params.page ? defaultPaginationParams : params}))

      dispatch(snackActions.setSnack({
        label: 'Проект был успешно скопирован',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось сделать копию проекта')
      return rejectWithValue({})
    }
  }
)