import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { ProjectSettings } from '../../../../../types'



export const fetchChainSettingsForProject = createAsyncThunk<
  ProjectSettings,
  string,
  ThunkConfig<any>>(
  'projects/fetch_chain_settings_for_project',
  async (id, { dispatch, rejectWithValue, extra }) => {

    try {
      const res = await extra.api().get<ProjectSettings>(`project/${id}/chains-setting`)

      return res.data

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось загрузить цепочку по умолчанию')
      return rejectWithValue({})
    }
  }
)