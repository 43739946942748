import React, { Fragment } from 'react'

import clsx from 'clsx'
import { Link } from 'react-router-dom'

import { Box, IconButton } from '@material-ui/core'

import { Send } from '@material-ui/icons'

export const HeaderLogo = () => {
  return (
    <Fragment>
      <div
        className={clsx('app-header-logo app-header-logo-close')}>
        <Box className='header-logo-wrapper' title='MTR-CRM'>
          <Link to='/' className='header-logo-wrapper-link'>
            <IconButton color='primary' size='small'>
              <Send color={'primary'} />
            </IconButton>
          </Link>
        </Box>
      </div>
    </Fragment>
  )
}