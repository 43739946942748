import { makeStyles } from '@material-ui/core/styles'
import { ToastVariant } from '../../../Redux/notificationsReducer'


export const useStyles = makeStyles<{}, {variant?: ToastVariant, disableActionCallback?:boolean}>({
    root: {
        marginLeft: '2px',
        position: 'fixed',
        right: '10px',
        top: '10px',
        zIndex: 99999
    },
    toastRoot: {
        animation: '$slideIn 0.3s',
        animationTimingFunction: 'ease-out',
        padding: '8px 20px',
        borderRadius: '4px',
        border: 0,
        color: '#fff',
        height: 'fit-content',
        marginBottom: '7px',
        position: 'relative',
        whiteSpace: 'normal',
        wordBreak: 'break-word',
    },
    toastHeader: {
        borderBottom: 0,
        color: ({variant}) => variant === 'error'? 'rgb(255 255 255)' : '#181818',
        margin: 0,
        opacity: '80%',
        '& > span': {
            fontWeight: 700
        }
    },
    toastBody: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& > button' : {
            marginLeft: '0.75rem',
            marginRight: '-0.375rem',
        }
    },
    toastActions: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'space-between',
    },
    toastAction: {
        marginLeft: 'auto',
        backgroundColor: ({variant, disableActionCallback}) =>  (
          disableActionCallback ? 'rgb(187,187,187)' :
          variant === 'info' ?  'rgb(176 215 242)'
                :  variant === 'warning' ?  'rgb(246 218 178)'
                   :  variant === 'success' ? 'rgb(183 225 183)' : 'rgb(255 255 255)'
        ),
        color: ({variant,disableActionCallback}) => (
          disableActionCallback ? 'rgb(136,136,136)' :
            variant === 'success' ? 'rgb(61 97 61)'
                : variant === 'warning' ? 'rgb(150 130 102)'
                : variant === 'info' ? 'rgb(92 121 141)' : 'rgb(159,35,17)'
        ),
        display: 'flex',
        alignItems: 'center',
        borderRadius: 3,
        border: ({variant, disableActionCallback}) => (
          disableActionCallback ? 'rgb(136,136,136)' :
            variant === 'success' ? '1px solid rgb(61 97 61)'
                : variant === 'warning' ? '1 px solid rgb(150 130 102)'
                    : variant === 'info' ? '1px solid rgb(92 121 141)' : ' 1px solid rgb(159,35,17)'
        ),
        padding: 4,
        height: 20,
        minWidth: 30,
        cursor: 'pointer',
        '& > span' : {
            fontSize: 10,
            margin: '0 auto'
        },
        '&:hover': {
            backgroundColor: ({variant ,disableActionCallback}) =>  (
              disableActionCallback ? 'rgb(164,164,164)' :
                variant === 'info' ?  'rgb(166,218,245)'
                    :  variant === 'warning' ?  'rgb(239,197,158)'
                        :  variant === 'success' ? 'rgb(171,225,171)' : 'rgb(232,232,232)'
            ),
        },
        pointerEvents: ({disableActionCallback}) => disableActionCallback ? 'none' : 'auto'
    },
    closeIconButton: {
        width: 15,
        height: 15
    },
    closeIcon: {
        width: 15,
        height: 15
    },
    error: {
        backgroundColor: 'rgb(255 89 69)',
        color: 'rgb(255 255 255)',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',

    },
    warning: {
        backgroundColor:'rgb(255, 244, 229)',
        color: 'rgb(102, 60, 0)',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',

    },
    info: {
        backgroundColor: 'rgb(232, 244, 253)',
        color: 'rgb(13, 60, 97)',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
    success: {
        backgroundColor: 'rgb(237, 247, 237)',
        color: 'rgb(30, 70, 32)',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',

    },

    slideOut: {
        animation: '$slideOut 0.5s',
        animationTimingFunction: 'ease-in'
    },
    '@keyframes slideIn': {
        '0%' : {
            right: '-120%'
        },
        '100%': {
            right: 0
        }
    },

    '@keyframes slideOut': {
        '0%' : {
            right: 0
        },
        '100%': {
            right: '-120%'
        }
    },

})