import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Typography } from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

type ErrorFragmentPropsType = {
  message?: string
}

export const ErrorFragment: FC<ErrorFragmentPropsType> = ({
  message
}) => {
  const classes = useStyles()

  return (
    <Container className={classes.container}>
      <ErrorOutlineIcon
        fontSize='large'
        color='error'
        className={classes.progress}
      />
      <Typography
        variant='caption'
        color='error'
        display='block'
        style={{ fontSize: '1.2rem' }}>
        <b>{message ? message : 'У вас нет доступа к этой странице'}</b>
      </Typography>
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    height: '80vh',
    textAlign: 'center',
    paddingTop: '15%'
  },
  progress: {
    margin: theme.spacing(2),
    fontSize: '8.1rem'
  }
}))