import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProjectLetterSettingSchema } from '../types/projectLetterSetting'
import {
  fetchProjectMailFooter,
  fetchProjectMailFooterForClient,
  fetchProjectMailHeader,
  fetchProjectMailHeaderForClient
} from '../services'


const initialState: ProjectLetterSettingSchema = {
  projectHeader: '',
  projectFooter: '',
  projectId: null,
  projectHeaderForClient: '',
  projectFooterForClient: ''
}


export const projectLetterSettingSlice = createSlice({
  name: 'projectLetterSetting',
  initialState,
  reducers: {
    setProjectFooterForClient: (state, action: PayloadAction<string>) => {
      state.projectFooterForClient = action.payload
    },
    setProjectHeaderForClient: (state, action: PayloadAction<string>) => {
      state.projectHeaderForClient = action.payload
    },
    clearSettingsDefaultState: (state) => {
      state.projectHeader = ''
      state.projectFooter = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectMailFooter.fulfilled, (state, { payload }) => {
      state.projectFooter = payload
    })
    builder.addCase(fetchProjectMailHeader.fulfilled, (state, { payload }) => {
      state.projectId = payload.projectId
      state.projectHeader = payload.header
    })
    builder.addCase(fetchProjectMailFooterForClient.fulfilled, (state, { payload }) => {
      if (payload) state.projectFooterForClient = payload
    })
    builder.addCase(fetchProjectMailHeaderForClient.fulfilled, (state, { payload }) => {
      if (payload) state.projectHeaderForClient = payload
    })
  }

})

export const { actions: projectLetterSettingActions } = projectLetterSettingSlice
export const { reducer: projectLetterSettingReducer } = projectLetterSettingSlice
