import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { fetchWaitingForApproveDeclineClients } from './fetchWaitingForApproveDeclineClients'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'

interface RequestProps {
    projectId: number,
    id: number,
    message: string
}

export const recoverClientFromArchive = createAsyncThunk<{},
    RequestProps,
    ThunkConfig<any>>(
    'archiveClients/recoverClientFromArchive',
    async (data, thunkApi) => {
        const { dispatch, getState, extra, rejectWithValue } = thunkApi
        const { paginationParams } = getState().archiveClients
        try {
            await extra.api().post(`client/${data.id}/restore`, { message: data.message })
            await dispatch(fetchWaitingForApproveDeclineClients({
                projectId: data.projectId,
                params: paginationParams
            }))
            dispatch(snackActions.setSnack({
                label: 'Клиент восстановлен успешно',
                typeLabel: 'success'
            }))
        } catch (e) {
            handleServerNetworkError(e, dispatch, 'Не удалось восстановить клиента')
            return rejectWithValue(e)
        } finally {
        }
    }
)