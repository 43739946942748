import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'


export const fetchProjectMailFooter = createAsyncThunk<string,
  number | undefined,
  ThunkConfig<any>>(
  'projectLetterSetting/fetch_project_mail_footer',
  async (projectId, { dispatch, rejectWithValue, getState, extra }) => {
    const idProject = projectId ? projectId : getState().projectLetterSetting.projectId
    if (idProject) {
      try {
        const response = await extra.api().get(`project/${idProject}/letters-setting/footer`)
        return response.data
      } catch (e) {
        handleServerNetworkError(e, dispatch, 'Не удалось загрузить нижнюю часть письма')
        return rejectWithValue({})
      }
    } else return null
  }
)