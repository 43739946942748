import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { CommonLetterType } from '../../lettersSlice'
import { fetchClientData } from '../../clientsSlice/services'


type RequestProps = {
  client_id: number
  params: any // todo types
}

export const editManagerLetter = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'managerLetter/edit_manager',
  async (data, { dispatch, rejectWithValue, extra }) => {
    const { client_id, params } = data
    const {letter_id, ...body} = params

    try {
      await extra.api().patch<CommonLetterType>(`manager-letter/${letter_id}`, body)
      dispatch(fetchClientData({  clientId: client_id }))

      dispatch(snackActions.setSnack({
        label: 'Письмо успешно изменено',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Произошла ошибка при редактировании письма')
      return rejectWithValue({})
    }
  }
)