import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { ProjectForSelect } from '../../../../../types/project'


export const fetchProjectsForSelect = createAsyncThunk<ProjectForSelect[],
  void,
  ThunkConfig<any>>(
  'projects/fetch_projects_for_select',
  async (_, { dispatch, rejectWithValue, extra }) => {
    try {
      const res = await extra.api().get<ProjectForSelect[]>(`projects-for-select`)

      return res.data

    } catch (e) {
      //todo check error
      handleServerNetworkError(e, dispatch, 'Не удалось получить список проектов')
      return rejectWithValue({})
    }
  }
)