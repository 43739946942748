import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { EmailTargetType, ModalValueType } from '../../emailSlice'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'
import { fetchEmails } from '../../emailSlice/services'
import { mailboxForDealActions } from '../slice/mailboxForDealSlice'


type RequestProps = {
  target: EmailTargetType,
  data: ModalValueType,
  emailId: number
  params: PaginationParamsType
}

// TODO Проверить логику санки обязательно !!!!
export const editEmail = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
    'emails/edit_email',
    async (req, { dispatch, getState, rejectWithValue, extra }) => {
      const emailsSlice = getState().emails
      const { emailId, data, target, params } = req

      const address = target === 'emailsForDealing' ? 'mailbox-for-deal/' : 'mailbox-for-new-clients/'

      //dispatch(emailsActions.setError(null))
      try {
        await extra.api().patch(address + emailId, data)

        /** Обновляем данные вместе с параметрами пагинации */
        emailsSlice.projectId && dispatch(fetchEmails({
          target: target,
          projectId: emailsSlice.projectId,
          params
        }))

        /** Обнуляем ошибки */
        dispatch(mailboxForDealActions.setErrorsForDeal([]))

        //   dispatch(emailsActions.setEmailModalOpen({ isOpen: false, target, id: emailsSlice.id }))
        dispatch(snackActions.setSnack({
          label: 'Email успешно изменен',
          typeLabel: 'success'
        }))

      } catch (e: any) {
        handleServerNetworkError(e, dispatch, 'Не удалось изменить данные')

        /** Добавляем ошибки */
        if (target === 'emailsForDealing') {
          dispatch(mailboxForDealActions.setErrorsForDeal(e.response.data))
        } else {
          dispatch(mailboxForDealActions.setErrorsForNewClients(e.response.data))
        }

        return rejectWithValue({})
      }

    }
  )
