import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { appActions } from '../../appSlice'
import { fetchProjectNotes } from './fetchProjectNotes'



export const deleteNoteFromProject = createAsyncThunk<{},
  number,
  ThunkConfig<any>>(
  'notes/delete_note_from_project',
  async (id, { dispatch, rejectWithValue, getState, extra }) => {

    const projectId = getState().notes.projectId
     dispatch(appActions.setIsLoad(true))
    try {
      await extra.api().delete(`project-note/${id}`)
      dispatch(fetchProjectNotes(projectId))

      dispatch(snackActions.setSnack({
        label: 'Заметка была успешно удалена',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch,'Произошла ошибка при удалении заметки')
      return rejectWithValue({})
    } finally {
      dispatch(appActions.setIsLoad(false))
    }
  }
)