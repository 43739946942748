import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { callsActions } from '../slice/callSlice'
import { fetchProjectPhonesForSelect } from '../../projectPhonesSlice/services'
import { snackActions } from '../../snackSlice'


type RequestProps = {
    numbers: string[],
    projectId: number,
    externalServiceId: number
}


export const addPhoneNumbers = createAsyncThunk<{},
    RequestProps,
    ThunkConfig<any>>(
    'call/add_phone_numbers',
    async (data, { dispatch, extra, rejectWithValue }) => {

        const { numbers, projectId, externalServiceId } = data

        dispatch(callsActions.setIsCreating('fetching'))
        try {
            const res = await extra.api().post<any>(`add_active_numbers/${externalServiceId}`, {
                numbers: numbers,
                project: projectId
            })
            if (res.data.error) {
                dispatch(snackActions.setSnack({
                    label: 'произошла ошибка при добавлении телефона',
                    typeLabel: 'error'
                }))
            } else dispatch(snackActions.setSnack({
                label: 'Телефон успешно добавлен',
                typeLabel: 'success'
            }))

            dispatch(fetchProjectPhonesForSelect(projectId))
        } catch (e) {
            return rejectWithValue({})
        } finally {
            dispatch(callsActions.setIsCreating('complete'))
        }
    }
)