import { FunctionComponent, PropsWithChildren, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

const Portal: FunctionComponent<PropsWithChildren<any>> = ({ children }) => {
  const [container] = useState(() => document.createElement('div'))

  useEffect(() => {
    document.body.appendChild(container)

    return () => {
      document.body.removeChild(container)
    }
  }, [container])

  return createPortal(children, container)
}

export default Portal