import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { Member } from '../../../../../types'


export const fetchAvailableUsers = createAsyncThunk<Member[],
    void,
    ThunkConfig<any>>(
    'chats/edit_private_chat',
    async (_, thunkApi) => {
        const { dispatch, extra, rejectWithValue } = thunkApi
        try {
            const response = await extra.api().get(`private-chat/available-users`)
            return response.data
        } catch (e) {
            handleServerNetworkError(e, dispatch, 'Не удалось загрузить список пользователей')
            return rejectWithValue(e)
        }
    }
)