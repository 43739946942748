import { Action, Dispatch } from 'redux'
import { CommonResponseType } from '../Api/chainLettersApi'
import { ResponseStatusCodes } from '../enums/enums'
import CreateProjectSnackbarAction from './SnackbarActions/CreateProjectSnackbarAction'
import { authActions } from '../providers/StoreProvider/slices/authSlice'
import { snackActions } from '../providers/StoreProvider/slices/snackSlice'


export const handleServerAppError = (response: CommonResponseType<any>, dispatch: Dispatch<Action>, errorText?: string) => {
  switch (response.status) {
    case ResponseStatusCodes.notAuthorized:
      //dispatch(actions.setIsAuth(false))
      dispatch(authActions.setIsAuth(false))
      //dispatch(snackActions.setSnack('Время сессии истекло', 'info'))
      dispatch(snackActions.setSnack({ label: 'Время сессии истекло', typeLabel: 'info' }))
      break
    case ResponseStatusCodes.noRights:
      //dispatch(snackActions.setSnack(response.data.error, 'error'))
      dispatch(snackActions.setSnack({ label: response.data.error, typeLabel: 'error' }))
      break
    case ResponseStatusCodes.badRequest:
      if (Array.isArray(response.data)) {
        const badRequestError: string = response.data ? convertResponseErrorArrayToString(response.data) : errorText ? errorText : ''
        //dispatch(snackActions.setSnack(badRequestError, 'error'))
        dispatch(snackActions.setSnack({ label: badRequestError, typeLabel: 'error' }))
      } else {
        //dispatch(snackActions.setSnack(response.data.error, 'error'))
        dispatch(snackActions.setSnack({ label: response.data.error, typeLabel: 'error' }))
      }
      break
    case ResponseStatusCodes.notFoundOrNotValid:
      dispatch(snackActions.setSnack({ label: 'Данный объект не найден', typeLabel: 'error' }))
      break
    case ResponseStatusCodes.noMailBoxForDealInProject:
      dispatch(snackActions.setSnack({
        label: response.data.error ? response.data.error : errorText,
        typeLabel: 'error'
      }))
      break
    default:
      break
  }
}

export const handleServerNetworkError = (error: any, dispatch: Dispatch<Action>, errorText: string, data?: any) => {
  switch (error.response.status) {
    case ResponseStatusCodes.notAuthorized:
      dispatch(authActions.setIsAuth(false))
      dispatch(snackActions.setSnack({ label: 'Время сессии истекло', typeLabel: 'info' }))
      break
    case ResponseStatusCodes.noRights:
      dispatch(snackActions.setSnack({
        label: error.response.data.error ? error.response.data.error : errorText,
        typeLabel: 'error'
      }))
      break
    case ResponseStatusCodes.notFoundOrNotValid:
      dispatch(snackActions.setSnack({
        label: errorText ? errorText : 'Данный объект не найден',
        typeLabel: 'error'
      }))
      break
    case ResponseStatusCodes.badRequest:
      if (Array.isArray(error.response.data)) {
        const badRequestError: string = error.response.data 
          ? convertResponseErrorArrayToString(error.response.data) 
          : 'Имя проекта должно быть уникальным'
          
        if ('name : Проект с таким именем существует (в архиве)' === badRequestError) {
          dispatch(snackActions.setSnack({
            label: badRequestError,
            typeLabel: 'error',
            action: CreateProjectSnackbarAction
          }))
        } else {
          dispatch(snackActions.setSnack({ label: badRequestError, typeLabel: 'error' }))
        }
      } else {
        dispatch(snackActions.setSnack({ label: error.response.data.error ?? errorText, typeLabel: 'error' }))
      }
      break
    case ResponseStatusCodes.noMailBoxForDealInProject:
      dispatch(snackActions.setSnack({
        label: error.response.data.error ? error.response.data.error : errorText,
        typeLabel: 'error'
      }))
      break
  }
}


const convertResponseErrorArrayToString = (error: ErrorType[]): string => {
  const objKey = Object.keys(error[0])[0]
  const objValue = Object.values(error[0])[0]
  return objKey + ' : ' + objValue
}

type ErrorType = Record<string, string>