import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { ProjectNotesType } from '../../../../../types'


type FullFilledResponse = {
  projectNotes: ProjectNotesType[]
  projectId: number
}

export const fetchProjectNotes = createAsyncThunk<
  FullFilledResponse,
  number,
  ThunkConfig<any>>(
  'projects/fetch_project_notes',
  async (projectId, { dispatch, rejectWithValue, extra }) => {
    try {
      const res = await extra.api().get<ProjectNotesType[]>(`project-notes/project/${projectId}`)

      return { projectNotes: res.data, projectId }

    } catch (e) {
      //todo check error
      handleServerNetworkError(e, dispatch, 'Не удалось получить информацию о проекте')
      return rejectWithValue({})
    }
  }
)