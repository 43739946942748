import { withStyles } from '@material-ui/core/styles'
import { Button, Theme } from '@material-ui/core'

export const CancelDeletionButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText('#4C4261'),
    backgroundColor: '#4C4261',
    fontSize: '12px',
    borderRadius: '5px',
    width: '100px',
    margin: '5px',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: '#9588a4',
      color: '#fff'
    }
  }
}))(Button)

export const DeleteButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText('#f83245'),
    backgroundColor: '#f83245',
    borderRadius: '5px',
    width: '100px',
    margin: '5px',
    fontSize: '12px',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: '#ef7777',
      color: '#fff'
    },
    '&:disabled': {
      backgroundColor: '#e8e8e8',
      color: '#fff'
    }
  }
}))(Button)

export const SuccessButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText('#1b7022'),
    backgroundColor: '#1b7022',
    borderRadius: '5px',
    width: '100px',
    margin: '5px',
    fontSize: '12px',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: '#6db470',
      color: '#fff'
    },
    '&:disabled': {
      backgroundColor: '#e8e8e8',
      color: '#fff'
    }
  }
}))(Button)
