import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { fetchSpreedSheetsListForProject } from './fetchSpreedSheetsListForProject'
import { projectSpreedSheetActions } from '../slice/projectSpreedSeetSlice'


type RequestProps = {
  projectId: number,
  source: number
}

export const processedSpreadSheetInProject = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'spreadSheets/processed_spread_sheet_in_project',
  async (dto, { dispatch, rejectWithValue, getState, extra }) => {

    const { projectId, source } = dto
    const params = getState().projectSpreedSheet.paginationParams
    dispatch(projectSpreedSheetActions.setIsProcessSpreadSheet(true))
    try {
      await extra.api().post(`/project/${projectId}/spreadsheets/${source}/process`)

      // dispatch(fetchSpreedSheetsListForProject({ projectId, params }))
      dispatch(snackActions.setSnack({
        label: 'Таблица обработана',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось обработать таблицу')
      return rejectWithValue({})
    } finally {
      dispatch(projectSpreedSheetActions.setIsProcessSpreadSheet(false))
    }
  }
)