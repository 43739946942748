import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { fetchChains } from './fetchChains'
import { defaultPaginationParams } from '../slice/chainsSlice'
import { fetchPublicChains } from './fetchPublicChains'


interface RequestProps {
  chainId: number
  params: any // Todo typing
}


export const editChainLetter = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'chains/edit_chain_letter',
  async (data, thunkApi) => {
    const { chainId, params } = data
    const { dispatch, getState, extra } = thunkApi
    const myChainsParams = getState().chains.chainPagination
    const publicChainsParams = getState().chains.publicChainPagination
    try {
      await extra.api().patch(`chain-letters/${chainId}`, params)
      dispatch(fetchChains(!myChainsParams.page ? defaultPaginationParams : myChainsParams))
      dispatch(fetchPublicChains(!publicChainsParams.page ? defaultPaginationParams : publicChainsParams))

      dispatch(snackActions.setSnack({
        label: 'Цепочка успешно изменена',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'При редактировании цепочки произошла ошибка')
    } finally {
    }
  }
)