import { FC, MouseEventHandler, ReactNode } from 'react'
import { Badge, createStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CustomTooltip } from '../index'


type BadgeColor = 'default' | 'primary' | 'secondary' | 'error'

type LettersIconPropsType = {
  id?: string
  title: string
  contentLength?: number
  children: ReactNode
  onClick?: MouseEventHandler<HTMLDivElement>
  showZero?: boolean
  color?: BadgeColor
  className?: string
  disabled?: boolean
}

export const BadgeIcon: FC<LettersIconPropsType> = ({
  id,
  title,
  contentLength,
  onClick,
  showZero,
  color,
  className,
  disabled,
  children
}) => {

  const cls = useStyles()

  const finalClassName = `${cls.root} ${className ? className : ''} ${disabled ? cls.disabled : ''}`

  return (
    <CustomTooltip title={title} arrow>
      <Badge
        id={id}
        badgeContent={contentLength}
        onClick={onClick}
        showZero={showZero}
        color={color}
        className={finalClassName}
        overlap='rectangular'
      >
        {children}
      </Badge>
    </CustomTooltip>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      cursor: 'pointer'
    },
    disabled: {
      pointerEvents: 'none',
      '& > svg': {
        fill: '#b9b9b9',
        borderBottom: 'none'
      }
    }
  })
)