import { addDays, addHours, addMinutes, format, getUnixTime, isWeekend, nextMonday, setHours, setMinutes, subMinutes } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { ru } from 'date-fns/locale'
import { getTimeZoneOffsetInSeconds } from '../Utils/getTimeZoneOffsetInSeconds'

import { SelectNameType, SelectType } from '../ui-kit'

const getSendDescriptionIfIsWeekend = (): string => {
  const tzDate = addHours(formatTimezone(new Date()), -6)
  return format(nextMonday(setHours(setMinutes(tzDate, 0), 8)),
    'eeeeee HH:mm BBBBB',
    { locale: ru }
  )
}

const formatTimezone = (date: Date): Date => {
  const offset = date.getTimezoneOffset();
  return Math.sign(offset) !== -1 ? addMinutes(date, offset) : subMinutes(date, Math.abs(offset));
}

export const getSendMessageDescription = (name: SelectNameType, hoursInterval?: number): string => {
  let dateWithTimeZone: string = ''
  const zonedDate = utcToZonedTime(new Date(), 'America/Chicago')
  let date

  if (name === 'IN_3_HOURS') {
    date = addHours(zonedDate, 3)
    if (isWeekend(date)) {
      dateWithTimeZone = getSendDescriptionIfIsWeekend()
    } else {
      dateWithTimeZone = format(date, 'eeeeee HH:mm BBBBB', { locale: ru })
    }
  } else if (name === 'NEXT_DAY') {
    const tzDate = addDays(addHours(formatTimezone(new Date()), -6), 1)
    if (isWeekend(tzDate)) {
      dateWithTimeZone = getSendDescriptionIfIsWeekend()
    } else {
      dateWithTimeZone = format(addDays(setHours(setMinutes(zonedDate, 0), 8), 1), 'eeeeee HH:mm BBBBB', { locale: ru })
    }
  } else {
    if (hoursInterval) {
      date = addHours(zonedDate, hoursInterval)
      if (isWeekend(date)) {
        dateWithTimeZone = getSendDescriptionIfIsWeekend()
      } else {
        dateWithTimeZone = format(date, 'eeeeee HH:mm BBBBB', { locale: ru })
      }
    }
  }
  return dateWithTimeZone
}


export const getSendTime = (name: SelectNameType, isSnoozed: boolean, options: SelectType[]): number => {
  const zonedDate = utcToZonedTime(new Date(), 'America/Chicago')
  const unixIsWeekendTime = getUnixTime(nextMonday(setHours(setMinutes(zonedDate, 0), 8)))

  if (name === 'IN_3_HOURS') {
    const date = addHours(zonedDate, 3)
    if (isSnoozed) {
      if (isWeekend(date)) {
        return unixIsWeekendTime + getTimeZoneOffsetInSeconds()
      } else {
        return getUnixTime(addHours(zonedDate, 3)) + getTimeZoneOffsetInSeconds()
      }
    } else {
      return options.filter(el => el.name === name)[0].value + getTimeZoneOffsetInSeconds()
    }
  } else if (name === 'NEXT_DAY') {
    const tzDate = addHours(formatTimezone(new Date()), -6)
    if (isSnoozed) {
      if (isWeekend(addDays(tzDate, 1))) {
        return unixIsWeekendTime + getTimeZoneOffsetInSeconds()
      } else {
        return getUnixTime(addDays(setHours(setMinutes(zonedDate, 0), 8),1)) + getTimeZoneOffsetInSeconds()
      }
    } else {
      if(isWeekend(addDays(tzDate, 1))) {
        return unixIsWeekendTime + getTimeZoneOffsetInSeconds()
      } else {
        return getUnixTime(addDays(setHours(setMinutes(tzDate, 0), 17), 1)) 
      }
    }
  } else {
    return 0
  }
}

const checkIsDayOff = (zonedDate: Date,date: Date): number => {
  let weekEndTime
  const day = date.getDay()
  if (day === 6) {
    weekEndTime = getUnixTime(addDays(nextMonday(setHours(setMinutes(zonedDate, 0), 8)), 1))  + getTimeZoneOffsetInSeconds()
  } else if (day === 7){
    weekEndTime = getUnixTime(nextMonday(setHours(setMinutes(zonedDate, 0), 8))) + getTimeZoneOffsetInSeconds()
  } else {
    return 0
  }
  return weekEndTime
}

export const getSnoozeTime = (name: SelectNameType, isSnoozed: boolean, hoursInterval?: number): number => {
  const zonedDate = utcToZonedTime(new Date(), 'America/Chicago')
  const weekendTime = nextMonday(setHours(setMinutes(zonedDate, 0), 8))
  if (name === 'IN_3_HOURS') {
    const date = addHours(zonedDate, 3)
    if (isSnoozed) {
      if (isWeekend(date)) {
        return checkIsDayOff(zonedDate, weekendTime)
      } else {
        return getUnixTime(addDays(setHours(setMinutes(zonedDate, 0), 8), 1)) + getTimeZoneOffsetInSeconds()
      }
    } else {
      return 0
    }
  } else if (name === 'NEXT_DAY') {
    const date = addDays(zonedDate, 1)
    if (isSnoozed) {
      if (isWeekend(date)) {
        return checkIsDayOff(zonedDate, weekendTime)
      } else {
        return getUnixTime(addDays(setHours(setMinutes(date, 0), 8), 1)) + getTimeZoneOffsetInSeconds()
      }
    } else {
      return 0
    }
  } else {
    if (hoursInterval) {
      const date = addHours(zonedDate, hoursInterval)
      if (isSnoozed) {
        if (isWeekend(date)) {
          return checkIsDayOff(zonedDate, weekendTime)
        } else {
          return getUnixTime(addDays(setHours(setMinutes(date, 0), 8), 1)) + getTimeZoneOffsetInSeconds()
        }
      } else {
        return 0
      }
    } else {
      return 0
    }
  }
}

