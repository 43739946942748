import React, { ReactNode } from 'react'
import { checkAccess } from '../../../z_old_site/crm-functions/checkAccess'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { authSelectors } from '../../../providers/StoreProvider/slices/authSlice'

interface Props  {
    children: ReactNode
    byRole?: string[]
    projectId?: any
    forTestingData?: any
}

const AccessByRole:React.FC<Props> = ({byRole, projectId, forTestingData, children}) => {

    const roles = useSelector(authSelectors.getAuthRoles)
    const projectsRoles = useSelector(authSelectors.getAuthProjectRoles)

    const match = useParams()

    if (children && checkAccess(match, byRole, projectId, roles, projectsRoles, forTestingData )) {
        return <>{children}</>
    } else {
        return <></>;
    }
}

export default  AccessByRole



