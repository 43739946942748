import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { prepareFilterParams } from '../../../../../Api/API'
import { IClientForProject } from '../../../../../types'
import { clientsActions } from '../slice/clientsSlice'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'


interface RequestProps {
  projectId: number,
  params?: PaginationParamsType
}

type ReturnFullFilledResponse = {
  items: IClientForProject[]
  totalCount:number
}

export const fetchClientsByProjectForOrderManager = createAsyncThunk<
  ReturnFullFilledResponse,
  RequestProps,
  ThunkConfig<any>>(
  'client/fetch_clients_by_project_for_order_manager',
  async (data, thunkApi) => {
    const { dispatch, rejectWithValue, getState, extra } = thunkApi
    const { projectId, params } = data
    const managersList = getState().clients.clientsForOrderManager
    !managersList && dispatch(clientsActions.setIsFetchData(true))
    try {
      const response = await extra.api().get(`project/${projectId}/clients/order-manager?${prepareFilterParams(params)}`)
      return response.data
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить список лидов')
      return rejectWithValue({})
    } finally {
      dispatch(clientsActions.setIsFetchData(false))
    }
  }
)