import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { ProjectManagersType } from '../types/projectUser'
import { appActions } from '../../appSlice'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'

type RequestType = {
  params?: PaginationParamsType
  projectId: number
}

export const fetchOrderManagers = createAsyncThunk<ProjectManagersType[],
  RequestType,
  ThunkConfig<any>>(
  'projectUser/fetch_order_managers',
  async (data, { dispatch, rejectWithValue, extra }) => {

   dispatch(appActions.setIsLoad(true))
    try {
      const res = await extra.api().get<ProjectManagersType[]>(`project/${data.projectId}/order-managers`)

      return res.data

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить список менеджеров заказа')
      return rejectWithValue({})
    } finally {
       dispatch(appActions.setIsLoad(false))
    }
  }
)