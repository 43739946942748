import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import queryString from 'query-string'
import { fetchEmails } from './fetchEmails'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'
import { EmailTargetType } from '../types/emails'
import { snackActions } from '../../snackSlice'


type RequestProps = {
  target: EmailTargetType
  ids: number[]
  emailAfterDelete?: number
  params: PaginationParamsType
  projectId: number
}

// TODO Проверить логику санки обязательно !!!!
export const deleteEmails = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'emails/delete_emails',
  async (data, thunkApi) => {
    const { dispatch, rejectWithValue, getState, extra } = thunkApi
    const { target, emailAfterDelete, ids, params, projectId } = data

    const queryParams = {
      ids: ids.join(','),
      emailAfterDelete
    }
    const query = queryString.stringify(queryParams)
    const address = target === 'emailsForDealing' ? 'mailboxes-for-deal?' : 'mailboxes-for-new-clients?'

    try {
      await extra.api().delete(address + query)

      dispatch(fetchEmails({
        target,
        projectId,
        params
      }))

      dispatch(snackActions.setSnack({
        label: 'Email успешно удален',
        typeLabel: 'success'
      }))

      // target && state.emails.projectId && dispatch(fetchEmails({
      //   target,
      //   //@ts-ignore
      //   params: state.emails[target + 'PaginationParams'],
      //   projectId: state.emails.projectId
      // }))

      // todo юзается старый редюсер
      // await dispatch(getEmails(
      //  state.EmailReducer.target,
      //  // @ts-ignore
      //  state.emailReducer[state.emailReducer.target + 'PaginationParams'],
      //  state.EmailReducer.projectId)
      //)
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось удалить email')
      return rejectWithValue({})
    }

  }
)