import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { EmailTargetType } from '../types/emails'

type ReturnThunkType = {
    id: number,
    enabled: boolean
    target: EmailTargetType
}

type RequestParams = {
    id: number,
    target: EmailTargetType
}
export const enableNotWorkingEmail = createAsyncThunk<
    ReturnThunkType,
    RequestParams,
    ThunkConfig<any>
>(
    'email/enable_not_working_email',
    async (data, { extra, dispatch, rejectWithValue }) => {
        try {
            await extra.api().patch(
                data.target === 'emailsForDealing'
                    ? `mailbox-for-deal/${data.id}/enable`
                    : `mailbox-for-new-clients/${data.id}/enable`
            )

            return { id: data.id, enabled: true, target: data.target }
        } catch (e) {
            handleServerNetworkError(e, dispatch, 'Произошла ошибка при попытке включить почту')
            return rejectWithValue({})
        }
    }
)