import axios from 'axios'
import { Action, AnyAction, Dispatch, Store } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import * as queryString from 'query-string'
import { checkConnectionStatus, socketReducer } from '../Redux/socket-io-handler'
import { appActions } from '../providers/StoreProvider/slices/appSlice'
import { StateSchema, ThunkExtraArg } from '../providers/StoreProvider'
import { PaginationParamsType } from '../ui-kit/Table/types/query'

export const url = process.env.REACT_APP_PROXY_URL
export const wsUrl = process.env.REACT_APP_WS_URL

export let instance = axios.create({
  withCredentials: false,
  baseURL: url,
  headers: {
    Authorization: 'Bearer ' + localStorage.getItem('apikey'),
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
})


export const createAxiosInstance = () => {
  const apikey = localStorage.getItem('apikey')
  return axios.create({
    withCredentials: false,
    baseURL: url,
    headers: {
      Authorization: 'Bearer ' + apikey,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  })
}


export const addToken = (token: string) => {
  instance = axios.create({
    withCredentials: false,
    baseURL: url,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('apikey'),
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  })
}


export const prepareFilterParams = (params: any): string => {
  let filterParam = ''
  if (params.page) {
    filterParam = 'page=' + params.page.toString()
    //delete params.page
  }
  if (params.per_page) {
    filterParam = filterParam + '&per_page=' + params.per_page.toString()
    //delete params.per_page
  }

  for (let key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const element = params[key]
      if (element && element !== 'undefined') {
        filterParam = filterParam + '&filter[' + key + ']=' + encodeURIComponent(element)
      }
    }
  }

  return filterParam
}


export const createConnection = (name: string,
                                 id: number,
                                 retryCount: number,
                                 maxReconnects: number,
                                 dispatch: ThunkDispatch<StateSchema, ThunkExtraArg, AnyAction> & Dispatch<Action<any>>,
                                 store?: Store<StateSchema>
) => {

  if (retryCount > maxReconnects) {
    //dispatch(loadAndErrorActions.checkMaxNumberOfWsReconnections(true))
    dispatch(appActions.checkWsMaxReconnectCount(true))
    return
  }
  const apikey = localStorage.getItem('apikey')

  try {
    let socket = new WebSocket(wsUrl as string)

  socket.onopen = function(event) {
    checkConnectionStatus(event.type, dispatch)
    socket.send(
      JSON.stringify({
        sender: 'client',
        username: name,
        id: id,
        token: apikey
      }))
    retryCount = 0
  }

  socket.onmessage = function(event) {
    socketReducer(event.data, dispatch, store)
  }

  socket.onclose = function(event) {
    checkConnectionStatus(event.type, dispatch)
    smartReconnect(name, id, retryCount, maxReconnects, dispatch)
  }

  socket.onerror = function(event) {
    checkConnectionStatus(event.type, dispatch)
    smartReconnect(name, id, retryCount, maxReconnects, dispatch)

  }
  } catch (error) {
    console.log('СОКЕТ НЕ ПОДКЛЮЧЕН')
  }

  
}


const smartReconnect = (name: string,
                        id: number,
                        retryCount: number,
                        maxReconnects: number,
                        dispatch: ThunkDispatch<StateSchema, ThunkExtraArg, AnyAction> & Dispatch<Action<any>>
) => {
  if (retryCount >= 0 && retryCount <= maxReconnects) {
    setTimeout(() => {
      createConnection(name, id, retryCount + 1,
        maxReconnects, dispatch)
    }, 10000 * Math.pow(2, retryCount))
  }
}

// export const getQueryParams = (queryParams?: PaginationParamsType): string => {
//   const params: { [key: string]: any } = {}
//   if (queryParams) {
//     for (const filterName in queryParams) {
//       if (Object.prototype.hasOwnProperty.call(queryParams, filterName)) {
//         // @ts-ignore
//         const filterValue = queryParams[filterName]
//         if (['page', 'per_page'].includes(filterName)) {
//           params[filterName] = filterValue
//         } else {
//           params['filter[' + filterName + ']'] = filterValue
//         }

//       }
//     }
//     const stringified = queryString.stringify(params)
//     return stringified
//   } else {
//     return ''
//   }

// }