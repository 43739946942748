import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { DetailingDataType, ExternalServiceType } from '../types/externalService'
import { externalServiceActions } from '../slice/externalServiceSlice'
import { ProviderStatus } from '../../../../../Api/projectPhonesAPI'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'

type RequestType = {
    externalServiceId: number
    start?: number
    end?: number
    data?: string[]
}

type ResponseType = DetailingDataType & ExternalServiceType

export const checkExternalService = createAsyncThunk<ExternalServiceType,
    RequestType,
    ThunkConfig<any>>(
    'emails/check_external_service',
    async (data, thunkApi) => {
        const { dispatch, rejectWithValue, extra } = thunkApi
        dispatch(externalServiceActions.setIsUpdated(true))
        let query = ''
        if (data.start) query = `?start=${data.start}`
        if (data.end) query = `${query}&end=${data.end}`
        if (data.data) query = `${query}&${data.data.map(i => `data[]=${i}`).join('&')}`

        if (data.start && data.end && data.data) {
            dispatch(externalServiceActions.setDetailingData({}))
        }

        try {
            const response = await extra.api().get<ResponseType>(`external-service/${data.externalServiceId}${query}`)


            if (data.start && data.end && data.data) {
                dispatch(externalServiceActions.setDetailingData({
                    externalServiceId: response.data.id,
                    calls: response.data.calls,
                    phoneNumbers: response.data.phoneNumbers,
                    sms: response.data.sms
                }))
            }

            return response.data as ExternalServiceType

        } catch (e) {
            handleServerNetworkError(e, dispatch, 'Отрицательный баланс или другая ошибка')

            if (data.start && data.end && data.data) {
                dispatch(externalServiceActions.setDetailingData({
                    externalServiceId: data.externalServiceId,
                    calls: undefined,
                    phoneNumbers: undefined,
                    sms: undefined
                }))
            }

            return rejectWithValue({
                id: data.externalServiceId,
                balance: '',
                name: '',
                status: ProviderStatus.INACTIVE,
                type: 'SMS'
            })

        } finally {
            dispatch(externalServiceActions.setIsUpdated(false)) //todo do this
        }
    }
)