import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { CommonLetterType, lettersActions } from '../../lettersSlice'


export const deleteManagerLetterInQueue = createAsyncThunk<{},
  number,
  ThunkConfig<any>>(
  'managerLetter/delete_manager_letter_in_queue',
  async (letterId, { dispatch, rejectWithValue, extra }) => {

    try {
      const response = await extra.api().delete<CommonLetterType>(`manager-letter/${letterId}`)
      dispatch(lettersActions.deleteLetterFromArray(response.data))

      dispatch(snackActions.setSnack({
        label: 'Письмо успешно удалено из очереди',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch,'Произошла ошибка при удалении письма из очереди')
      return rejectWithValue({})
    }
  }
)