import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { ProviderStatus, ProviderStatusInfo } from '../types/projectPhones'
import { fetchGlobalPhones } from './fetchGlobalPhones'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'

// type RequestType = {
//   phoneId: number
//   params?: PaginationParamsType
// }

// export const checkProviderStatusForCurrentPhone = createAsyncThunk<{ status: ProviderStatus, phoneId: number },
//   RequestType,
//   ThunkConfig<any>>(
//   'projectPhones/check_provider_status_for_current_phone',
//   async (data, { dispatch, rejectWithValue, extra }) => {

//     try {
//       const response = await extra.api().get<ProviderStatusInfo>(`check_available_number/${data.phoneId}`)
//       console.log('fetchGlobalPhones checkProviderStatusForCurrentPhone', response)
//       dispatch(fetchGlobalPhones())

//       return { status: response.data.response.status, data.phoneId }
//     } catch (e) {
//       handleServerNetworkError(e, dispatch, 'Не удалось получить информацию о статусе')
//       return rejectWithValue({})
//     }
//   }
// )

type ResponseType = {
  status: ProviderStatus,
  phoneId: number
}

type RequestPropsType = {
  phoneId: number
  params?: PaginationParamsType
}

export const checkProviderStatusForCurrentPhone = createAsyncThunk<
  any,
  RequestPropsType,
  ThunkConfig<any>
>(
  'projectPhones/check_provider_status_for_current_phone',
  async (data, thunkApi) => {
    try {
      const response = await thunkApi.extra.api().get<ProviderStatusInfo>(`check_available_number/${data.phoneId}`)
      data.params && thunkApi.dispatch(fetchGlobalPhones(data.params))

      return { 
        status: response.data.response.status,
        phoneId: data.phoneId
      }
    } catch (e) {
      handleServerNetworkError(e, thunkApi.dispatch, 'Не удалось получить информацию о статусе')
      return thunkApi.rejectWithValue({})
    }
  }

)