import { FC, MouseEvent } from 'react'

import { Box, Typography } from '@material-ui/core'

import { Icon, Portal } from '../../../ui-kit'
import { PointsType } from '../hooks/useContextMenu'
import { useStyles } from './styles'
import { useAppDispatch } from '../../../providers/StoreProvider/hooks/useAppDispatch'
import { chatThunks } from '../../../providers/StoreProvider/slices/chatsSlice'
import { IChatMessage } from '../../../types/chat'
//import { CustomEvents } from '../../../enums/enums'

type ContextMenuProps = {
  message: IChatMessage
  points: PointsType
  className?: string
  setClicked: (value: boolean) => void
}

export const ContextMenu: FC<ContextMenuProps> = ({
                                                    message,
                                                    points,
                                                    className,
                                                    setClicked
                                                  }) => {
  const classes = useStyles(points)
  const dispatch = useAppDispatch()

  const handleClickToContent = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }

  const handleClose = () => setClicked(false)

  const handleCloseContext = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setClicked(false)
  }

  const handlePinnedMessage = (): void => {
    // const event = new CustomEvent(CustomEvents.USER_CHAT_MESSAGE_PIN)
    // document.dispatchEvent(event)
    message.pinned
      //? dispatch(pinUnpinMessageInCurrentChat(message.id, message.chatId, false))
      ? dispatch(chatThunks.pinUnpinMessageInCurrentChat({ messageId: message.id, pinned: false }))
      //: dispatch(pinUnpinMessageInCurrentChat(message.id, message.chatId, true))
      : dispatch(chatThunks.pinUnpinMessageInCurrentChat({ messageId: message.id, pinned: true }))
    handleClose()
  }

  return (
    <Portal>
      <Box className={classes.root}
           onClick={handleClose}
           onContextMenu={handleCloseContext}
      >
        <Box className={`${classes.content} ${className}`}
             onClick={handleClickToContent}
        >
          <Box className={classes.menuItem}
               onClick={handlePinnedMessage}
          >
            <Icon name='pin' size={18} />
            <Typography>{message.pinned ? 'Открепить' : 'Закрепить'}</Typography>
          </Box>
        </Box>
      </Box>
    </Portal>
  )
}