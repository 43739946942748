import { createSlice } from '@reduxjs/toolkit'
import { OperatorsSchema } from '../types/operators'
import {
    fetchOperatorsProjectStatistic,
    fetchStatisticBySalesFunnel, fetchStatisticFromCancellationReshipRequests,
    fetchStatisticFromForwardingRequests
} from "../services"

const initialState: OperatorsSchema = {}

export const operatorsSlice = createSlice({
    name: 'operators',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchOperatorsProjectStatistic.fulfilled, (state, { payload }) => {
                state.operators = payload
            })
            .addCase(fetchStatisticBySalesFunnel.pending, (state) => {
                state.isLoad = true
            })
            .addCase(fetchStatisticBySalesFunnel.fulfilled, (state, { payload }) => {
                state.statisticBySalesFunnel = payload
                state.isLoad = false
            })
            .addCase(fetchStatisticFromForwardingRequests.pending, (state) => {
                state.isLoad = true
            })
            .addCase(fetchStatisticFromForwardingRequests.fulfilled, (state, { payload }) => {
                state.statisticFromForwardingRequests = payload
                state.isLoad = false
            })
            .addCase(fetchStatisticFromCancellationReshipRequests.pending, (state) => {
                state.isLoad = true
            })
            .addCase(fetchStatisticFromCancellationReshipRequests.fulfilled, (state, { payload }) => {
                state.statisticFromCancellationReshipRequests = payload
                state.isLoad = false
            })

    }
})

export const { actions: operatorsActions } = operatorsSlice
export const { reducer: operatorsReducer } = operatorsSlice

