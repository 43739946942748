import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { snackActions } from '../../snackSlice'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { fetchProductNotesForProject } from './fetchProductNotesForProject'
import { FilesType } from '../../../../../Components/Inputs/FilesAttach/FilesAttach'

type RequestProps = {
  projectId: number,
  productNotesId: number,
  notes: string[],
  filesData: FilesType
}

export const editProductNotesInProject = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'productNotes/edit_product_notes_in_project',
  async (data, { dispatch, rejectWithValue, getState, extra }) => {
    const { projectId, productNotesId, notes, filesData } = data
    const params = getState().productNotes.productNotesTablePaginationParams

    try {
      await extra.api().patch(`project/${projectId}/product-notes/${productNotesId}/edit`, {
        notes: notes,
        attachedFiles: filesData
      })
      dispatch(fetchProductNotesForProject({ projectId, params }))

      dispatch(snackActions.setSnack({
        label: 'Данные успешно изменены',
        typeLabel: 'success'
      }))
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось изменить данные')
      return rejectWithValue({})
    } finally {
    }
  }
)