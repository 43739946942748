import {ChatType, IShortClientData} from '../../../types'
import {ClientsSchema} from '../slices/clientsSlice'
import {CommonLetterType, ISMSLetter} from '../slices/lettersSlice'
import {NoteAdded} from '../../../Redux/socket-io-handler'
import {IChatMessage} from '../../../types/chat'
import {ChatsSchema} from '../slices/chatsSlice'

type ClientsArraySchema = Pick<ClientsSchema, 'activeClients' | 'snoozedClients'>
type ClientsArraySchemaKeys = keyof ClientsArraySchema

type ClientFindingStatus = 'active' | 'snooze' | null


export const getItemList = (state: any[] = [], data: { id: number, checked: boolean, projectName: string }): any[] => {
    if (data.checked) {
        return [...state.map(el => el.id === data.id
            ? {...el, projects: [...el.projects, data.projectName]}
            : el)
        ]
    } else {
        return [...state.map(el => el.id === data.id
            ? {
                ...el,
                projects: [...el.projects.filter((el: string) => el !== data.projectName)]
            }
            : el)]
    }
}


export const checkIsNeedToNotify = (mess: IChatMessage) => {
    const {chatParameters, needNotification} = mess
    const {type} = chatParameters

    if (type === ChatType.GENERAL || type === ChatType.PRIVATE) {
        return true
    } else if (type === ChatType.CLIENT && needNotification) {
        return true
    }
    return false
}

export const getClientsListAfterClientEdit = (
    state: ClientsSchema,
    payload: any,
    listType: ClientsArraySchemaKeys
) => {
    return state[listType]?.map(l => l.id === payload.clientId ? {
        ...l, ...payload.params,
        address: {...payload.params}
    } : l)
}

export const getClientsListAfterClientFunnelStepEdit = (
    state: ClientsSchema,
    payload: IShortClientData,
    listType: ClientsArraySchemaKeys
) => {
    return state[listType]?.map(l => l.id === payload.id ? {
        ...l, 
        salesFunnelStep: payload.salesFunnelStepKey,
        hasTrackProblem: payload.hasTrackProblem
    } : l)
}

export const getClientsStateAfterMailArrived = (
    state: ClientsSchema,
    payload: ISMSLetter | CommonLetterType,
    clientStatus: ClientFindingStatus
) => {
    if (clientStatus === 'active') {
        const client = state.activeClients?.find(cl => cl.id === payload.clientId)
        if (client) {
            const newActiveClients = state.activeClients?.filter(cl => cl.id !== payload.clientId)
            const trustedClients = newActiveClients
                ? newActiveClients.filter(cl => cl.status === 'TRUSTED')
                : []
            state.activeClients = newActiveClients && [
                ...trustedClients,
                {...client, hasUnreadLetter: true},
                ...newActiveClients.filter(cl => cl.status !== 'TRUSTED')
            ]
        }

    } else {
        const client = state.snoozedClients?.find(cl => cl.id === payload.clientId)
        state.snoozedClients = state.snoozedClients?.filter(cl => cl.id !== payload.clientId)
        const trustedClients = state.activeClients
            ? state.activeClients?.filter(cl => cl.status === 'TRUSTED')
            : []
        if (client) state.activeClients = state.activeClients &&
            [...trustedClients, {
                ...client,
                hasUnreadLetter: true
            }, ...state.activeClients.filter(cl => cl.status !== 'TRUSTED')]
    }

    return state
}

export const getClientsListAfterChangeNoteData = (
    state: ClientsSchema,
    payload: NoteAdded,
    listType: ClientsArraySchemaKeys
) => {
    return state[listType]?.map(l => l.id === payload.client.id
        ? {
            ...l,
            notes: payload.client.notes
        } : l)
}
export const getClientsListAfterClearUnreadMessages = (
    state: ClientsSchema,
    payload: number,
    listType: ClientsArraySchemaKeys
) => {
    return state[listType]?.map(l => l.id === payload ? {...l, hasUnreadLetter: false} : l)
}

export const getClientsListAfterUpdateSalesFunnelStep = (
    state: ClientsSchema,
    payload: { clientId: number, editFields: { step: string } },
    listType: ClientsArraySchemaKeys
) => {
    return state[listType]?.map(l => l.id === payload.clientId ? {
        ...l,
        salesFunnelStep: payload.editFields.step
    } : l)
}

export const getClientsListAfterSetEmptyMacros = (
    state: ClientsSchema,
    payload: { clientId: number, status?: boolean },
    listType: ClientsArraySchemaKeys
) => {
    const {clientId, status} = payload
    return state[listType]?.map(l => l.id === clientId ?
        {...l, hasEmptyMacrosInMessages: status ? status : false} : l)
}


export const checkWhereIsClient = (state: ClientsSchema, id?: number): ClientFindingStatus => {
    if (state.activeClients?.find(cl => cl.id === id)) return 'active'
    if (state.snoozedClients?.find(cl => cl.id === id)) return 'snooze'
    else return null
}

export const getChatsStateAfterReadAllMessages = (state: ChatsSchema, chatId: number): ChatsSchema => {
    state.globalChatList = state.globalChatList?.map(ch => ch.id === chatId ? {
        ...ch,
        unreadMessagesCount: 0,
        messages: [...ch.messages.map(m => ({...m, isRead: true}))]
    } : ch)
    state.chats = state.chats?.map(ch => ch.id === chatId ? {
        ...ch,
        unreadMessagesCount: 0,
        messages: [...ch.messages.map(m => ({...m, isRead: true}))]
    } : ch)
    if (state.activeChat?.id === chatId) {
        state.activeChat = {
            ...state.activeChat, unreadMessagesCount: 0,
            messages: [...state.activeChat.messages.map(m => ({...m, isRead: true}))]
        }
    }
    return state
}
