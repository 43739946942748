import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { fetchTemplates } from './fetchTemplates'
import { defaultPaginationParams } from '../slice/templatesSlice'


export const deleteTemplate = createAsyncThunk<{},
  number,
  ThunkConfig<any>>(
  'templates/delete_template',
  async (templateId, { dispatch, rejectWithValue, getState, extra }) => {

    const params = getState().templates.templatePagination

    try {
      await extra.api().delete(`letter-template/${templateId}`)
      dispatch(fetchTemplates(!params.page ? defaultPaginationParams : params))

      dispatch(snackActions.setSnack({
        label: 'Шаблон успешно удален',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Произошла ошибка при удалении шаблона')
      return rejectWithValue({})
    }
  }
)