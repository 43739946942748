import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { tokenCheck } from './tokenCheck'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { authActions } from '../slice/authSlice'


type LoginData = {
  username: string
  password: string
  remember?: boolean
}

export const loginCheck = createAsyncThunk<{},
  LoginData,
  ThunkConfig<any>>(
  'auth/loginCheck',
  async (data, thunkApi) => {
    const { dispatch, extra } = thunkApi
    dispatch(authActions.setLoginMessage(null))
    try {
      const response = await extra.api().post(`login_check`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      if (response.data.token) {
        localStorage.setItem('apikey', response.data.token)
        await dispatch(tokenCheck())
      }
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Введенные логин или пароль не верны')
      dispatch(authActions.setLoginMessage('Введенные логин или пароль не верны'))
    }
  }
)