import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { appActions } from '../../appSlice'

type RequestProps = {
  id: number,
  projectId: number,
  checked: boolean,
  projectName: string // todo не юзается в запросе
}

export const linkFragmentForCurrentProject = createAsyncThunk<RequestProps,
  RequestProps,
  ThunkConfig<any>>(
  'fragments/link_fragment_for_current_project',
  async (data, thunkApi) => {
    const { dispatch, rejectWithValue, extra } = thunkApi
    const { id, projectId, checked } = data
    dispatch(appActions.setIsLoad(true))
    try {
      await extra.api().patch(`letter-fragment/${id}/project/${projectId}/edit`, { checked })
      return data
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось обновить данные')
      return rejectWithValue({})

    } finally {
      dispatch(appActions.setIsLoad(false))
    }
  }
)