import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Box, createStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ExpandedRowLetter } from './ExpandedRowLetter/ExpandedRowLetter'
import { Loader } from '../../../../../../ui-kit'
import { useAppDispatch } from '../../../../../../providers/StoreProvider/hooks/useAppDispatch'
import {
  lettersActions,
  letterSelectors,
  letterThunks
} from '../../../../../../providers/StoreProvider/slices/lettersSlice'


type ExpandedLetterPropsType = {};

export const ExpandedLetter: FC<ExpandedLetterPropsType> = () => {

  const classes = useStyles()

  //const clientLetter = useSelector(selectClientLetterInfo)
  const clientLetter = useSelector(letterSelectors.getClientLetterInfo)
 // const target = useSelector(selectLetterTarget)
  const target = useSelector(letterSelectors.getLetterTarget)

  const { letterId } = useParams<{ letterId: string }>()

  const dispatch = useAppDispatch()

  useEffect(() => {
    //dispatch(getLetterInfo(letterId as string))
    letterId && dispatch(letterThunks.fetchLetterInfo(letterId))
    return () => {
      //dispatch(actions.clearLetterInfo())
      dispatch(lettersActions.clearLetterInfo())
    }
  }, [dispatch, letterId, target])


  return (
    <Box className={classes.container}>
      <ExpandedRowLetter clientLetter={clientLetter}
                         target={target}
                         letterId={Number(letterId)}
      />
      {!clientLetter && <Loader />}
    </Box>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center'
    }
  })
)