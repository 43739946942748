import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { PhoneType } from '../types/projectPhones'


export const fetchProjectPhonesForSelect = createAsyncThunk<PhoneType[],
 number,
  ThunkConfig<any>>(
  'projectPhones/fetch_project_phones_for_select',
  async (projectId, { dispatch, rejectWithValue, extra }) => {

    try {
      const response = await extra.api().get<PhoneType[]>(`project/${projectId}/phones-for-select`)
      return response.data
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить список телефонов')
      return rejectWithValue({})
    }
  }
)