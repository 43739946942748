import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { prepareFilterParams } from '../../../../../Api/API'
import { CommonLetterType, MessagesFeedType } from '../types/letters'

type ResponseType = {
    
}

type RequestPropsType = {
    clientId: number,
    header: string
}

export const fetchAllClientMessageFeed = createAsyncThunk<
    CommonLetterType[],
    RequestPropsType,
    ThunkConfig<any>
>(
    'letters/fetch_all_client_message_feed',
    async (data, thunkApi) => {
        try {
            const res = await thunkApi.extra.api().get<CommonLetterType[]>(`client/${data.clientId}/messages-all?header=${data.header}`)
            
            return res.data
        } catch (e) {
            handleServerNetworkError(e, thunkApi.dispatch, 'Не удалось получить список менеджеров клиента')
            return thunkApi.rejectWithValue({})
        } finally {
            
        }
    }
    
)