import { FC } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'

import { Icon } from '../../../../ui-kit'
import { IChatMessage } from '../../../../types/chat'


type ChatMessageInfoPropsType = {
  isMyMessage: boolean | undefined,
  message: IChatMessage
}

export const ChatMessageInfo: FC<ChatMessageInfoPropsType> = ({
                                                                isMyMessage,
                                                                message
                                                              }) => {
  const classes = useStyles({ isMyMessage })

  return (
    <>
      <Box className={classes.messageInfo}>
        {!isMyMessage && (
          <Typography className={classes.companionName}>
            {message.author.username}
          </Typography>
        )}
        <Typography className={classes.messageTime}>
          {message.postDate}
        </Typography>
        {isMyMessage && (
          message.isSent
            ? <Icon name='done' size={14} style={{ fill: '#5383ff' }} />
            : <Icon name='waitToSend' size={12} style={{ fill: 'rgb(27,39,51)' }} />
        )}
        {message.pinned ? <Icon name='pin' size={14} /> : null}
      </Box>
    </>
  )
}

const useStyles = makeStyles<{}, { isMyMessage?: boolean }>({
    messageInfo: {
      marginTop: '4px',
      display: 'flex',
      width: '100%',
      minHeight: '16px',
      alignItems: 'center',
      gap: '3px',
      justifyContent: ({ isMyMessage }) => isMyMessage ? 'flex-end' : 'flex-start'
    },
    companionName: {
      marginRight: 5,
      fontSize: '12px',
      fontWeight: 'bold',
      lineHeight: '12px',
      color: 'rgba(27,39,51,.5)'
    },
    messageTime: {
      fontSize: '12px',
      color: 'rgba(27,39,51,.5)',
      marginRight: ({ isMyMessage }) => isMyMessage ? 5 : 0,
      lineHeight: ({ isMyMessage }) => isMyMessage ? 0 : '12px'
    }
  }
)