import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { appActions } from '../../appSlice'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'
import { fetchArchiveProjects } from './fetchArchiveProjects'


interface RequestProps {
  id: number,
  params: PaginationParamsType
}

export const deleteArchiveProject = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'archiveProjects/deleteArchiveProject',
  async (data, thunkApi) => {
    const { dispatch, rejectWithValue, extra } = thunkApi
    try {
      await extra.api().delete(`project/${data.id}`)

      dispatch(fetchArchiveProjects(data.params))

      dispatch(snackActions.setSnack({
        label: 'Проект был успешно удален',
        typeLabel: 'success'
      }))
      return data.id
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось удалить проект')
      return rejectWithValue({})
    } finally {
      dispatch(appActions.setIsFetchClientData(false))
    }
  }
)