import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { useStyles } from './styles'
import { Link } from 'react-router-dom'
import { RoutePath } from '../../providers/router/routeConfig/routeConfig'


export const PageError = () => {
  const classes = useStyles()

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.description}>
        <Typography variant='h2'>
          Что-то пошло не так
        </Typography>
        <Typography variant='subtitle1' className={classes.typography}>
          Перейти на {' '}
          <Link to={RoutePath.main}>
            главную
          </Link>
        </Typography>
      </Box>
    </Box>
  )
}