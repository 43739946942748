import React from 'react'
import { TableFieldType } from '../types/field'
import { PaginationParamsType } from '../types/query'
import { Fade, TableCell, TableRow, Typography } from '@material-ui/core'

type TableContentBodyPropsType = {
    items: any[]
    fields: TableFieldType[]
    refreshData: () => void
    paginationParams?: PaginationParamsType
    fetch?: {
        function: (params?: any) => void
    }
    target?: string
    prefixParam: string
    isPublic?: boolean
    extraData?: {
        [key: string]: any
    }
    setIsFetching?: (isFetchong: boolean) => void
}

const refreshData = () => {

}

const TableContentBody: React.FC<TableContentBodyPropsType> = (props) => {

    if (props.items.length === 0) {
        return (
            <TableRow data-testid='empty-table-row'>
                <TableCell align='center' colSpan={props.fields.length}>
                    <Typography
                        variant='h6'
                        style={{ margin: '10px 0' }}
                    >
                        Таблица пуста
                    </Typography>
                </TableCell>
            </TableRow>
        )
    }

    return (
        <>
            {props.items.map((item, keyItem) => {
                console.log('tableProps', props)
                return (
                    <Fade key={keyItem} in={true} timeout={500}>
                        <TableRow hover data-testid='table-row'>
                            {props.fields.map((field, fieldKey) => {
                                if (!field.component) {
                                    return (
                                        <TableCell key={keyItem + '-' + fieldKey}>
                                            {field.name !== undefined ? item[field.name] : null}
                                        </TableCell>
                                    )
                                } else {
                                    return (
                                        <TableCell key={keyItem + '-' + fieldKey}>
                                            <field.component
                                                data={item}
                                                name={field.name}
                                                refreshData={refreshData}
                                                target={props.target}
                                                paginationParams={props.paginationParams}
                                                fetch={fetch}
                                                action={field.action ? field.action : undefined}
                                                isPublic={props.isPublic ?? field.isPublic}
                                                managerType={field.managerType}
                                                prefixParam={props.prefixParam}
                                                extraData={field.extraData}
                                                setIsFetching={props.setIsFetching}
                                            />
                                        </TableCell>
                                    )
                                }
                            })}
                        </TableRow>
                    </Fade>
                )
            })}
        </>
    )
}

export default TableContentBody