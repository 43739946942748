import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { FetchTemplatesResponse } from '../types/templates'
import { prepareFilterParams } from '../../../../../Api/API'
import { templatesActions } from '../slice/templatesSlice'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'


export const fetchTemplates = createAsyncThunk<FetchTemplatesResponse,
  PaginationParamsType | undefined,
  ThunkConfig<any>>(
  'templates/fetch_templates',
  async (params, { dispatch, rejectWithValue, getState, extra }) => {

    const templatesList = getState().templates.templateList
    !templatesList && dispatch(templatesActions.setIsFetch(true))

    try {
      const res = await extra.api().get<FetchTemplatesResponse>(`letter-templates?${prepareFilterParams(params)}`)
      params && dispatch(templatesActions.setParams(params))
      return res.data

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось загрузить шаблоны')
      return rejectWithValue({})
    } finally {
      dispatch(templatesActions.setIsFetch(false))
    }
  }
)