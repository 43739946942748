import { FilteredValueType } from '../../../../../ui-kit'
import { ActionType, IClientForProject, NoteType } from '../../../../../types'
import { CallType } from '../../callSlice'

export interface LettersSchema {
  clientId?: number
  openedLettersSettings: OpenedLettersSettings
  generalMessageFeed: MessagesFeedType
  totalCount: number
  currentPage: number
  isFetching: boolean,
  filter: FilteredValueType
  plannedLetters: PlannedMessagesFeed
  failedLetters: FailedMessagesFeed,
  clientLetter: CommonLetterType | null
  letterTarget: LetterInfoTargetType | string
  haveUnreadNotes: boolean
  histories: HistoriesType[]
  isMessagesFeedLoading: boolean
  lettersByHeader?: CommonLetterType[]
}

export enum LetterType {
  ACTION = 'ACTION',
  LETTER = 'LETTER',
  NOTE = 'NOTE',
  SMS = 'SMS',
  CALL = 'CALL'
}

export type MessagesFeedType = (CommonLetterType | NoteWithType | HistoriesWithType | ISMSLetter | CallType)[]
export type MessageFeedType = (CommonLetterType | NoteWithType | HistoriesWithType | ISMSLetter)
export type PlannedMessagesFeed = (CommonLetterType | ISMSLetter)[]
export type FailedMessagesFeed = (CommonLetterType | ISMSLetter)[]

export interface CommonLetterType {
  rights?: CommonLetterRightsType
  clientId?: number
  attachedFiles?: any[]
  clientFirstName?: string
  clientLastName?: string
  clientSalesFunnelStep?: string
  clientStatus?: 'NEW' | 'TRUSTED' | ''
  hasEmptyMacrosInMessages: boolean
  macrosErrors: string[]
  header?: string
  id: number
  isChainLetter?: boolean
  isClientLetter?: boolean
  isRead: boolean
  isSent: boolean
  message: string
  postDate: string
  postTimestamp: number
  projectId?: number
  recipient: string
  sender?: string | {
    id: number
    email: string
  },
  status?: {
    key: UnsentLetterSendStatus
  },
  sendNow?: boolean
  sendings?: SendingTryType[]
  type: LetterType.LETTER
}

export interface ISMSLetter extends Omit<CommonLetterType,
  | 'attachedFiles'
  | 'header'
  | 'sender'
  | 'type'> {
  sender: string | {
    id: number
    phone: string
  },
  hasEmptyMacrosInMessages: boolean
  type: LetterType.SMS;
}

export interface NoteWithType extends NoteType {
  type: LetterType.NOTE;
}

export interface HistoriesWithType extends HistoriesType {
  type: LetterType.ACTION;
}

export type SendingTryType = {
  date: string
  message: string
  status: string
}

export type CommonLetterRightsType = {
  canDelete: boolean
  canEdit: boolean
}


export type UnsentLetterSendStatus =
  | 'HALTED'
  | 'FAIL'
  | 'STOPED'
  | 'WAITING'
  | 'DELETED'
  | 'DECLINED'


export type LetterInfoTargetType = 'client' | 'manager'

export type OpenedLettersSettings = {
  isOpenAllLetters: boolean
  isOpenPlannedLetters: boolean
  isOpenFailedLetters: boolean
}

export type HistoriesType = {
  id: number
  actions: ActionType[]
  createdAt: string
  modifier: string
  postTimestamp: number
}

export type FetchClientsMessagesResponse = {
  items: MessagesFeedType
  totalCount: number
}

export type CreateNoteResponse = {
  notes: NoteWithType[]
  client: IClientForProject[]
}

export type OpenClientLettersQueryParams = {
  isOpenPlannedLetters: boolean
  isOpenAllLetters: boolean
  isOpenFailedLetters: boolean
}