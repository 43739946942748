import React, { FC, MouseEventHandler } from 'react'
import { CancelDeletionButton, DeleteButton, SuccessButton } from './styled-buttons'

type ModalActionButtonsProps = {
  handleCancelClick: MouseEventHandler<HTMLButtonElement>
  handleDeleteItem: (e?: any) => void
  disabled?: boolean
  cancelTitle?: string
  successTitle?: string
  isUpdate?: boolean
  isSuccess?: boolean
}

export const ModalActionButtons: FC<ModalActionButtonsProps> = ({
  handleCancelClick,
  handleDeleteItem,
  disabled,
  isUpdate,
  isSuccess,
  cancelTitle,
  successTitle,
}) => {
  return (
    <>
      <CancelDeletionButton onClick={handleCancelClick}
                            disabled={false}
      >
        <span>{cancelTitle ? cancelTitle : 'Закрыть'}</span>
      </CancelDeletionButton>
      {isSuccess ?
        <SuccessButton disabled={disabled} onClick={handleDeleteItem}>
          <span>{successTitle ? successTitle : isUpdate ? 'Изменить' : 'Удалить'}</span>
        </SuccessButton>
        :
        <DeleteButton
          disabled={disabled}
          onClick={handleDeleteItem}>
          <span>{successTitle ? successTitle : isUpdate ? 'Изменить' : 'Удалить'}</span>
        </DeleteButton>
      }
    </>
  )
}