import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'

import { CommonLetterType } from '../types/letters'
import { appActions } from '../../appSlice'

export const fetchLetterInfo = createAsyncThunk<CommonLetterType,
  string,
  ThunkConfig<any>>(
  'letters/fetch_letter_info',
  async (letterId, { dispatch, rejectWithValue, getState, extra }) => {

    dispatch(appActions.setStatus('loading'))
    const target = getState().letters.letterTarget

    try {
      if (target === 'client') {
        const clientLetterResponse = await extra.api().get<CommonLetterType>(`client/letter/${letterId}/show`)
        return clientLetterResponse.data
      } else {
        const managerLetterResponse = await extra.api().get<CommonLetterType>(`manager-letter/${letterId}/show`)
        return managerLetterResponse.data
      }
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить детализацию письма')
      return rejectWithValue({})
    } finally {
      dispatch(appActions.setStatus('success'))
    }
  }
)