import { ServerUserRolesType } from "../Api/projectUserAPI"
import { CommonLetterType } from "../providers/StoreProvider/slices/lettersSlice"
import { SalesFunnelStepType } from './client'
import { StatusLeadKeyType } from '../default-values'

export enum ChatType {
    GENERAL = 'GENERAL',
    CLIENT = 'CLIENT',
    PRIVATE = 'PRIVATE'
}


interface BaseChat {
    id: number
    members: Member[]
    messages: IChatMessage[]
    rights: ChatRights
    hasNotification: boolean
    needToNotify: boolean
    unreadMessagesCount: number
}

export interface GeneralChat extends BaseChat {
    type: ChatType.GENERAL
}

export interface PrivateChat extends BaseChat {
    name: string
    creator: {
        id: number,
        username: string
    }
    type: ChatType.PRIVATE
}

export interface ClientChat extends BaseChat {
    clientFirstName: string
    clientLastName: string
    clientId: number
    projectId: number,
    clientInfo: {
        projectName: string
        salesFunnel: {
            key: SalesFunnelStepType,
            value: string
        }
        status: {
            key: StatusLeadKeyType,
            value: string
        }
    },
    type: ChatType.CLIENT
}

export interface IChatMessage extends Pick<CommonLetterType,
  'id' | 'message' | 'postDate' | 'postTimestamp' | 'isSent' | 'isRead'> {
    author: {
        id: number
        username: string
    },
    isMyMessage: boolean
    pinned: boolean
    needNotification: boolean
    chatId: number
    files: any[],
    chatParameters: {
        clientId: number | null
        type: ChatType
        chatId: number
        chatName: string | null
        clientFirstName: string | null
        clientLastName: string | null
    }
}


export type Member = {
    id: number
    username: string
    roles?: ServerUserRolesType[]
}

type ChatRights = {
    canEdit: boolean
    canDelete: boolean
    canViewManageLink: boolean
}