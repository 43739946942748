import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { fetchClientsByProject } from '../../clientsSlice/services'
import { AxiosResponse } from 'axios'
import { CommonLetterType, ISMSLetter, lettersActions } from '../../lettersSlice'
import { LetterType } from '../../../../../Api/lettersApi'
import { clientsActions } from '../../clientsSlice'


interface RequestProps {
  userId: number,
  chain_id: string | null,
  params: {
    snooze: boolean,
    snoozeTime: number,
    isSendAsAnswer: boolean,
    ids: number[]
  }
}

export const sendChainTemplate = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'chains/send_chain_template',
  async (data, thunkApi) => {
    const { userId, chain_id, params } = data
    const { dispatch, rejectWithValue, extra } = thunkApi

    try {
      const res = await extra.api().post<ISMSLetter[] | CommonLetterType[],
        AxiosResponse<ISMSLetter[] | CommonLetterType[]>>(`manager-letter/client/${userId}/chain-letters/${chain_id}`, params)

      if (params.snooze) {
       // project_id && dispatch(fetchClientsByProject({ projectId: project_id }))
        dispatch(snackActions.setSnack({
          label: 'Клиент отправлен в "Отложенные"',
          typeLabel: 'success'
        }))
      } else {
        if (res.data[0] && res.data[0].type === LetterType.SMS) {
          dispatch(lettersActions.setChainOfSms(res.data as ISMSLetter[]))
        } else {
          dispatch(lettersActions.setChainOfLetters(res.data as CommonLetterType[]))
        }
        dispatch(snackActions.setSnack({
          label: 'Цепочка была успешно отправлена',
          typeLabel: 'success'
        }))
      }

      if (res.data[0] && res.data[0].clientId) {
        dispatch(clientsActions.setEmptyMacros({
          clientId: res.data[0].clientId,
          status: res.data[0].hasEmptyMacrosInMessages
        }))
      }

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось отправить цепочку')
      return rejectWithValue({})
    } finally {
    }
  }
)