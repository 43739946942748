import { ModalValueType } from '../types/emails'

export const emptyModalValue: ModalValueType = {
  templateName: undefined,
  email: '',
  // password: '',
  host: '',
  port: 0,
  protocol: 'pop3',
  smtpHost: '',
  smtpPort: 0,
  proxy: '',
  isEncryption: false,
  toDeleteLetters: false,
  checkFrequencyInMin: 1,
  hourLimit: 1,
  limitErrorsToNotify: 1,
  lastErrors: [],
  id: 0
}