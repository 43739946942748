import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { snackActions } from '../../snackSlice'
import { handleServerAppError, handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { mailboxForDealActions } from '../slice/mailboxForDealSlice'
import { ResponseStatusCodes } from '../../../../../enums/enums'
import { EmailTargetType } from '../../emailSlice'
import { EmailForDealingType, EmailForNewClientsType } from '../../emailSlice/types/emails'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'
import { fetchEmails } from '../../emailSlice/services'

type RequestType = {
  target: EmailTargetType
  mailboxData: EmailForDealingType[] | EmailForNewClientsType[]
  params: PaginationParamsType
  projectId: number
}

export const createEmailsForNewClientsPack = createAsyncThunk<{},
  RequestType,
  ThunkConfig<any>>(
  'mailboxForDeal/create_emails_for_new_clients_pack',
  async (data, { dispatch, rejectWithValue, extra }) => {
    try {
      const { target, mailboxData, params, projectId } = data
      const res = await extra.api().post(`mailboxes-for-new-clients`, {
        mailboxes: mailboxData
      })
      if (res.status === ResponseStatusCodes.success) {
        dispatch(mailboxForDealActions.setIsSuccess(true))
        dispatch(mailboxForDealActions.setErrorsForNewClients([]))
        dispatch(snackActions.setSnack({
          label: 'Импорт почты из файла был успешно произведен',
          typeLabel: 'success'
        }))
        dispatch(mailboxForDealActions.setIsSuccess(false))

        /** Обновляем данные вместе с параметрами пагинации */
        dispatch(fetchEmails({
          target: target,
          projectId: projectId,
          params: params
        }))

        /** Обнуляем ошибки */
        dispatch(mailboxForDealActions.setErrorsForNewClients([]))

      } else {
        handleServerAppError(res, dispatch)
        dispatch(mailboxForDealActions.setErrorsForNewClients(res.data))
        dispatch(mailboxForDealActions.setIsSuccess(true)) // todo wtf ??
        dispatch(mailboxForDealActions.setIsSuccess(false)) // todo wtf ??
      }
    } catch (e: any) {
      handleServerNetworkError(e, dispatch, 'При импорте почты из файла произошла ошибка')

      dispatch(mailboxForDealActions.setErrorsForNewClients(e.response.data))

      return rejectWithValue({})
    } finally {

    }
  }
)