import React, { ReactNode, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Call, Device } from '@twilio/voice-sdk'
import IncomingCall from '../../../Components/Call/IncomingCall/IncomingCall'
import { authSelectors } from '../../StoreProvider/slices/authSlice'
import { useAppDispatch } from '../../StoreProvider/hooks/useAppDispatch'
import { callsActions, callsSelectors, callsThunks } from '../../StoreProvider/slices/callSlice'
import { fetchClientByPhone } from '../../StoreProvider/slices/clientsSlice/services/fetchClientByPhone'

type VoiceProviderPropsType = {
  children: ReactNode
}

const VoiceProvider: React.FC<VoiceProviderPropsType> = (props) => {
  const dispatch = useAppDispatch()

  const token = useSelector(callsSelectors.getToken)
  const device = useSelector(callsSelectors.getDevice)
  const userId = useSelector(authSelectors.getAuthUserId)
  const projectsUserPhones = useSelector(callsSelectors.getProjectUserPhones)


  const [incoming, setIncoming] = useState<boolean>(false)
  const [call, setCall] = useState<Call>()

  const userPhonesToArray = (): string[] => {
    const phonesArray: string[] = []
    if (projectsUserPhones) {
      for (const key in projectsUserPhones) {
        if (Object.prototype.hasOwnProperty.call(projectsUserPhones, key)) {
          const project = projectsUserPhones[key]
          project.forEach(phone => {
            phonesArray.push(phone.name.replace('+', '%2B'))
          })
        }
      }
    }
    return phonesArray
  }

  const [gettedPhones, setGettedPhones] = useState<string[]>([])
  useEffect(() => {
    if (userId && projectsUserPhones) {
      setGettedPhones(userPhonesToArray())
    }
  }, [projectsUserPhones, userId])
  useEffect(() => {
    gettedPhones.forEach((phone) => {
      userId && dispatch(callsThunks.fetchTwilioToken({
        userId,
        identity: phone,
        isAssistant: 0
      }))
    })
  }, [gettedPhones])

  const [gettedTokens, setGettedTokens] = useState<string[]>([])
  useEffect(() => {
    if (token) {
      for (const key in token) {
        if (Object.prototype.hasOwnProperty.call(token, key) && !gettedTokens.includes(key)) {
          setGettedTokens([...gettedTokens, key])
          dispatch(callsActions.setDevice({
            device: new Device(token[key], {
              codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
              tokenRefreshMs: 3600
            }),
            identity: key
          }))
        }
      }
    }
  }, [token])

  // useEffect(() => {
  //   if (token) {
  //     const currentToken = token[gettedTokens[gettedTokens.length - 1]]
  //     console.log('device VoiceProvider currentToken', [...processedDevices])
  //   }
  // }, [gettedTokens])

  const [processedDevices, setProcessedDevices] = useState<string[]>([])
  useEffect(() => {
    if (device) {
      for (const key in device) {
        if (!processedDevices.includes(key)) {
          setProcessedDevices([...processedDevices, key])
        }
      }
    }
  }, [device])
  const [registeredDevices, setRegisteredDevices] = useState<string[]>([])
  useEffect(() => {
    if (device) {

      for (const key in device) {
        if (Object.prototype.hasOwnProperty.call(device, key)) {
          if (!registeredDevices.includes(key)) {
            setRegisteredDevices([...registeredDevices, key])
          }
          const currentDevice = device[key]
          console.log('Twilio.Device currentDevice', currentDevice)

          currentDevice.on('registered', () => {
            console.log('Twilio.Device Ready to make and receive calls! to: ', processedDevices[processedDevices.length - 1])
            dispatch(callsActions.setDeviceStatus({status: 'registered', key}))
          })

          currentDevice.on('error', (error) => {
            console.log(`Twilio.Device Error: ${error}`)
            console.log(`Twilio.Device Error message: ${error.code}`)
            if (error.message === 'The Access Token provided to the Twilio API has expired, the expiration time specified in the token was invalid, or the expiration time specified was too far in the future') {
              alert('NEED TO UPDATE TWILIO TOKEN 1')
            }
            if (error.message === 'Twilio was unable to validate your Access Token') {
              alert('NEED TO UPDATE TWILIO TOKEN 2') 
            }
          })

          currentDevice.on('incoming', (call: Call) => {
            console.log('Twilio.Device.Incoming call', call)

            dispatch(fetchClientByPhone({
              clientPhone: call.parameters.From,
              createIncomingCall: true,
              projectUserPhone: call.parameters.To.split('client:+')[1]
            }))

            setCall(call)
            setIncoming(true)
          })

          currentDevice.on('tokenWillExpire', () => {
           // alert('REFRESH')
          })

          // ////////////////////////////////////////////////////////
          // currentDevice.on('cancel', (e) => {
          //   console.log('Twilio.Device cancel')
          // })
          // currentDevice.on('ignore', (e) => {
          //   console.log('Twilio.Device ignore')
          // })
          // currentDevice.on('disconnect', (e) => {
          //   console.log('Twilio.Device disconnect')
          // })
          
          // currentDevice.on('disconnected-by-local', () => {
          //   console.log('Twilio.Device disconnected-by-local')
          // })
          // currentDevice.on('accepted-by-local', () => {
          //   console.log('Twilio.Device accepted-by-local')
          // })
          // currentDevice.on('accepted-by-remote', () => {
          //   console.log('Twilio.Device accepted-by-remote')
          // })
          // currentDevice.on('disconnected-by-remote', () => {
          //   console.log('Twilio.Device disconnected-by-remote')
          // })
          // currentDevice.on('ignored-by-local', () => {
          //   console.log('Twilio.Device ignored-by-local')
          // })
          // currentDevice.on('unmuted', () => {
          //   console.log('Twilio.Device unmuted')
          // })
          // currentDevice.on('muted', () => {
          //   console.log('Twilio.Device muted')
          // })
          // currentDevice.on('connected', () => {
          //   console.log('Twilio.Device connected')
          // })
          // currentDevice.on('outgoing-ringing', () => {
          //   console.log('Twilio.Device outgoing-ringing')
          // })

          // ////////////////////////////////////////////////////////

          console.log('Twilio.Device state', key, ' ', currentDevice.state)

          if (currentDevice.state !== 'registered') {
            currentDevice.register()
          }
          
        }
      }
    }
  }, [processedDevices]);

  return (
    <>
      {incoming &&
        <IncomingCall
          call={call}
          setCallParams={setCall}
          setIncoming={setIncoming}
        />
      }
      {props.children}
    </>
  )
}

export default VoiceProvider

