import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ChatsSchema } from '../types/chats'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'
import {
    fetchAvailableUsers,
    markChatMessageAsRead, markMultipleMessagesAsRead, readAllMessagesInClientChat,
    readAllMessagesInGeneralChat,
    readAllMessagesInPrivateChat
} from '../services'
import { ClientChat, GeneralChat, PrivateChat } from '../../../../../types'
import { fetchGlobalChats } from '../services'
import { checkIsNeedToNotify, getChatsStateAfterReadAllMessages } from '../../../helpers/helpers'
import { IChatMessage } from '../../../../../types/chat'


const defaultPaginationParams: PaginationParamsType = { per_page: 15, page: 1 }


const initialState: ChatsSchema = {
    chats: [],
    isOpenChat: false,
    noChatForAction: false,
    actionClientId: null,
    actionChatId: null,
    actionChatType: null,
    isFetch: false,
    paginationParams: defaultPaginationParams,
    messageNotifyData: null
}


export const chatsSlice = createSlice({
    name: 'chats',
    initialState,
    reducers: {
        setIsFetchData: (state, action: PayloadAction<boolean>) => {
            state.isFetch = action.payload
        },
        setParams: (state, action: PayloadAction<PaginationParamsType>) => {
            state.paginationParams = action.payload
        },
        openChat: (state, action: PayloadAction<boolean>) => {
            state.isOpenChat = action.payload
            state.activeChat = undefined
        },
        openGroupChat: (state, action: PayloadAction<ClientChat | GeneralChat | PrivateChat>) => {
            let newObj = state.chats.find(el => el.id === action.payload.id)
            if (!newObj) {
                state.chats.unshift({ ...action.payload, needToNotify: false })
                state.activeChat = action.payload
                state.isOpenChat = true
            }
        },
        openCollapsedChat: (state, action: PayloadAction<number>) => {
            const currenActiveChat = state.chats.filter(el => el.id === action.payload)[0]
            state.activeChat = { ...currenActiveChat, messages: currenActiveChat.messages.filter(m => m.id !== 0) } // Todo логика отображения хардкод сообщения ( заглушка сообщения )
            state.chats = state.chats.map(ch => ch.id === action.payload ? {
                ...ch,
                hasNotification: false,
                needToNotify: false
            } : ch)
            state.isOpenChat = true
        },
        deleteActiveChat: (state, action: PayloadAction<{ id: number, isOpen: boolean }>) => {
            state.chats = state.chats.filter(ch => ch.id !== action.payload.id)
            state.activeChat = undefined
            state.isOpenChat = action.payload.isOpen
        },
        incrementUnreadMessagesCount: (state, action: PayloadAction<number>) => {
            state.globalChatList = state.globalChatList?.map(ch => ch.id === action.payload ? {
                ...ch, unreadMessagesCount: ++ch.unreadMessagesCount
            } : ch)
        },
        clearChats: (state) => {
            state.chats = []
            state.activeChat = undefined
            state.isOpenChat = false
        },
        wsChatAddedAfterSend: (state, action: PayloadAction<ClientChat | GeneralChat | PrivateChat>) => {
            state.chats = [{ ...action.payload, needToNotify: true }, ...state.chats]
            state.noChatForAction = false
            state.actionClientId = null
        },
        wsChatMessagePinned: (state, action: PayloadAction<IChatMessage>) => {
            let chatForAction = state.chats.find(el => el.id === action.payload.chatId)
            if (state.activeChat?.id === action.payload.chatId) {
                chatForAction?.messages.map(m => m.id === action.payload.id ? { ...action.payload } : m)
                state.activeChat = {
                    ...state.activeChat,
                    messages: state.activeChat.messages.map(m => m.id === action.payload.id ? { ...action.payload } : m)
                }
                state.chats = state.chats.map(c => c.id === chatForAction?.id ? {
                    ...chatForAction,
                    messages: chatForAction.messages.map(m => m.id === action.payload.id ? { ...action.payload } : m)
                } : c)
            } else if (chatForAction && chatForAction.id !== state.activeChat?.id) {
                chatForAction.messages.map(m => m.id === action.payload.id ? { ...action.payload } : m)
                state.chats = state.chats.map(ch => ch.id === chatForAction?.id ? { ...chatForAction } : ch)
            }
        },
        wsChatMessageAdded: (state, action: PayloadAction<IChatMessage>) => {
            let chatForAction = state.chats.find(el => el.id === action.payload.chatId)
            if (state.activeChat?.id === action.payload.chatId) {
                state.activeChat = {
                    ...state.activeChat,
                    messages: action.payload.id === 0 // Todo логика отображения хардкод сообщения ( заглушка сообщения )
                        ? [...state.activeChat.messages, action.payload]
                        : [...state.activeChat.messages.filter(m => m.id !== 0), action.payload]  //Todo логика отображения хардкод сообщения ( заглушка сообщения )
                }
                state.chats = state.chats.map(ch => ch.id === action.payload.id ? {
                    ...ch,
                    messages: [...ch.messages, action.payload]
                } : ch)
            } else if (chatForAction && chatForAction.id !== state.activeChat?.id) {
                chatForAction.messages = [...chatForAction.messages, action.payload]
                state.chats = state.chats.map(ch => ch.id === chatForAction?.id ? {
                    ...chatForAction,
                    needToNotify: checkIsNeedToNotify(action.payload)
                } : ch)
            } else if (!chatForAction && checkIsNeedToNotify(action.payload)) {
                state.noChatForAction = true
                state.actionClientId = action.payload.chatParameters.clientId
                state.actionChatId = action.payload.chatParameters.chatId
                state.actionChatType = action.payload.chatParameters.type
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAvailableUsers.fulfilled, (state, { payload }) => {
                state.availableUsers = payload
            })
            .addCase(fetchGlobalChats.fulfilled, (state, { payload }) => {
                state.globalChatList = payload.items
                state.totalCount = payload.totalCount
            })
            .addCase(fetchGlobalChats.rejected, (state) => {
                state.globalChatList = []
                state.totalCount = 0
            })
            .addCase(markChatMessageAsRead.fulfilled, (state, { payload }) => {
                state.globalChatList = state.globalChatList?.map(ch => ch.id === payload.chatId ? {
                    ...ch,
                    unreadMessagesCount: ch.unreadMessagesCount - 1
                } : ch)
                state.chats = state.chats.map(ch => ch.id === payload.chatId ? {
                    ...ch,
                    unreadMessagesCount: ch.unreadMessagesCount - 1
                } : ch)
            })
            .addCase(readAllMessagesInGeneralChat.fulfilled, (state) => {
                state.globalChatList = state.globalChatList?.map(ch => ch.type === 'GENERAL' ? {
                    ...ch,
                    unreadMessagesCount: 0,
                    messages: [...ch.messages.map(m => ({ ...m, isRead: true }))]
                } : ch)
                state.chats = state.chats?.map(ch => ch.type === 'GENERAL' ? {
                    ...ch,
                    unreadMessagesCount: 0,
                    messages: [...ch.messages.map(m => ({ ...m, isRead: true }))]
                } : ch)
                if (state.activeChat?.type === 'GENERAL') {
                    state.activeChat = {
                        ...state.activeChat,
                        unreadMessagesCount: 0,
                        messages: [...state.activeChat.messages.map(m => ({ ...m, isRead: true }))]
                    }
                }
            })
            .addCase(readAllMessagesInPrivateChat.fulfilled, (state, { payload }) => {
                getChatsStateAfterReadAllMessages(state, payload)
            })
            .addCase(readAllMessagesInClientChat.fulfilled, (state, { payload }) => {
                getChatsStateAfterReadAllMessages(state, payload)
            })
            .addCase(markMultipleMessagesAsRead.fulfilled, (state, { payload }) => {
                state.activeChat = state.activeChat && {
                    ...state.activeChat,
                    messages: state.activeChat.messages.map(m => payload.idsArray.includes(m.id) ? {
                        ...m,
                        isRead: true
                    } : m),
                }
                if (payload.chatType === 'GENERAL') {
                    state.globalChatList = state.globalChatList?.map(ch => ch.type === 'GENERAL' ? {
                        ...ch, unreadMessagesCount: ch.unreadMessagesCount - payload.idsArray.length < 0
                            ? 0
                            : ch.unreadMessagesCount - payload.idsArray.length
                    } : ch)
                    state.chats = state.chats?.map(ch => ch.type === 'GENERAL' ? {
                        ...ch,
                        unreadMessagesCount: ch.unreadMessagesCount - payload.idsArray.length < 0
                            ? 0
                            : ch.unreadMessagesCount - payload.idsArray.length,
                    } : ch)
                } else {
                    state.globalChatList = state.globalChatList?.map(ch => ch.id === payload.chatId ? {
                        ...ch,
                        unreadMessagesCount: ch.unreadMessagesCount - payload.idsArray.length < 0
                            ? 0
                            : ch.unreadMessagesCount - payload.idsArray.length,
                    } : ch)
                    state.chats = state.chats?.map(ch => ch.id === payload.chatId ? {
                        ...ch,
                        messages: ch.messages.map(m => payload.idsArray.includes(m.id) ? { ...m, isRead: true } : m),
                    } : ch)
                }
            })
    }
})

export const { actions: chatsActions } = chatsSlice
export const { reducer: chatsReducer } = chatsSlice
