import React from 'react'

type CreateProjectSnackbarActionPropsType = {

}

const CreateProjectSnackbarAction:React.FC<CreateProjectSnackbarActionPropsType> = (props) => {

    return (
        <>CreateProjectSnackbarAction</>
    )
}

export default  CreateProjectSnackbarAction