import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useParams } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { Box, CircularProgress, createStyles, Typography } from '@material-ui/core'

import { CommonLetterType } from '../../../../../../../../Api/lettersApi'
import { createMarkUp } from '../../../../../../../../Utils/cutTags'
import { useAppDispatch } from '../../../../../../../../providers/StoreProvider/hooks/useAppDispatch'
import { clientSelectors, clientThunks } from '../../../../../../../../providers/StoreProvider/slices/clientsSlice'
import { letterSelectors, letterThunks, MessagesFeedType } from '../../../../../../../../providers/StoreProvider/slices/lettersSlice'
import { LetterType } from '../../../../../../../../providers/StoreProvider/slices/lettersSlice/types/letters'


type LettersHistoryPropsType = {
  clientLetterId: number
  clientLetterHeader: string | null
};

export const LettersHistory: FC<LettersHistoryPropsType> = ({ clientLetterId, clientLetterHeader }) => {
  const dispatch = useAppDispatch()
  const { clientId } = useParams<{ clientId: string }>()
  const clientLetters = useSelector(letterSelectors.getLettersByHeader)

  useEffect(() => {
    !clientLetters && clientLetterHeader && clientId && dispatch(letterThunks.fetchAllClientMessageFeed({
      clientId: parseInt(clientId),
      header: clientLetterHeader
    }))
  }, [clientLetters, clientLetterHeader, clientId, dispatch])

  const [showHistory, setShowHistory] = useState<boolean>(false)

  const classes = useStyles()
  
  const handleChangeShowHistory = () => setShowHistory(!showHistory)

  return (
    <>
      {clientLetters && clientLetters.length > 1 &&
      <Typography className={classes.typography}
                  onClick={handleChangeShowHistory}
      >
        {showHistory ? 'Скрыть историю' : 'Показать историю'}
      </Typography>
      }
      {showHistory && clientLetters && clientLetters
        .map(el => {
          if(el.type === LetterType.LETTER) {
            return  <Box className={classes.letter_history_box} key={el.id}>
                      <div dangerouslySetInnerHTML={createMarkUp(el.message)} />
                    </Box>
          }
      })}
    </>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    letter_history_box: {
      margin: '0px 0px 20px 0px',
      padding: '10px 15px',
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,.1)',
      borderRadius: 3,
      wordBreak: 'break-word',
      '& p': {
        marginBottom: 0
      }
    },
    typography: {
      textAlign: 'end',
      margin: '20px 0',
      color: '#334bfa',
      textDecoration: 'none',
      cursor: 'pointer',
      fontSize: '14px'
    },
    preloader: {
      textAlign: 'end',
      marginLeft: '30%'
    }
  })
)