import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'

import { IFullClientData } from '../../../../../types'
import { clientsActions } from '../slice/clientsSlice'
import { appActions } from '../../appSlice'
import { lettersActions } from '../../lettersSlice'
import { fetchMacrosPreviewList } from '../../macrosSlice/services'


interface RequestProps {
  clientId: any, // todo types
  isNeedMacrosList?: boolean
}

export const fetchClientData = createAsyncThunk<IFullClientData,
  RequestProps,
  ThunkConfig<any>>(
  'client/fetch_client_data',
  async (data, { dispatch, extra, rejectWithValue }) => {

    const { clientId, isNeedMacrosList = false } = data

    dispatch(clientsActions.isClientChanged(true))
    dispatch(clientsActions.setClientId(clientId))
    dispatch(appActions.setIsFetchClientData(true))
    try {
      const response = await extra.api().get<IFullClientData>(`client/${clientId}/show`)
      dispatch(lettersActions.setClientLetters(response.data))
      dispatch(appActions.setIsFetchClientData(false))
      isNeedMacrosList && dispatch(fetchMacrosPreviewList(clientId))
      return response.data
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить информацию о клиенте')
      return rejectWithValue({})
    } finally {
      dispatch(appActions.setIsFetchClientData(false))
      dispatch(clientsActions.isClientChanged(false))
    }
  }
)