import { FC } from 'react'
import PageContainer from '../PageContainer/PageContainer'
import { ErrorFragment } from '../../ui-kit'

type NoAccessPagePropsType = {

}

export const NoAccessPage: FC<NoAccessPagePropsType> = () => {
  return (
    <>
      <PageContainer title='Нет доступа'>
        <ErrorFragment />
      </PageContainer>
    </>
  )
}