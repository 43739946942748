import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'

export type LetterSettings = {
  header: string
  footer: string
}

type RequestProps = {
  projectId: number,
  clientId: number
}

export const fetchProjectSettingsReplacedByClient = createAsyncThunk<LetterSettings,
  RequestProps,
  ThunkConfig<any>>(
  'client/fetch_project_settings_replaced_by_client',
  async (req, { dispatch, rejectWithValue, extra }) => {

    const { clientId, projectId } = req

    try {
      const res = await extra.api().get<LetterSettings>(`project/${projectId}/letters-settings-replaced-by-client/${clientId}`)
      return res.data
    } catch (e: any) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить настройки письма')
      return rejectWithValue({})
    }
  }
)