import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'


export const fetchLetterFragmentByProjectId = createAsyncThunk<
  any, // Todo types
  number,
  ThunkConfig<any>>(
  'fragments/fetch_letter_fragment_by_project_id',
  async (projectId, thunkApi) => {
    const { dispatch, rejectWithValue, extra } = thunkApi
    try {
      const response = await extra.api().get(`letter-fragments-for-select?filter%5Bproject%5D=${projectId}`)
      localStorage.setItem('letterFragmentList', JSON.stringify(response.data))
      return response.data
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить фрагмент')
      return rejectWithValue({})

    } finally {
    }
  }
)