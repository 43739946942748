import { createTheme } from '@material-ui/core';
import { ruRU } from '@material-ui/core/locale';
import typography from './typography';

const MuiTheme = createTheme(
  {
    palette: {
      action: {
        disabledBackground: 'rgba(63,53,53,0.62)',
        disabled: 'rgba(63,53,53,0.62)',
      },
      primary: {
         main: '#5383ff'
       // main: variables.primary
      },
      grey: {
        300: '#fefefe',
        A100:'#f8f9ff'
      },
      secondary: {
        //main: variables.secondary,
        main: '#3d4977',
      },
      error: {
       // main: vars.red
        main: '#f83245'
      },
      success: {
       // main: vars.green
      main: '#1bc943'
      },
      warning: {
        //main: vars.orange
        main: '#f4772e'
      },
      helpers: {
       // primary: vars.blue,
        main: 'rgba(25, 46, 91, .035)'
      },
      contrastThreshold: 3,
      tonalOffset: 0.1
    },
    shape: {
      borderRadius: '0.5rem'
    },
    overrides: {
      MuiInputBase:{
        root: {
          '&$disabled': {
            color:'black',
            opacity:1

          }
        }
      },
      MuiBreadcrumbs: {
        root: {
          margin: '0px 0px 8px 0px !important'
        },
        separator: {
          margin: '0px 2px 0px 2px !important'
        }
      },
      MuiButton: {
        text: {
          paddingLeft: '14px',
          paddingRight: '14px'
        },
        containedSizeSmall: {
          paddingLeft: '14px',
          paddingRight: '14px'
        },
        root: {
          textTransform: 'none',
          fontWeight: 'normal',
          padding: '4px 8px 4px 8px',
          fontSize: '8pt',
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: '#070919',
          padding: '8px 16px',
          fontSize: '0.75rem'
        },
        arrow: {
          color: '#070919'
        }
      },
      MuiTableCell: {
        root: {
          fontSize: '0.7rem',
          padding: '2px 6px 2px 6px'
        }
      },
      MuiTablePagination: {
        root: {
          fontSize: '0.7rem'
        }
      },
      MuiTypography: {
        root: {
          fontSize: '0.7rem'
        }
      },
      MuiExpansionPanelSummary: {
        root: {
          padding: '0px 12px 0px 12px'
        },
        content: {
          margin: '0px'
        }
      },
      MuiSvgIcon: {
        root: {}
      },

    },
    typography
  },
  ruRU
);

export default MuiTheme;
