import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { clientsActions, clientSelectors, clientThunks } from '../../../providers/StoreProvider/slices/clientsSlice'
import { useParams, useSearchParams } from 'react-router-dom'
import { IClientForProject } from '../../../types'
import { LeadsContext } from './lib/LeadsContext'
import { useAppDispatch } from '../../../providers/StoreProvider/hooks/useAppDispatch'
import { lettersActions } from '../../../providers/StoreProvider/slices/lettersSlice'
import { projectSelectors } from '../../../providers/StoreProvider/slices/projectsSlice'
import { SelectFilterType } from '../MainContentBlock/Leads/LeadHeader/HeaderBottom'
import { fragmentThunks } from '../../../providers/StoreProvider/slices/fragmentsSlice'
import { useLocalStorage } from "../../../Utils/localStorage/useLocalStorage";

export type ShowClientFilter = 'active' | 'snoozed' | 'archive' | 'empty'

export const LeadsProvider: FC<PropsWithChildren> = ({ children }) => {

    const activeLeads = useSelector(clientSelectors.getActiveClients)
    const snoozedLeads = useSelector(clientSelectors.getSnoozedClients)
    const availableProjects = useSelector(projectSelectors.getProjectsList)

    const searchError = useSelector(clientSelectors.getSearchError)
    const clientId = useSelector(clientSelectors.getClientId)

    const { id } = useParams<{ id: string }>()

    const [storedValue, setValue] = useLocalStorage('showLeadLetterEditorSettings', false)
    const dispatch = useAppDispatch()

    /** query параметры для поиска лида или обновления лида при переходе от одного к другому */
    const [searchParams, setSearchParams] = useSearchParams()

    /** Стейт, хранящий массив лидов, в которых находится выбранный клиент  */
    const [clients, setClients] = useState<IClientForProject[]>()

    /** Выбранный клиент */
    const [currentLead, setCurrentLead] = useState<IClientForProject>()

    /**  Значение фильтра по активным или снуз клиентам*/
    const [showClientFilter, setShowClientFilter] = useState<ShowClientFilter>('empty')

    /**  переменная, отвечающая за то, есть ли лиды в проекте*/
    const [isEmptyClientsList, setIsEmptyClientsList] = useState<boolean>(false)

    /**  переменная, которая нужна для предотвращения дубль-запроса, когда сбрасываем клиента */
    const [isResetState, setIsResetState] = useState<boolean>(false)

    /**  переменная, которая нужна для кейса, когда клиент не найден */
    const [isNotFoundClient, setIsNotFoundClient] = useState<boolean>(false)

    /**  переменная, которая нужна для кейса, когда у юзера нет прав в проекте */
    const [notAccess, setNotAccess] = useState<boolean>(false)

    /**  переменная, которая нужна для отобажения настроек в сан эдиторе (тестовая версия) */
    const [showLetterSettings, setShowLetterSettings] = useState<boolean>(storedValue)

    /** переменная, хранящая айди клиента*/
    const currentClientId = searchParams.get('client')

    const allParams = Object.fromEntries(searchParams)
    const { client, ...rest } = allParams

    const filterKey = Object.keys(rest)[0]
    const filterValue = Object.values(rest)[0]

    /** функция, обновляющая выбранного клиента и значение client в урле*/
    const updateSearch = useCallback(
        (newClientId?: number,
         newClient?: IClientForProject,
         filter?: string,
         filterValue?: SelectFilterType | string
        ) => {
            const filterQuery = filter ? {
                [filter]: filterValue + ''
            } : {}

            const clientQuery: { client?: string } = newClientId ? { client: newClientId + '' } : {}
            const allQuery = { ...clientQuery, ...filterQuery }
            setSearchParams(allQuery)
            setCurrentLead(newClient)
        }, [setSearchParams])


    /** функция, при помощи которой выбираем, каких лидов отображать (активные или снуз) */
    const onChangeFilter = useCallback((filter: ShowClientFilter) => {
        setCurrentLead(undefined)
        setShowClientFilter(filter)
        filter === 'active' ? setClients(activeLeads) : setClients(snoozedLeads)
        const firstNoTrustedClient = activeLeads?.filter(l => l.status !== 'TRUSTED')[0]
        updateSearch(
            filter === 'active'
                ? firstNoTrustedClient?.id
                : snoozedLeads?.[0].id,
            // undefined,
            // undefined
        )
    }, [activeLeads, snoozedLeads, updateSearch])


    /** Функция для действий с отправкой в снуз или архивирование лида*/
    const goToTheNextClient = useCallback((clientId: number) => {
        setIsResetState(true)
        dispatch(lettersActions.clearClientLetters())
        dispatch(clientsActions.clearClientData())
        const clientIndex = clients?.findIndex(cl => cl.id === clientId)
        typeof clientIndex === 'number' && clients && clientIndex !== clients?.length - 1
            ? updateSearch?.(clients?.[clientIndex + 1].id, undefined, filterKey, filterValue)
            : updateSearch?.()
    }, [clients, dispatch, filterKey, filterValue, updateSearch])


    /** Функция, сбрасывающая все параметры ( при переходе из проекта в проект, при изменении фильтра по ролям и т.д)*/
    const resetState = useCallback(() => {
        setIsResetState(true)
        dispatch(clientsActions.clearLeadManagePage())
        dispatch(lettersActions.clearClientLetters())
        setShowClientFilter('empty')
        setClients(undefined)
        setCurrentLead(undefined)
    }, [dispatch])

    /** функция, переключающая настройки в сан эдиторе (тестовое)*/
    const onHandleSwitchLetterSettings = useCallback(() => {
        setShowLetterSettings(prev => !prev)
        setValue(!showLetterSettings)
    }, [setValue, showLetterSettings])

    /** функция, проверяюшая, найден ли клиент*/
    const checkIsClientFind = (
        searchError: boolean,
        currentClientId: string | null,
        clients?: IClientForProject[],
    ): boolean => {
        if (clients && clients.length > 0 && currentClientId &&
            !clients.find(cl => cl.id === +currentClientId)) {
            return true
        }
        return !clients && searchError;
    }

    /** Проверка на права для конкретного клиента */
    useEffect(() => {
        if (id && availableProjects && !availableProjects.some(pr => pr.id === +id)) {
            setNotAccess(true)
        } else setNotAccess(false)
    }, [id, availableProjects])

    useEffect(() => {
        if (!currentClientId) {
            /** Если нет лидов в проекте то показываем пустую страницу*/
            if (activeLeads?.length === 0 && snoozedLeads?.length === 0) {
                setClients([])
                setIsEmptyClientsList(true)
            }
            /** Если нет активных лидов в проекте а есть в снузе то берем первого лида из снуза*/
            if (activeLeads?.length === 0 && snoozedLeads && snoozedLeads?.length > 0) {
                const firstNoTrustedClient = snoozedLeads.filter(l => l.status !== 'TRUSTED')[0]
                setClients(snoozedLeads)
                /** выбираем первого лида без статуса 'TRUSTED' */
                snoozedLeads && updateSearch(firstNoTrustedClient.id, undefined, filterKey, filterValue)
            }
            if (activeLeads && activeLeads.length > 0) {
                const firstNoTrustedClient = activeLeads.filter(l => l.status !== 'TRUSTED')[0]
                setClients(activeLeads)
                activeLeads && updateSearch(firstNoTrustedClient.id, undefined, filterKey, filterValue)
            }
        } else {
            /** Если в урле есть айдишка, ищем клиента в активных и скрытых
             *  и если нашли, то делаем запрос за данными для найденного клиента
             **/
            const clientInActiveList = activeLeads?.find(l => l.id === +currentClientId)
            const clientInSnoozeList = snoozedLeads?.find(l => l.id === +currentClientId)
            if (clientInActiveList) {
                // console.log('IDS', clientId, currentClientId)
                setClients(activeLeads)
                setCurrentLead(clientInActiveList)
                setShowClientFilter('active')
                //activeLeads && !isResetState && !isUpdateClient &&
                activeLeads && clientId !== +currentClientId && !isResetState &&
                dispatch(clientThunks.fetchClientData({ clientId: clientInActiveList.id }))
            } else if (clientInSnoozeList) {
                setClients(snoozedLeads)
                setCurrentLead(clientInSnoozeList)
                setShowClientFilter('snoozed')
                // snoozedLeads && !isResetState && !isUpdateClient &&
                snoozedLeads && clientId !== +currentClientId && !isResetState &&
                dispatch(clientThunks.fetchClientData({ clientId: clientInSnoozeList.id }))
            } else {
                /** иначе сбрасываем выбранного лида, сетаем список активных лидов
                 *  и показываем страницу, что лид не найден
                 **/
                updateSearch(+currentClientId, undefined, filterKey, filterValue)
                setClients(activeLeads)
                setShowClientFilter('active')
            }
            setIsResetState(false)
            // dispatch(clientsActions.setIsUpdateClient(false))

            return () => {
                setClients(undefined)
                setShowClientFilter('empty')
                setIsNotFoundClient(false)
                setIsEmptyClientsList(false)
                // dispatch(clientsActions.setIsUpdateClient(false))
            }
        }
    }, [dispatch, activeLeads, snoozedLeads, currentClientId, updateSearch, isResetState, filterKey, filterValue, clientId])

    /** для проверки, есть ли такой клиент в этом проекте (влияет на отображение в странице)*/
    useEffect(() => {
        checkIsClientFind(searchError, currentClientId, clients)
            ? setIsNotFoundClient(true)
            : setIsNotFoundClient(false)
    }, [clients, currentClientId, currentLead, searchError])

    /** При инициализации загружаем фрагменты  */
    useEffect(() => {
        id && dispatch(fragmentThunks.fetchLetterFragmentByProjectId(parseInt(id)))
    }, [dispatch, id])

    const value = useMemo(
        () => ({
            clients,
            currentClient: currentLead,
            updateSearch,
            showClientFilter,
            searchParams,
            onChangeFilter,
            goToTheNextClient,
            resetState,
            isEmptyClientsList,
            setIsResetState,
            isNotFoundClient,
            notAccess,
            filter: {
                filterName: filterKey,
                filterValue: filterValue
            },
            searchError,
            showLetterSettings,
            onHandleSwitchLetterSettings
        }),
        [
            clients,
            currentLead,
            onChangeFilter,
            searchParams,
            showClientFilter,
            updateSearch,
            goToTheNextClient,
            resetState,
            isEmptyClientsList,
            setIsResetState,
            isNotFoundClient,
            notAccess,
            filterValue,
            filterKey,
            searchError,
            showLetterSettings,
            onHandleSwitchLetterSettings
        ]
    )

    return (
        <LeadsContext.Provider value={value}>{children}</LeadsContext.Provider>
    )
}