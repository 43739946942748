import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EmailsSchema, EmailTargetType, EmailType } from '../types/emails'
import { emptyModalValue } from '../constants/constants'
import { enableNotWorkingEmail, fetchEmails, fetchTemplatesForEmail } from '../services'
import { createEmail, uploadEmails } from '../../mailboxForDealSlice/services'


const initialState: EmailsSchema = {
    // templates: [],
    isTemplatesGetted: false,

    // emailsForNewClients: [],
    isEmailsForNewClients: false,
    // totalCountEmailsForNewClients: null,
    emailsForNewClientsPaginationParams: {
        per_page: 15,
        page: 1
    },

    // emailsForDealing: [],
    isEmailsForDealingGetted: false,
    // totalCountEmailsForDealing: null,
    emailsForDealingPaginationParams: {
        per_page: 15,
        page: 1
    },

    isEmailModalOpen: false,
    modalValue: emptyModalValue,
    target: null,
    id: null,
    projectId: null,
    error: null,
    isEmailProblematic: false,
    isEmailDeleteModalOpen: false,
    isUploadEmailsFetching: false
}


export const emailsSlice = createSlice({
    name: 'emails',
    initialState,
    reducers: {
        setEmailModalOpen: (state, action: PayloadAction<{
            isOpen: boolean,
            id: number | null,
            target: EmailTargetType | null
        }>) => {
            state.isEmailModalOpen = action.payload.isOpen
            state.target = action.payload.target
            state.id = action.payload.id
        },
        clearEmailsState: (state) => {
            state.emailsForNewClients = []
            state.emailsForDealing = []
        },
        setEmailDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isEmailDeleteModalOpen = action.payload
        },
        clearProblematicField: (state) => {
            state.isEmailProblematic = false
        },
        setIsWSEmailProblematic: (state, action: PayloadAction<any>) => {
            state.emailsForDealing && state.emailsForDealing.map(e => e.id === action.payload.id ? {
                ...e,
                isProblematic: true
            } : e)
        },
        setEmailTarget: (state, action: PayloadAction<{ emailId: number, target: EmailTargetType }>) => {
            state.id = action.payload.emailId
            state.target = action.payload.target
        },
        setProjectId: (state, action: PayloadAction<number>) => {
            state.projectId = action.payload
        },
        setError: () => {
        },
        wsMailboxForNewClientsUpdated: (state, action: PayloadAction<EmailType[]>) => {
            const newEmailsForNewClients = action.payload.filter(email => email.projectId === state.projectId)
            const notWorkEmails = state.emailsForNewClients?.filter((email) => {
                return !newEmailsForNewClients.find(newEmail => email.id === newEmail.id)
            })
            state.emailsForNewClients = notWorkEmails
                ? [...newEmailsForNewClients, ...notWorkEmails].sort((a, b) => a.id - b.id)
                : [...newEmailsForNewClients]
        },
        wsMailboxForDealUpdated: (state, action: PayloadAction<EmailType[]>) => {
            const newEmailsForDeal = action.payload
                .filter(email => email.projectId === state.projectId)
                .filter(email => state.emailsForDealing?.find(e => e.id == email.id))
            const noCheckedEmails = state.emailsForDealing?.filter((email) => {
                return !newEmailsForDeal.find(newEmail => email.id === newEmail.id)
            })
            state.emailsForDealing = noCheckedEmails
                ? [...newEmailsForDeal, ...noCheckedEmails].sort((a, b) => a.id - b.id)
                : [...newEmailsForDeal]

            // state.emailsForDealing = [...newEmailsForDeal]
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchEmails.fulfilled, (state, { payload }) => {
            if (payload) {
                if (payload.target === 'emailsForDealing') {
                    state.emailsForDealing = payload.data.items
                    state.isEmailsForDealingGetted = true
                    state.totalCountEmailsForDealing = payload.data.totalCount
                } else {
                    state.emailsForNewClients = payload.data.items
                    state.isEmailsForDealingGetted = true
                    state.totalCountEmailsForNewClients = payload.data.totalCount
                }
            }
        })
        builder.addCase(fetchTemplatesForEmail.fulfilled, (state, { payload }) => {
            state.templates = payload.data
        })
        builder.addCase(createEmail.fulfilled, (state, { payload }) => {
            if (payload.target === 'emailsForDealing') {
                state.emailsForDealing = state.emailsForDealing && [...state.emailsForDealing, { ...payload.currentEmail }]
            } else {
                state.emailsForNewClients = state.emailsForNewClients && [...state.emailsForNewClients, { ...payload.currentEmail }]
            }
        })
        builder.addCase(enableNotWorkingEmail.fulfilled, (state, { payload }) => {
            if (payload.target === 'emailsForDealing') {
                state.emailsForDealing = state.emailsForDealing?.map((el) => el.id === payload.id ? {
                    ...el,
                    enabled: payload.enabled
                } : el)
            } else {
                state.emailsForNewClients = state.emailsForNewClients?.map((el) => el.id === payload.id ? {
                    ...el,
                    enabled: payload.enabled
                } : el)
            }
        })
        builder.addCase(uploadEmails.pending, (state) => {
            state.isUploadEmailsFetching = true
        })
        builder.addCase(uploadEmails.fulfilled, (state, { payload }) => {
            if (payload.isDealingMail) {
                state.emailsForDealing = state.emailsForDealing?.map(el => el.id === payload.data.id
                    ? { ...payload.data } : el)
            } else {
                state.emailsForNewClients = state.emailsForNewClients?.map(el => el.id === payload.data.id
                    ? { ...payload.data } : el)
            }
            state.isUploadEmailsFetching = false
        })

    }
})

export const { actions: emailsActions } = emailsSlice
export const { reducer: emailsReducer } = emailsSlice
