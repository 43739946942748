import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { EmailTargetType } from '../types/emails'
import { snackActions } from '../../snackSlice'
import { fetchEmails } from './fetchEmails'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'


type RequestProps = {
    target: EmailTargetType,
    emailId: number
    data?: {
        emailAfterDelete: string
    }
    params: PaginationParamsType
}

// TODO Проверить логику санки обязательно !!!!
export const switchActiveEmail = createAsyncThunk<{},
    RequestProps,
    ThunkConfig<any>>(
        'emails/edit_email',
        async (req, { dispatch, getState, rejectWithValue, extra }) => {
            const emailsSlice = getState().emails
            const { emailId, target, params } = req

            const address = target === 'emailsForDealing'
                ? 'mailbox-for-deal/' + emailId + '/switch-enabled'
                : 'mailbox-for-new-clients/' + emailId + '/switch-enabled'

            // dispatch(emailsActions.setError(null))
            try {
                const response = await extra.api().patch(address)
                emailsSlice.projectId && dispatch(fetchEmails({
                    target: target,
                    projectId: emailsSlice.projectId,
                    params: params
                }))

                dispatch(snackActions.setSnack({
                    label: 'Email успешно изменен',
                    typeLabel: 'success'
                }))

            } catch (e) {
                handleServerNetworkError(e, dispatch, 'Не удалось изменить данные')
                return rejectWithValue({})
            }

        }
    )
