import axios from 'axios'
import { url } from '../../../Api/API'

export const createAxiosInstance = () => {
  const apikey = localStorage.getItem('apikey')
  return axios.create({
    withCredentials: false,
    baseURL: url,
    headers: {
      Authorization: 'Bearer ' + apikey,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  })
}