import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { prepareFilterParams } from '../../../../../Api/API'
// import { CallType } from '../../../../../Api/lettersApi'
import { CallType } from '../types/calls'
import { callsActions } from '../slice/callSlice'

type ResponseType = {
    
}

type ApiResponseType = {
    items: CallType[],
    totalCount: number
}

type RequestPropsType = {
    projectPhone: number
    params?: PaginationParamsType
}

export const fetchCallsByProjectPhone = createAsyncThunk<
    ResponseType,
    RequestPropsType,
    ThunkConfig<any>
>(
    'call/fetch_calls_by_project_phone',
    async (data, thunkApi) => {
        const query = data.params ? prepareFilterParams(data.params) : ''
        try {
            const res = await thunkApi.extra.api().get<ApiResponseType>(`project-phone/${data.projectPhone}/calls?`+query)
            thunkApi.dispatch(callsActions.callsByProjectPhone(res.data))
            return res.data
        } catch (e) {
            handleServerNetworkError(e, thunkApi.dispatch, 'Не удалось получить список звонков')
        } finally {
            
        }
    }
    
)