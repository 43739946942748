import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { defaultPaginationParams } from '../slice/templatesSlice'
import { snackActions } from '../../snackSlice'
import { fetchTemplates } from './fetchTemplates'
import { ResponseStatusCodes } from '../../../../../enums/enums'


export const copyTemplate = createAsyncThunk<{},
  any,// todo types
  ThunkConfig<any>>(
  'templates/copy_template',
  async (dto, { dispatch, rejectWithValue, getState, extra }) => {

    const params = getState().templates.templatePagination

    const { id, ...body } = dto

    try {
      const res = await extra.api().post(`letter-template/${id}/copy`, body)
      if (res.status === ResponseStatusCodes.success) {
        dispatch(fetchTemplates(!params.page ? defaultPaginationParams : params))
      }

      dispatch(snackActions.setSnack({
        label: 'Шаблон успешно скопирован',
        typeLabel: 'success'
      }))

    } catch (e) {
      // todo check error types
      // if (e.response.status === ResponseStatusCodes.noRights) {
      //   dispatch(snackActions.setSnack({
      //     label: e.response.data.error,
      //     typeLabel: 'error'
      //   }))
      // }
      handleServerNetworkError(e, dispatch, 'Произошла ошибка при копировании шаблона')
      return rejectWithValue({})
    }
  }
)