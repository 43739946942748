import { RouteProps } from 'react-router-dom'
//import TasksPage from '../z_old_site/crm-containers/pages/TasksPage'
//import PageProjectSettings from '../z_old_site/containers/pages/PageProjectSettings'
//import PageTaskView from '../z_old_site/containers/pages/PageTaskView'
//import PageViewLead from '../z_old_site/containers/pages/PageViewLead'

import { TaskViewPageAsync } from '../../../z_old_site/containers/pages/PageTaskView/TaskViewPage.async'
import { ExpandedLetter } from '../../../Pages/LeadsManagePage/MainContentBlock/ManageLeadsLetters/ManageLeadsLettersContent/ExpandedLetter/ExpandedLetter'
import { ViewLeadPageAsync } from '../../../z_old_site/containers/pages/PageViewLead/ViewLeadPage.async'
import { NoAccessPage } from '../../../Pages/NoAccesPage/NoAccessPage'
import { Page404 } from '../../../Pages/Page404/Page404'
import {
    CancellationOfReshipRequests,
    ProcessingNewLeadsPage,
    OperatorStatisticPage,
    ForwardingRequestsPage,
    GlobalTemplatesPage,
    ArchiveProjectsPage,
    ArchiveClientsPage,
    ProjectSettingPage,
    DeclineReasonsPage,
    GlobalPhonesPage,
    ProductNotesPage,
    GlobalChatsPage,
    GlobalLeadsPage,
    LeadsManagePage,
    ProjectsPage
} from '../../../Pages'
import { MainPageAsync } from '../../../Components/PageMain/MainPage.async'
import { MyLeadsPageAsync } from '../../../Components/MyLeadsPage/MyLeadsPage.async'
import { Login } from '../../../Components/Login/Login'
import { LeadsProvider } from '../../../Pages/LeadsManagePage/LeadsContext/LeadsProvider'
import { ForceLogin } from '../../../Components/Login/ForceLogin'


export type AppRoutesProps = RouteProps & {
    authOnly?: boolean
}

export enum AppRoutes {
    MAIN = 'main',
    PROJECTS = 'projects',
    DELETED_PROJECTS = 'deleted_projects',
    // TASKS = 'tasks',
    //DELETED_TASKS = 'deleted_tasks',
    PROJECT_SETTINGS = 'project_settings',
    TASK_VIEW = 'task_view',
    RAW_LEADS = 'raw_leads',
    MY_LEADS = 'my_leads',
    LEADS_MANAGE = 'leads_manage',
    EXPANDED_LETTER = 'expanded_letter',
    VIEW_LEAD = 'view_lead',
    PRODUCT_NOTES = 'product_notes',
    ARCHIVE_CLIENTS = 'archive_clients',
    TEMPLATES = 'templates',
    TEMPLATES_TAB = 'templates_tab',
    NO_ACCESS = 'no_access',
    NOT_FOUND = 'not_found',
    FORCE_LOGIN = 'force_login',
    LOGIN = 'login',
    PHONES = 'phones',
    GLOBAL_LEADS = 'leads',
    GLOBAL_CHATS = 'chats',
    OPERATOR_STATISTICS = 'operator_statistics',
    DECLINE_REASONS = 'decline_reasons',
    FORWARDING_REQUESTS = 'forwarding_requests',
    CANCELLATION_OF_RESHIP_REQUESTS = 'cancellation_of_reship_requests',
    OTHER = 'other'
}


export const RoutePath: Record<AppRoutes, string> = {
    [AppRoutes.MAIN]: '/',
    [AppRoutes.PROJECTS]: '/projects',
    [AppRoutes.DELETED_PROJECTS]: '/deleted_projects',
    // [AppRoutes.TASKS]: '/project/:id_project',
    //[AppRoutes.DELETED_TASKS]: '/project/:id_project/deleted_tasks',
    [AppRoutes.PROJECT_SETTINGS]: '/project/:id_project/settings',
    [AppRoutes.TASK_VIEW]: '/project/:id_project/task/:id_task',
    [AppRoutes.RAW_LEADS]: '/project/:id_project/raw_leads',
    [AppRoutes.MY_LEADS]: '/project/:id_project/my_leads',
    [AppRoutes.LEADS_MANAGE]:
    //'/project/:id/leads_manage/'
        '/project/:id/leads_manage/',
    //|| '/project/:id/leads_manage/:clientId/unsent_emails',
    [AppRoutes.EXPANDED_LETTER]: '/project/:id/leads_manage/:clientId/row_email/:letterId',
    [AppRoutes.VIEW_LEAD]: '/project/:id_project/lead/:id_lead',
    [AppRoutes.PRODUCT_NOTES]: '/project/:id_project/product_notes',
    [AppRoutes.ARCHIVE_CLIENTS]: '/project/:id_project/archive_clients',
    [AppRoutes.TEMPLATES]: '/templates/',
    [AppRoutes.TEMPLATES_TAB]: '/templates/:tab_index',
    [AppRoutes.PHONES]: '/phones',
    [AppRoutes.NO_ACCESS]: '/403',
    [AppRoutes.FORCE_LOGIN]: '/force_login',
    [AppRoutes.NOT_FOUND]: '/404',
    [AppRoutes.GLOBAL_LEADS]: '/leads',
    [AppRoutes.GLOBAL_CHATS]: '/chats',
    [AppRoutes.OPERATOR_STATISTICS]: '/operator_statistics',
    [AppRoutes.DECLINE_REASONS]: '/decline_reasons_statistics',
    [AppRoutes.FORWARDING_REQUESTS]: '/forwarding_requests',
    [AppRoutes.CANCELLATION_OF_RESHIP_REQUESTS]: '/cancellation_of_reship_requests',
    [AppRoutes.LOGIN]: '/login',
    [AppRoutes.OTHER]: '*'
}


export const routeConfig: Record<AppRoutes, AppRoutesProps> = {
    [AppRoutes.FORCE_LOGIN]: {
        path: RoutePath.force_login,
        element: <ForceLogin/>,
    },
    [AppRoutes.LOGIN]: {
        path: RoutePath.login,
        element: <Login/>,
    },
    [AppRoutes.MAIN]: {
        path: RoutePath.main,
        element: <MainPageAsync/>,
        authOnly: true
    },
    [AppRoutes.PROJECTS]: {
        path: RoutePath.projects,
        element: <ProjectsPage/>,
        authOnly: true

    },
    [AppRoutes.DELETED_PROJECTS]: {
        path: RoutePath.deleted_projects,
        element: <ArchiveProjectsPage/>,
        authOnly: true
    },
    // [AppRoutes.TASKS]: {
    //   path: RoutePath.tasks,
    //   element: <TasksPageAsync /> // Todo старый компонент
    // },
    [AppRoutes.PROJECT_SETTINGS]: {
        path: RoutePath.project_settings,
        element: <ProjectSettingPage/>,
        authOnly: true
    },
    [AppRoutes.TASK_VIEW]: {
        path: RoutePath.task_view,
        element: <TaskViewPageAsync/>,
        authOnly: true
    },
    [AppRoutes.RAW_LEADS]: {
        path: RoutePath.raw_leads,
        element: <ProcessingNewLeadsPage/>,
        authOnly: true
    },
    [AppRoutes.MY_LEADS]: {
        path: RoutePath.my_leads,
        element: <MyLeadsPageAsync/>,
        authOnly: true
    },
    [AppRoutes.LEADS_MANAGE]: {
        path: RoutePath.leads_manage,
        element: <LeadsProvider><LeadsManagePage/></LeadsProvider>,
        authOnly: true
    },
    [AppRoutes.EXPANDED_LETTER]: {
        path: RoutePath.expanded_letter,
        element: <ExpandedLetter/>,
        authOnly: true
    },
    [AppRoutes.VIEW_LEAD]: {
        path: RoutePath.view_lead,
        element: <ViewLeadPageAsync/>, // Todo старый компонент
        authOnly: true
    },
    [AppRoutes.PRODUCT_NOTES]: {
        path: RoutePath.product_notes,
        element: <ProductNotesPage/>,
        authOnly: true
    },
    [AppRoutes.ARCHIVE_CLIENTS]: {
        path: RoutePath.archive_clients,
        element: <ArchiveClientsPage/>,
        authOnly: true
    },
    [AppRoutes.TEMPLATES]: {
        path: RoutePath.templates,
        element: <GlobalTemplatesPage/>,
        authOnly: true
    },
    [AppRoutes.TEMPLATES_TAB]: {
        path: RoutePath.templates_tab,
        element: <GlobalTemplatesPage/>,
        authOnly: true
    },
    [AppRoutes.PHONES]: {
        path: RoutePath.phones,
        element: <GlobalPhonesPage/>,
        authOnly: true
    },
    [AppRoutes.NO_ACCESS]: {
        path: RoutePath.no_access,
        element: <NoAccessPage/>,
        authOnly: false
    },
    [AppRoutes.NOT_FOUND]: {
        path: RoutePath.not_found,
        element: <Page404/>,
        authOnly: false
    },
    [AppRoutes.GLOBAL_LEADS]: {
        path: RoutePath.leads,
        element: <GlobalLeadsPage/>,
        authOnly: true
    },
    [AppRoutes.GLOBAL_CHATS]: {
        path: RoutePath.chats,
        element: <GlobalChatsPage/>,
        authOnly: true
    },
    [AppRoutes.OPERATOR_STATISTICS]: {
        path: RoutePath.operator_statistics,
        element: <OperatorStatisticPage/>,
        authOnly: true
    },
    [AppRoutes.DECLINE_REASONS]: {
        path: RoutePath.decline_reasons,
        element: <DeclineReasonsPage/>,
        authOnly: true
    },
    [AppRoutes.FORWARDING_REQUESTS]: {
        path: RoutePath.forwarding_requests,
        element: <ForwardingRequestsPage/>,
        authOnly: true
    },
    [AppRoutes.CANCELLATION_OF_RESHIP_REQUESTS]: {
        path: RoutePath.cancellation_of_reship_requests,
        element: <CancellationOfReshipRequests/>,
        authOnly: true
    },
    [AppRoutes.OTHER]: {
        path: RoutePath.other,
        element: <Page404/>,
        authOnly: true
    }
}