import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { RequestSpreadSheetDataDto, SpreadSheetType } from '../types/projectSpreedSheet'
import { fetchSpreadSheetData } from './fetchSpreadSheetData'


type RequestProps = {
  projectId: number,
  data: RequestSpreadSheetDataDto
}

export const createSpreadSheetForProject = createAsyncThunk<SpreadSheetType,
  RequestProps,
  ThunkConfig<any>>(
  'spreadSheets/create_spread_sheet_for_project',
  async (dto, { dispatch, rejectWithValue, extra }) => {

    const { projectId, data } = dto

    try {
      const res = await extra.api().post<SpreadSheetType,
        AxiosResponse<SpreadSheetType>>(`project/${projectId}/spreadsheets/create`, data)
      // dispatch(fetchSpreadSheetData({ projectId, source: res.data.id }))

      dispatch(snackActions.setSnack({
        label: 'Таблица успешно добавлена',
        typeLabel: 'success'
      }))

      return res.data

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось добавить таблицу')
      return rejectWithValue({})
    }
  }
)