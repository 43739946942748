import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { RequestSpreadSheetDataDto } from '../types/projectSpreedSheet'
import { SpreadSheetType } from '../../../../../Api/projectSpreedSheetApi'
import { AxiosResponse } from 'axios'


type RequestProps = {
  projectId: number,
  source: number,
  data: RequestSpreadSheetDataDto
}

type FullFilledResponse = {
  data: RequestSpreadSheetDataDto
  source: number
}


export const editSpreadSheetForProject = createAsyncThunk<FullFilledResponse,
  RequestProps,
  ThunkConfig<any>>(
  'spreadSheets/edit_spread_sheet_for_project',
  async (dto, { dispatch, rejectWithValue, extra }) => {

    const { projectId, source, data } = dto

    try {
      await extra.api().patch<SpreadSheetType,
        AxiosResponse<SpreadSheetType>>(`/project/${projectId}/spreadsheets/${source}/edit`, data)

      dispatch(snackActions.setSnack({
        label: 'Таблица успешно изменена',
        typeLabel: 'success'
      }))
      
      return {
        data,
        source
      }

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось изменить данные')
      return rejectWithValue({})
    }
  }
)