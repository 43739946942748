import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { clientThunks } from '../../clientsSlice'
import { UpdateMultipleTrackParamsType, UploadTrackType } from '../types/tracks'
import { snackActions } from '../../snackSlice'
import { tracksActions } from '../slice/tracksSlice'


type RequestProps = {
    params: UploadTrackType[],
    clientId?: number
}

export const updateMultipleTrack = createAsyncThunk<UploadTrackType[] | undefined,
    RequestProps,
    ThunkConfig<any>>(
    'tracks/update_multiple_track',
    async (dto, { dispatch, rejectWithValue, extra }) => {

        const { clientId, params } = dto

        const body: UpdateMultipleTrackParamsType = {
            tracks: params.map(i => {
                return {
                    number: i.tracknumber,
                    barcode: i.barcodes ? i.barcodes[0] : '',
                    name: i.name as string
                }
            })
        }

        dispatch(tracksActions.setIsLoad(true))
        dispatch(tracksActions.setUploadedTracks(params))
        try {
            await extra.api().post(`tracks/client/${clientId}`, body)
            clientId && dispatch(clientThunks.fetchClientData({ clientId }))

            dispatch(snackActions.setSnack({
                label: 'Трекномера были успешно добавлены',
                typeLabel: 'success'
            }))
            // возвращаем использующиеся треки:
            // return res.data[0].tracks.filter((uploadedTrack: TrackType) => {
            //   return !!getState().tracks.uploadedTracks?.find(i => i.name === uploadedTrack.number)
            // })
            return params

        } catch (e) {
            handleServerNetworkError(e, dispatch, 'Не удалось добавить трекномера')
            return rejectWithValue({})
        } finally {
            dispatch(tracksActions.setIsLoad(false))
        }
    }
)