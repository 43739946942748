import {createAsyncThunk} from '@reduxjs/toolkit'
import {ThunkConfig} from '../../../config/StateSchema'
import {handleServerNetworkError} from "../../../../../helpers/error-helpers";
import {AxiosError} from "axios";


type RequestType = {
    id: number,
    client?: number
}

// type ResponseType = {
//     path:  URL | undefined
// }

export const fetchPdfExistingFile = createAsyncThunk<
    any,
    RequestType,
    ThunkConfig<AxiosError | string>>(
    'letters/fetch_pdf_existing_file',
    async (data, {dispatch, rejectWithValue, extra}) => {
        const {id, client} = data

        try {
            const res = await extra.api().get(`file/${id}/preview?client=${client}`)
            return res.data
        } catch (e) {
            if (e instanceof AxiosError) {
                handleServerNetworkError(e, dispatch, 'Что-то пошло не так')
                return rejectWithValue(e)
            } else return  rejectWithValue('')
        } finally {}
    }
)