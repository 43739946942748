import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { GeneralChat } from '../../../../../types'
import { notificationsActions } from '../../notitficationsSlice'
import { chatsActions } from '../slice/chatsSlice'


export const fetchGeneralChat = createAsyncThunk<{},
  boolean,
  ThunkConfig<any>>(
  'chats/fetch_general_chat',
  async (isWsEvent = false, thunkApi) => {
    const { dispatch, extra } = thunkApi
    dispatch(notificationsActions.disableNotificationActionCallback(true))

    try {
      const response = await extra.api().get<GeneralChat>(`general-chat/info`)
      if (isWsEvent) {
        dispatch(chatsActions.wsChatAddedAfterSend(response.data))
      } else {
        dispatch(chatsActions.openGroupChat(response.data))
      }
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Произошла ошибка')
    } finally {
      dispatch(notificationsActions.disableNotificationActionCallback(false))
    }
  }
)