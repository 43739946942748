import { createSlice } from '@reduxjs/toolkit'
import { ManagerLetterSchema } from '../types/managerLetter'


const initialState: ManagerLetterSchema = {}


export const managerLetterSlice = createSlice({
  name: 'manager_letter',
  initialState,
  reducers: {},
  extraReducers: (builder) => {}
})

export const { actions: managerLetterActions } = managerLetterSlice
export const { reducer: managerLetterReducer } = managerLetterSlice
