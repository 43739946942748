import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'


export const fetchActiveNumbers = createAsyncThunk<string[],
  number | undefined,
  ThunkConfig<any>>(
  'call/fetch_active_numbers',
  async (externalServiceId, { extra, rejectWithValue }) => {

    try {
      const res = await extra.api().get(`get_active_numbers/${externalServiceId}`)

      return res.data.numbers
    } catch (e) {
      return rejectWithValue({})
    }


  }
)