import { useEffect, useState } from 'react'

export const useDebounce = <V>(value: V, timeOut = 1000) => {

  const [debouncedValue, setDebouncedValue] = useState<V>(value)

  useEffect(() => {
    let timerId = setTimeout(() => {
      setDebouncedValue(value)
    }, timeOut)
    return () => {
      clearTimeout(timerId)
    }
  }, [value])

  return debouncedValue
}