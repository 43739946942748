import React from 'react'
import {createRoot} from 'react-dom/client'
import './index.css'
import {StoreProvider} from './providers/StoreProvider'
import {NetworkStatusProvider} from './providers/NetworkStatusProvider'
import DateFnsUtils from '@date-io/date-fns'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import {Main} from './Containers/Main'
import {BrowserRouter} from 'react-router-dom'
import {CssBaseline} from '@material-ui/core'
import './z_old_site/assets/base.scss'
import 'suneditor/dist/css/suneditor.min.css'
import 'antd/dist/antd.css'


const root = createRoot(document.getElementById('root') as HTMLElement)


root.render(
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <NetworkStatusProvider>
            <BrowserRouter>
                {/*<ErrorBoundary>*/}
                    <StoreProvider>
                        <CssBaseline/>
                        <Main/>
                    </StoreProvider>
                {/*</ErrorBoundary>*/}
            </BrowserRouter>
        </NetworkStatusProvider>
    </MuiPickersUtilsProvider>
)

