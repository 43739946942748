import { useSelector } from 'react-redux'
import { authSelectors } from '../../../providers/StoreProvider/slices/authSlice'
import { RoutePath } from '../../../providers/router/routeConfig/routeConfig'
import { MouseEvent, useEffect, useMemo, useState } from 'react'
import { Button, Menu, MenuItem, withStyles } from '@material-ui/core'
import { Link, useLocation } from 'react-router-dom'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { MenuProps } from '@material-ui/core/Menu'

type LinkMenuItem = {
    name: string
    path: string
    access?: boolean
}

export const StatisticLinks = () => {

    const userPermissions = useSelector(authSelectors.getAuthUserProjectRights)
    const menuItems: LinkMenuItem[] = useMemo(() => {
        return  [
            {
                path: RoutePath.operator_statistics,
                access: userPermissions?.canViewOperatorStatistics,
                name: 'Cтатистика операторов'
            },
            {
                path: RoutePath.decline_reasons,
                access: userPermissions?.canViewSalesFunnelStatistics,
                name: 'Cтатистика отказов'
            },
            {
                path: RoutePath.forwarding_requests,
                access: userPermissions?.canViewSalesFunnelStatistics,// todo права
                name: 'Cтатистика по запросам на переотправку'
            },
            {
                path: RoutePath.cancellation_of_reship_requests,
                access: userPermissions?.canViewSalesFunnelStatistics, // todo права
                name: 'Cтатистика по отмене запросов на переотправку'
            },
        ]
    }, [userPermissions?.canViewOperatorStatistics, userPermissions?.canViewSalesFunnelStatistics])



    const location = useLocation()

    const [anchor, setAnchor] = useState<HTMLElement | null>(null)
    const [selectedIndex, setSelectedIndex] = useState<number>()

    useEffect(() => {
        const index = menuItems.findIndex(el => el.path === location.pathname)
        setSelectedIndex(index)
    }, [location.pathname, menuItems])



    const onOpen = (e: MouseEvent<HTMLButtonElement>) => setAnchor(e.currentTarget)
    const onClose = () => setAnchor(null)

    const handleClick = (index: number) => {
        setSelectedIndex(index)
        onClose()
    }


    return (
        <>
            <Button
                onClick={onOpen}
                style={{color: '#fff', fontSize: 12}}
                endIcon={<ExpandMoreIcon style={{width: 14, height: 14}}/>}
            >
                Статистика
            </Button>

            <StyledMenu
                open={Boolean(anchor)}
                anchorEl={anchor}
                keepMounted
                onClose={onClose}
            >
                {menuItems.map((el, i) => (
                    <MenuItem
                        hidden={!el.access}
                        component={Link}
                        onClick={() => handleClick(i)}
                        to={el.path}
                        key={el.path}
                        selected={i === selectedIndex}
                    >
                        {el.name}
                    </MenuItem>
                ))}

            </StyledMenu>

        </>
    )
}


const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        borderRadius: '4px'
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

