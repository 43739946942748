import { FC } from 'react'
import { Helmet } from 'react-helmet'
import { Page404Content } from './Page404Content/Page404Content'

type Page404Props = {}

export const Page404: FC<Page404Props> = () => {
  return (
    <>
      <Helmet>
        <title>MTR-CRM :: 404 Страница не найдена</title>
      </Helmet>
      <Page404Content />
    </>
  )
}