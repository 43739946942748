import React, { FC, useState } from 'react'

import { Box, createStyles, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { checkOriginMessage, createMarkUp, cutCitation, stripTags } from '../../../../../../../../Utils/cutTags'
import LettersAttaches from '../LettersAttaches/LettersAttaches'


type LetterSubjectPropsType = {
  message: string | null
  attachedFiles: any[]
}

export const LetterSubject: FC<LetterSubjectPropsType> = ({ message, attachedFiles }) => {

  const classes = useStyles()

  const [isShowOrigin, setIsShowOrigin] = useState<boolean>(false)


  const handleShowOriginOnClick = (): void => setIsShowOrigin(!isShowOrigin)

  const checkForCitation = (message: string | null): boolean => {
    if (message) {
      return message === cutCitation(message)
    } else {
      return true
    }
  }


  return (
    <Box className={classes.letter_subject}>
      {message &&
      <div
        style={{ maxHeight: 'auto', wordBreak: 'break-word' }}
        dangerouslySetInnerHTML={isShowOrigin ? createMarkUp(checkOriginMessage(message)) : createMarkUp(stripTags(message))}
      />
      }
      {!checkForCitation(message) &&
      <Typography className={classes.showCitationText}
                  onClick={handleShowOriginOnClick}>
        {!isShowOrigin ? 'Показать цитирование' : 'Скрыть цитирование'}
      </Typography>
      }

      {attachedFiles && attachedFiles.length > 0 && <LettersAttaches attachedFiles={attachedFiles}/>}
      
    </Box>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    letter_subject: {
      width: '100%',
      minHeight: 20,
      overflow: 'hidden'
    },
    show_origin_button: {
      width: 20,
      height: 14
    },
    show_origin_icon: {
      width: 20,
      height: 14
    },
    showCitationText: {
      margin: '15px 0',
      fontSize: 11,
      fontStyle: 'italic',
      cursor: 'pointer',
      color: '#6379af',
      '&:hover': {
        color: '#3d61ad'
      }
    }
  })
)