import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { mailboxForDealActions } from '../slice/mailboxForDealSlice'
import { EmailType } from '../../emailSlice'

type RequestType = {
    id: number
    isDealingMail: boolean
}

type ReturnType = {
    data: EmailType,
    isDealingMail: boolean
}

export const uploadEmails = createAsyncThunk<ReturnType,
    RequestType,
  ThunkConfig<any>>(
  'mailboxForDeal/upload_emails',
  async (data, { dispatch, rejectWithValue, extra }) => {
    try {
      dispatch(mailboxForDealActions.setGetEmailsButtonStatusStatus(true))
      //await extra.api().get(`mailbox-for-new-clients/${id}/get-emails`)
      const res = await extra.api().get(`mailbox/${data.id}/get-emails`)

      dispatch(snackActions.setSnack({
        //label: 'Задача на получение почты была отправлена в очередь',
        label: 'Письма получены. Смотрите лог для результата',
        typeLabel: 'success'
      }))
        return {data: res.data, isDealingMail: data.isDealingMail }

    } catch (e) {
      dispatch(mailboxForDealActions.setGetEmailsButtonStatusStatus(false))
      handleServerNetworkError(e, dispatch, 'Задача на получение почты не была отправлена в очередь')
      return rejectWithValue({})
    } finally {
      dispatch(mailboxForDealActions.setGetEmailsButtonStatusStatus(null))
    }
  }
)