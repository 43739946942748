import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { snackActions } from '../../snackSlice'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { TemplateFragmentFieldType } from '../../../../../types'
import { fragmentsActions } from '../slice/fragmentsSlice'
import { fetchLetterFragments } from './fetchLetterFragments'


export const createFragment = createAsyncThunk<{},
  TemplateFragmentFieldType,
  ThunkConfig<any>>(
  'fragments/create_fragment',
  async (data, thunkApi) => {
    const { dispatch, rejectWithValue, getState, extra } = thunkApi
    const id = data.id
    const params = getState().fragments.fragmentPagination
    try {
      await extra.api().post(`letter-fragment`, data)
      dispatch(fragmentsActions.setIsUpdate({ isUpdate: true, id: id ? id : null }))
      dispatch(fetchLetterFragments(params))

      dispatch(snackActions.setSnack({
        label: 'Фрагмент успешно создан',
        typeLabel: 'success'
      }))

    } catch (e) {

      handleServerNetworkError(e, dispatch, 'При создании фрагмента произошла ошибка')
      return rejectWithValue({})

    } finally {
    }
  }
)