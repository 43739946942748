import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { fetchChains } from './fetchChains'
import { defaultPaginationParams } from '../slice/chainsSlice'
import { fetchPublicChains } from './fetchPublicChains'


interface RequestProps {
  id: number,
  params: any // Todo typing
}

export const copyChain = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'chains/copyChain',
  async (data, thunkApi) => {
    const {id, params} = data
    const { dispatch, getState, extra } = thunkApi

    const myChainsParams = getState().chains.chainPagination
    const publicChainsParams = getState().chains.publicChainPagination

    try {
      await extra.api().post(`chain-letters/${id}/copy`, params)
      dispatch(fetchChains(!myChainsParams.page ? defaultPaginationParams : myChainsParams))
      dispatch(fetchPublicChains(!publicChainsParams.page ? defaultPaginationParams : publicChainsParams))

      dispatch(snackActions.setSnack({
        label: 'Цепочка писем была успешно копирована',
        typeLabel: 'success'
      }))
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'При копировании произошла ошибка')
    } finally {
    }
  }
)