
export const chainCreateEditValue = {
  contents: [],
  days: [],
  isStopWhenAnswer: false,
  name: '',
  schedule: {
    endTime: '',
    startTime: '',
    isFriday: false,
    isMonday: false,
    isSaturday: false,
    isSunday: false,
    isThursday: false,
    isTuesday: false,
    isWednesday: false
  },
  templatesList: [],
  triggers: [],
  isPublic: false,
  useSchedule: false,
  author: {
    id: 0,
    name: ''
  }
}