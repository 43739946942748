import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { notificationsActions } from '../slice/notificationsSlice'
import { ToastVariant } from '../types/notifications'


type RequestProps = {
  message: string | JSX.Element
  title: string
  variant: ToastVariant
  actionCallback?: any // todo types
}

export const showNotification = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'notifications/show_notification',
  async (req, { dispatch }) => {
    const {
      message,
      title = '',
      variant,
      actionCallback
    } = req
    dispatch(notificationsActions.addNotification({
      message,
      title,
      variant,
      actionCallback
    }))
  }
)