import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { authActions } from '../slice/authSlice'
import { TokenCheckResponse } from '../types/auth'
import { callsActions } from '../../callSlice'

export const tokenCheck = createAsyncThunk<TokenCheckResponse,
  void,
  ThunkConfig<boolean>>(
  'auth/tokenCheck',
  async (_, thunkApi) => {
    const { dispatch, rejectWithValue, extra } = thunkApi
    try {
      const response = await extra.api().get(`token_check`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('apikey')
        }
      })
      if (response.status === 200) {
        dispatch(authActions.setIsTryAuth(true))
        dispatch(authActions.setIsAuth(true))
        //dispatch(authActions.setAuthStatus(true))

        // dispatch(setProjectsUserPhones(response.projectsUserPhones)) // todo сделать для твилио редъюсера (callSlice)
        dispatch(callsActions.setProjectsUserPhones(response.data.projectsUserPhones))

        return response.data
      } else {
        dispatch(authActions.setIsTryAuth(true))
        dispatch(authActions.setIsAuth(false))
        localStorage.removeItem('apikey')
      }
    } catch (e) {
      dispatch(authActions.setIsTryAuth(true))
      localStorage.removeItem('apikey')
      return rejectWithValue(false)

    }
  }
)