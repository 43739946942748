import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { UserByProjectType } from '../types/projectUser'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'


export const fetchUsersByProjects = createAsyncThunk<UserByProjectType[],
  number,
  ThunkConfig<any>>(
  'projectUser/fetch_users_by_projects',
  async (projectId, { dispatch, rejectWithValue, extra }) => {
    try {
      const res = await extra.api().get<UserByProjectType[]>(`project/${projectId}/users-without-role`)
      return res.data

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить список пользователей')
      return rejectWithValue({})
    }
  }
)