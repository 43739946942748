import React, { FC, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { createStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import { AlertTitle } from '@material-ui/lab'

import { connectSocketIo } from '../Redux/socket-io-handler'
import { CustomTooltip, MuiPopover } from '../ui-kit'
import { useAppDispatch } from '../providers/StoreProvider/hooks/useAppDispatch'
import { appSelectors } from '../providers/StoreProvider/slices/appSlice'
import { authSelectors } from '../providers/StoreProvider/slices/authSlice'


type WsStatusViewPropsType = {};

let retryCount = 0
const maxReconnects = 3

export const WsStatusView: FC<WsStatusViewPropsType> = () => {

  //const wsConnectionStatus = useSelector(selectWSConnectionStatus)
  const wsConnectionStatus = useSelector(appSelectors.getWSConnectionStatus)
  //const isCheckWSReconnect = useSelector(selectCheckMaxNumberOfWsReconnections)
  const isCheckWSReconnect = useSelector(appSelectors.getIsMaxNumberOfWsReconnections)
 // const name = useSelector(selectUserName)
  const name = useSelector(authSelectors.getAuthUsername)
  //const id = useSelector(selectUserProjectId)
  const id = useSelector(authSelectors.getAuthUserProjectId)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | SVGSVGElement | null>(null)
  const dispatch = useAppDispatch()

  const classes = useStyles()

  const ref = useRef<any>()
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClick = (event: any) => setAnchorEl(event.currentTarget)

  const reconnectToWSOnClick = () => {
    name && id && connectSocketIo(name, id, retryCount, maxReconnects, dispatch)
    handleClose()
  }

  useEffect(() => {
    isCheckWSReconnect && setAnchorEl(ref.current)
  }, [isCheckWSReconnect])

  return (
    <>
      <MuiPopover popoverId='ws-connection-status'
                  name='WS_CONNECTION_STATUS'
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  actionIcon={
                    wsConnectionStatus === 'open' ?
                      <CustomTooltip title='соединение установлено'>
                        <FiberManualRecordIcon className={classes.open_status_icon} />
                      </CustomTooltip>
                      :
                      wsConnectionStatus === 'close' || wsConnectionStatus === 'error' ?
                        <CustomTooltip title='соединение прервано'>
                          <FiberManualRecordIcon className={classes.close_status_icon}
                                                 innerRef={ref}
                                                 onClick={handleClick} />
                        </CustomTooltip>
                        :
                        null
                  }
      >
        <Alert severity='error'>
          <AlertTitle style={{ fontSize: 12 }}>Ошибка подключения</AlertTitle>
          <strong onClick={reconnectToWSOnClick}
                  style={{ color: '#5383ff', fontSize: 13, cursor: 'pointer' }}>
            Попробовать снова
          </strong>
        </Alert>
      </MuiPopover>
    </>
  )
}


const useStyles = makeStyles(() =>
  createStyles({
    open_status_icon: {
      '&.MuiSvgIcon-root': {
        width: '10px',
        height: '10px'
      },
      color: '#94E185',
      borderRadius: '50%',
      borderColor: '#78D965',
      boxShadow: '0px 0px 4px 1px #94E185',
      marginRight: 10
    },
    close_status_icon: {
      '&.MuiSvgIcon-root': {
        width: '10px',
        height: '10px'
      },
      marginRight: 10,
      color: '#C9404D',
      borderRadius: '50%',
      borderColor: '#C42C3B',
      boxShadow: '0px 0px 4px 1px #C9404D',
      cursor: 'pointer'
    }
  })
)