import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { snackActions } from '../../snackSlice'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'

interface RequestProps {
    name: string
    members: number[]
}

export const createPrivateChat = createAsyncThunk<{},
    RequestProps,
    ThunkConfig<any>>(
    'chats/create_private_chat',
    async (data, thunkApi) => {
        const { dispatch, extra, rejectWithValue } = thunkApi
        try {
            const response = await extra.api().post(`private-chat/create`, data)

            dispatch(snackActions.setSnack({
                label: 'Чат успешно создан',
                typeLabel: 'success'
            }))
            return response.data
        } catch (e) {
            handleServerNetworkError(e, dispatch, 'При создании чата произошла ошибка')
            return rejectWithValue(e)
        }
    }
)