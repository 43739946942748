import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { NameAndCommentProjectType } from '../../../../../types'
import { appActions } from '../../appSlice'


export const fetchNameAndCommentProject = createAsyncThunk<NameAndCommentProjectType,
  number,
  ThunkConfig<any>>(
  'projects/fetch_name_and_comment_project',
  async (projectId, { dispatch, rejectWithValue, extra }) => {
    dispatch(appActions.setStatus('loading'))
    try {
      const res = await extra.api().get<NameAndCommentProjectType>(`project/${projectId}/name-and-comment`)

      return res.data

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить информацию о проекте')
      return rejectWithValue({})
    } finally {
      dispatch(appActions.setStatus('success'))
    }
  }
)