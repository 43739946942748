import { CSSProperties, FC, memo } from 'react'
import { useSelector } from 'react-redux'
import { Mention, MentionsInput } from 'react-mentions'

import { FormControl, FormHelperText, MenuItem } from '@material-ui/core'
import { projectSelectors } from '../../providers/StoreProvider/slices/projectsSlice'
import { defaultMentionStyle, mentionInputClassName, useStyles } from './defaultStyles'
import { AssistantOperator, IProject, ManagerType } from '../../types'

export type MuiMentionsInputProps = {
  trigger: string
  helperText?: any
  placeholder?: any
  onAdd?: any
  markup?: any
  isLoading?: any
  displayTransform?: any
  appendSpaceOnAdd?: any
  multiline?: boolean
  onFocus?: any
  onBlur?: any
  inputRef?: any
  allowSuggestionsAboveCursor?: any
  allowSpaceInQuery?: any
  className?: string
  disabled?: boolean
  error?: boolean
  inputClassName?: string
  mentionStyle?: CSSProperties
  onChange?: (value: string) => void
  value?: string
  autoFocus?: boolean
  id?: string
  renderSuggestionItem?: any
  needInheritBackGround?: boolean
}


const defaultRenderSuggestionItem = (dataTestId: string): MuiMentionsInputProps['renderSuggestionItem'] => {
  return (suggestion: IProject) => {
    const itemContent = suggestion.name
    return (
      <MenuItem
        component='div'
        style={{ height: '100%', width: '100%' }}
        data-testid={`${dataTestId}-menu-item-${itemContent}`}
      >
        {itemContent}
      </MenuItem>
    )
  }
}


const MuiMentionsInput: FC<MuiMentionsInputProps> = (props: MuiMentionsInputProps) => {
  const projectData = useSelector(projectSelectors.getProjectData)
  const classes = useStyles(props)

  const mentionInputId = props.id || 'id-mention-input'

  const getAssistantOperators = (projectData?: IProject): AssistantOperator[] => {
    let newArray: AssistantOperator[] = []
    if (projectData) {
      projectData.clientManagers.map(el => el.assistantOperators.forEach(el => newArray.push(el)))
    } else {
      newArray = []
    }
    return newArray
  }

  const assistantOperators = getAssistantOperators(projectData)

  const usersArray = projectData ? [...projectData.projectManagers, ...projectData.orderManagers, ...projectData.clientManagers, ...assistantOperators]
    .map(el => ({ ...el, display: `@${el.name}` }))
    .reduce((acc: (ManagerType | AssistantOperator)[], el) => {
      if (!acc.find(u => u.name === el.name)) {
        acc.push(el)
      }
      return acc
    }, []) : []

  const handleChange = (event: { target: { value: string } }): void => {
    const value = event.target.value.replace(/@\[(@[^\]]+)\]\([0-9]+\)/gism, '$1')
    props.onChange?.(value)
  }

  const formControlClassName = `${classes.root} ${props.className}`
  const inputClassName = `${mentionInputClassName} ${props.inputClassName}`
  return (
    <FormControl className={formControlClassName} error={props.error}>
      <MentionsInput
        onChange={handleChange}
        value={props.value}
        className={inputClassName}
        inputMode={'text'}
        placeholder={props.placeholder}
        disabled={props.disabled}
      >
        <Mention trigger={props.trigger}
                 renderSuggestion={props.renderSuggestionItem || defaultRenderSuggestionItem(mentionInputId)}
                 data={usersArray}
                 style={{
                   ...defaultMentionStyle,
                   ...props.mentionStyle
                 }}
        />
      </MentionsInput>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  )
}

export default memo(MuiMentionsInput)