import { Typography, createStyles, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { AttachedFiles } from '../../../../ManageLeadsDialog/AttachedFiles/AttachedFiles'

type LettersAttachesPropsType = {
    attachedFiles: any[]
}

const LettersAttaches:React.FC<LettersAttachesPropsType> = (props) => {
    const classes = useStyles()

    const [showAttaches, setShowAttaches] = useState<boolean>(false)

    const handleChangeShowAttaches = () => {
        setShowAttaches(!showAttaches)
    }

    console.log('LettersAttaches', props)
    return (
        <>
            <Typography className={classes.typography}
                  onClick={handleChangeShowAttaches}
            >
                {showAttaches ? 'Скрыть аттачи' : 'Показать аттачи'}
            </Typography>

            { showAttaches && <AttachedFiles attachedFiles={props.attachedFiles}/> }
        </>
    )
}

export default  LettersAttaches

const useStyles = makeStyles(() =>
  createStyles({
    letter_history_box: {
      margin: '0px 0px 20px 0px',
      padding: '10px 15px',
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,.1)',
      borderRadius: 3,
      wordBreak: 'break-word',
      '& p': {
        marginBottom: 0
      }
    },
    typography: {
      textAlign: 'end',
      margin: '20px 0',
      color: '#334bfa',
      textDecoration: 'none',
      cursor: 'pointer',
      fontSize: '14px'
    },
    preloader: {
      textAlign: 'end',
      marginLeft: '30%'
    }
  })
)