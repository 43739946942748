import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import queryString from 'query-string'
import { EmailTargetType, FetchEmailsResponse } from '../types/emails'
import { appActions } from '../../appSlice'


type RequestProps = {
  target: EmailTargetType,
  params: any, //todo types
  projectId?: number
}

type FullFilledResponse = {
  target: EmailTargetType
  data: FetchEmailsResponse
}

export const fetchEmails = createAsyncThunk<FullFilledResponse | null,
  RequestProps,
  ThunkConfig<any>>(
    'emails/fetch_emails',
    async (data, thunkApi) => {
      const { dispatch, rejectWithValue, extra } = thunkApi
      const { target, params, projectId } = data
        console.log('paramsEmails', data)
      if (projectId) {
        const query = queryString.stringify(params)
        dispatch(appActions.setIsLoad(true))
        try {
          const response = target === 'emailsForDealing'
            ? await extra.api().get<FetchEmailsResponse>(`mailboxes-for-deal/project/${projectId}?${query}`)
            : await extra.api().get<FetchEmailsResponse>(`mailboxes-for-new-clients/project/${projectId}?${query}`)
             //response.status === ResponseStatusCodes.success && dispatch(emailsThunks.mailForceAuthorizeCheck(366))
          return { target, data: response.data }
        } catch (e) {
          handleServerNetworkError(e, dispatch, 'Не удалось получить емэйлы')
          return rejectWithValue({})
        } finally {
          dispatch(appActions.setIsLoad(false))
        }
      } else {
        return null
      }

    }
  )