import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'

type RequestProps = {
  messageId: number
  chatId: number
}

export const markChatMessageAsRead = createAsyncThunk<RequestProps,
  RequestProps,
  ThunkConfig<any>>(
  'chats/mark_chat_message_as_read',
  async (req, { dispatch, extra, rejectWithValue }) => {

    const { messageId, chatId } = req

    try {
      await extra.api().post(`chat-message/${messageId}/read`)
      return { messageId, chatId }

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Произошла ошибка')
      return rejectWithValue({})
    }
  }
)