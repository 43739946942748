import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { NewClientID } from '../../../../../Api/clientApi'
import { appActions } from '../../appSlice'


export const fetchNewClientsIds = createAsyncThunk<NewClientID[],
  number,
  ThunkConfig<any>>(
  'client/fetch_new_clients_ids',
  async (projectId, thunkApi) => {
    const { dispatch, rejectWithValue, extra } = thunkApi
    dispatch(appActions.setStatus('loading'))
    try {
      const response = await extra.api().get(`new-clients-ids/project/${projectId}`)
      return response.data
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить клиентов')
      return rejectWithValue({})
    } finally {
      dispatch(appActions.setStatus('success'))
    }
  }
)