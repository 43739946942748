import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TracksSchema } from '../types/tracks'
import {
    checkTrackStatus,
    checkTracksUniqueFromLeads,
    fetchClientDataListByEmails,
    updateMultipleTrack
} from '../services'
import { UploadTrackType } from '../../../../../Pages/ProjectsPage/ImportTrackNumbers/helpers/parseTrackNumbersHelper'


const initialState: TracksSchema = {
    isLoad: false,
    trackList: [],
    track: [],
    isUploadStarted: false
}

export const tracksSlice = createSlice({
    name: 'tracks',
    initialState,
    reducers: {
        setIsLoad: (state, action: PayloadAction<boolean>) => {
            state.isLoad = action.payload
        },
        setUploadStarted: (state, action: PayloadAction<boolean>) => {
            state.isUploadStarted = action.payload
        },
        setUploadedTracks: (state, action: PayloadAction<UploadTrackType[] | undefined>) => {
            state.uploadedTracks = action.payload
                ? (state.uploadedTracks ? state.uploadedTracks : []).concat(action.payload)
                : undefined
        },
        setSuccessUploadedTracks: (state, action: PayloadAction<UploadTrackType[] | undefined>) => {
            state.successUploadedTracks = action.payload
                // ? (state.successUploadedTracks ? state.successUploadedTracks : []).concat(action.payload)
                // : undefined
        }
    },
    extraReducers: (builder) => {
        builder.addCase(updateMultipleTrack.fulfilled, (state, action) => {
            state.successUploadedTracks = action.payload
                // ? (state.successUploadedTracks ? state.successUploadedTracks : []).concat(action.payload)
                // : undefined
        })
        builder.addCase(fetchClientDataListByEmails.fulfilled, (state, action) => {
            state.clientDataList = action.payload
        })
        builder.addCase(checkTrackStatus.fulfilled, (state, action) => {
            state.track = action.payload
        })
        builder.addCase(checkTracksUniqueFromLeads.fulfilled, (state, { payload }) => {
            state.clientsFromCheckTracks = payload
        })
    }

})

export const { actions: tracksActions } = tracksSlice
export const { reducer: tracksReducer } = tracksSlice
