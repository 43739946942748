import {Breadcrumbs, Typography} from '@material-ui/core'
import React, {useEffect} from 'react'
import {Link, useLocation, useParams} from 'react-router-dom'
import {Chat, ChromeReaderMode, Delete, Folder, Home, NavigateNext, Person, Phone, Settings, BarChart} from '@material-ui/icons'
import {useSelector} from 'react-redux'
import {getProjectsForSelect} from '../../providers/StoreProvider/slices/projectsSlice/selectors'
import {useAppDispatch} from '../../providers/StoreProvider/hooks/useAppDispatch'
import {projectThunks} from '../../providers/StoreProvider/slices/projectsSlice'
import ProjectShowPagesBreadcrumbItem from './ProjectShowPagesBreadcrumbItem'
import {RoutePath} from '../../providers/router/routeConfig/routeConfig'
import BaseBreadcrumbItem from './BaseBreadcrumbItem'
import AccessByRole from '../Access/AccessByRole/AccessByRole'
import {permissionsValues} from '../../z_old_site/crm-default-values/access-by-role'
import ProjectShowBreadcrumbItem from './ProjectShowBreadcrumbItem'
import './style.css'

const BreadCrumbs: React.FC = () => {
    const {id_project} = useParams<{ id_project: string }>()
    const location = useLocation()
    const dispatch = useAppDispatch()
    const projectsForSelect = useSelector(getProjectsForSelect)

    useEffect(() => {
        !projectsForSelect && dispatch(projectThunks.fetchProjectsForSelect())
    }, [dispatch, projectsForSelect])

    const pathname = id_project
        ? location.pathname.replace(id_project, ':id_project')
        : location.pathname

    if (!pathname || !breadcrumbsList[pathname]) {
        return null;
    }

    return (
        <div style={{margin: '10px 0px 8px 0px'}}>
            <Breadcrumbs
                // maxItems={props.width === 'sm' || props.width === 'xs' ? 3 : 10}
                aria-label='breadcrumb'
                separator={<NavigateNext fontSize='small'/>}
                className='mb-4 Breadcrumbs'

            >
                {breadcrumbsList[pathname]}
            </Breadcrumbs>
        </div>
    )
}

export default BreadCrumbs

export const makeStaticOrLink = (
    text: React.ReactElement | string,
    icon: React.ReactElement,
    isLink: boolean,
    link: string,
    key: string | number,
    state?: any
) => {
    if (isLink) {
        return (
            <Link color='inherit' to={link} state={state} className={'link'}>
                {icon}
                {text}
            </Link>
        );
    } else {
        return (
            <Typography color='textPrimary' className={'link'}>
                {icon}
                {text}
            </Typography>
        );
    }
}

type PathKeys = keyof typeof RoutePath;
type PathValues = typeof RoutePath[PathKeys]
type breadcrumbsListType = {
    [key: PathValues]: React.ReactNode[]
}

const DeleteProjectsBreadcrumbItem: React.FC<{ key: number }> = (props) => {

    return makeStaticOrLink(
        'Архивные проекты',
        <AccessByRole byRole={[...permissionsValues.archive_projects]}>
            <Delete fontSize='small' className='icon'/>
        </AccessByRole>,
        false,
        '/deleted_projects',
        props.key
    );
}

const breadcrumbsList: breadcrumbsListType = {
    '/test': [<BaseBreadcrumbItem key={1} icon={Settings} label='Тестовая страница' link='/'/>],
    '/': [<BaseBreadcrumbItem key={2} icon={Home} label='Главная страница' link='/'/>],
    '/403': [<BaseBreadcrumbItem key={222} icon={Home} label='Главная страница' link='/'/>],
    '/projects': [
        <BaseBreadcrumbItem isLink key={3} icon={Home} label='Главная страница' link='/'/>,
        <BaseBreadcrumbItem key={4} icon={Folder} label='Проекты' link='/projects'/>
    ],
    '/deleted_projects': [
        <BaseBreadcrumbItem isLink key={3} icon={Home} label='Главная страница' link='/'/>,
        <DeleteProjectsBreadcrumbItem key={4}/>
    ],
    '/chains': [
        <BaseBreadcrumbItem isLink key={3} icon={Home} label='Главная страница' link='/'/>,
        <BaseBreadcrumbItem isLink key={3} icon={ChromeReaderMode} label='Мои цепочки' link='/chains'/>
    ],
    '/phones': [
        <BaseBreadcrumbItem isLink key={3} icon={Home} label='Главная страница' link='/'/>,
        <BaseBreadcrumbItem isLink key={38} icon={Phone} label='Телефоны' link='/'/>
    ],
    '/leads': [
        <BaseBreadcrumbItem isLink key={3} icon={Home} label='Главная страница' link='/'/>,
        <BaseBreadcrumbItem isLink key={55} icon={Person} label='Лиды' link='/leads'/>
    ],
    '/chats': [
        <BaseBreadcrumbItem isLink key={3} icon={Home} label='Главная страница' link='/'/>,
        <BaseBreadcrumbItem key={63} icon={Chat} label='Чаты' link='/chats'/>
    ],
    '/operator_statistics': [
        <BaseBreadcrumbItem isLink key={3} icon={Home} label='Главная страница' link='/'/>,
        <BaseBreadcrumbItem key={91} icon={BarChart} label='Статистика по операторам' link='/operator_statistics'/>
    ],
    '/decline_reasons_statistics': [
        <BaseBreadcrumbItem isLink key={3} icon={Home} label='Главная страница' link='/'/>,
        <BaseBreadcrumbItem key={91} icon={BarChart} label='Статистика отказов по воронке продаж' link='/decline_reasons_statistics'/>
    ],
    '/forwarding_requests': [
        <BaseBreadcrumbItem isLink key={3} icon={Home} label='Главная страница' link='/'/>,
        <BaseBreadcrumbItem key={93} icon={BarChart} label='Статистика по запросам на переотправку' link='/forwarding_requests'/>
    ],
    '/cancellation_of_reship_requests': [
        <BaseBreadcrumbItem isLink key={3} icon={Home} label='Главная страница' link='/'/>,
        <BaseBreadcrumbItem key={93} icon={BarChart} label='Статистика по отмене запросов на переотправку' link='/cancellation_of_reship_requests'/>
    ],
    '/project/:id_project/settings': [
        <BaseBreadcrumbItem isLink key={3} icon={Home} label='Главная страница' link='/'/>,
        <BaseBreadcrumbItem isLink key={4} icon={Folder} label='Проекты' link='/projects'/>,
        <ProjectShowBreadcrumbItem isLink key={7}/>,
        <ProjectShowPagesBreadcrumbItem
            type='settings'
            isLink
            key={12}
        />
    ],
    '/project/:id_project/raw_leads': [
        <BaseBreadcrumbItem isLink key={3} icon={Home} label='Главная страница' link='/'/>,
        <BaseBreadcrumbItem isLink key={4} icon={Folder} label='Проекты' link='/projects'/>,
        <ProjectShowBreadcrumbItem isLink key={7}/>,
        <ProjectShowPagesBreadcrumbItem isLink type='raw_leads' key={16}/>
    ],
    '/project/:id_project/archive_clients': [
        <BaseBreadcrumbItem isLink key={3} icon={Home} label='Главная страница' link='/'/>,
        <BaseBreadcrumbItem isLink key={4} icon={Folder} label='Проекты' link='/projects'/>,
        <ProjectShowBreadcrumbItem isLink key={7}/>,
        <ProjectShowPagesBreadcrumbItem isLink type='archive_clients' key={16}/>
    ],
    '/project/:id_project/leads_manage': [
        <BaseBreadcrumbItem isLink key={3} icon={Home} label='Главная страница' link='/'/>,
        <BaseBreadcrumbItem isLink key={4} icon={Folder} label='Проекты' link='/projects'/>,
        <ProjectShowBreadcrumbItem isLink key={7}/>,
        <ProjectShowPagesBreadcrumbItem isLink type='leads_manage' key={16}/>
    ],
    '/project/:id_project/product_notes': [
        <BaseBreadcrumbItem isLink key={3} icon={Home} label='Главная страница' link='/'/>,
        <BaseBreadcrumbItem isLink key={4} icon={Folder} label='Проекты' link='/projects'/>,
        <ProjectShowBreadcrumbItem isLink key={7}/>,
        // <BaseBreadcrumbItem key={37} icon={InsertDriveFile} label='Заметки о продуктах' link='/' />,
        <ProjectShowPagesBreadcrumbItem
            type='leads_manage_as_manager'
            isLink
            key={16}
        />
    ],
}