import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import axios from 'axios'


type RequestProps = {
  userId: number
  identity: string
  isAssistant: 0 | 1
}

type FullFilledResponse = {
  token: string
  identity: string
}

export const fetchTwilioToken = createAsyncThunk<FullFilledResponse,
  RequestProps,
  ThunkConfig<any>>(
  'call/fetch_twilio_token',
  async (data, { rejectWithValue }) => {
    const { userId, isAssistant, identity } = data
    const twilioInstance = axios.create({
      withCredentials: false,
      // baseURL: `https://api.154.56.0.102.nip.io/api/call/voice-token?Identity=${userIdentity}&externalService=1&userId=${userId}&&phoneNumber[]=${phonesArrayQuery}`,
      baseURL: `https://api.154.56.0.102.nip.io/api/call/voice-token-phone?Identity=${identity}&userId=${userId}&isAssistant=${isAssistant}`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json'
      }
    })

    try {
      const res = await twilioInstance.get('')

      console.log('voice-token: ', res)

      return { token: res.data.token, identity: res.data.identity }
    } catch (e) {
      return rejectWithValue({})
    }


  }
)