import React, { ChangeEvent, FC } from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'

type SearchInputPropsType = {
    search: string
    onSearch?: (e: ChangeEvent<HTMLInputElement>) => void,
    onSearchItems?: (value: string) => void
    onReset?: () => void
    placeholder?: string
};

export const SearchInput: FC<SearchInputPropsType> = ({onSearch, search, onSearchItems, onReset, placeholder}) => {

    const classes = useStyles()

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        onSearch?.(e)
        onSearchItems?.(e.currentTarget.value)
    }

    return (
        <Paper component="form" className={classes.root}>
            <SearchIcon className={classes.iconButton}/>
            <InputBase
                value={search}
                onChange={handleSearch}
                className={classes.input}
                placeholder={placeholder ? placeholder : 'Поиск'}
                inputProps={{'aria-label': 'Search'}}
            />
            {search && (
                <CloseIcon
                    onClick={onReset}
                    className={classes.resetButton}
                />
            )}
        </Paper>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            '&.MuiPaper-rounded': {
                borderRadius: '0'
            }
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1
        },
        iconButton: {
            color: 'gray',
            padding: 5,
            width: 30,
            height: 30
        },
        resetButton: {
            color: 'gray',
            padding: 5,
            width: 30,
            height: 30,
            cursor: 'pointer'
        }
    })
)