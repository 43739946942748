import { ListItem } from '@material-ui/core'
import { LinkProps, Link as RouterLink, useResolvedPath, useMatch } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'


export const Link = ({ children, className, to, ...props }: LinkProps) => {

  const cls = useStyles()
  let resolved = useResolvedPath(to)
  let match = useMatch({ path: resolved.pathname, end: true })

  return (
    <ListItem className={cls.listItem}>
      <RouterLink
        style={{ color: match ? '#40a9ff' : 'white' }}
        to={to}
        {...props}
      >
        {children}
      </RouterLink>
    </ListItem>
  )
}

const useStyles = makeStyles({
  listItem: {
    fontSize: 12
  },
  active: {
    color: 'red'
  }
})

