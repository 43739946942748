import React, { useState } from 'react'
import { StateSchema } from '../../../providers/StoreProvider'
import { TablePagination as TablePaginationComponent } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { PaginationParamsType } from '../types/query'
import { useSearchParams } from 'react-router-dom'

type TablePaginationPropsType = {
    itemsCountSelector?: (state: StateSchema) => number | undefined
    prefixParam?: string | undefined
    itemList?: any[]
}

const TablePagination: React.FC<TablePaginationPropsType> = (props) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const totalCount = useSelector(props.itemsCountSelector ? props.itemsCountSelector : (state: StateSchema) => {return 0})
    const per_page = props.prefixParam ? `${props.prefixParam}per_page` : 'per_page'
    const page = props.prefixParam ? `${props.prefixParam}page` : 'page'

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        searchParams.set(per_page, event.target.value)
        searchParams.set(page, '1')
        setSearchParams(searchParams)
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        searchParams.set(page, (newPage + 1).toString())
        searchParams.set(per_page, searchParams.get(per_page) ?? '15')
        setSearchParams(searchParams)
    }

    return (
        <TablePaginationComponent
            style={{ fontSize: '13px' }}
            rowsPerPageOptions={[5, 10, 15, 25, 50, 75, 100]}
            count={props.itemList ? props.itemList.length : totalCount ? totalCount : 0}
            rowsPerPage={searchParams.get(per_page) ? parseInt(searchParams.get(per_page) as string) : 15}
            page={searchParams.get(page) ? parseInt(searchParams.get(page) as string)-1 : 0}
            SelectProps={{
                inputProps: { 'aria-label': 'rows per page' }
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    )
}

export default TablePagination