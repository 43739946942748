import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { clientThunks } from '../../clientsSlice'
import { tracksActions } from '../slice/tracksSlice'


export const createTrack = createAsyncThunk<{},
  any, // todo types
  ThunkConfig<any>>(
  'tracks/create_track',
  async (params, { dispatch, rejectWithValue, extra }) => {
    dispatch(tracksActions.setIsLoad(true))
    try {
      await extra.api().post('track', params)
      dispatch(clientThunks.fetchClientData({ clientId: params.clientId }))
      dispatch(snackActions.setSnack({
        label: 'Трекномер был успешно добавлен',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось добавить трекномер')
      return rejectWithValue({})
    } finally {
      dispatch(tracksActions.setIsLoad(false))
    }
  }
)