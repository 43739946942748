import React, { useEffect, useState } from 'react'
import { Box, Button, Card, CardContent, Container, FormControl, Grid, Input, InputLabel } from '@material-ui/core'
import svgImage9 from '../../assets/svg/login.svg'
import { useSelector } from 'react-redux'
import { authSelectors, authThunks } from '../../providers/StoreProvider/slices/authSlice'
import { useAppDispatch } from '../../providers/StoreProvider/hooks/useAppDispatch'
import { Loader } from '../../ui-kit'
import { makeStyles } from '@material-ui/core/styles'


export const Login = () => {
  const loginMessages = useSelector(authSelectors.getAuthLoginMessages)
  const isTryAuth = useSelector(authSelectors.getIsTryAuth)
  const dispatch = useAppDispatch()


  const [isLoad, setIsLoad] = useState<boolean>(true)
  const [message, setMessage] = useState(loginMessages)
  const [password, setPassword] = useState('')
  const [userName, setUserName] = useState('')
  const cls = useStyles()

  useEffect(() => {
    console.log('Login useEffect', loginMessages)
    if (loginMessages) {
      setMessage(loginMessages)
    }
    if (isTryAuth) {
      setIsLoad(false)
    }
  }, [isTryAuth, loginMessages])


  const changeLogin = async () => {
    setIsLoad(true)
    dispatch(authThunks.loginCheck({
      username: userName,
      password: password
    }))

  }
  if (isLoad) {
    return (
      <Box className={cls.loaderBox}>
        <Loader />
      </Box>
    )
  }


  return (
    <>
      <div className='app-wrapper min-vh-100'>
        <div className='app-main flex-column'>
          <div className='app-content p-0'>
            <div className='app-content--inner d-flex align-items-center'>
              <div className='flex-grow-1 w-100 d-flex align-items-center'>
                <div className='bg-composed-wrapper--content py-5'>
                  <Container maxWidth='lg'>
                    <Grid container spacing={5}>
                      <Grid
                        item
                        xs={12}
                        lg={5}
                        className='d-none d-xl-flex align-items-center'>
                        <img
                          alt='...'
                          className='w-100 mx-auto d-block img-fluid'
                          src={svgImage9}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={7}
                        className='d-flex flex-column align-items-center'>
                                                <span className='w-100 text-left text-md-center pb-4'>
                                                    <h1
                                                      className='display-3 text-xl-left text-center mb-3 font-weight-bold'>
                                                        Войти в свой аккаунт
                                                    </h1>
                                                    <p
                                                      className='font-size-lg text-xl-left text-center mb-0 text-black-50'>
                                                        Мы рады, что вы пользуйтесь нашим приложением.
                                                        Авторизуйтесь, чтобы продолжить.
                                                    </p>
                                                </span>
                        <Card className='m-0 w-100 p-0 border-0'>
                          <CardContent className='p-3'>
                            <div className='text-center text-black-50 mb-3'>
                              <span>Войти с учетными данными</span>
                            </div>
                            <form
                              className='px-5'
                              onSubmit={e => {
                                e.preventDefault()
                                if (password !== '' && userName !== '')
                                  changeLogin()
                                else setMessage('')
                              }}>
                              <div className='mb-3'>
                                <FormControl className='w-100' required>
                                  <InputLabel htmlFor='input-with-icon-adornment'>
                                    Логин
                                  </InputLabel>
                                  <Input
                                    autoFocus
                                    error={!!message}
                                    value={userName}
                                    required
                                    onChange={(e: any) => {
                                      setUserName(e.target.value)
                                    }}
                                    fullWidth
                                    id='input-with-icon-adornment'
                                  />
                                </FormControl>
                              </div>
                              <div className='mb-3'>
                                <FormControl className='w-100' required>
                                  <InputLabel htmlFor='standard-adornment-password'>
                                    Пароль
                                  </InputLabel>
                                  <Input
                                    required
                                    error={!!message}
                                    value={password}
                                    onChange={(e: any) => {
                                      setPassword(e.target.value)
                                    }}
                                    id='standard-adornment-password'
                                    fullWidth
                                    type='password'
                                  />
                                </FormControl>
                              </div>
                              <div className='text-center'>
                                {message && (
                                  <div className='text-center text-red-50 mb-3'>
                                    <span>{message}</span>
                                  </div>
                                )}
                                <Button
                                  type={'submit'}
                                  color='primary'
                                  variant='contained'
                                  size='large'
                                  className='my-2'>
                                  Войти
                                </Button>
                              </div>
                            </form>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const useStyles = makeStyles({
  loaderBox: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

