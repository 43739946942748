import { FC, useEffect } from 'react'
import { Box, createStyles, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { getLink } from '../../Utils/getLink'
import { List, SvgSelector } from '../../ui-kit'
import { appActions, appSelectors } from '../../providers/StoreProvider/slices/appSlice'
import { clientThunks } from '../../providers/StoreProvider/slices/clientsSlice'
import { useAppDispatch } from '../../providers/StoreProvider/hooks/useAppDispatch'

type NoteNotificationSnackPropsType = {}

export const NoteNotificationSnack: FC<NoteNotificationSnackPropsType> = () => {

  const noteInfo = useSelector(appSelectors.getNoteInfo)
  const showInfo = useSelector(appSelectors.getShowInfo)
  const clientData = useSelector(appSelectors.getClientDataForNote)
  const projectId = useSelector(appSelectors.getProjectId)

  const dispatch = useAppDispatch()
  const classes = useStyles()

  const onHideInfoHandler = (): void => {
    dispatch(appActions.setIsShowNotice())
    // projectId && dispatch(clientThunks.fetchClientsByProject({
    //   projectId,
    //   filterName: 'email',
    //   filterValue: clientData?.email
    // }))
  }

  useEffect(() => {
    const id = setTimeout(() => {
      dispatch(appActions.setIsShowNotice())
    }, 10000)
    return () => clearTimeout(id)
  }, [dispatch, showInfo])


  return (
    <Box className={`${classes.infoContainer} ${showInfo ? classes.active : ''}`}>
      <Alert className={classes.alert}>
        <AlertTitle>Уведомление</AlertTitle>
        {noteInfo && noteInfo.length > 1 ?
          <List items={noteInfo} renderItem={(item, index) => (
            <Typography style={{ fontWeight: 700 }}
                        key={item + '-' + index}>
              • {item.text}
            </Typography>
          )} />
          :
          <>
            <Typography variant='h6' style={{ marginBottom: 5 }}>{noteInfo && noteInfo[0].text}</Typography>
            {getLink(projectId, clientData?.id, clientData?.snooze, onHideInfoHandler)}
          </>
        }

        <Typography component='span'
                    role='presentation'
                    onClick={onHideInfoHandler}
                    className={classes.close}>
          <SvgSelector id='close-icon' />
        </Typography>
      </Alert>
    </Box>
  )
}


const useStyles = makeStyles(() =>
  createStyles({
    infoContainer: {
      position: 'fixed',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      top: '-200px',
      left: '50%',
      transform: 'translateX(-50%)',
      overflow: 'hidden',
      minWidth: '200px',
      maxWidth: '460px',
      padding: '10px',
      borderRadius: '5px',
      transition: 'top 0.3s ease-in-out'
    },
    active: {
      zIndex: 500,
      top: '35px',
      left: '50%',
      transform: 'translateX(-50%)'
    },
    alert: {
      width: '100%',
      backgroundColor: 'rgba(254,237,175,.9)',
      '& .MuiTypography-root.MuiAlertTitle-root.MuiTypography-body1.MuiTypography-gutterBottom': {
        color: '#3d4977'
      },
      '&.MuiAlert-standardSuccess .MuiAlert-icon': {
        color: '#3d4977'
      },
      '&.MuiAlert-standardSuccess': {
        color: 'black'
      }
    },
    close: {
      position: 'absolute',
      right: 4,
      top: 20,
      transform: 'translate(0, -50%)',
      marginRight: '3px',
      border: '2px solid rgba(255, 0, 13, 0)',
      cursor: 'pointer',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat'
    },
    link: {
      '&.a:not(.MuiButtonBase-root)': {
        color: '#5383ff',
        '&:hover': {
          color: '#3d5dbb'
        }
      }
    }
  })
)