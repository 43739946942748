import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'

export const readAllMessagesInPrivateChat = createAsyncThunk<number,
  number,
  ThunkConfig<any>>(
  'chats/read_all_messages_in_private_chat',
  async (chatId, { dispatch, extra, rejectWithValue }) => {

    try {
      await extra.api().post(`private-chat/${chatId}/read-all`)
      return chatId
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Произошла ошибка')
      return rejectWithValue({})
    }
  }
)