import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { snackActions } from '../../snackSlice'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { RequestDataType } from '../../../../../Pages/LeadsManagePage/MainContentBlock/ManageLeadsLetters/ManageLeadsLetterHeader/ManageLeadsLettersHeader'
import { clientsActions } from '../slice/clientsSlice'
import { fetchClientsByProject } from './fetchClientsByProject'
import { checkWhereIsClient } from '../../../helpers/helpers'
import { IClientForProject } from '../../../../../types'

interface RequestProps {
  id?: number
  projectId?: number
  data: RequestDataType
}

export const addClientToArchive = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'client/add_client_to_archive',
  async (request,  { dispatch, getState, extra } ) => {
    const { id, data, projectId } = request
    let leadList: IClientForProject[] | undefined
    const archivedClientStatus = id && checkWhereIsClient(getState().clients, id)
    archivedClientStatus === 'active'
      ? leadList = getState().clients.activeClients
      : leadList = getState().clients.snoozedClients

    try {
      await extra.api().post(`client/${id}/decline`, data)
      id && dispatch(clientsActions.addClientToArchive(id))
      if (leadList && leadList.length === 1) {
        dispatch(clientsActions.clearLeadManagePage())
      } else {
       projectId && await dispatch(fetchClientsByProject({ projectId }))
      }
      dispatch(snackActions.setSnack({
        label: 'Клиент успешно добавлен в архив',
        typeLabel: 'success'
      }))
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось добавить клиента в архив')
    }
  }
)