import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { MacrosType } from '../types/macros'



export const fetchMacros = createAsyncThunk<
  MacrosType[],
  void,
  ThunkConfig<any>>(
  'macros/fetch_macros',
  async (_, { dispatch, rejectWithValue, extra }) => {

    try {
      const response = await extra.api().get<MacrosType[]>(`macros`)
      localStorage.setItem('macrosList', JSON.stringify(response.data))

      return response.data

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить список макросов')
      return rejectWithValue({})
    }
  }
)