import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { snackActions } from '../../snackSlice'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { ChatType } from '../../../../../types'

interface RequestProps {
    chatId: number,
    type: ChatType,
    clientId?: number
}

export const deleteChat = createAsyncThunk<{},
    RequestProps,
    ThunkConfig<any>>(
    'chats/delete_chat',
    async (data, thunkApi) => {
        const { clientId, type, chatId } = data
        const { dispatch, rejectWithValue, extra } = thunkApi
        // const paginationParams = getState().chats.paginationParams
        try {
            if (type === ChatType.PRIVATE) {
                await extra.api().delete(`private-chat/${chatId}/delete`)
            } else {
                await extra.api().delete(`client-chat/${clientId}/delete`)
            }
            dispatch(snackActions.setSnack({
                label: 'Чат успешно удален',
                typeLabel: 'success'
            }))
        } catch (e) {
            handleServerNetworkError(e, dispatch, 'При удалении чата произошла ошибка')
            return rejectWithValue(e)
        }
    }
)