import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { ProjectInformationFormType } from '../types/projects'
import { fetchNameAndCommentProject } from './fetchNameAndCommentProject'


type RequestProps = {
  projectId: number,
  data: ProjectInformationFormType | { comment: string }
}


export const saveProjectData = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'projects/save_project_data',
  async (dto, { dispatch, rejectWithValue, extra }) => {

    const { projectId, data } = dto

    try {
      await extra.api().patch(`project/${projectId}`, data)

      dispatch(snackActions.setSnack({
        label: 'Данные успешно сохранены',
        typeLabel: 'success'
      }))
      dispatch(fetchNameAndCommentProject(projectId))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Произошла ошибка при сохранении')
      return rejectWithValue({})
    }
  }
)