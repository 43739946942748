import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
    Accordion,
    AccordionSummary,
    createStyles,
    AccordionDetails,
    Grid,
    Typography,
    Button,
    AccordionActions
} from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import { ExpandMore } from '@material-ui/icons'
import { TableFilterType } from '../types/filter'
import { useSearchParams } from 'react-router-dom'

type FilterPropsType = {
    filter: TableFilterType[]
    /** Префикс который добавляется в query для параметров пагинации (Передается из пропсов Table) */
    prefixParam?: string
}

const TableFilter: React.FC<FilterPropsType> = (props) => {
    const classes = useStyles()
    const [searchParams, setSearchParams] = useSearchParams()

    const prefix = props.prefixParam ?? ''

    const [expanded, setExpanded] = useState<boolean>(false)
    const [filterData, setFilterData] = useState<{
        [key: string]: string
    }>(Object.fromEntries(
        props.filter
            .filter(i => i.defaultValue || searchParams.get(prefix+i.name))
            .map(i => [
                prefix+i.name, 
                searchParams.get(prefix+i.name) !== null
                    ? searchParams.get(prefix+i.name) as string
                    : i.defaultValue 
                        ? i.defaultValue?.toString() 
                        : '']
            )
        ))

    useEffect(() => {
        console.log('filterData', filterData)
    }, [filterData])

    const onAccept = () => {
        for (const key in filterData) {
            if (Object.prototype.hasOwnProperty.call(filterData, key)) {
                searchParams.set(key, filterData[key])
            }
        }

        /** проверяем пагинацию таблицы, если при начале фильтрации указана не первая страница меняет ее на первую */
        let newSearchParamsArray = [...searchParams]
        newSearchParamsArray.forEach((param, index) => {
            console.log('param', param)
            if (props.prefixParam && param[0] === `${props.prefixParam}page`) {
                searchParams.set(`${props.prefixParam}page`, '1')
            }
        })


        setSearchParams(searchParams)
    }

    const onReset = () => {
        setFilterData({})
        for (const key in filterData) {
            if (Object.prototype.hasOwnProperty.call(filterData, key)) {
                searchParams.delete(key)
            }
        }
        setSearchParams(searchParams)
    }

    return (
        <Accordion
            expanded={expanded}
            className={classes.filter_panel}
            data-testid='table-filter'
        >

            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1c-content"
                id="panel1c-header"
                data-testid='toggle-btn'
                onClick={() => setExpanded(!expanded)}
            >
                <Typography
                    variant="button"
                >
                    Фильтр
                </Typography>
            </AccordionSummary>

                {/* {props.needToHint && <TableFilterHint/>} */}

            <AccordionDetails>
                <Grid container spacing={2}>
                    {props.filter.map((el: TableFilterType, index: number) => {
                        return (
                            <Grid
                                key={index.toString()}
                                item
                                {...el.gridItemSettings}
                            >
                                <>
                                    {<el.element 
                                        field={el}
                                        filterData={filterData}
                                        setFilterData={setFilterData}
                                        prefixParam={props.prefixParam}
                                        value={searchParams.get(prefix+el.name) ?? el.defaultValue?.toString() ?? null}
                                    />}
                                </>
                            </Grid>
                        )
                    })}
                </Grid>
            </AccordionDetails>

            <Divider variant='fullWidth'/>
            <AccordionActions>
                <Button
                    type="reset"
                    size="small"
                    onClick={onReset}
                >
                    Сбросить фильтр
                </Button>
                <Button 
                    size="small"
                    color="primary"
                    type="submit"
                    onClick={onAccept}
                >
                    Применить фильтр
                </Button>
            </AccordionActions>
        </Accordion>
    )
}

export default TableFilter

const useStyles = makeStyles(() =>
    createStyles({
        filter_panel: {
            marginBottom: 8
        }
    })
)