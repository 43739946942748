export type DaysValuesType = {
    name: string
    shortLabel: string
    label: string
}

export const daysValues: DaysValuesType[] = [
    {
        name: 'isMonday',
        shortLabel: 'пн',
        label: 'Понедельник'
    },
    {
        name: 'isTuesday',
        shortLabel: 'вт',
        label: 'Вторник'
    },
    {
        name: 'isWednesday',
        shortLabel: 'ср',
        label: 'Среда'
    },
    {
        name: 'isThursday',
        shortLabel: 'чт',
        label: 'Четверг'
    },
    {
        name: 'isFriday',
        shortLabel: 'пт',
        label: 'Пятница'
    },
    {
        name: 'isSaturday',
        shortLabel: 'сб',
        label: 'Суббота'
    },
    {
        name: 'isSunday',
        shortLabel: 'вс',
        label: 'Воскресенье'
    }
];