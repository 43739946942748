import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DetailingDataType, ExternalServiceSchema } from '../types/externalService'
import { checkExternalService, fetchExternalServices } from '../services'


const initialState: ExternalServiceSchema = {
  totalCount: 0,
  isUpdated: false,
  detailingData: {
    externalServiceId: undefined,
    calls: undefined,
    phoneNumbers: undefined,
    sms: undefined
  }
}


export const externalServiceSlice = createSlice({
  name: 'external_service',
  initialState,
  reducers: {
    setDetailingData: (state, action: PayloadAction<DetailingDataType>) => {
      state.detailingData = action.payload
    },
    setIsUpdated: (state, action: PayloadAction<boolean>) => {
      state.isUpdated = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchExternalServices.fulfilled, (state, { payload }) => {
      state.externalServicesList = payload.items
      state.totalCount = payload.totalCount
    })
    builder.addCase(checkExternalService.fulfilled, (state, { payload }) => {
      state.externalServicesList = state.externalServicesList?.map(s => s.id === payload.id 
        ? {
            ...s,
            balance: payload.balance,
            status: payload.status
          } 
        : s)
    })
    builder.addCase(checkExternalService.rejected, (state, { payload }) => {
      state.externalServicesList = state.externalServicesList?.map(s => s.id === payload.id ? {
        ...s, balance: payload.balance, status: payload.status
      } : s)
    })
  }

})

export const { actions: externalServiceActions } = externalServiceSlice
export const { reducer: externalServiceReducer } = externalServiceSlice
