import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'


type RequestProps = {
  clientId: number
  chatId: number
}

export const readAllMessagesInClientChat = createAsyncThunk<number,
  RequestProps,
  ThunkConfig<any>>(
  'chats/read_all_messages_in_client_chat',
  async (req, { dispatch, extra, rejectWithValue }) => {

    const { clientId, chatId } = req

    try {
      await extra.api().post(`client-chat/${clientId}/read-all`)
      return chatId
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Произошла ошибка')
      return rejectWithValue({})
    }
  }
)