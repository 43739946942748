import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ChainsSchema } from '../types/chains'
import {
  fetchChainInfo,
  fetchChains,
  fetchChainsForSelect,
  fetchProjectListForCurrentChain,
  fetchPublicChains, linkChainToCurrentProject
} from '../services'
import { getItemList } from '../../../helpers/helpers'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'


export const defaultPaginationParams: PaginationParamsType = { per_page: 15, page: 1 }


const initialState: ChainsSchema = {
  chainPagination: { per_page: 15, page: 1 },
  publicChainPagination: { per_page: 15, page: 1 },
  chainsForSelect: [],
  isFetchMyChains: false,
  isFetchPublicChains: false
}


export const chainsSlice = createSlice({
  name: 'chains',
  initialState,
  reducers: {
    setIsFetchMyChainsData: (state, action: PayloadAction<boolean>) => {
      state.isFetchMyChains = action.payload
    },
    setIsFetchPublicChainsData: (state, action: PayloadAction<boolean>) => {
      state.isFetchPublicChains = action.payload
    },
    setMyChainsParams: (state, action: PayloadAction<PaginationParamsType>) => {
      state.chainPagination = action.payload
    },
    setPublicChainsParams: (state, action: PayloadAction<PaginationParamsType>) => {
      state.publicChainPagination = action.payload
    },
    updateProjectStatusFromCurrentChain: (state, action: PayloadAction<PaginationParamsType>) => {
      state.publicChainPagination = action.payload
    },
    clearProjectListForChain: (state) => {
      state.projectListForCurrentChain = undefined
    },
    clearChainsForSelect: (state) => {
      state.chainsForSelect = []
    }

  },
  extraReducers: (builder) => {
    builder.addCase(fetchChainInfo.fulfilled, (state, { payload }) => {
      if (payload.isPublic) {
        state.publicChainList = state.publicChainList?.map((ch) => ch.id === payload.chainId ? {
          ...payload.data,
          messagesCount: ch.messagesCount,
          isPublic: ch.isPublic,
          useSchedule: ch.useSchedule,
          author: ch.author,
          projects: ch.projects,
          contents: payload.data.contents
        } : ch)
      } else {
        state.chainList = state.chainList?.map((ch) => ch.id === payload.chainId ? {
          ...payload.data,
          messagesCount: ch.messagesCount,
          isPublic: ch.isPublic,
          useSchedule: ch.useSchedule,
          author: ch.author,
          projects: ch.projects,
          contents: payload.data.contents
        } : ch)
      }
    })
    builder.addCase(fetchChains.fulfilled, (state, { payload }) => {
      state.chainList = payload.items
      state.totalCount = payload.totalCount
    })
    builder.addCase(fetchPublicChains.fulfilled, (state, { payload }) => {
      state.publicChainList = payload.items
      state.publicTotalCount = payload.totalCount
    })
    builder.addCase(fetchChainsForSelect.fulfilled, (state, { payload }) => {
      state.chainsForSelect = payload
    })
    builder.addCase(fetchProjectListForCurrentChain.fulfilled, (state, { payload }) => {
      state.projectListForCurrentChain = payload.items
    })
    builder.addCase(linkChainToCurrentProject.fulfilled, (state, { payload }) => {
      state.chainList = getItemList(state.chainList, payload)
      state.publicChainList = getItemList(state.publicChainList, payload)
    })
  }

})

export const { actions: chainsActions } = chainsSlice
export const { reducer: chainsReducer } = chainsSlice
