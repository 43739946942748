import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { ResponseStatusCodes } from '../../../../../enums/enums'

type RequestProps = {
  projectId: number,
  clientId: number
}

export const fetchProjectMailHeaderForClient = createAsyncThunk<string | undefined,
  RequestProps,
  ThunkConfig<any>>(
  'projectLetterSetting/fetch_project_mail_header_for_client',
  async (data, { dispatch, rejectWithValue, getState, extra }) => {
    const { projectId, clientId } = data
    try {
      const response = await extra.api().get<string>(`project/${projectId}/letters-setting/header`)

      const macrosReplaceContentResponse = await extra.api().post(`macros/replace-content`, {
        clientId: clientId,
        content: response.data
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      })

      if (macrosReplaceContentResponse.status === ResponseStatusCodes.success) {
        return macrosReplaceContentResponse.data
      } else return undefined
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось загрузить верхнюю часть письма')
      return rejectWithValue({})
    }
  }
)