import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'

type RequestProps = {
  ids: number[]
}

export const readAllClientLetters = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'letters/read_all_client_letters',
  async (data, { dispatch, rejectWithValue, extra }) => {

    try {
      await extra.api().patch(`clients/read-all-letters`, data, {
        headers: {
          'Content-type': 'application/json'
        }
      })

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Произошла ошибка')
      return rejectWithValue({})
    } finally {
    }
  }
)