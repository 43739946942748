import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { appActions } from '../../appSlice'
import * as queryString from 'query-string'


export const fetchProjectInfo = createAsyncThunk<any,
  any,
  ThunkConfig<any>>(
  'projects/fetch_project_info',
  async (data, { dispatch, rejectWithValue, extra }) => {
    const query = queryString.stringify(data)

    try {
      const res = await extra.api().get(`projects-info?${query}`)

      return res.data

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить информацию о проекте')
      return rejectWithValue({})
    } finally {
      dispatch(appActions.setStatus('success'))
    }
  }
)