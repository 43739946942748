import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { NoteRequestType } from '../../../../../Pages/LeadsManagePage/MainContentBlock/ManageLeadsLetters/ManageLeadsDialog/SendNote/SendNote'
import { snackActions } from '../../snackSlice'
import { AxiosResponse } from 'axios'
import { CreateNoteResponse, NoteWithType } from '../types/letters'
import { clientsActions } from '../../clientsSlice'

type RequestProps = {
  data: NoteRequestType,
  refreshDataCallback?: any, // todo types
  refreshData?: any // todo types
}

export const createClientNote = createAsyncThunk<NoteWithType[],
  RequestProps,
  ThunkConfig<any>>(
  'letters/create_client_note',
  async (request, thunkApi) => {
    const { dispatch, rejectWithValue, extra } = thunkApi
    const { refreshData, refreshDataCallback, data } = request
    try {
      const response = await extra.api().post<CreateNoteResponse, AxiosResponse<CreateNoteResponse>>(`client-note`, data)

      if (response.data.client.length > 0) {
        const leadItem = response.data.client[0]
        dispatch(clientsActions.setLeadListItem(leadItem))
      }
      if (refreshDataCallback) {
        dispatch(refreshDataCallback(refreshData[0], refreshData[1]))
      }

      dispatch(snackActions.setSnack({
        label: 'Заметка успешно создана',
        typeLabel: 'success'
      }))

      return response.data.notes
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось создать заметку')
      return rejectWithValue({})
    }
  }
)