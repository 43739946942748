import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProjectsSchema } from '../types/projects'
import {
    fetchChainSettingsForProject,
    fetchNameAndCommentProject,
    fetchProjectNotes,
    fetchProjectData,
    createProject,
    fetchProjects, fetchProjectsForSelect, isAcceptableProject, fetchProjectInfo, saveProjectsChainsSettings
} from '../services'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'

export const defaultPaginationParams: PaginationParamsType = { per_page: 15, page: 1 }


const initialState: ProjectsSchema = {
    defaultProjectChain: {
        chainForDeliveredOrder: null,
        chainForFirstMessage: null,
        chainForSentOrder: null,
        chainForDeliveredOrderSingleSend: false,
        chainForSendOrderSingleSend: false
    },
    accessForProjects: {},
    projectName: '',
    projectComment: '',
    managerName: '',
    hasProductNotes: false,
    currentProjectData: undefined,

    projectPagination: defaultPaginationParams
}


export const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        clearCurrentProjectData: (state) => state.currentProjectData = undefined,
        clearCreatedProjectId: (state) => state.createdProjectId = undefined,
        clearProjectNameAndComment: (state) => {
            state.projectName = ''
            state.projectComment = ''
        },
        setParams: (state, action: PayloadAction<PaginationParamsType>) => {
            state.projectPagination = action.payload
        },
        clearDefaultChain: (state) => {
            state.defaultProjectChain = {
                chainForDeliveredOrder: null,
                chainForFirstMessage: null,
                chainForSentOrder: null,
                chainForSendOrderSingleSend: false,
                chainForDeliveredOrderSingleSend: false
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProjects.fulfilled, (state, action) => {
            state.projectsList = action.payload.items
            state.totalCount = action.payload.totalCount
        })
        builder.addCase(createProject.fulfilled, (state, action) => {
            state.createdProjectId = action.payload.projectId
        })
        builder.addCase(fetchChainSettingsForProject.fulfilled, (state, action) => {
            state.defaultProjectChain = action.payload
        })
        builder.addCase(fetchProjectData.fulfilled, (state, action) => {
            state.currentProjectData = action.payload
        })
        builder.addCase(fetchProjectsForSelect.fulfilled, (state, action) => {
            state.projectsForSelect = action.payload
        })
        builder.addCase(isAcceptableProject.fulfilled, (state, action) => {
            state.accessForProjects[action.payload.projectId] = action.payload.isAcceptable
        })
        builder.addCase(fetchNameAndCommentProject.fulfilled, (state, action) => {
            state.projectName = action.payload.name
            state.projectComment = action.payload.comment
            state.managerName = action.payload.managerName
            state.hasProductNotes = action.payload.hasProductNotes
        })
        builder.addCase(fetchProjectNotes.fulfilled, (state, action) => {
            const { projectNotes, projectId } = action.payload
            let newProjectNotes = { ...state.projectNotes }

            projectNotes.forEach((note) => {
                if (newProjectNotes[projectId]) {
                    newProjectNotes[projectId][note.id] = note
                } else {
                    newProjectNotes[projectId] = {}
                    newProjectNotes[projectId][note.id] = note
                }
            })
            state.projectNotes = newProjectNotes
        })
        builder.addCase(fetchProjectInfo.fulfilled, (state, { payload }) => {
            state.projectInfo = payload
        })
        builder.addCase(saveProjectsChainsSettings.fulfilled, (state, { payload }) => {
            state.defaultProjectChain = payload
        })
    }
})

export const { actions: projectsActions } = projectsSlice
export const { reducer: projectsReducer } = projectsSlice
