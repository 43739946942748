import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { SalesFunnelDescribe } from '../../../../../types'


export const fetchSalesFunnelDescribeForClient = createAsyncThunk<
  SalesFunnelDescribe[],
  number,
  ThunkConfig<any>>(
  'client/fetch_sales_funnel_describe_for_client',
  async (clientId, thunkApi) => {
    const { dispatch, rejectWithValue, extra } = thunkApi
    try {
      const response = await extra.api().get(`client/${clientId}/sales-funnel/describe`)
      return response.data
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось загрузить воронку продаж')
      return rejectWithValue({})
    }
  }
)