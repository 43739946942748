import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { ChatType } from '../../../../../types'

type FullFilledResponse = {
  idsArray: number[]
  chatId?: number
  chatType?: ChatType
}

type RequestProps = {
  idsArray: number[],
  chatId?: number,
  chatType?: ChatType
}

export const markMultipleMessagesAsRead = createAsyncThunk<FullFilledResponse,
  RequestProps,
  ThunkConfig<any>>(
  'chats/read_multiple_messages',
  async (req, { dispatch, extra, rejectWithValue }) => {
    const { idsArray, chatId, chatType } = req

    try {
      await extra.api().post(`chat-message/read-multiple`, {
        ids: idsArray
      })

      return { idsArray, chatId, chatType }
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Произошла ошибка')
      return rejectWithValue({})
    }
  }
)