import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { snackActions } from '../../snackSlice'
import { clientThunks } from '../../clientsSlice'
import { tracksActions } from '../slice/tracksSlice'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'


type RequestProps = {
  params: any,  // todo types
  clientId?: number
}

export const createClientProduct = createAsyncThunk<
  {},
  RequestProps,
  ThunkConfig<any>>(
  'tracks/create_client_product',
  async (data, { dispatch, rejectWithValue, extra }) => {

    const { clientId, params } = data

    const { trackId, ...body } = params
    dispatch(tracksActions.setIsLoad(true))
    try {
      await extra.api().post(`track/${trackId}/client-product`, body)
      clientId && dispatch(clientThunks.fetchClientData({clientId}))
      dispatch(snackActions.setSnack({
        label: 'Продукт был успешно добавлен',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось добавить продукт')
      return rejectWithValue({})
    } finally {
      dispatch(tracksActions.setIsLoad(false))
    }
  }
)