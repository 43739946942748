import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { snackActions } from '../../snackSlice'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { fetchProductNotesForProject } from './fetchProductNotesForProject'
import { FilesType } from '../../../../../Components/Inputs/FilesAttach/FilesAttach'


type RequestProps = {
  projectId: number,
  productNoteName: string[]
  filesData: FilesType
}

export const createProductNotesInProject = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'productNotes/create_product_notes_in_project',
  async (data, { dispatch, rejectWithValue, getState, extra }) => {
    const { projectId, productNoteName, filesData } = data
    const params = getState().productNotes.productNotesTablePaginationParams

    try {
      await extra.api().post(`project/${projectId}/product-notes/create`, {
        notes: productNoteName,
        attachedFiles: filesData
      })
      dispatch(fetchProductNotesForProject({ projectId, params }))

      dispatch(snackActions.setSnack({
        label: 'Заметки о продукте успешно созданы',
        typeLabel: 'success'
      }))
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось создать заметки о продукте')
      return rejectWithValue({})
    } finally {
    }
  }
)