import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SnackbarType, SnackSchema } from '../types/snack'
import { FunctionComponent } from 'react'


const initialState: SnackSchema = {
  snackbar: []
}

const genId = () => {
  const number = Math.random()
  number.toString(36)
  return number.toString(36).substr(2, 9)
}


export const snackSlice = createSlice({
  name: 'snack',
  initialState,
  reducers: {
    setSnack: (state, action: PayloadAction<{
      label: string | null,
      typeLabel: 'success' | 'error' | 'info' | null,
      action?: FunctionComponent
    }>) => {
      const newSnack: SnackbarType = {
        id: genId(),
        message: action.payload.label,
        type: action.payload.typeLabel,
        action: action.payload.action
      }
      state.snackbar = [newSnack, ...state.snackbar]
    },
    clearSnackBar: (state, action: PayloadAction<string[]>) => {
      let newSnackBar: SnackbarType[] = []
      const beDelete = action.payload
      state.snackbar.forEach(el => {
        if (!beDelete.includes(el.id)) {
          newSnackBar.push(el)
        }
      })
      state.snackbar = newSnackBar
    }
  }
})

export const { actions: snackActions } = snackSlice
export const { reducer: snackReducer } = snackSlice
