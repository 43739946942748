import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { clientThunks } from '../../clientsSlice'
import { snackActions } from '../../snackSlice'
import { tracksActions } from '../slice/tracksSlice'

type RequestProps = {
  productId: number,
  clientId?: number
}

export const deleteClientProduct = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'tracks/delete_client_product',
  async (dto, { dispatch, rejectWithValue, extra }) => {

    const { clientId, productId } = dto

    dispatch(tracksActions.setIsLoad(true))
    try {
      await extra.api().delete(`client-product/${productId}`)

      clientId && dispatch(clientThunks.fetchClientData({ clientId }))
      dispatch(snackActions.setSnack({
        label: 'Продукт был успешно удален',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось удалить продукт')
      return rejectWithValue({})
    } finally {
      dispatch(tracksActions.setIsLoad(false))
    }
  }
)