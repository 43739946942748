import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
// import BreadCrumbs from '../../z_old_site/crm-components/breadcrumps/BreadCrumps'
import BreadCrumbs from '../../Components/breadcrumps/BreadCrumps'
import { useSelector } from 'react-redux'
import { projectSelectors, projectThunks } from '../../providers/StoreProvider/slices/projectsSlice'
import { useAppDispatch } from '../../providers/StoreProvider/hooks/useAppDispatch'

const PageContainer: React.FC<PageContainerPropsType> = (props) => {


    const projectsList = useSelector(projectSelectors.getProjectsList)
    const dispatch = useAppDispatch()

    useEffect(() => {
      !projectsList && dispatch(projectThunks.fetchProjects({
          params: {
              page: 1,
              per_page: 100
          }
      }))
    }, [dispatch, projectsList])

    return (
        <div style={{ marginTop: 0 }}>
            <Helmet>
                <title>MTR-CRM :: {props.title}</title>
            </Helmet>
          <div style={{ marginLeft: 16 }}>
                <BreadCrumbs/>
            </div>
            <div style={{ paddingTop: 8 }}>{props.children}</div>
        </div>
    )
}

export default PageContainer

type PageContainerPropsType = {
    title: string
    isProjects?: boolean
    children?: JSX.Element|JSX.Element[]
}