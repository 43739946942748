import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FragmentsSchema } from '../types/fragments'
import {
  fetchProjectListForCurrentFragment,
  fetchLetterFragmentByProjectId,
  linkFragmentForCurrentProject,
  fetchLetterFragments,
} from '../services'
import { getItemList } from '../../../helpers/helpers'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'

export const defaultPaginationParams: PaginationParamsType = { page: 1, per_page: 15 }

const initialState: FragmentsSchema = {
  isUpdate: false,
  updateId: null,
  fragmentPagination: { per_page: 15, page: 1 },
  openProjectModal: false,
  isFetchData: false
}


export const fragmentsSlice = createSlice({
  name: 'fragments',
  initialState,
  reducers: {
    setIsUpdate: (state, action: PayloadAction<{ isUpdate: boolean, id: number | null }>) => {
      state.isUpdate = action.payload.isUpdate
      state.updateId = action.payload.id
    },
    setIsFetchData: (state, action: PayloadAction<boolean>) => {
      state.isFetchData = action.payload
    },
    setParams: (state, action: PayloadAction<PaginationParamsType>) => {
      state.fragmentPagination = action.payload
    },
    clearProjectListForFragment: (state) => {
      state.projectListForCurrentFragment = undefined
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLetterFragmentByProjectId.fulfilled, (state, { payload }) => {
      state.letterFragmentList = payload
      state.totalCount = payload.length
    })
    builder.addCase(fetchLetterFragments.fulfilled, (state, { payload }) => {
      state.letterFragmentList = payload.items
      state.totalCount = payload.totalCount
    })
    builder.addCase(fetchProjectListForCurrentFragment.fulfilled, (state, { payload }) => {
      state.projectListForCurrentFragment = payload.items
    })
    builder.addCase(linkFragmentForCurrentProject.fulfilled, (state, { payload }) => {
      state.projectListForCurrentFragment?.map(p => p.id === payload.id ? { ...p, checked: payload.checked } : p)
      state.letterFragmentList = getItemList(state.letterFragmentList, payload)
    })
  }

})

export const { actions: fragmentsActions } = fragmentsSlice
export const { reducer: fragmentsReducer } = fragmentsSlice
