import { ReactNode, useEffect, useState } from 'react'
import { NoInternetPage } from '../../../Pages'


interface NetworkStatusProviderProps {
    children: ReactNode
}

export const NetworkStatusProvider = ({children}: NetworkStatusProviderProps) => {

    const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine)

    useEffect(() => {
        // обновление статуса инетернет-соединения
        const handleStatusChange = () => {
            setIsOnline(navigator.onLine)
        }
        // слушаем онлайн статус
        window.addEventListener('online', handleStatusChange)
        // слушаем офлайн статус
        window.addEventListener('offline', handleStatusChange)

        // очистка эффектов
        return () => {
            window.removeEventListener('online', handleStatusChange)
            window.removeEventListener('offline', handleStatusChange)
        }

    }, [isOnline])


    if (isOnline) {
        return (
            <>{children}</>
        )
    }

    return (
        <NoInternetPage/>
    )
}