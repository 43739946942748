import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { prepareFilterParams } from '../../../../../Api/API'
import { archiveClientsActions } from '../slice/arhiveClientsSlice'
import { ManagerInfoType } from '../types/archiveClients'

type ApiResponseType = {
    managers: ManagerInfoType[]
    lastStatusChangers: ManagerInfoType[]
}

type RequestPropsType = {
    projectId: number
    params?: PaginationParamsType
}

export const fetchWaitingForApproveDeclineClientManagers = createAsyncThunk<
    any,
    RequestPropsType,
    ThunkConfig<any>
>(
    'archive_clients/fetch_waiting_for_approve_decline_client_managers',
    async (data, thunkApi) => {
        const { projectId, params } = data
        if (params) {
            delete params.lastStatusChanger
            delete params.manager
        }

        try {
            const res = await thunkApi.extra.api()
                .get<ApiResponseType>(`waiting-for-approve-clients/project/${projectId}/managers?` + prepareFilterParams(params))

            return { data: res.data, projectId }
        } catch (e) {
            handleServerNetworkError(e, thunkApi.dispatch, 'Не удалось получить список менеджеров клиента')
            return thunkApi.rejectWithValue(e)
        } finally {

        }
    }
)