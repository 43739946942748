import { MutableRefObject, useEffect, useRef, useState } from 'react'

type ReturnHookValue = [
  containerRef: MutableRefObject<HTMLDivElement | null>,
  isVisible: boolean
]

export const useIsInViewPort = (options: IntersectionObserverInit): ReturnHookValue => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  const [isVisible, setIsVisible] = useState<boolean>(false)

  const callBackFunction = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries
    setIsVisible(entry.isIntersecting)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(callBackFunction, options)
    if (containerRef.current) observer.observe(containerRef.current)

    return () => {
      if (containerRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(containerRef.current)
      }
    }
  }, [containerRef, options])


  return [containerRef, isVisible]
}