import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MailboxForDealSchema } from '../types/mailboxForDeal'
import { fetchMailBoxForDealForSelect } from '../services'
import { EmailErrorsType } from '../../emailSlice/types/emails'


const initialState: MailboxForDealSchema = {
  isSuccess: false,
  sendCheckEmailButtonStatus: null,
  getEmailsButtonStatus: null,
  mailBoxesForSelect: [],

  mailboxForDealErrors: [],
  mailboxForNewClientsErrors: []
}


export const mailboxForDealSlice = createSlice({
  name: 'mailbox_for_deal',
  initialState,
  reducers: {
    setGetEmailsButtonStatusStatus: (state, action: PayloadAction<boolean | null>) => {
      state.getEmailsButtonStatus = action.payload
    },
    setSendCheckEmailButtonStatus: (state, action: PayloadAction<boolean | null>) => {
      state.sendCheckEmailButtonStatus = action.payload
    },
    setIsSuccess: (state, action: PayloadAction<boolean>) => {
      state.isSuccess = action.payload
    },
    setErrorsForNewClients: (state, action: PayloadAction<any[]>) => {
      state.mailboxForNewClientsErrors = action.payload
    },
    setErrorsForDeal: (state, action: PayloadAction<EmailErrorsType[]>) => {
      state.mailboxForDealErrors = action.payload
    },
    clearErrorsForNewClients: (state) => {
      state.mailboxForNewClientsErrors = []
    },
    clearErrorsForDeal: (state) => {
      state.mailboxForDealErrors = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMailBoxForDealForSelect.fulfilled, (state, { payload }) => {
      state.mailBoxesForSelect = payload
    })
  }

})

export const { actions: mailboxForDealActions } = mailboxForDealSlice
export const { reducer: mailboxForDealReducer } = mailboxForDealSlice
