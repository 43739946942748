import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { IBaseClient } from '../../../../../types'


export const fetchClientDataListByEmails = createAsyncThunk<IBaseClient[],
  string[],
  ThunkConfig<any>>(
  'tracks/fetch_client_data_list_by_emails',
  async (emails, { dispatch, rejectWithValue, extra }) => {

    const query = emails.map(email => `emails[]=${email}`).join('&')
    try {
      const res = await extra.api().get(`/client/by-emails?${query}`)

      return res.data.clients

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось обновить продукт')
      return rejectWithValue({})
    }
  }
)