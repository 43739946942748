function getBase64(file: any) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            resolve(reader.result);
        };

        reader.onerror = error => reject(error);
    });
}




function getFileData(file: any) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

//   type ClbType = (Array<any>)=>void

export const getAllBaseFiles = (filesToBase: any, clb: any, doneFiles: Array<any>) => {
    if (filesToBase.length) {
        const fileWork = filesToBase.shift();
        getBase64(fileWork).then(baseData => {
            let newDoneFiles = [...doneFiles];
            newDoneFiles.push({
                name: fileWork.name,
                data: baseData,
                description: ''
            });
            getAllBaseFiles(filesToBase, clb, newDoneFiles);
        });
    } else {
        clb(doneFiles);
    }
};

export const getAllFiles = (filesToBase: any, clb: any, doneFiles: any) => {
    if (filesToBase.length) {
        const fileWork = filesToBase.shift();
        getFileData(fileWork).then(baseData => {
            let newDoneFiles = [...doneFiles];
            newDoneFiles.push({
                name: fileWork.name,
                data: baseData,
                description: ''
            });
            getAllFiles(filesToBase, clb, newDoneFiles);
        });
    } else {
        clb(doneFiles);
    }
};


export const convertFilesToBase64 = (files: any, clb: any, doneFiles:any) => {
    if (files.length) {
        const fileWork = files.shift();
        getBase64(fileWork.file).then(baseData => {
            let newDoneFiles = doneFiles ? [...doneFiles] : [];
            newDoneFiles.push({
                name: fileWork.file.name,
                data: baseData,
                description: fileWork.comment
            });
            convertFilesToBase64(files, clb, newDoneFiles);
        });
    } else {
        clb(doneFiles ? doneFiles : []);
    }
};

