import { makeStyles } from '@material-ui/core/styles';
import { ListItem, withStyles } from '@material-ui/core';

export const useStyles = makeStyles<{}, {}>({
  badgeIcon: {
    width: 18,
    height: 18,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    fill: '#fff',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.07)'
    },
    '& .MuiBadge-badge': {
      height: 14,
      minWidth: 12,
      padding: '0 4px'
    }
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px 10px'
  },
  card: {
    padding: '6px',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(153, 153, 153, 0.3)',
    backgroundColor: '#f5f8fd',
    fontSize: '12px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#ebedf0',
    }
  },
  selectedCard: {
    backgroundColor: '#a8c1f8',
    '&:hover': {
      backgroundColor: '#b0c3f8',
    }
  },
  content: {
    display: 'flex',
    color: 'black',
    alignItems: 'center'
  },
  image: {
    margin: '5px',
    '& > img': {
      height: '40px',
      borderRadius: '50%'
    }
  },
  message: {
    margin: '0 8px',
    textAlign: 'start'
  },
  time: {
    color: '#999'
  },
  checkBox: {
    '&.MuiCheckbox-root': {
      height: 20,
      width: 20,
    },
  },
  actions: {
    height: 42,
    padding: '5px 6px',
    display: 'flex',
    alignItems: 'center',
    gap: 15,
  },
  actionIconBox: {
    marginBottom: 5
  },
  removeBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    padding: 3,
    cursor: 'pointer',
    transition: 'all .3s',
    '& > svg': {
      marginBottom: 3,
      fill: '#757474',
    },
    '&:hover':{
      backgroundColor: '#f3f3f3',
      borderRadius: 4,
      '& > svg': {
        fill: '#5e5e5e',
      },
    }
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px 10px'
  }
});


export const CustomLIstItem = withStyles(() => ({
  root: {
    '&:hover': {
      color: '#40a9ff'
    }
  }
}))(ListItem);