import { ChangeEvent, FC, ReactNode, useCallback } from 'react'
import { Checkbox, createStyles, FormControlLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

export type CheckBoxWithIconProps = {
  label: string
  icon: ReactNode
  checkedIcon: ReactNode
  disabled?: boolean
  checked?: boolean
  onChange?: (checked: boolean) => void
  className?: string
}

export const CheckBoxWithIcon: FC<CheckBoxWithIconProps> = ({
  label,
  disabled,
  checked,
  onChange,
  icon,
  checkedIcon,
  className
}) => {
  const classes = useStyles()

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.currentTarget.checked)
    }, [onChange])

  return (
    <FormControlLabel className={`${classes.root} ${className}`}
                      control={
                        <Checkbox icon={icon}
                                  checkedIcon={checkedIcon}
                                  checked={checked}
                                  onChange={handleChange}
                                  disabled={disabled}
                        />
                      }
                      label={label}
    />
  )
}


const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '&.MuiFormControlLabel-root': {
        marginBottom: 0,
        marginRight: '20px',
        '& .MuiCheckbox-root': {
          marginRight: 3,
          border: '0.5px solid rgba(0,0,0,.1)',
          color: 'rgba(0,0,0,.1)',
          fill: 'rgba(0,0,0,.1)',
          height: 25,
          width: 25,
          borderRadius: 7
        },
        '& .MuiCheckbox-root.Mui-checked': {
          border: '0.5px solid #5383ff',
          color: '#5383ff',
          fill: '#5383ff',
        },
        '& .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1': {
          fontSize: '13px'
        }
      }
    }
  })
)