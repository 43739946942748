import { AppBar, Box } from '@material-ui/core'
import { HeaderLinks } from './components/HeaderLinks'
import { HeaderUserBox } from '../layout-components/HeaderUserBox'
import { makeStyles } from '@material-ui/core/styles'
import { HeaderLogo } from './components'
import React from 'react'

export const NavigationPanel = () => {

  const cls = useStyles()

  return (
    <>
      <AppBar
        position='fixed'
        className={cls.header}
        elevation={11}>

        <Box className={cls.toolBar}>
          <Box style={{display: 'flex', gap: 50}}>
            <Box className={cls.logoWrapper} title='MTR-CRM'>
              <HeaderLogo />
              {/*<Link to='/' className={cls.logoLink}>*/}
              {/*  <IconButton*/}
              {/*    color='primary'*/}
              {/*    size='medium'*/}
              {/*    className={cls.logoBtn}>*/}
              {/*    <img*/}
              {/*      className={cls.logoImg}*/}
              {/*      alt='MTR-CRM'*/}
              {/*      src={projectLogo}*/}
              {/*    />*/}
              {/*  </IconButton>*/}
              {/*</Link>*/}
              {/*<Hidden smDown/>*/}
              <Box className={cls.logoText}>MTR-CRM</Box>
              {/*</Hidden>*/}
            </Box>
            {/*</Hidden>*/}
            <HeaderLinks />
          </Box>


          <Box className={cls.userBox}>
            <HeaderUserBox />
          </Box>
        </Box>
      </AppBar>

    </>
  )
}

const useStyles = makeStyles({
  header: {
    height: 35,
    display: 'flex',
    background: '#3d4977',
    flexDirection: 'row',
  },
  toolBar: {
    height: '100%',
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 16,
    paddingRight: 16
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 10
  },
  logoLink: {
    textDecoration: 'none'
  },
  logoBtn: {
    padding: 8,
    background: 'rgba(255,255,255,.05)'
  },
  logoImg: {
    width: 28,
    height: 28
  },
  logoText: {
    fontSize: '.95rem',
    fontWeight: 'bold',
    width: 'auto',
    display: 'block'
  },
  userBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  }
})