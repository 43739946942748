import React from 'react';
import { SnackbarProvider } from 'notistack';
import { MyApp } from './MyApp';


const SnackbarsTracker = () => {

    return (
        <>
            <SnackbarProvider 
                maxSnack={3} 
                preventDuplicate={true}
                // hideIconVariant={true}
                autoHideDuration={10000}
            >
                <MyApp/>
            </SnackbarProvider>
        </>
    );
}


export default SnackbarsTracker;
