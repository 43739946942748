import React, { ChangeEvent, FC, useState } from 'react';
import { Icon, MuiPopover } from '../../../ui-kit';
import { Box, Checkbox, Typography } from '@material-ui/core';
import { useStyles } from '../styles';

interface HeaderUserNotificationProps {
  anchorEl: HTMLElement;
  onClose: () => void;
}

const noticeList = [
  {
    image: 'https://cutshort-data.s3.amazonaws.com/cloudfront/public/companies/5809d1d8af3059ed5b346ed1/logo-1615367026425-logo-v6.png',
    message: 'Notification one.',
    detailPage: '/events',
    receivedTime: '12h ago'
  },
  {
    image: 'https://cutshort-data.s3.amazonaws.com/cloudfront/public/companies/5809d1d8af3059ed5b346ed1/logo-1615367026425-logo-v6.png',
    message: 'Notification two.',
    detailPage: '/events',
    receivedTime: '18h ago'
  },
  {
    image: 'https://cutshort-data.s3.amazonaws.com/cloudfront/public/companies/5809d1d8af3059ed5b346ed1/logo-1615367026425-logo-v6.png',
    message: 'Notification three.',
    detailPage: '/events',
    receivedTime: '25h ago'
  }
];


export const HeaderUserNotification: FC<HeaderUserNotificationProps> = ({
                                                                          anchorEl,
                                                                          onClose
                                                                        }) => {

  const [data, setData] = useState(noticeList);
  const [selected, setSelected] = useState<any[]>([]);
  const cls = useStyles();

  const allSelected = data.length > 0 && selected.length === data.length;


  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (value === 'all') {
      setSelected(selected.length === data.length ? [] : data.map(el => el.message));
      return;
    }
    if (selected.indexOf(value) !== -1) {
      const newSelected = selected.filter(s => s !== value);
      setSelected(newSelected);
    } else {
      setSelected([...selected, value]);
    }
  };

  return (
    <MuiPopover
      anchorEl={anchorEl}
      name='HEADER_USER_NOTIFICATIONS'
      popoverId='header-notifications'
      onClose={onClose}

    >
      <Box style={{ width: 400, minHeight: 500 }}>
        <Box className={cls.header}>
          <Typography variant='subtitle2'>Уведомления</Typography>
        </Box>
        <Box className={cls.actions}>
          <Box>
            <Checkbox
              size='small'
              className={cls.checkBox}
              value='all'
              checked={allSelected}
              onChange={handleChange}
            />
            <Typography variant='caption'>выбрать все</Typography>
          </Box>
          {selected.length > 0 && (
            <Box className={cls.removeBox}>
              <Icon name='delete' size={14} />
              <Typography variant='caption'>Удалить</Typography>
            </Box>
          )}

        </Box>
        {data.map((el) => (
          <Box className={`${cls.card} ${selected.includes(el.message) ? cls.selectedCard : ''}`}>
            <Box className={cls.content}>
              <Checkbox size='small' className={cls.checkBox}
                        onChange={handleChange}
                        value={el.message}
                        checked={selected.includes(el.message) }/>
              <Box className={cls.image}>
                <img src={el.image} alt='notification-item' />
              </Box>
              <Box className={cls.message}>
                <Box className={cls.text}>{el.message}</Box>
                <Box className={cls.time}>{el.receivedTime}</Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      <Box className={cls.footer}>
        <Typography variant='caption'>Очистить все</Typography>
      </Box>
    </MuiPopover>
  );
};