import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { snackActions } from '../../snackSlice'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { fetchLetterFragments } from './fetchLetterFragments'
import { defaultPaginationParams } from '../slice/fragmentsSlice'

type RequestProps = {
  id: number
  params: any // todo types
}

export const copyFragment = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'fragments/copy_fragment',
  async (data, thunkApi) => {
    const { dispatch, rejectWithValue, getState, extra } = thunkApi
    const { id, params } = data
    const pagination = getState().fragments.fragmentPagination
    try {
      await extra.api().post(`letter-fragment/${id}/copy`, params)
     dispatch(fetchLetterFragments(!pagination.page ? defaultPaginationParams : pagination))

      dispatch(snackActions.setSnack({
        label: 'Фрагмент успешно скопирован',
        typeLabel: 'success'
      }))

    } catch (e) {

      handleServerNetworkError(e, dispatch, 'Произошла ошибка при копировании')
      return rejectWithValue({})

    } finally {
    }
  }
)