import { createAsyncThunk } from "@reduxjs/toolkit"
import { ThunkConfig } from "../../.."
import { UserMailboxForDealDataDto } from "../types/projectUser"
import { handleServerNetworkError } from "../../../../../helpers/error-helpers"
// import { fetchMailboxForNewClientsForSelect } from "../../clientsSlice/services"
import { fetchClientManagers } from "./fetchClientManagers"

type RequestProps = {
    managerId: number,
    data: UserMailboxForDealDataDto
}

export const addUserMailboxForNewClients = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'projectUser/delete_manager',
  async (request, { dispatch, rejectWithValue, getState, extra }) => {
    const { managerId, data } = request
    try {
      await extra.api().patch(`project-user/${managerId}/add-mailbox-for-new-clients`, data)

      // await dispatch(fetchMailboxForNewClientsForSelect(data.projectId))
      await dispatch(fetchClientManagers({projectId: data.projectId}))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось добавить email для спама')
      return rejectWithValue({})
    } finally {
    //   dispatch(projectUserActions.setIsDelete(false))
    }
  }
)