import {FC, memo, useState} from 'react'

import {Box, CircularProgress, createStyles, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import AttachmentOutlinedIcon from '@material-ui/icons/AttachmentOutlined'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import {openFile} from '../../../../../../../Utils/openFileInNewWindow'
import {useAppDispatch} from '../../../../../../../providers/StoreProvider/hooks/useAppDispatch'


type AttachFilePropsType = {
    file: any,
    removeAttachFile: (index: number) => void
    index: number,
    clientId?: number
};

export const AttachFile: FC<AttachFilePropsType> = memo(({file, removeAttachFile, index, clientId}) => {

    const classes = useStyles();

    const [load, setLoad] = useState<boolean>(false);

    const dispatch = useAppDispatch()

    const getPdf = async (file: any) => {
        setLoad(true)
        await openFile(dispatch, file, undefined, clientId)
        setLoad(false)
    }

    const handleDeleteFile = (index: number) => {
        removeAttachFile(index);
    };

    return (
        <Box className={classes.attach_files_wrapper}>
            <AttachmentOutlinedIcon className={classes.attach_icon}/>
            <Typography className={classes.typography}
                        onClick={() => getPdf(file)}
            >
                {file.name}
            </Typography>
            {load
                ? <CircularProgress className={classes.preloader}/>
                :
                !file.path ?
                    <HighlightOffIcon className={classes.delete_icon}
                                      onClick={() => handleDeleteFile(index)}/>
                    : null
            }
        </Box>
    );
});


const useStyles = makeStyles(() =>
    createStyles({
        attach_files_wrapper: {
            maxWidth: '320px',
            minWidth: '90px',
            minHeight: '32px',
            borderRadius: 5,
            border: '1px solid rgba(0,0,0,.1)',
            paddingLeft: 5,
            margin: '8px 16px 0 0',
            cursor: 'pointer',

            display: 'flex',
            alignItems: 'center',
            '&:hover': {
                border: '1px solid #5383ff',
                transition: 'all .3s',
            }
        },
        attach_icon: {
            width: 16,
            height: 16,
            margin: '0 3px 2px 0',
            transform: 'rotate(123deg)'
        },
        delete_icon: {
            width: 32,
            height: 32,
            padding: '7px 8px',
            cursor: 'pointer',
            '&:hover': {
                fill: 'blue'
            }
        },
        preloader: {
            width: 18,
            height: 18,
            padding: '12px 12px'
        },
        typography: {
            fontSize: 14,
            paddingRight: 5
        }
    })
);