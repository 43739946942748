import React, { ReactNode } from 'react'
import { TableFieldType } from '../types/field'
import { TableCell, TableRow } from '@material-ui/core'

type TableSubheaderItemRowPropsType = {
    fields: TableFieldType[]
    prefixParam?: string
}

const TableSubheaderItemRow:React.FC<TableSubheaderItemRowPropsType> = (props) => {
    const renderHeader = (field: TableFieldType): ReactNode => {
        if (field.subHeader) {
            return (
                <field.subHeader
                    data={field}
                    name={field.name}
                    label={field.label}
                    // refreshData={refreshData}
                    target={null}
                    // paginationParams={props.paginationParams}
                    fetch={fetch}
                    action={field.action ? field.action : undefined}
                    // isPublic={props.isPublic ?? field.isPublic}
                    managerType={field.managerType}
                    prefixParam={props.prefixParam}
                />
            )
        } else {
            return <></>
        }
    }

    return (
        <TableRow>
            {
                    props.fields.map((el, key) => {
                        return (
                            <TableCell key={key}>
                                {renderHeader(el)}
                            </TableCell>);
                    })
                }
        </TableRow>
    )
}

export default  TableSubheaderItemRow