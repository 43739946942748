import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { CommonLetterType } from '../../../../../Api/lettersApi'
import { AxiosResponse } from 'axios'
import { snackActions } from '../../snackSlice'
import { clientsActions } from '../../clientsSlice'
import { lettersActions } from '../../lettersSlice'

type RequestProps = {
  user_id: number,
  template_id: number | null,
  params: {
    snooze: boolean,
    snoozeTime: number,
    isSendAsAnswer: boolean,
    sendTime: number
  },
}

export const sendFetchTemplate = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'templates/send_fetch_template',
  async (data, { dispatch, rejectWithValue, extra }) => {

    const { template_id, user_id, params } = data

    try {
      const res = await extra.api().post<CommonLetterType[], AxiosResponse<CommonLetterType[]>,
        { snooze: boolean, snoozeTime: number, isSendAsAnswer: boolean, sendTime: number }>(`manager-letter/client/${user_id}/letter-template/${template_id}`, params)

      if (params.snooze) {
        dispatch(snackActions.setSnack({
          label: 'Клиент отправлен в "Отложенные"',
          typeLabel: 'success'
        }))
        //project_id && dispatch(clientThunks.fetchClientsByProject({ projectId: project_id }))
      } else {
        dispatch(lettersActions.setLetter({ letter: res.data }))
        dispatch(snackActions.setSnack({
          label: 'Шаблон был принят в отправку',
          typeLabel: 'success'
        }))
      }

      let hasEmptyMacrosInMessages = false
      res.data.forEach(letter => {
        if (letter.hasEmptyMacrosInMessages) {
          letter.clientId && !hasEmptyMacrosInMessages && dispatch(clientsActions.setEmptyMacros({
            clientId: letter.clientId,
            status: true
          }))
          hasEmptyMacrosInMessages = true
        }
      })

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось отправить шаблон')
      return rejectWithValue({})

    }
  }
)