import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { appActions } from '../../appSlice'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'
import { fetchArchiveProjects } from './fetchArchiveProjects'


interface RequestProps {
  id: number
  params: PaginationParamsType
}

export const restoreArchiveProject = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'archiveProjects/restoreArchiveProject',
  async (data, thunkApi) => {
    const { dispatch, rejectWithValue, extra } = thunkApi
    try {
      await extra.api().patch(`project-restore/${data.id}`)
      dispatch(snackActions.setSnack({
        label: 'Проект был успешно востановлен',
        typeLabel: 'success'
      }))

      /** Обновляем список архивных проектов */
      dispatch(fetchArchiveProjects(data.params))

      return data.id
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось восстановить проект')
      return rejectWithValue(e)
    } finally {
      dispatch(appActions.setIsFetchClientData(false))
    }
  }
)