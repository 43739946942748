import { createContext } from 'react'
import { IClientForProject } from '../../../../types'
import { ShowClientFilter } from '../LeadsProvider'
import { SelectFilterType } from '../../MainContentBlock/Leads/LeadHeader/HeaderBottom'

export interface LeadsContextProps {
  clients?: IClientForProject[]
  currentClient?: IClientForProject
  updateSearch?: (
    newClientId?: number,
    newClient?: IClientForProject,
    filter?: string,
    filterValue?: SelectFilterType | string
  ) => void
  showClientFilter?: ShowClientFilter
  onChangeFilter?: (filter: ShowClientFilter, clientId?: number) => void
  goToTheNextClient?: (clientId: number) => void
  resetState?: () => void
  setIsResetState?: (value: boolean) => void
  isEmptyClientsList: boolean
  isNotFoundClient: boolean
  notAccess: boolean
  filter?: {
    filterName: string
    filterValue: string
  },
  searchError:boolean
  showLetterSettings: boolean,
  onHandleSwitchLetterSettings?: () => void
}

export const LeadsContext = createContext<LeadsContextProps>({
  showClientFilter: 'active',
  isEmptyClientsList: false,
  isNotFoundClient: false,
  notAccess: false,
  searchError: false,
  showLetterSettings: false
})
