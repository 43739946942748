import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { prepareFilterParams } from '../../../../../Api/API'
import { CallType, callsActions } from '../../callSlice'
import { IBaseClient } from '../../../../../types'

type ResponseType = {
    
}

type ApiResponseType = {
    client: IBaseClient
    call: CallType
}

type RequestType = {
    clientPhone: string,
    /** Если true создаст экземпляр входящего телефонного вызова */
    createIncomingCall?: boolean
    projectUserPhone?: string
}

export const fetchClientByPhone = createAsyncThunk<
    ResponseType,
    RequestType,
    ThunkConfig<any>
>(
    'client/fetch_client_by_phone',
    async (data, thunkApi) => {
        try {
            const createIncomingCall = data.createIncomingCall
                ? `&createIncomingCall=1&projectUserPhone=${data.projectUserPhone}`
                : ''

            const res = await thunkApi.extra.api().get<ApiResponseType>(`client/by-phone?phone=${data.clientPhone}${createIncomingCall}`)
            console.log('fetchClientByPhone', res)

            thunkApi.dispatch(callsActions.setIncomingCallClientData(res.data.client))
            thunkApi.dispatch(callsActions.setActiveCallData(res.data.call))

            return res.data
        } catch (e) {
            handleServerNetworkError(e, thunkApi.dispatch, 'Не удалось получить клиента')
        } finally {
            
        }
    }
    
)