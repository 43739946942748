import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { EmailTargetType } from '../types/emails'
import { snackActions } from '../../snackSlice'
import { fetchEmails } from './fetchEmails'


type RequestProps = {
  target: EmailTargetType,
  data: any,
  emailId: number
}

// TODO Проверить логику санки обязательно !!!!
export const editEmail = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'emails/edit_email',
  async (req, { dispatch, getState, rejectWithValue, extra }) => {
    const emailsSlice = getState().emails
    const { emailId, data, target } = req

    const address = target === 'emailsForDealing' ? 'mailbox-for-deal/' : 'mailbox-for-new-clients/'

    // dispatch(emailsActions.setError(null))
    try {
      await extra.api().patch(address + emailId, data)

      emailsSlice.projectId && dispatch(fetchEmails({
        target: target,
        projectId: emailsSlice.projectId,
        params: emailsSlice[target]
      }))
      
      dispatch(snackActions.setSnack({
        label: 'Email успешно изменен',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось изменить данные')
      return rejectWithValue({})
    }

  }
)
