import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import * as queryString from 'query-string'


export const fetchMyClientsWidget = createAsyncThunk<any,
  any,
  ThunkConfig<any>>(
  'client/fetch_my_clients_widget',
  async (data, thunkApi) => {
    const { dispatch, rejectWithValue, extra } = thunkApi
    const query = queryString.stringify(data)
    try {
      const res = await extra.api().get(`me-accepted-clients?${query}`)
      return res.data
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить клиентов')
      return rejectWithValue({})
    }
  }
)