import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ArchiveClientsSchema, WaitingForApproveClientManagersType } from '../types/archiveClients'
import { DeclineClientType } from '../../../../../Redux/socket-io-handler'
import { fetchWaitingForApproveDeclineClients } from '../services'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'
import { fetchWaitingForApproveDeclineClientManagers } from '../services/fetchWaitingForApproveDeclineClientManagers'


const initialState: ArchiveClientsSchema = {
    paginationParams: { per_page: 15, page: 1 },
    isFetchData: false,
    waitingForApproveClientManagers: undefined
}

export const archiveClientsSlice = createSlice({
    name: 'archive_clients',
    initialState,
    reducers: {
        setWaitingForApproveClientManagers: (state, action: PayloadAction<WaitingForApproveClientManagersType | undefined>) => {
            state.waitingForApproveClientManagers = action.payload
        },
        setPaginationParams: (state, action: PayloadAction<PaginationParamsType>) => {
            state.paginationParams = action.payload
        },
        setIsFetchData: (state, action: PayloadAction<boolean>) => {
            state.isFetchData = action.payload
        },
        wsDeclineClient: (state, action: PayloadAction<DeclineClientType>) => {
            state.archiveClientList = state.archiveClientList ? [action.payload.client, ...state.archiveClientList] : [action.payload.client]
            state.totalCount = state.totalCount ? state.totalCount++ : 1
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchWaitingForApproveDeclineClients.fulfilled, (state, action) => {
            state.archiveClientList = action.payload.items.map(i => {
                return {
                    ...i,
                    letters: [
                        ...i.clientLetters,
                        ...i.managerLetters,
                        ...i.plannedManagerLetters
                    ]
                }

            })
            state.totalCount = action.payload.totalCount
        })
            .addCase(fetchWaitingForApproveDeclineClientManagers.fulfilled, (state, { payload }) => {
                state.waitingForApproveClientManagers = { ...payload.data, projectId: payload.projectId }
            })
    }
})

export const { actions: archiveClientsActions } = archiveClientsSlice
export const { reducer: archiveClientsReducer } = archiveClientsSlice
