import { FC } from 'react'

type SvgSelectorPropsType = {
    id: string,
    isHover?: boolean
    size?: number
};

export const SvgSelector: FC<SvgSelectorPropsType> = ({id, size, isHover}) => {
    switch (id) {
        case 'snooze':
            return (
                <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M13.467 12.169c.277-.329-.03-.794-.459-.786l-.13.001a6.557 6.557 0 0 1-6.129-8.89c.153-.403-.181-.848-.584-.698A6.56 6.56 0 0 0 8.453 14.5c2.011 0 3.81-.905 5.014-2.331Z"
                        fill="#222">
                    </path>
                    <path
                        d="M9.247 2.77a.77.77 0 0 1 .77-.77h3.422a.77.77 0 0 1 .61 1.238L11.577 6.46h1.863a.77.77 0 1 1 0 1.539h-3.423a.77.77 0 0 1-.61-1.238l2.473-3.223h-1.863a.77.77 0 0 1-.77-.77Z"
                        fill="#222">
                    </path>
                </svg>
            )
        case 'send-attach':
            return (
                <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1 2C1 1.44772 1.44772 1 2 1H8H10L13 4L15 5.5V10V17C15 18.1046 14.1046 19 13 19H2C1.44772 19 1 18.5523 1 18V2Z"
                        fill="white">
                    </path>
                    <path
                        d="M2.19604 2.89014C2.19604 2.61399 2.4199 2.39014 2.69604 2.39014H7.88169H10.6319C10.6931 2.39014 10.7538 2.40139 10.811 2.42335L12.0149 2.88545C12.0655 2.90489 12.1127 2.93245 12.1545 2.96704L12.5762 3.31604C12.5948 3.33144 12.6123 3.34817 12.6284 3.36609L13.0992 3.8873L13.3993 4.15378C13.5062 4.24867 13.5673 4.38475 13.5673 4.52766V6.2158V10.0415V17.1928C13.5673 17.4689 13.3435 17.6928 13.0673 17.6928H2.69604C2.4199 17.6928 2.19604 17.4689 2.19604 17.1928V2.89014Z"
                        fill="#F1FBFF">
                    </path>
                    <path
                        d="M9.89526 5.56469L9.97729 1.02539L14.6865 5.81753L9.89526 5.56469Z"
                        fill="white">
                    </path>
                    <path
                        d="M15 8.125V17.5C15 17.9973 14.8025 18.4742 14.4508 18.8258C14.0992 19.1775 13.6223 19.375 13.125 19.375H2.5C2.00272 19.375 1.52581 19.1775 1.17417 18.8258C0.822544 18.4742 0.625 17.9973 0.625 17.5V2.5C0.625 2.00272 0.822544 1.52581 1.17417 1.17417C1.52581 0.822544 2.00272 0.625 2.5 0.625H10.3125L15 5.3125V8.125ZM10 1.25V4.375C10 4.70641 10.1316 5.02426 10.3659 5.25866C10.6002 5.49306 10.918 5.62483 11.2494 5.625H14.375L10 1.25ZM14.375 6.25H11.2494C10.7521 6.25 10.2752 6.05246 9.92355 5.70083C9.57192 5.3492 9.37437 4.87228 9.37437 4.375V1.25H2.5C2.16848 1.25 1.85054 1.3817 1.61612 1.61612C1.3817 1.85054 1.25 2.16848 1.25 2.5V17.5C1.25 17.8315 1.3817 18.1495 1.61612 18.3839C1.85054 18.6183 2.16848 18.75 2.5 18.75H13.125C13.4565 18.75 13.7745 18.6183 14.0089 18.3839C14.2433 18.1495 14.375 17.8315 14.375 17.5V6.25Z"
                        fill="#8C96B1">
                    </path>
                    <mask id="__Image-File-Icon__" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="12"
                          height="16">
                        <path
                            d="M2.19604 2.89014C2.19604 2.61399 2.4199 2.39014 2.69604 2.39014H7.88169H10.6319C10.6931 2.39014 10.7538 2.40139 10.811 2.42335L12.0149 2.88545C12.0655 2.90489 12.1127 2.93245 12.1545 2.96704L12.5762 3.31604C12.5948 3.33144 12.6123 3.34817 12.6284 3.36609L13.0992 3.8873L13.3993 4.15378C13.5062 4.24867 13.5673 4.38475 13.5673 4.52766V6.2158V10.0415V17.1928C13.5673 17.4689 13.3435 17.6928 13.0673 17.6928H2.69604C2.4199 17.6928 2.19604 17.4689 2.19604 17.1928V2.89014Z"
                            fill="#F1FBFF">
                        </path>
                    </mask>
                    <g mask="url(#__Image-File-Icon__)">
                        <path
                            d="M11.0059 9.39404L7.07397 15.2713L4.57103 19.3752H19.309L11.0059 9.39404Z"
                            fill="#9CB8D8">
                        </path>
                        <path
                            d="M5.16216 7.82812L1.23022 13.7054L-1.27272 17.8093H13.4652L5.16216 7.82812Z"
                            fill="#CFE2F9">
                        </path>

                    </g>
                    <circle cx="4.5398" cy="5.04786" r="1.07716" fill="white"></circle>
                </svg>
            )
        case 'close-icon':
            return (
                <svg fill="rgb(13, 60, 97)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14px"
                     height="14px">
                    <path
                        d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"/>
                </svg>
            )
        default:
            return <></>
    }
}