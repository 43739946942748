import { FC, useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import { IChatMessage } from '../../../types/chat'


type PinnedMessagesProps = {
    pinnedMessages: IChatMessage[]
}

export const PinnedMessages: FC<PinnedMessagesProps> = ({pinnedMessages}) => {

    const [activePinnedMessage, setActivePinnedMessage] = useState<IChatMessage>()
    const [clickFromPinned, setClickForPinned] = useState<boolean>(false)

    const havePinned = pinnedMessages.length > 0
    const isOwner = activePinnedMessage?.isMyMessage

    const classes = useStyles({havePinned, isOwner})

    const handleClick = () => setClickForPinned(true)

    useEffect(() => {
        setActivePinnedMessage(pinnedMessages[0])
    }, [pinnedMessages])

    useEffect(() => {
        if (clickFromPinned) {
            const index = pinnedMessages.findIndex(el => el.id === activePinnedMessage?.id)
            if (index < pinnedMessages.length) setActivePinnedMessage(pinnedMessages[index + 1])
            if (index === pinnedMessages.length - 1) setActivePinnedMessage(pinnedMessages[0])
            const element = document.getElementById(String(activePinnedMessage?.id))
            element && element.scrollIntoView()
            element?.classList.add(classes.activePinMessage)
            setTimeout(() => {
                element?.classList.remove(classes.activePinMessage)
                setClickForPinned(false)
            }, 500)
        }
    }, [clickFromPinned])

    return (
        <>
            {pinnedMessages.length > 0 && activePinnedMessage && (
                <Box className={classes.pinnedContentBlock}
                     onClick={handleClick}>
                    <Box className={classes.pinnedContent}>
                        <Typography className={classes.title}>Закрепленные
                            сообщения{` (${pinnedMessages.length})`}</Typography>
                        <Typography className={classes.pinnedMessage}>
                            {activePinnedMessage.message}
                        </Typography>
                    </Box>
                </Box>
            )}
        </>
    )
}

const useStyles = makeStyles<{}, { havePinned: boolean, isOwner?: boolean }>({
    pinnedContentBlock: {
        position: 'absolute',
        width: '100%',
        zIndex: 10,
        top: 50,
        overflowY: 'hidden',
        transition: 'all 0.3s',
        padding: '5px 10px',
        borderBottom: '0.5px solid rgba(0,0,0,.1)',
        cursor: 'pointer',
        height: ({havePinned}) => havePinned ? 50 : 0
    },
    pinnedContent: {
        padding: '5px 5px',
        height: '40px',
        borderLeft: '1px solid #5383ff'
    },
    title: {
        color: '#5383ff',
        marginBottom: '5px',
        lineHeight: 1
    },

    pinnedMessage: {
        display: 'inline-block',
        width: '320px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineHeight: 1
    },
    activePinMessage: {
        backgroundColor: '#e3f3ff',
        borderRadius: '12px'
    }
})