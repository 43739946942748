import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { ProjectNotesType } from '../types/notes'
import { appActions } from '../../appSlice'


type FullFilledResponse = {
  projectId: number | null
  projectNotes: ProjectNotesType[]
}

export const fetchProjectNotes = createAsyncThunk<FullFilledResponse,
  number | null,
  ThunkConfig<any>>(
  'notes/fetch_project_notes',
  async (projectId, { dispatch, rejectWithValue, extra }) => {

    dispatch(appActions.setStatus('loading'))
    try {
      if (projectId === null) {
        // Todo проверить как отрабатывает
        //dispatch(notesActions.setProjectNotesAction({ projectId : null, projectNotes: []}))
        return { projectId: null, projectNotes: [] }
      } else {
        const response = await extra.api().get<ProjectNotesType[]>(`project-notes/project/${projectId}`)
        return { projectId, projectNotes: response.data }
      }

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить информацию о заметках')
      return rejectWithValue({})
    } finally {
      dispatch(appActions.setStatus('success'))
    }
  }
)