import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { ProjectSettings } from '../../../../../types'
import { snackActions } from '../../snackSlice'


type RequestProps = {
  data: ProjectSettings,
  projectId: string
}


export const saveProjectsChainsSettings = createAsyncThunk<ProjectSettings,
  RequestProps,
  ThunkConfig<any>>(
  'projects/save_project_chains_settings',
  async (dto, { dispatch, rejectWithValue, extra }) => {

    const { projectId, data } = dto

    try {
      await extra.api().patch(`project/${projectId}/chains-setting`, data)

      dispatch(snackActions.setSnack({
        label: 'Данные успешно сохранены',
        typeLabel: 'success'
      }))
        return data

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось сохранить данные')
      return rejectWithValue({})
    }
  }
)