import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { snackActions } from '../../snackSlice'
import { fetchClientManagers } from './fetchClientManagers'
import { projectUserActions } from '../slice/projectUserSlice'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'


type RequestProps = {
  userId: number,
  mailboxId: number
}

export const addMailboxForDeal = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'projectUser/add_mailbox_for_deal',
  async (dto, { dispatch, rejectWithValue, getState, extra }) => {

    const { mailboxId, userId } = dto

    const projectId = getState().projectUser.project_id
    dispatch(projectUserActions.setIsAdd(true))
    try {
      await extra.api().patch(`project-user/${userId}/add-mailbox-for-deal`, { mailbox: mailboxId })
      dispatch(snackActions.setSnack({
        label: 'Имэйл был успешно добавлен',
        typeLabel: 'success'
      }))
      projectId && await dispatch(fetchClientManagers({projectId}))
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось добавить имейл')
      return rejectWithValue({})
    } finally {
      dispatch(projectUserActions.setIsAdd(false))
    }
  }
)