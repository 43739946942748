import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { ParamsType } from '../../../../../Pages/LeadsManagePage/MainContentBlock/ManageLeadsLetters/ManageLeadsLettersContent/Letters/MessagesFeedWithClient/MessagesFeedWithClient'
import { FetchClientsMessagesResponse } from '../types/letters'
import { FilteredValueType } from '../../../../../ui-kit'
import { lettersActions } from '../slice/lettersSlice'

type RequestProps = {
  clientId?: number,
  params: ParamsType,
  isFilter: boolean
}

type FullFilledResponse = {
  data: FetchClientsMessagesResponse
  page: number
  filter: FilteredValueType
  isFilter: boolean
}

export const fetchClientMessageFeed = createAsyncThunk<FullFilledResponse,
  RequestProps,
  ThunkConfig<any>>(
  'letters/fetch_client_messages_feed',
  async (data, { dispatch, rejectWithValue, extra }) => {
    const { clientId, isFilter, params, } = data
    const { page, filter, per_page } = params

    dispatch(lettersActions.setIsLoading(true))
    try {
      const response = await extra.api()
        .get<FetchClientsMessagesResponse>(
          `client/${clientId}/messages?page=${page}&per_page=${per_page}&filter=${filter}`

        )
      return {
        data: response.data,
        page,
        filter,
        isFilter
      }
    } catch (e: any) {
      handleServerNetworkError(e, dispatch, 'Не удалось загрузить ленту')
      return rejectWithValue({})
    } finally {
      dispatch(lettersActions.setIsFetching(false))
      dispatch(lettersActions.setIsLoading(false))
    }
  }
)