import { Link } from 'react-router-dom'


export const getLink = (projectId: number | null, clientId?: number, snooze?: boolean, callBack?: () => void): JSX.Element => {
    return (
        <Link to={`/project/${projectId}/leads_manage?client=${clientId}`}
              onClick={callBack}
              style={{ color: '#5383ff', textDecoration: 'underLine' }}
        >
            Перейти
        </Link>
    )
}