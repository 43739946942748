import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { CallStatusType, CallType } from '../types/calls'
import { callsActions } from '../slice/callSlice'
import { lettersActions } from '../../lettersSlice'


type RequestProps = {
  callId: number,
  status: CallStatusType
  sid?: string
}


export const updateCall = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'call/update_call',
  async (data, { dispatch, extra, rejectWithValue }) => {

    const { status, callId, sid } = data

    if (status === 'FAILED') {
      dispatch(callsActions.setActiveCallData(undefined))
    }
    try {
      const res = await extra.api().patch<CallType>(`call/${callId}/update`, {
        status: status,
        sid: sid
      })
      /** обновляем данные в списке сообщений */
      dispatch(lettersActions.updateNewCallInGeneralMessageFeed(res.data))
      
      dispatch(callsActions.setActiveCallData(res.data))
    } catch (e) {
      return rejectWithValue({})
    }


  }
)