import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'


type RequestType = {
  name: string,
  type: any,
  positionAndSize: any,
  data: any
}

export const createWidget = createAsyncThunk<{},
  RequestType,
  ThunkConfig<any>>(
  'widgets/create_widget',
  async (data, { dispatch, rejectWithValue, extra }) => {

    try {
      const res = await extra.api().post(`user-widget`, data)

      console.log(res)
    } catch (e) {
      return rejectWithValue({})
    } finally {
    }
  }
)