import React, { FC } from 'react'
import { ScaleLoader } from 'react-spinners'
import { Backdrop } from '@material-ui/core'
import { useStyles } from './styles'


type PropsType = {
  open?: boolean
}

export const Loader: FC<PropsType> = ({ open }) => {

  const classes = useStyles()

  return (
    <Backdrop
      className={classes.backdrop}
      open={open || true}
    >
      <ScaleLoader color={'var(--primary)'} loading={true} />
    </Backdrop>
  )
}
