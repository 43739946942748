import { FC, MouseEvent, useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Box, CircularProgress, Typography } from '@material-ui/core'

import { ChatMessageInfo } from './ChatMessageInfo'
import { ContextMenu } from '../../ContextMenu/ContextMenu'
import { useContextMenu } from '../../hooks/useContextMenu'
import { openFile } from '../../../../Utils/openFileInNewWindow'
import { SvgSelector } from '../../../../ui-kit'
import { useAppDispatch } from '../../../../providers/StoreProvider/hooks/useAppDispatch'
import { IChatMessage } from '../../../../types/chat'
import { useIsInViewPort } from '../../../../helpers/hooks/useIsInViewPort'

type ChatMessageItemPropsType = {
  message: IChatMessage
  addUnreadMessage: (ids: number) => void
}

export const ChatMessageItem: FC<ChatMessageItemPropsType> = ({ message, addUnreadMessage }) => {
  const [load, setLoad] = useState<boolean>(false)

  const dispatch = useAppDispatch()

  const { clicked, setClicked, points, setPoints } = useContextMenu()
  const [containerRef, isVisible] = useIsInViewPort({
    root: null,
    rootMargin: '0px',
    threshold: 1
  })

  const { isMyMessage, isSent, isRead } = message

  const hasFiles = message.files.length > 0

  const classes = useStyles({ isMyMessage, hasFiles, isRead })

  const getPdf = async (file: any) => {
    setLoad(true)
    await openFile(dispatch, file)
    setLoad(false)
  }

  const onContextMenu = (e: MouseEvent<HTMLDivElement>): void => {
    e.preventDefault()
    setPoints({ x: e.pageX, y: e.pageY })
    setClicked(true)
  }

  const handleOpenFilePreview = () => {
    isSent && getPdf(message.files[0])
  }

  useEffect(() => {
    !isMyMessage && !message.isRead && isVisible && addUnreadMessage(message.id)
  }, [isMyMessage, addUnreadMessage, isVisible, message.id, message.isRead])


  return (
    <div ref={containerRef}>
      <Box className={classes.message_row} id={String(message.id)}>
        <Box className={classes.message}
             onContextMenu={onContextMenu}
        >
          {message.files.length > 0
            ?
            <>
              {message.files.length > 0 && (
                <Box style={{ display: 'flex', paddingLeft: '45px' }}>
                  <Typography className={classes.message_content}>
                    {message.message}
                  </Typography>
                </Box>
              )}
              <Box className={classes.attachFileContainer}>
                <Box className={classes.fileIconContainer}
                     onClick={handleOpenFilePreview}>
                  {load ? <CircularProgress className={classes.preloader} /> :
                    <SvgSelector id='send-attach' />}

                </Box>
                <Box className={classes.attachFileMessageContent}>
                  <Typography className={classes.message_content}>
                    {message.files[0].name}
                  </Typography>
                </Box>
              </Box>
            </>
            :
            <>
              <Typography className={classes.message_content}>{message.message}</Typography>
              {clicked && (
                <ContextMenu points={points}
                             setClicked={setClicked}
                             message={message}
                />
              )}
            </>
          }
        </Box>
        <ChatMessageInfo isMyMessage={isMyMessage} message={message} />
      </Box>
    </div>
  )
}


export const useStyles = makeStyles<{}, { isMyMessage?: boolean, hasFiles: boolean, isRead: boolean }>({
  message_row: {
    maxWidth: '100%',
    minHeight: '30px',
    padding: 5,
    marginBottom: '10px',
    backgroundColor: ({isRead}) => !isRead ? 'rgb(246,250,253)' : '',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: ({ isMyMessage }) => isMyMessage ? 'flex-end' : 'flex-start',
    transition: 'all .2s',
  },
  message: {
    maxWidth: '73%',
    padding: '10px 16px 8px',
    wordBreak: 'break-all',
    borderRadius: ({ isMyMessage }) => isMyMessage
      ? '12px 12px 0 12px'
      : '12px 12px 12px 0',
    background: ({ isMyMessage }) => isMyMessage
      ? 'linear-gradient(351.81deg,#767d85 -23.59%,#9ba2ab 63.4%)'
      : 'rgba(242,243,247,1)'
  },
  message_content: {
    color: ({ isMyMessage }) => isMyMessage
      ? 'rgba(255,255,255,1)'
      : 'rgba(27,39,51,.9)',
    fontSize: '10.5pt',
    lineHeight: '19px',
    textAlign: 'left',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word'
  },
  attachFileContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  attachFileMessageContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  fileIconContainer: {
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: 'rgba(255,255,255,1)',
    cursor: 'pointer',
    marginRight: 8,
    flex: 'none'
  },
  preloader: {
    width: 20,
    height: 20,
    padding: '12px 12px'
  }
})
