import { FC, MouseEvent } from 'react'
import { useSelector } from 'react-redux'

import { Badge, Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { CustomTooltip } from '../../ui-kit'
import { ChatType, ClientChat, GeneralChat, PrivateChat } from '../../types'
import { useAppDispatch } from '../../providers/StoreProvider/hooks/useAppDispatch'
import { chatsActions, chatSelectors } from '../../providers/StoreProvider/slices/chatsSlice'

type ActiveChatListPropsType = {}

export const ActiveChatList: FC<ActiveChatListPropsType> = () => {

  const activeChatList = useSelector(chatSelectors.getActiveChatList)
  const classes = useStyles()
  const dispatch = useAppDispatch()

  const deleteChat = (e: MouseEvent<SVGSVGElement>, id: number): void => {
    e.stopPropagation()
    dispatch(chatsActions.deleteActiveChat({ id, isOpen: false }))
  }

  const handleOpenCollapsedChat = (id: number): void => {
    dispatch(chatsActions.openCollapsedChat(id))
  }

  const handleCloseAllChats = () => {
    dispatch(chatsActions.clearChats())
  }

  const getChatTitle = (chat: ClientChat | GeneralChat | PrivateChat) => {
    if (chat.type === ChatType.CLIENT) return `${chat.clientFirstName} ${chat.clientLastName}`
    if (chat.type === ChatType.PRIVATE) return chat.name
    else return 'Общий'
  }

  return (
    <Box hidden={activeChatList.length === 0} className={classes.chat_container}>
      {activeChatList.length > 0 && activeChatList.map((el, i) => {
        const unreadMessagesLength = el.messages.filter(m => !m.isRead).length
        return (
          <Box key={el.id + '-' + i}
               onClick={() => handleOpenCollapsedChat(el.id)}
               className={`${classes.active_chat_item} ${el.needToNotify ? classes.notification : ''}`}
          >
            <Typography variant='subtitle2' className='typography'>
              {getChatTitle(el)}
            </Typography>
            <CustomTooltip title='закрыть чат'>
              <CloseIcon className={classes.close_icon}
                         onClick={(e) => deleteChat(e, el.id)}
              />
            </CustomTooltip>
            <Badge badgeContent={unreadMessagesLength} color='primary' className={classes.badge}
                   overlap='rectangular' />
          </Box>
        )
      })}
      <Box className={classes.closeAllChatsBox} onClick={handleCloseAllChats}>
        <CloseIcon className={classes.close_all_icon} />
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  chat_container: {
    position: 'fixed',
    width: '100%',
    bottom: 0,
    minHeight: 40,
    padding: '0 5px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 5,
    alignItems: 'center',
    backgroundColor: 'rgb(161 161 161 / 70%)',
    transition: 'all 0.4s',
    zIndex: 100
  },

  active_chat_item: {
    height: '30px',
    minWidth: '140px',
    maxWidth: '150px',
    position: 'relative',
    backgroundColor: '#e1dede',
    borderRadius: 3,
    padding: '5px 5px 5px 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fff',
      transition: 'all .3s'
    },
    '& .typography': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  },
  close_icon: {
    width: 20,
    height: 20,
    cursor: 'pointer',
    padding: 3,
    color: 'red',
    borderRadius: '50%',
    transition: 'background .3s ',
    '&:hover': {
      backgroundColor: '#e7e4e4',
      borderRadius: '50%'
    }
  },
  notification: {
    animation: ' blinker 1.5s linear infinite',
    backgroundColor: '#fff'
  },
  closeAllChatsBox: {
    marginLeft: 'auto',
    height: 20,
    width: 25,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all .3s ',
    '&:hover': {
      borderRadius: 3,
      backgroundColor: 'rgb(132 132 132)',
      '& .MuiSvgIcon-root': {
        padding: 5
      }
    }
  },
  close_all_icon: {
    color: '#fff',
    padding: ' 3px'
  },
  badge: {
    position: 'absolute',
    top: 2,
    right: 1,
    '& > span': {
      width: 20,
      height: 20,
      borderRadius: '50%',
      fontSize: '10px'
    }
  }
})
