import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { FetchChainsResponse } from '../types/chains'
import { prepareFilterParams } from '../../../../../Api/API'
import { chainsActions } from '../slice/chainsSlice'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'


export const fetchChains = createAsyncThunk<FetchChainsResponse,
  PaginationParamsType | undefined,
  ThunkConfig<any>>(
  'chains/fetch_chains',
  async (params, thunkApi) => {

    const { dispatch, getState, rejectWithValue, extra } = thunkApi

    const myChains = getState().chains.chainList
    !myChains && dispatch(chainsActions.setIsFetchMyChainsData(true))

    try {
      const response = await extra.api().get(`chains-letters?${prepareFilterParams(params)}`)
      params && dispatch(chainsActions.setMyChainsParams(params))

      return response.data
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Невозможно загрузить мои цепочки')
      return rejectWithValue({})
    } finally {
      dispatch(chainsActions.setIsFetchMyChainsData(false))
    }
  }
)