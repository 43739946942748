import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { callsActions } from '../slice/callSlice'


export const fetchAvailableNumbers = createAsyncThunk<string[],
  number | undefined,
  ThunkConfig<any>>(
  'call/fetch_available_numbers',
  async (externalServiceId, { extra, dispatch, rejectWithValue }) => {

    dispatch(callsActions.setIsFetching(true))
    try {
      const res = await extra.api().get(`get_available_numbers/${externalServiceId}`)

      if (res.data.numbers) {
        dispatch(callsActions.setIsTwilioAvailable(true))
        return res.data.numbers
      } else {
        dispatch(callsActions.setIsTwilioAvailable(false))
      }
    } catch (e) {
      return rejectWithValue({})
    } finally {
      dispatch(callsActions.setIsFetching(false))
    }


  }
)