import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProductNotesSchema, ProductNoteType } from '../types/productNotes'
import { fetchProductNotesForProject } from '../services'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'


const initialState: ProductNotesSchema = {
  productNotesTablePaginationParams: { per_page: 15, page: 1 },
  isFetchData: false,
  productNotesList: undefined
}

export const productNotesSlice = createSlice({
  name: 'productNotes',
  initialState,
  reducers: {
    setProductNotesList: (state, action: PayloadAction<ProductNoteType[] | undefined>) => {
      state.productNotesList = action.payload
    },
    isFetchData: (state, action: PayloadAction<boolean>) => {
      state.isFetchData = action.payload
    },
    setParams: (state, action: PayloadAction<PaginationParamsType>) => {
      state.productNotesTablePaginationParams = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductNotesForProject.fulfilled, (state, { payload }) => {
      state.productNotesList = payload.items
      state.totalCount = payload.totalCount
    })
  }
})

export const { actions: productNotesActions } = productNotesSlice
export const { reducer: productNotesReducer } = productNotesSlice
