import { createSlice } from '@reduxjs/toolkit'
import { WidgetsSchema } from '../types/widgets'
import { fetchAllWidget } from '../services'


const initialState: WidgetsSchema = {
  widgetList: []
}


export const widgetsSlice = createSlice({
  name: 'widgets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllWidget.fulfilled, (state, { payload }) => {
      state.widgetList = payload
    })
  }

})

export const { actions: widgetsActions } = widgetsSlice
export const { reducer: widgetsReducer } = widgetsSlice
