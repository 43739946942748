import { ReactComponent as GroupMessage } from '../../assets/svg/mail-checkmark-icon.svg'
import { ReactComponent as ThreeDots } from '../../assets/svg/3-horizontal-dots.svg'
import { ReactComponent as MailClose } from '../../assets/svg/mail-close-icon.svg'
import { ReactComponent as Schedule } from '../../assets/svg/daily-schedule.svg'
import { ReactComponent as Dot } from '../../assets/svg/ellipse-shape-icon.svg'
import { ReactComponent as Mail } from '../../assets/svg/mail.svg'
import { ReactComponent as Done } from '../../assets/svg/done.svg'
import { ReactComponent as Check } from '../../assets/svg/check-time.svg'
import { ReactComponent as Remove } from '../../assets/svg/remove-icon.svg'
import { ReactComponent as Info } from '../../assets/svg/information-outline-icon.svg'
import { ReactComponent as Notice } from '../../assets/svg/exclamation-round-icon.svg'
import { ReactComponent as Next } from '../../assets/svg/right-chevron-arrow-round-outline-icon.svg'
import { ReactComponent as Prev } from '../../assets/svg/left-chevron-arrow-round-outline-icon.svg'
import { ReactComponent as Help } from '../../assets/svg/question-mark-circle-outline-icon.svg'
import { ReactComponent as GroupChat } from '../../assets/svg/group-discussion-icon.svg'
import { ReactComponent as Pin } from '../../assets/svg/pin.svg'
import { ReactComponent as Note } from '../../assets/svg/checklist-icon.svg'
import { ReactComponent as Client } from '../../assets/svg/corporate-user-icon.svg'
import { ReactComponent as Manager } from '../../assets/svg/project-management-icon.svg'
import { ReactComponent as Random } from '../../assets/svg/random-icon.svg'
import { ReactComponent as Date } from '../../assets/svg/wall-clock-icon.svg'
import { ReactComponent as SuccessBulb } from '../../assets/svg/creative-idea-icon.svg'
import { ReactComponent as ArrowDown} from '../../assets/svg/arrow-down-icon.svg'
import { ReactComponent as RemoveWithoutCircle} from '../../assets/svg/remove2.svg'
import { ReactComponent as DoneBold} from '../../assets/svg/small-check-mark-icon.svg'
import { ReactComponent as Pencil} from '../../assets/svg/pencil-icon.svg'
import { ReactComponent as Discussion} from '../../assets/svg/group-discussion.svg'
import { ReactComponent as Move} from '../../assets/svg/pull-right-icon.svg'
import { ReactComponent as Bell} from '../../assets/svg/bell.svg'
import { ReactComponent as Delete} from '../../assets/svg/delete.svg'
import { ReactComponent as WaitToSend} from '../../assets/svg/clock-line-icon.svg'
import { ReactComponent as Phone} from '../../assets/svg/phone-call.svg'
import { ReactComponent as NotFoundClient} from '../../assets/svg/not-found-client.svg'
import { ReactComponent as NoAccess} from '../../assets/svg/no-access.svg'
import { ReactComponent as Settings} from '../../assets/svg/settings.svg'
import { ReactComponent as Notes} from '../../assets/svg/notes.svg'
import { ReactComponent as Send} from '../../assets/svg/sent-mail-icon.svg'
import { ReactComponent as PhoneFilled} from '../../assets/svg/phone-icon.svg'
import { ReactComponent as Sender} from '../../assets/svg/attherate-icon.svg'

export default {
  groupMessage: GroupMessage,
  mailClose: MailClose,
  schedule: Schedule,
  threeDots: ThreeDots,
  dot: Dot,
  mail: Mail,
  done: Done,
  check: Check,
  remove: Remove,
  info: Info,
  notice: Notice,
  next: Next,
  prev: Prev,
  help: Help,
  groupChat: GroupChat,
  note: Note,
  client: Client,
  manager: Manager,
  random: Random,
  date: Date,
  bulb: SuccessBulb,
  arrowDown: ArrowDown,
  removeWithoutCircle: RemoveWithoutCircle,
  doneBold: DoneBold,
  pencil: Pencil,
  pin: Pin,
  discussion: Discussion,
  move: Move,
  bell: Bell,
  delete: Delete,
  waitToSend: WaitToSend,
  phone: Phone,
  phone_filled: PhoneFilled,
  notFoundClient: NotFoundClient,
  noAccess: NoAccess,
  settings: Settings,
  notes: Notes,
  send: Send,
  sender: Sender
}