import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ArchiveProjectsSchema } from '../types/archiveProjects'
import {
  fetchArchiveProjects,
  deleteArchiveProject,
  restoreArchiveProject } from '../services/'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'

const initialState: ArchiveProjectsSchema = {
  projectPagination: {per_page: 15, page: 1},
}

export const archiveProjectsSlice = createSlice({
  name: 'archive_projects',
  initialState,
  reducers: {
    setParams: (state, action: PayloadAction<PaginationParamsType>) => {
      state.projectPagination = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchArchiveProjects.fulfilled, (state, action) => {
      state.projectsList = action.payload.items
      state.totalCount = action.payload.totalCount
    })
    builder.addCase(deleteArchiveProject.fulfilled, (state, action) => {
      state.projectsList?.filter( el => el.id !== action.payload)
    })
    builder.addCase(restoreArchiveProject.fulfilled, (state, action) => {
      state.projectsList?.filter( el => el.id !== action.payload)
    })
  }
})

export const { actions: archiveProjectsActions } = archiveProjectsSlice
export const { reducer: archiveProjectsReducer } = archiveProjectsSlice
