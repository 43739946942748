import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { appActions } from '../../appSlice'
import { ProjectForCurrentItemType } from '../../tempaltesSlice'

interface RequestProps {
  id: number
  name?: string
}

type FullFilledResponse = {
  items: ProjectForCurrentItemType[]
}

export const fetchProjectListForCurrentChain = createAsyncThunk<FullFilledResponse,
  RequestProps,
  ThunkConfig<any>>(
  'chains/fetch_project_list_for_current_chain',
  async (data, thunkApi) => {
    const { id, name } = data
    const { dispatch, rejectWithValue, extra } = thunkApi
    dispatch(appActions.setIsLoad(true))

    try {
      const response = await extra.api().get(`chain-letters/${id}/projects?filter[name]=${name}`)
      return response.data
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось загрузить список проектов')
    return rejectWithValue({})
    } finally {
      dispatch(appActions.setIsLoadTable(false))
      dispatch(appActions.setIsLoad(false))
    }
  }
)