import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { PhoneType } from '../types/projectPhones'
import { snackActions } from '../../snackSlice'
import { projectPhonesActions } from '../slice/projectPhonesSlice'
import { fetchProjectPhonesForSelect } from './fetchProjectPhonesForSelect'


type RequestProps = {
  phoneId: number,
  phone: string,
  projectId: number
}

export const updatePhone = createAsyncThunk<number,
  RequestProps,
  ThunkConfig<any>>(
  'projectPhones/update_phone',
  async (data, { dispatch, rejectWithValue, extra }) => {

    const { phoneId, phone, projectId } = data
    dispatch(projectPhonesActions.setIsLoad(true))
    try {
      await extra.api().patch<PhoneType[]>(`project/phones-for-select/${phoneId}`, {
        number: phone,
        project: projectId
      })
      dispatch(fetchProjectPhonesForSelect(projectId))

      dispatch(snackActions.setSnack({
        label: 'Телефон успешно изменен',
        typeLabel: 'success'
      }))
      return projectId
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'При изменении телефона произошла ошибка')
      return rejectWithValue({})
    } finally {
      dispatch(projectPhonesActions.setIsLoad(false))
    }
  }
)