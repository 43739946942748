import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { clientThunks } from '../../clientsSlice'
import { tracksActions } from '../slice/tracksSlice'


type RequestProps = {
  params: any, // todo types
  clientId?: number
}

export const updateTrack = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'tracks/update_track',
  async (dto, { dispatch, rejectWithValue, extra }) => {

    const { clientId, params } = dto

    dispatch(tracksActions.setIsLoad(true))
    try {
      await extra.api().post(`track/${params.trackId}`, params)
      clientId && dispatch(clientThunks.fetchClientData({ clientId }))
      dispatch(snackActions.setSnack({
        label: 'Трекномер был успешно обновлен',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось обновить трекномер')
      return rejectWithValue({})
    } finally {
      dispatch(tracksActions.setIsLoad(false))
    }
  }
)