import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { MailboxForDealForSelectType } from '../types/clients'

export const fetchMailboxForDealForSelect = createAsyncThunk<
  MailboxForDealForSelectType[],
  number,
  ThunkConfig<any>>(
  'client/fetch_mailbox_for_deal_for_select',
  async (projectId, thunkApi) => {
    const { dispatch, rejectWithValue, extra } = thunkApi
    try {
      const response = await extra.api().get(`mailboxes-for-deal-for-select/project/${projectId}`)
      return response.data
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить email для связи с клиентом')
      return rejectWithValue({})
    }
  }
)