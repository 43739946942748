import React, { FC, ReactNode, useMemo } from 'react';
import { TableCell, TableRow, TableSortLabel } from '@material-ui/core';
import { SortDirectionType } from '../types/query';
import { TableFieldType } from '../types/field';
import { useSearchParams } from 'react-router-dom';

type TableItemRowPropsType = {
    fields: Array<TableFieldType>
    prefixParam?: string
};

export const TableItemRow: FC<TableItemRowPropsType> = (props) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const sort = props.prefixParam ? `${props.prefixParam}sort` : 'sort'
    const direction = props.prefixParam ? `${props.prefixParam}direction` : 'direction'


    const styles = useMemo(() => {
        return !!props.fields.find(i => i.subHeader) ? { border: 'none' } : {}
    }, [props.fields])


    const createSortHandler = (property?: string) => {
        if (property) {
            const isAsc = searchParams.get(sort) === property && searchParams.get(direction) === 'asc';
            const sortValue = isAsc ? 'desc' : 'asc'
            searchParams.set(direction, sortValue)
            searchParams.set(sort, property)
            setSearchParams(searchParams)
        }
    }

    const renderHeader = (field: TableFieldType): ReactNode => {
        if (field.sortName) {
            return <TableSortLabel
                active={searchParams.get(sort) ? searchParams.get(sort) === field.sortName : false}
                direction={
                    searchParams.get(sort) === field.sortName && searchParams.get(direction)
                        ? searchParams.get(direction) as SortDirectionType
                        : 'asc'
                }
                onClick={() => {
                    createSortHandler(field.sortName);
                }}
            >
                {field.label}
            </TableSortLabel>
        } else {
            return <>{field.label}</>
        }
    }

    return (
        <TableRow>
            {
                props.fields.map((el, key) => {
                    return (
                        <TableCell
                            key={key}
                            style={styles}
                            align={el.subHeader ? 'center' : 'left'}
                        >
                            {renderHeader(el)}
                        </TableCell>);
                })
            }
        </TableRow>
    );
};