import React, {FC, MouseEventHandler } from 'react'
import { IconButton } from '@material-ui/core'
import { Icon } from '../Icon/Icon'


interface ActionMenuButtonProps {
    className?: string
    onClick? : MouseEventHandler<HTMLButtonElement>
    disableFocusRipple?: boolean
}

export const ActionMenuButton:FC<ActionMenuButtonProps> = (props) => {
    const {onClick, disableFocusRipple} = props
    return (
        <IconButton size="small"
                    color="primary"
                    onClick={onClick}
                    disableFocusRipple={disableFocusRipple}
        >
            <Icon name="threeDots" size={18} style={{fill: '#5383ff'}}/>
        </IconButton>
    );
};