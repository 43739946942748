import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { FetchProjectsResponse } from '../types/projects'
import { appActions } from '../../appSlice'
import { projectsActions } from '../slice/projectsSlice'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'
import { prepareFilterParams } from '../../../../../Api/API'

type RequestType = {
  params?: PaginationParamsType
}

export const fetchProjects = createAsyncThunk<FetchProjectsResponse,
  RequestType,
  ThunkConfig<any>>(
  'projects/fetch_projects',
  async (data, { dispatch, rejectWithValue, getState, extra }) => {
    const projectsList = getState().projects.projectsList
    !projectsList && dispatch(appActions.setIsFetchClientData(true))
    const query = data.params ? prepareFilterParams(data.params) : ''
    try {
      const res = await extra.api().get<FetchProjectsResponse>(`projects?${query}`)
      data.params && projectsActions.setParams(data.params)
      return res.data

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить  список проектов')
      return rejectWithValue({})
    } finally {
      dispatch(appActions.setIsFetchClientData(false))
      dispatch(appActions.setIsLoadTable(false))
    }
  }
)