import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'


export const mailForceAuthorizeCheck = createAsyncThunk<
    {}, number, ThunkConfig<any>
>(
    'emails/mail_force-authorize_check',
    async (id, { dispatch, extra, rejectWithValue }) => {
        try {
             await extra.api().patch(`/mailbox/${id}/force-authorize-check`)
        } catch (e) {
            handleServerNetworkError(e, dispatch, 'Не удалось обновить информацию о имейле')
            return rejectWithValue({})
        }
    }
)