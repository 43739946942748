import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { snackActions } from '../../snackSlice'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { appActions } from '../../appSlice'
import { clientsActions } from '../slice/clientsSlice'


interface RequestProps {
  client: number,
  noteId: number
}

export const editProjectNoteForClient = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'client/edit_project_note_for_client',
  async (data, thunkApi) => {
    const { client, noteId } = data
    const { dispatch, rejectWithValue, getState, extra } = thunkApi
    dispatch(appActions.setIsLoad(true))
    try {
      await extra.api().patch(`client/${client}/project-note/${noteId}`)
      const note = getState().notes.projectNotes.find(i => i.id === noteId)
     dispatch(clientsActions.setProjectNoteForClient(note))
      dispatch(snackActions.setSnack({
        label: 'Данные успешно обновлены',
        typeLabel: 'success'
      }))
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось обновить данные')
    } finally {
      dispatch(appActions.setIsLoad(false))
    }
  }
)