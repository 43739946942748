import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { projectLetterSettingThunks } from '../../projectLetterSettingSlice'
import { snackActions } from '../../snackSlice'

type RequestProps = {
  header: string,
  footer: string,
  projectId: number
}

export const saveProjectLetterSettings = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'projectLettersSettings/save_project_letter_settings',
  async (data, { dispatch, rejectWithValue, extra }) => {

    const { projectId, header, footer } = data

    try {
      await extra.api().patch(`/project/${projectId}/letters-setting/header`, { header })
     // dispatch(projectLetterSettingThunks.fetchProjectMailHeader(projectId))
      dispatch(snackActions.setSnack({
        label: 'Верхняя часть письма успешно изменена',
        typeLabel: 'success'
      }))
      await extra.api().patch(`/project/${projectId}/letters-setting/footer`, { footer })
     // dispatch(projectLetterSettingThunks.fetchProjectMailFooter(projectId))
      dispatch(snackActions.setSnack({
        label: 'Нижняя часть письма успешно изменена',
        typeLabel: 'success'
      }))
    } catch (e) {

      //todo check error
      handleServerNetworkError(e, dispatch, 'При попытке изменения письма произошла ошибка')
      return rejectWithValue({})
    }

  }
)