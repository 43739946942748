import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'

export const readAllMessagesInGeneralChat = createAsyncThunk<{},
  void,
  ThunkConfig<any>>(
  'chats/read_all_messages_in_general_chat',
  async (_, { dispatch, extra }) => {

    try {
      await extra.api().post(`general-chat/read-all`)

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Произошла ошибка')
    }
  }
)