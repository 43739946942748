import React, { ChangeEvent, FC, KeyboardEvent, memo, ReactNode, useState } from 'react'
import { Box, IconButton, TextField, Typography } from '@material-ui/core'
import { useStyles } from './styles'
import { Icon } from '../Icon/Icon'
import {
  AddressInfoKey,
  ContactInfoKey
} from '../../Pages/LeadsManagePage/LeadsInformationBlock/MainLeadInformation/LeadInformation/types'
import { PhoneValueType } from '../../Pages/LeadsManagePage/LeadsInformationBlock/MainLeadInformation/LeadInformation/hooks/useValidateContactInformation'

type EditableTypographyProps = {
  name: ContactInfoKey | AddressInfoKey
  value: string
  placeHolder?: string
  fieldName?: string
  phoneValues?: PhoneValueType[]
  leftChildIcon?: ReactNode
  callBack?: (value: string, name: ContactInfoKey | AddressInfoKey) => void
  handleValidate?: (value: string, name: ContactInfoKey | AddressInfoKey) => void
  error?: string
  disabled?: boolean
}

export const EditableTypography: FC<EditableTypographyProps> = memo(({
  name,
  value,
  placeHolder,
  fieldName,
  phoneValues,
  leftChildIcon,
  callBack,
  handleValidate,
  error,
  disabled
}) => {

  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')

  const classes = useStyles({ isEditMode })

  const onEditMode = (): void => {
    if (disabled) return
    else {
      setIsEditMode(true)
      value && setInputValue(value)
    }
  }

  const offEditMode = (): void => {
    setIsEditMode(false)
    callBack?.(inputValue, name)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.currentTarget.value)
    handleValidate?.(e.currentTarget.value, name)
  }

  const handleOnKeyPress = (e: KeyboardEvent<HTMLInputElement>): void => {
    e.key === 'Enter' && offEditMode()
  }

  return (
    isEditMode
      ? (
        <>
          <Box className={classes.editBox}>
            {leftChildIcon}
            <TextField
              variant='outlined'
              autoFocus
              fullWidth
              name={name}
              value={inputValue}
              placeholder={placeHolder}
              onChange={handleChange}
              onBlur={offEditMode}
              onKeyPress={handleOnKeyPress}
              className={classes.editInput}
              error={!!error}
            />

          </Box>
          {error && (
            <Typography
              className={classes.errorTypography}
              color='error'
              variant='inherit'>
              {error}
            </Typography>
          )}
          {phoneValues && phoneValues.map((phone: PhoneValueType, index: number) => {
            let isHeaderShow = false
            if (phone.clear !== phone.original) {
              return (
                <Box className={classes.phonePreviewBox}>
                  {!isHeaderShow && (
                    <Box className={classes.phonePreviewHeader}>
                      <Typography>Оригинал</Typography>
                      <Typography>Исправленное</Typography>
                    </Box>
                  )}
                  <Box style={{ backgroundColor: phone.isValid ? 'lightgreen' : 'lightcoral' }}
                       className={classes.phonePreviewContent}>
                    <Typography>{phone.original}</Typography>
                    <Typography>{phone.clear}</Typography>
                  </Box>
                </Box>
              )
            }
            return <></>
          })}
        </>
      ) : (
        <>
          <Box onDoubleClick={onEditMode} className={classes.editBox}>
            <Typography
              variant='body2'
              color='textSecondary'
              component='p'
              className={classes.typography}
            >
              {leftChildIcon}
              {fieldName} <b>{value}</b>
            </Typography>
            {!disabled && (
              <IconButton onClick={onEditMode}
                          className={classes.iconButton}
              >
                <Icon name='pencil' size={14} className={classes.icon} />
              </IconButton>
            )}
          </Box>
          {error && (
            <Typography color='error'
                        className={classes.errorTypography}
                        variant='inherit'>
              {error}
            </Typography>
          )}
        </>
      )
  )
})