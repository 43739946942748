import {Action, AnyAction, Dispatch} from 'redux';
import {letterThunks} from '../providers/StoreProvider/slices/lettersSlice'
import {ThunkDispatch} from "redux-thunk";
import {StateSchema, ThunkExtraArg} from "../providers/StoreProvider";


export const openFile = async (dispatch: ThunkDispatch<StateSchema, ThunkExtraArg, AnyAction> & Dispatch<Action>, file?: any, file_id?: number, client?: number) => {

    const token = localStorage.getItem('apikey');

    const anchor = document.createElement('a');
    document.body.appendChild(anchor);
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);

    const path = await getPdfLink(dispatch, file, file_id, client)
    const response = await fetch(path, {headers});

    const blobObj = await response.blob();
    const objectUrl = window.URL.createObjectURL(blobObj);
    anchor.href = objectUrl;
    window.open(objectUrl);
};


const getPdfLink = async (dispatch: ThunkDispatch<StateSchema, ThunkExtraArg, AnyAction> & Dispatch<Action>, file: any, file_id?: number, client?: number) => {
    if (file.id) {
        if (file_id) {
            return dispatch(letterThunks.fetchPdfExistingFile({id: file_id, client}))
                .then(res => {
                    if (res.meta.requestStatus !== 'rejected' && res.payload) {
                        return res.payload.path
                    }
                })
        } else {
            return file.path
        }
    } else {
        return dispatch(letterThunks.fetchPdfFiles({
            files: [
                {
                    name: file.name,
                    data: file.data,
                    description: file.description
                }
            ],
            client: client
        })).then(res => {
            if (res.meta.requestStatus !== 'rejected' && Array.isArray(res.payload)) {
                return res.payload[0][0]
            }
        })

    }
};

