import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { ThunkConfig } from '../../../config/StateSchema'
import { snackActions } from '../../snackSlice'
import { ISMSLetter, lettersActions } from '../../lettersSlice'
import { clientThunks } from '../../clientsSlice'
import { SMSRequest } from '../types/sms'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'

type RequestProps = {
  data: SMSRequest,
  projectId?: number
}

export const sendSms = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'sms/send_sms',
  async (dto, { dispatch, rejectWithValue, extra }) => {

    const { projectId, data } = dto

    try {
     const res =  await extra.api().post<ISMSLetter[],
       AxiosResponse<ISMSLetter[]>>(`manager-sms`, data)

      if (data.snooze) {
        dispatch(snackActions.setSnack({
          label: 'Клиент отправлен в "Отложенные"',
          typeLabel: 'success'
        }))
        projectId && dispatch(clientThunks.fetchClientsByProject({ projectId })) // todo переделать логику на локальную
      } else {
       dispatch(lettersActions.setSMSLetter({ letter: res.data }))
      }

      dispatch(snackActions.setSnack({
        label: 'смс принято в отправку',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Произошла ошибка при отправке смс')
      return rejectWithValue({})
    }
  }
)