import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { InheritorsDataType, PhoneType } from '../types/projectPhones'
import { snackActions } from '../../snackSlice'
import { fetchProjectPhonesForSelect } from './fetchProjectPhonesForSelect'
import { fetchGlobalPhones } from './fetchGlobalPhones'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'


type RequestProps = {
  projectId: number,
  phoneId: string,
  inheritorsData?: InheritorsDataType[]
  pagination?: PaginationParamsType
}


export const deletePhone = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'projectPhones/delete_phone',
  async (data, { dispatch, rejectWithValue, extra }) => {

    const { projectId, phoneId, inheritorsData, pagination} = data

    try {
      await extra.api().delete<PhoneType[]>(`project/phones-for-select/${phoneId}`, 
        {data: {
          inheritorsData
        }}
      )

      dispatch(fetchProjectPhonesForSelect(projectId))

      pagination && dispatch(fetchGlobalPhones(pagination))

      dispatch(snackActions.setSnack({
        label: 'Телефон успешно удален',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'При удалении телефона произошла ошибка')
      return rejectWithValue({})
    }
  }
)