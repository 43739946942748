import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { ClientNotesValueType } from '../../../../../Components/Plases/LeadViewPlace/NotesInformationLead'


type RequestProps = {
  clientId: number,
  notes: ClientNotesValueType
}

// Todo проверить эту санку ( в старом редюсере она голая, никакой обработки санки нет)
export const updateClientNotes = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'notes/update_client_notes',
  async (data, { dispatch, rejectWithValue, extra }) => {
    const { clientId, notes } = data
    try {
      await extra.api().post(`client/${clientId}/note`, { clientTempateNotes: notes })

    } catch (e) {
      return rejectWithValue({})
    } finally {
    }
  }
)