import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { snackActions } from '../../snackSlice'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { fetchClientData } from './fetchClientData'


interface RequestProps {
  clientId: number
  params: any, // Todo types
}

export const editClientProductNotes = createAsyncThunk<{},
  RequestProps, // todo types
  ThunkConfig<any>>(
  'client/edit_client_product_notes',
  async (data, thunkApi) => {
    const { clientId, params } = data
    const { dispatch, rejectWithValue, getState, extra } = thunkApi

    const idClient = getState().clients.clientId

    try {
      await extra.api().patch(`client/${clientId}/product-notes`, params)
     idClient && await dispatch(fetchClientData({  clientId: idClient }))

      dispatch(snackActions.setSnack({
        label: 'Данные успешно обновлены',
        typeLabel: 'success'
      }))
      return
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось обновить данные')
    }
  }
)