import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LastSmsNumbersType, SmsSchema } from '../types/sms'


const initialState: SmsSchema = {
  lastSmsNumbers: {
    clientId: undefined,
    lastClientSmsNumber: undefined,
    lastManagerSmsNumber: undefined
  }
}


export const smsSlice = createSlice({
  name: 'sms',
  initialState,
  reducers: {
    setLastSmsNumbers: (state, action: PayloadAction<LastSmsNumbersType>) => {
      state.lastSmsNumbers = action.payload
    },
  },
  extraReducers: (builder) => {

  }

})

export const { actions: smsActions } = smsSlice
export const { reducer: smsReducer } = smsSlice
