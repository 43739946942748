import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { ProjectForCurrentItemType } from '../types/templates'
import { appActions } from '../../appSlice'


type RequestProps = {
  id: number,
  name?: string
}

type FullFilledResponse = {
  items: ProjectForCurrentItemType[]
}

export const fetchProjectListForCurrentTemplate = createAsyncThunk<FullFilledResponse,
  RequestProps,
  ThunkConfig<any>>(
  'templates/fetch_project_list_for_current_template',
  async (dto, { dispatch, rejectWithValue, extra }) => {

    const { id, name } = dto

    dispatch(appActions.setIsLoad(true))
    try {
      const res = await extra.api().get(`letter-template/${id}/projects?filter[name]=${name}`)

      return res.data

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось загрузить список шаблонов')
      return rejectWithValue({})
    } finally {
      dispatch(appActions.setIsLoadTable(false))
      dispatch(appActions.setIsLoad(false))

    }
  }
)