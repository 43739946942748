import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { snackActions } from '../../snackSlice'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { CreateChatData } from '../../../../../Containers/Modal-content'

interface RequestProps {
  chatId: number,
  data: CreateChatData
}

export const editPrivateChat = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'chats/edit_private_chat',
  async (request, thunkApi) => {
    const { chatId, data } = request
    const { dispatch, extra, rejectWithValue } = thunkApi
    try {
      await extra.api().patch(`private-chat/${chatId}/edit`, data)

      dispatch(snackActions.setSnack({
        label: 'Чат успешно отредактирован',
        typeLabel: 'success'
      }))
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'При редактировании чата произошла ошибка')
        return rejectWithValue(e)
    }
  }
)