import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { snackActions } from '../../snackSlice'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { mailboxForDealActions } from '../slice/mailboxForDealSlice'
import { ResponseStatusCodes } from '../../../../../enums/enums'
import { EmailTargetType } from '../../emailSlice'
import { EmailForDealingType, EmailForNewClientsType } from '../../emailSlice/types/emails'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'

type RequestType = {
    target: EmailTargetType
    mailboxData: EmailForDealingType | EmailForNewClientsType
    params: PaginationParamsType
    projectId: number
}

export type SuccessResponseType = {
    currentEmail: EmailForNewClientsType | EmailForDealingType
    target: EmailTargetType
}

export const createEmail = createAsyncThunk<SuccessResponseType,
    RequestType,
    ThunkConfig<any>>(
    'email/create_email',
    async (data, { dispatch, rejectWithValue, extra }) => {
        try {
            const { target, mailboxData } = data

            const res = await extra.api().post(target === 'emailsForDealing'
                    ? `mailboxes-for-deal`
                    : `mailbox-for-new-clients`,
                target === 'emailsForDealing' ? {
                    mailboxes: [mailboxData]
                } : mailboxData
            )

            if (res.status === ResponseStatusCodes.success) {
                dispatch(mailboxForDealActions.setIsSuccess(true))
                dispatch(snackActions.setSnack({
                    label: 'Импорт почты из файла был успешно произведен',
                    typeLabel: 'success'
                }))
                dispatch(mailboxForDealActions.setIsSuccess(false))
            } else {
                dispatch(mailboxForDealActions.setErrorsForDeal(res.data))
                dispatch(mailboxForDealActions.setIsSuccess(true))
                dispatch(mailboxForDealActions.setIsSuccess(false))
            }

            return {
                currentEmail: res.data.mailboxes[0],
                target
            }
        } catch (e: any) {
            handleServerNetworkError(e, dispatch, 'При импорте почты из файла произошла ошибка')

            dispatch(mailboxForDealActions.setErrorsForDeal(e.response.data[0]))
            return rejectWithValue({})
        }
    }
)