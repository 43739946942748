import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'

type RequestProps = {
  clientId: number,
  value: number,
  projectId: number // Todo зачем он если не юзается
}

export const snoozeClient = createAsyncThunk<
  {},
  RequestProps,
  ThunkConfig<any>>(
  'client/snooze_client',
  async (data, thunkApi) => {
    const { dispatch, rejectWithValue, extra } = thunkApi
    const { clientId, value } = data
    try {
      await extra.api().patch(`client/${clientId}/snooze`, { snoozeTime: value })
      dispatch(snackActions.setSnack({
        label: 'Клиент успешно добавлен в отложенные',
        typeLabel: 'success'
      }))
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось скрыть клиента')
      return rejectWithValue({})
    }
  }
)