import { createAsyncThunk } from '@reduxjs/toolkit'
import { CommonResponseClientsType } from '../types/archiveClients'
import { ThunkConfig } from '../../../config/StateSchema'
import { prepareFilterParams } from '../../../../../Api/API'
import { archiveClientsActions } from '../slice/arhiveClientsSlice'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'
import { fetchWaitingForApproveDeclineClientManagers } from './fetchWaitingForApproveDeclineClientManagers'

interface RequestProps {
    projectId: number,
    params?: PaginationParamsType
}

export const fetchWaitingForApproveDeclineClients = createAsyncThunk<CommonResponseClientsType,
    RequestProps,
    ThunkConfig<any>>(
    'archiveClients/getWaitingForApproveDeclineClients',
    async (data, thunkApi) => {
        const { dispatch, getState, extra, rejectWithValue } = thunkApi
        const { projectId, params } = data
        const archiveClients = getState().archiveClients.archiveClientList
        !archiveClients && dispatch(archiveClientsActions.setIsFetchData(true))
        try {
            const response = await extra.api().get(`waiting-for-approve-clients/project/${projectId}?` + prepareFilterParams(params))

            /** Получаем менеджеров для фильтров */
            const waitingForApproveClientManagers = thunkApi.getState().archiveClients.waitingForApproveClientManagers
            if (!waitingForApproveClientManagers || waitingForApproveClientManagers.projectId !== projectId) {
                dispatch(fetchWaitingForApproveDeclineClientManagers(data))
            }

            params && dispatch(archiveClientsActions.setPaginationParams(params))
            return response.data

        } catch (e) {
            handleServerNetworkError(e, dispatch, 'Не удалось получить список клиентов')
            return rejectWithValue(e)
        } finally {
            dispatch(archiveClientsActions.setIsFetchData(false))
        }
    }
)