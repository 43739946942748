import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { fetchChains } from './fetchChains'
import { fetchPublicChains } from './fetchPublicChains'


export const deleteChain = createAsyncThunk<{},
  number | null,
  ThunkConfig<any>>(
  'chains/delete_chain',
  async (chainId, thunkApi) => {
    const { dispatch, getState, extra } = thunkApi
    const myChainsParams = getState().chains.chainPagination
    const publicChainsParams = getState().chains.publicChainPagination

    if (chainId) {
      try {
        await extra.api().delete(`chain-letters/${chainId}`)
        dispatch(fetchChains(myChainsParams))
        dispatch(fetchPublicChains(publicChainsParams))
        dispatch(snackActions.setSnack({
          label: 'цепочка успешно удалена',
          typeLabel: 'success'
        }))
      } catch (e) {
        handleServerNetworkError(e, dispatch, 'Не удалось удалить цепочку')
      } finally {
      }
    }
  }
)