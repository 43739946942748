import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { snackActions } from '../../snackSlice'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { fetchProductNotesForProject } from './fetchProductNotesForProject'
import { FilesType } from '../../../../../Components/Inputs/FilesAttach/FilesAttach'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'


type RequestProps = {
    projectId: number,
    notes: {
        notes: string[]
        file?: FilesType
    }[]
    params?: PaginationParamsType
}

export const createPackOfProductNotesInProject = createAsyncThunk<{},
    RequestProps,
    ThunkConfig<any>>(
        'productNotes/create_pack_of_product_notes_in_project',
        async (data, { dispatch, rejectWithValue, getState, extra }) => {
            const { projectId, notes, params } = data
            try {
                const res = await extra.api().post(`project/${projectId}/product-notes/create-pack`, {
                    notes
                })

                dispatch(fetchProductNotesForProject({ projectId, params }))

                dispatch(snackActions.setSnack({
                    label: `${res.data.addedNotes} заметки о продукте успешно созданы`,
                    typeLabel: 'success'
                }))
            } catch (e) {
                handleServerNetworkError(e, dispatch, 'Не удалось создать заметки о продукте')
                return rejectWithValue({})
            } finally {
            }
        }
    )