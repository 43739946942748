import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { ResponseTemplateType } from '../types/templates'
import { appActions } from '../../appSlice'


export const fetchGlobalLetterTemplatesForSelect = createAsyncThunk<ResponseTemplateType[],
  number | undefined,
  ThunkConfig<any>>(
  'templates/fetch_global_templates_for_select',
  async (projectId, { dispatch, rejectWithValue, extra }) => {

    dispatch(appActions.setStatus('loading'))
    try {
      const res = await extra.api().get<ResponseTemplateType[]>(`letter-templates-for-select?filter[project]=${projectId}`)

      return res.data

    } catch (e) {
      dispatch(appActions.setError('Не удалось загрузить список шаблонов'))
      handleServerNetworkError(e, dispatch, 'Не удалось загрузить список шаблонов')
      return rejectWithValue({})
    } finally {
       dispatch(appActions.setStatus('success'))
    }
  }
)