import { instance } from './API'
import { HistoriesType, NoteType } from '../types'
import { ParamsType } from '../Pages/LeadsManagePage/MainContentBlock/ManageLeadsLetters/ManageLeadsLettersContent/Letters/MessagesFeedWithClient/MessagesFeedWithClient'
import { CallType } from '../providers/StoreProvider/slices/callSlice'


export enum LetterType {
    ACTION = 'ACTION',
    LETTER = 'LETTER',
    NOTE = 'NOTE',
    SMS = 'SMS',
    CALL = 'CALL'
}

export type MessagesFeedType = MessageFeedType[]
export type MessageFeedType = CommonLetterType | NoteWithType | HistoriesWithType | ISMSLetter | CallType
export type PlannedMessagesFeed = (CommonLetterType | ISMSLetter)[]
export type FailedMessagesFeed = (CommonLetterType | ISMSLetter)[]

export interface CommonLetterType {
    rights?: CommonLetterRightsType
    clientId?: number
    attachedFiles?: any[]
    clientFirstName?: string
    clientLastName?: string
    clientSalesFunnelStep?: string
    hasEmptyMacrosInMessages: boolean
    macrosErrors: string[]
    header?: string
    id: number
    isChainLetter?: boolean
    isClientLetter?: boolean
    isRead: boolean
    isSent: boolean
    message: string
    postDate: string
    postTimestamp: number
    projectId?: number
    recipient: string
    sender?: string | {
        id: number
        email: string
    },
    status?: {
        key: UnsentLetterSendStatus
    },
    sendNow?: boolean
    sendings?: SendingTryType[]
    type: LetterType.LETTER
}

export type CallDirectionsType = 'OUTCOMING' | 'INCOMING'

// export interface CallType extends Omit<CommonLetterType,
//     | 'attachedFiles'
//     | 'header'
//     | 'sender'
//     | 'recipient'
//     | 'type'
//     | 'status'> {
//     type: LetterType.CALL;
//     direction: CallDirectionsType;
//     status: string

//     /** Время в которое был начат звонок */
//     calledAt: number
//     /** Время в которое был завершен звонок */
//     completedAt: number
//     /** Время в которое был завершен звонок */
//     answeredAt: number
    
//     sender: string
//     recipient: string
//     /** Стоимость разговора */
//     price: number
// }

export interface ISMSLetter extends Omit<CommonLetterType,
    | 'attachedFiles'
    | 'header'
    | 'sender'
    | 'type'> {
    sender: string | {
        id: number
        phone: string
    },
    hasEmptyMacrosInMessages: boolean
    type: LetterType.SMS;
}


export interface NoteWithType extends NoteType {
    type: LetterType.NOTE;
}

export interface HistoriesWithType extends HistoriesType {
    type: LetterType.ACTION;
}

export type SendingTryType = {
    date: string
    message: string
    status: string
}

export type CommonLetterRightsType = {
    canDelete: boolean
    canEdit: boolean
}

export type UnsentLetterSendStatus =
    | 'HALTED'
    | 'FAIL'
    | 'STOPED'
    | 'WAITING'
    | 'DELETED'
    | 'DECLINED'


export type CommonResponseType = {
    items: MessagesFeedType
    totalCount: number
}

export const lettersApi = {
    getClientLettersFeed(clientId: number, params: ParamsType) {
        const {page, per_page, filter} = params
        return instance.get<CommonResponseType>(
            `client/${clientId}/messages?page=${page}&per_page=${per_page}&filter=${filter}`
        )
    },

    getClientLetterInfo(letterId: string) {
        return instance.get<CommonLetterType>(`client/letter/${letterId}/show`)
    },

    getManagerLetterInfo(letterId: string) {
        return instance.get<CommonLetterType>(`manager-letter/${letterId}/show`)
    }
}