import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { prepareFilterParams } from '../../../../../Api/API' // todo change import
import { FetchProductNotesResponse } from '../types/productNotes'
import { productNotesActions } from '../slice/productNotesSlice'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'
import { fetchProductNotesForProject } from './fetchProductNotesForProject'
import { clientsActions } from '../../clientsSlice'


interface RequestProps {
    productNotesId: number
    projectId: number,
    params?: PaginationParamsType
    /** Нужно ли обновить fullClientData в LeadsManage */
    isUpdateClientData?: boolean
}


export const deleteAssignmentForProductNotes = createAsyncThunk<FetchProductNotesResponse,
    RequestProps,
    ThunkConfig<any>>(
        'product_notes/delete_assignment_for_product_notes',
        async (data, { dispatch, rejectWithValue, getState, extra }) => {
            try {
                const response = await extra.api().delete<FetchProductNotesResponse>(
                    `project/${data.projectId}/product-notes/${data.productNotesId}/delete-assignment`
                )

                data.params && dispatch(productNotesActions.setParams(data.params))
                data.params && dispatch(fetchProductNotesForProject(data))

                const notes = getState().clients.fullClientData?.productNotes?.notes

                if (data.isUpdateClientData && notes) {
                    dispatch(clientsActions.setProductNotesForClient({
                        productNotes: {
                            notes: notes
                        }
                    }))
                }

                return response.data

            } catch (e) {
                handleServerNetworkError(e, dispatch, 'Не удалось загрузить заметки о продукте для проекта')
                return rejectWithValue({})
            } finally {
                dispatch(productNotesActions.isFetchData(false))
            }
        }
    )