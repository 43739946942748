import { createElement, CSSProperties, FC, memo, MouseEventHandler } from 'react'
import { IconName } from './types'
import allIcons from './allIcons'


type Props = {
    id?: string
    className?: string
    name: IconName
    size?: number
    style?: CSSProperties
    onClick?: MouseEventHandler<SVGElement>
    onMouseOver?: MouseEventHandler<SVGElement>
    onMouseOut?: MouseEventHandler<SVGElement>
}


export const Icon: FC<Props> = memo(({
                                         id,
                                         name,
                                         size = 24,
                                         className,
                                         onClick,
                                         onMouseOut,
                                         onMouseOver,
                                         style
                                     }) =>
    createElement(allIcons[name], {
        id,
        height: size,
        width: size,
        className,
        style,
        onClick,
        onMouseOut,
        onMouseOver,
    })
)

