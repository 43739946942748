const weekTimestamp = 7 * 24 * 60 * 60 * 1000

const date = new Date()

const weekendPreset = ((Date.now() - weekTimestamp) / 1000).toFixed(0).toString()
const monthPreset = (date.setMonth(date.getMonth() - 1) / 1000).toFixed(0).toString()
const quarterOfTheYearPreset = (date.setMonth(date.getMonth() - 2) / 1000).toFixed(0).toString()


export const timestamps = {
    week: weekTimestamp,
}

export const timestampsPresets = {
    week: weekendPreset,
    month: monthPreset,
    quarterOfTheYear: quarterOfTheYearPreset
}