import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'

export const fetchTemplates = createAsyncThunk<any,
  void,
  ThunkConfig<any>>(
  'emails/fetch_templates',
  async (_, { dispatch, rejectWithValue, extra }) => {
    try {
      const res = await extra.api().get(`mailbox-templates-for-select`)
      return res.data
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить список шаблонов')
      return rejectWithValue({})
    }

  }
)