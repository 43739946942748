import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { FetchExternalServicesResponseType } from '../types/externalService'


export const fetchExternalServices = createAsyncThunk<
  FetchExternalServicesResponseType,
  any, //todo types
  ThunkConfig<any>>(
  'emails/fetch_external_services',
  async (data, thunkApi) => {
    const { rejectWithValue, extra } = thunkApi
    try {
      const response = await extra.api().get<FetchExternalServicesResponseType>(`external-service-list`)
      return response.data
    } catch (e) {
      return rejectWithValue({})
    }
  }
)