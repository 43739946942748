import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { lettersActions } from '../../lettersSlice'


export const declineSmsLetter = createAsyncThunk<{},
  number,
  ThunkConfig<any>>(
  'sms/decline_sms_letter',
  async (smsId, { dispatch, rejectWithValue, extra }) => {
    try {
      await extra.api().patch(`manager-sms/${smsId}/decline`)
      dispatch(lettersActions.declineSmsLetter(smsId))
      dispatch(snackActions.setSnack({
        label: 'смс успешно удалено из очереди',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Произошла ошибка')
      return rejectWithValue({})
    }
  }
)