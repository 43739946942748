import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { prepareFilterParams } from '../../../../../Api/API'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'


export const fetchDetailedStatistic = createAsyncThunk<
    any,
    Record<string, string | number>,
    ThunkConfig<any>
>(
    'operators/fetch_detailed_statistic',
    async (params, { dispatch, extra, rejectWithValue }) => {
        const query = params ? prepareFilterParams(params) : ''
        try {
            const response = await extra.api().get(`track-problems/detailed-statistics?${query}`)
            return response.data
        } catch (e) {
            handleServerNetworkError(e, dispatch, 'Не удалось получить детализацию')
            return rejectWithValue(e)
        }

    }
)