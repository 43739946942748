import { FC, useCallback, useEffect, useState } from 'react'

import ScrollBar from 'react-perfect-scrollbar'
import { makeStyles } from '@material-ui/core/styles'

import { ChatMessageItem } from './ChatMessageItem/ChatMessageItem'
import { ClientChat, GeneralChat, IChatMessage, PrivateChat } from '../../../types/chat'
import { List } from '../../../ui-kit'
import { useAppDispatch } from '../../../providers/StoreProvider/hooks/useAppDispatch'
import { chatThunks } from '../../../providers/StoreProvider/slices/chatsSlice'
import { useDebounce } from '../../../helpers/hooks/useDebounce'


type MessagesPropsType = {
  currentChat?: ClientChat | GeneralChat | PrivateChat
  messages?: IChatMessage[]
  pinnedMessages: IChatMessage[]
  scrollEl?: HTMLElement
  setScrollEl: (scrollEl: HTMLElement) => void
  unreadMessagesCount: number
  setIsInitialRender: (value: boolean) => void
  isInitialRender: boolean
  isReadAll: boolean
};

export const Messages: FC<MessagesPropsType> = ({
                                                  currentChat,
                                                  messages,
                                                  pinnedMessages,
                                                  scrollEl,
                                                  setScrollEl,
                                                  unreadMessagesCount,
                                                  isInitialRender,
                                                  setIsInitialRender,
                                                  isReadAll
                                                }) => {

  const [unreadMessagesIds, setUnreadMessagesIds] = useState<number[]>([])
  const dispatch = useAppDispatch()

  const debouncedValue = useDebounce(unreadMessagesIds, 500)

  const havePinned = pinnedMessages.length > 0

  const cls = useStyles({ havePinned })

  const addUnreadMessage = useCallback((id: number) => {
    setUnreadMessagesIds(prev => [...prev, id])
  }, [])

  useEffect(() => {
    if (scrollEl && isInitialRender) {
      if (unreadMessagesCount > 0) {
        const unreadMessages = messages?.filter(m => !m.isRead)
        const element = document.getElementById(String(unreadMessages?.[0].id))
        element?.scrollIntoView()
      } else {
        scrollEl.scrollTop = scrollEl.children[0].clientHeight
      }
      setIsInitialRender(false)
    }
  }, [isInitialRender, unreadMessagesCount, messages, scrollEl, setIsInitialRender])

  useEffect(() => {
    if (debouncedValue.length > 0 && !isReadAll) {
      dispatch(chatThunks.markMultipleMessagesAsRead({
        idsArray: debouncedValue,
        chatId: currentChat?.type !== 'GENERAL' ? currentChat?.id : undefined,
        chatType: currentChat?.type
      }))
        .unwrap()
        .then(() => {
          setIsInitialRender(false)
          setUnreadMessagesIds([])
        })
    }
  }, [isReadAll,dispatch, debouncedValue, currentChat?.type, currentChat?.id, setIsInitialRender])

  return (
    <>
      {messages && (
        <ScrollBar
          containerRef={ref => setScrollEl(ref)}
          className={cls.scrollBar}
        >
          <List items={messages}
                className={cls.messages_content}
                renderItem={(message) => (
                  <div>
                    <ChatMessageItem
                      key={message.id}
                      message={message}
                      addUnreadMessage={addUnreadMessage}
                    />
                  </div>
                )}
          />
        </ScrollBar>
      )}
    </>

  )
}

const useStyles = makeStyles<{}, { havePinned: boolean }>({
  messages_content: {
    width: '100%',
    padding: '20px 20px 0 16px'
  },
  scrollBar: {
    transition: 'all 0.3s',
    height: 'auto',
    marginTop: ({ havePinned }) => havePinned ? 50 : 0
  },
  readAllBtn: {
    position: 'fixed',
    right: 15,
    bottom: 90,
    padding: '10px 12px',
    borderRadius: '50%',
    background: 'rgb(161 161 161)',
    cursor: 'pointer',
    transition: 'background 0.3s',
    boxShadow: '0 1px 5px 0px rgba(0,0,0,0.75)',
    '&:hover': {
      background: 'rgb(175,174,174)'
    }
  },
  icon: {
    fill: '#fff'
  }
})