import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { IShortClientData } from '../../../../../types'


export const fetchNewClients = createAsyncThunk<
  IShortClientData[],
  number,
  ThunkConfig<any>>(
  'client/fetch_new_clients',
  async (projectId, thunkApi) => {
    const { dispatch, rejectWithValue, extra } = thunkApi
    try {
      const response = await extra.api().get(`new-clients/project/${projectId}`)
      return response.data
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить клиентов')
      return rejectWithValue({})
    }
  }
)