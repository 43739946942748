import React, { FC, MouseEvent, useState } from 'react'
import { useSelector } from 'react-redux'

import Clock from 'react-live-clock'
import 'moment/locale/ru'

import { Avatar, Box, Button, Divider, List, ListItem, Menu } from '@material-ui/core'
import { permissionsValues } from '../../z_old_site/crm-default-values/access-by-role'
import { WsStatusView } from '../../Containers/WsStatusView'
import AccessByRole from '../Access/AccessByRole/AccessByRole'
import { Icon } from '../../ui-kit'
import { CustomLIstItem, useStyles } from './styles'
import { HeaderUserNotification } from './HeaderUserNotification/HeaderUserNotification'
import { authActions, authSelectors } from '../../providers/StoreProvider/slices/authSlice'
import { useAppDispatch } from '../../providers/StoreProvider/hooks/useAppDispatch'


type HeaderUserBoxProps = {}

export const HeaderUserBox: FC<HeaderUserBoxProps> = () => {

  // const adminUrl = useSelector(selectAdminUrl);
  const adminUrl = useSelector(authSelectors.getAuthAdminUrl)
  //const name = useSelector(selectUserName);
  const name = useSelector(authSelectors.getAuthUsername)
  //const date = useSelector(selectUserDate);
  const date = useSelector(authSelectors.getAuthUserDate)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [userNotificationAnchorEl, setUserNotificationAnchorEl] = useState<HTMLElement | null>(null)
  const dispatch = useAppDispatch()
  const cls = useStyles()

  const handleClick = (e: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = (): void => setAnchorEl(null)

  const handleLogout = (): void => {
    localStorage.removeItem('apikey')
    //dispatch(setAuthStatus(false)); // todo юзается в старом редюсере (AuthReducer)
    dispatch(authActions.setAuthStatus(false))
    dispatch(authActions.setIsAuth(false))
    window.location.replace(`${window.location.origin}${window.location.pathname}`)
  }

  const handleSwitchUser = (): void => {
    const oldApiKey = localStorage.getItem('prevapikey')
    oldApiKey && localStorage.setItem('apikey', oldApiKey)
    localStorage.removeItem('prevapikey')
    window.location.reload()
  }

  const checkIsShowSwitchUser = (): boolean => {
    const oldApiKey = localStorage.getItem('prevapikey')
    return !!oldApiKey
  }

  const handleCloseNotifications = () => setUserNotificationAnchorEl(null)
  //const handleOpenNotifications = (e: MouseEvent<HTMLDivElement>) => setUserNotificationAnchorEl(e.currentTarget)

  return (
    <>
      <WsStatusView />
      {/*<BadgeIcon*/}
      {/*  title='Уведомления'*/}
      {/*  contentLength={0}*/}
      {/*  showZero*/}
      {/*  color='error'*/}
      {/*  className={cls.badgeIcon}*/}
      {/*  onClick={handleOpenNotifications}*/}
      {/*>*/}
      {/*  <Icon name='bell' size={14}/>*/}
      {/*</BadgeIcon>*/}
      <Clock
        format={'dddd, D MMMM, HH:mm:ss'}
        ticking={true}
        timezone={date.timezone}
        locale={'ru'}
      />
      <Button
        color='inherit'
        onClick={handleClick}
        className=' text-left btn-inverse d-flex align-items-center'>
        <div className='d-none d-xl-block pl-2'>
          <div className='font-weight-bold line-height-1'>{name && name}</div>
        </div>
        <span className='pl-1 pl-xl-3'>
          <Icon name='arrowDown' size={12} style={{ fill: '#fff' }} className='opacity-5' />
        </span>
      </Button>


      {userNotificationAnchorEl && (
        <HeaderUserNotification anchorEl={userNotificationAnchorEl}
                                onClose={handleCloseNotifications}
        />
      )}


      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        onClose={handleClose}
        className='ml-2'>
        <div className='dropdown-menu-right dropdown-menu-lg overflow-hidden p-0'>
          <List className='text-left bg-transparent d-flex align-items-center flex-column pt-0'>
            <Box>
              <Avatar sizes='44'>
                {name && name.substr(0, 2).toUpperCase()}
              </Avatar>
            </Box>
            <div className='pl-1 '>
              <div className='font-weight-bold text-center pt-2 line-height-1'>
                {name && name}
              </div>
            </div>
            <Divider className='w-100 mt-2' />
            <AccessByRole byRole={[...permissionsValues.admin_page_link]}>
              <ListItem
                button
                component={'a'}
                href={adminUrl}>
                Админ панель
              </ListItem>
            </AccessByRole>
            {checkIsShowSwitchUser() && (
              <CustomLIstItem button onClick={handleSwitchUser}>
                Переключиться на прошлую учётную запись
              </CustomLIstItem>
            )}
            <CustomLIstItem button onClick={handleLogout}>
              Выход
            </CustomLIstItem>
          </List>
        </div>
      </Menu>
    </>
  )
}