import React, { useEffect, useState } from 'react'
import { TablePropsType } from './types/table'
import { makeStyles } from '@material-ui/core/styles'
import {
    Box,
    Card,
    createStyles,
    Grid,
    Table as MaterialTable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core'
import TableFilter from './Filter/TableFilter'
import { useSearchParams } from 'react-router-dom'
import { PaginationParamsType } from './types/query'
import { TableItemRow } from './Row/TableItemRow'
import TableContentBody from './Body/TableContentBody'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../providers/StoreProvider/hooks/useAppDispatch'
import TablePagination from './Pagination/TablePagination'
import { ScaleLoader } from 'react-spinners'
import { StateSchema } from '../../providers/StoreProvider'
import TableSubheaderItemRow from './Row/TableSubheaderItemRow'
import { Loader } from '../Loader/Loader'

const Table: React.FC<TablePropsType> = (props) => {
    const classes = useStyles()
    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams()
    const [isInit, setIsInit] = useState<boolean>(true)
    const [prevParams, setPrevParams] = useState<PaginationParamsType>()

    const [isFetching, setIsFetching] = useState<boolean>(false)

    /** ToDo Придумать более элегантное решение */
    const fetchList = useSelector(props.fetch && props.fetch.itemsSelector ? props.fetch.itemsSelector : (state: StateSchema) => [])
    let itemList = props.itemList ? props.itemList : fetchList
    const [isLoadTable, setIsLoadTable] = useState<boolean>(false)
    console.log('itemsList', itemList)

    useEffect(() => {
        let newSearchParamsArray = [...searchParams]
        if (props.prefixParam && Array.isArray(newSearchParamsArray)) {
            newSearchParamsArray = newSearchParamsArray
                .filter(i => {
                    return i[0].split(props.prefixParam as string).length === 2// && i[1]
                })
                .map(i => {
                    const name = i[0].replace(props.prefixParam as string, '')
                    return [name, i[1]]
                })
        }

        /** Проверяем были ли изменены параметры пагинации по сравнению с предыдущей загрузкой */
        let isSame = true
        let prevParamsLength = 0
        newSearchParamsArray.forEach(i => {
            // prevParams && console.log('1) ',prevParams[i[0]], '!==', i[1])
            prevParams && i[0] && console.log('123', prevParams[i[0]], i[1])
            if (prevParams && i[0] && prevParams[i[0]] !== i[1]) {
                isSame = false
            }
        })
        if (prevParams && isSame) {
            for (const key in prevParams) {
                prevParamsLength++
                if (Object.prototype.hasOwnProperty.call(prevParams, key)) {
                    const el = prevParams[key];
                    isSame = false
                    newSearchParamsArray.forEach(i => {
                        if (i[0] === key && i[1] === el) {
                            isSame = true
                        }
                    })
                }
            }
        }

        if (
            newSearchParamsArray.length > 0 && !isSame && props.fetch
            || newSearchParamsArray.length === 0 && prevParamsLength > 0 && !isSame && props.fetch
            || isInit && props.fetch
        ) {
            setIsFetching(true)
            const newSearchParams = props.fetch.getterParamsHandler
                ? props.fetch.getterParamsHandler(Object.fromEntries(newSearchParamsArray))
                : Object.fromEntries(newSearchParamsArray)

            dispatch(props.fetch.itemsGetter({
                ...newSearchParams
            }))
                .unwrap()
                .then((res: any) => {
                    dispatch(props.additionalParams?.additionalFetch())
                    setIsFetching(false)
                })
                .catch(() => setIsFetching(false))

            setIsLoadTable(true)

            isInit && setIsInit(false)

            /** Сохраняем параметры предыдущей загрузки */
            setPrevParams(Object.fromEntries(newSearchParamsArray) as PaginationParamsType)
        }
    }, [searchParams])

    useEffect(() => {
        setIsLoadTable(false)
    }, [itemList])

    return (
        <Box className={classes.table_container} data-testid='table-container'>
            <Card className='card-box mb-4' elevation={2} square>
                {props.filter && props.filter.length !== 0 &&
                    <TableFilter
                        filter={props.filter}
                        prefixParam={props.prefixParam}
                    />
                }

                <Grid container className='card-header pr-2'>
                    <Grid item xs={6}>
                        <div className='card-header--title'>
                            <Typography variant='overline'>{props.tableName}</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className='card-header--actions' style={{ textAlign: 'right' }}>
                            {props.headActions &&
                                React.cloneElement(props.headActions, {
                                    setIsFetching: (isLoad: boolean) => setIsFetching(isLoad)
                                })
                            }
                        </div>
                    </Grid>
                </Grid>

                <TableContainer data-testid='table'>
                    <MaterialTable stickyHeader aria-label='sticky table'>
                        <TableHead className={classes.table_head}>
                            <TableItemRow
                                fields={props.fields}
                                prefixParam={props.prefixParam}
                            />
                            {!!props.fields.find(i => i.subHeader) &&
                                <TableSubheaderItemRow
                                    fields={props.fields}
                                    prefixParam={props.prefixParam}
                                />
                            }
                        </TableHead>

                        {isFetching ?
                            <Loader />
                            :
                            <TableBody className={classes.body} data-testid='table-body'>
                                {itemList ?
                                    <TableContentBody
                                        fields={props.fields}
                                        refreshData={() => {
                                            alert('refreshData')
                                        }}
                                        items={itemList}
                                        fetch={props.fetch ? props.fetch.itemsGetter : (params?: any) => { }}
                                        target={props.target}
                                        prefixParam={props.prefixParam}
                                        isPublic={props.isPublic}
                                        setIsFetching={setIsFetching}
                                    />
                                    :
                                    <TableRow>
                                        <TableCell align='center' colSpan={props.fields.length}>
                                            <ScaleLoader color={'var(--primary)'}
                                                loading={true}
                                                height={20}
                                                data-testid='loader' />
                                        </TableCell>
                                    </TableRow>
                                }

                                {isLoadTable &&
                                    <Box className={classes.loader}>
                                        <ScaleLoader color={'var(--primary)'} loading={true} height={20} />
                                    </Box>
                                }

                                <TableRow>
                                    {
                                        // props.fetch && 
                                        !props.hidePagination &&
                                        <TablePagination
                                            itemsCountSelector={props.fetch ? props.fetch.itemsCountSelector : undefined}
                                            prefixParam={props.prefixParam}
                                            itemList={props.itemList}
                                        />
                                    }
                                </TableRow>

                            </TableBody>
                        }


                    </MaterialTable>
                </TableContainer>
            </Card>
        </Box>
    )
}

export default Table

const useStyles = makeStyles(() =>
    createStyles({
        table_container: {
            position: 'relative'
        },
        table_head: {
            backgroundColor: '#3b3e660f'
        },
        body: {
            position: 'relative',
            minHeight: 100
        },
        loader: {
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255,252,252,0.76)'
        }
    })
)