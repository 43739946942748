import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    LetterInfoTargetType,
    LettersSchema,
    MessageFeedType,
    MessagesFeedType,
    OpenClientLettersQueryParams
} from '../types/letters'
import { CommonLetterType, ISMSLetter, LetterType } from '../types/letters'
import {
    createClientNote,
    declineManagerLetter,
    deleteClientNote,
    fetchClientMessageFeed,
    fetchAllClientMessageFeed,
    fetchLetterInfo, readAllClientLetters
} from '../services'
import { NoteWithType, UnsentLetterSendStatus } from '../../../../../Api/lettersApi'
import { ClientLogUpdated } from '../../../../../Redux/socket-io-handler'
import { IFullClientData } from '../../../../../types'
import { CallType } from '../../callSlice'

const letterLSTarget: string | null = localStorage.getItem('letterTarget')

const initialState: LettersSchema = {
    openedLettersSettings: {
        isOpenAllLetters: true,
        isOpenPlannedLetters: false,
        isOpenFailedLetters: false
    },
    generalMessageFeed: [],
    currentPage: 1,
    totalCount: 0,
    filter: 'DEFAULT',
    isFetching: true,
    plannedLetters: [],
    failedLetters: [],
    clientLetter: null,
    letterTarget: letterLSTarget !== null ? letterLSTarget : 'client',
    haveUnreadNotes: false,
    histories: [],
    isMessagesFeedLoading: false
}

export const lettersSlice = createSlice({
    name: 'letters',
    initialState,
    reducers: {
        declineSmsLetter: (state, action: PayloadAction<number>) => {
            state.generalMessageFeed = state.generalMessageFeed.filter(el => el.id !== action.payload)
            state.plannedLetters = state.plannedLetters.filter(el => el.id !== action.payload)
        },
        deletePlannedLetterFromQueue: (state, action: PayloadAction<ISMSLetter>) => {
            state.plannedLetters = state.plannedLetters.filter(el => el.id !== action.payload.id)
            state.failedLetters.push(action.payload)
        },
        editPlannedSmsLetter: (state, action: PayloadAction<ISMSLetter>) => {
            state.plannedLetters = state.plannedLetters.map(el => el.id === action.payload.id ? { ...action.payload } : el)
        },
        deleteLetterFromArray: (state, action: PayloadAction<CommonLetterType>) => {
            state.plannedLetters = state.plannedLetters.filter(l => l.id !== action.payload.id)
            state.failedLetters.push(action.payload)
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isMessagesFeedLoading = action.payload
        },
        setIsFetching: (state, action: PayloadAction<boolean>) => {
            state.isFetching = action.payload
        },
        setLetterTarget: (state, action: PayloadAction<LetterInfoTargetType>) => {
            state.letterTarget = action.payload
        },
        clearClientLetters: (state) => {
            state.generalMessageFeed = []
            state.plannedLetters = []
            state.failedLetters = []
            state.clientId = undefined
            state.histories = []
        },
        clearClientMessages: (state) => {
            state.generalMessageFeed = []
            state.totalCount = 0
            state.currentPage = 1
        },
        clearLetterInfo: (state) => {
            state.clientLetter = null
        },
        readAllClientNotes: (state) => {
            state.generalMessageFeed = state.generalMessageFeed.map(m => m.type === 'NOTE' ? { ...m, isRead: true } : m)
        },
        setQueryParams: (state, action: PayloadAction<OpenClientLettersQueryParams>) => {
            state.openedLettersSettings = action.payload
        },
        setOpenPlannedLetters: (state) => {
            state.openedLettersSettings = {
                ...state,
                isOpenFailedLetters: false,
                isOpenPlannedLetters: true,
                isOpenAllLetters: false
            }
        },
        setOpenStoppedLetters: (state) => {
            state.openedLettersSettings = {
                ...state,
                isOpenPlannedLetters: false,
                isOpenAllLetters: false,
                isOpenFailedLetters: true
            }
        },
        setOpenAllLetters: (state) => {
            state.openedLettersSettings = {
                ...state,
                isOpenAllLetters: true,
                isOpenFailedLetters: false,
                isOpenPlannedLetters: false
            }
        },
        setClientLetters: (state, action: PayloadAction<IFullClientData>) => {
            state.plannedLetters = action.payload.plannedLetters
            state.failedLetters = action.payload.failedLetters
            state.clientId = action.payload.id
            state.histories = action.payload.histories
        },
        setChainOfSms: (state, action: PayloadAction<ISMSLetter[]>) => {
            state.plannedLetters = [...state.plannedLetters, ...action.payload.filter(el => !el.sendNow).map(el => ({
                ...el, status: { ...el.status, key: 'WAITING' as UnsentLetterSendStatus }
            }))]
            if (state.filter === 'ALL' || state.filter === 'LETTER' || state.filter === 'DEFAULT') {
                state.generalMessageFeed = [...state.generalMessageFeed, ...action.payload.filter(el => el.sendNow).map(el => ({
                    ...el, type: LetterType.SMS
                } as ISMSLetter))]
            }
        },
        setChainOfLetters: (state, action: PayloadAction<CommonLetterType[]>) => {
            state.plannedLetters = [...state.plannedLetters, ...action.payload.filter(el => !el.sendNow).map(el => ({
                ...el, status: { ...el.status, key: 'WAITING' as UnsentLetterSendStatus }
            }))]
            if (state.filter === 'ALL' || state.filter === 'LETTER' || state.filter === 'DEFAULT') {
                state.generalMessageFeed = [...state.generalMessageFeed, ...action.payload.filter(el => el.sendNow).map(el => ({
                    ...el, type: LetterType.LETTER
                } as CommonLetterType))]
            }
        },
        setLetter: (state, action: PayloadAction<{ letter: CommonLetterType[] }>) => {
            const { letter } = action.payload
            if (state.filter === 'LETTER' || state.filter === 'ALL' || state.filter === 'DEFAULT') {
                if (!letter[0].sendNow) {
                    state.plannedLetters = [...state.plannedLetters, {
                        ...letter[0],
                        status: { ...letter[0].status, key: 'WAITING' }
                    }]
                } else {
                    state.generalMessageFeed = [...state.generalMessageFeed,
                        { ...letter[0], type: LetterType.LETTER }
                    ]
                }
            }
        },
        setSMSLetter: (state, action: PayloadAction<{ letter: ISMSLetter[] }>) => {
            const { letter } = action.payload
            if (state.filter === 'SMS' || state.filter === 'ALL' || state.filter === 'DEFAULT') {
                if (!letter[0].sendNow) {
                    state.plannedLetters = [...state.plannedLetters, {
                        ...letter[0],
                        status: { ...letter[0].status, key: 'WAITING' }
                    }]
                } else {
                    state.generalMessageFeed = [...state.generalMessageFeed, { ...letter[0], type: LetterType.SMS }]
                }
            }
        },

        // web-sockets
        wsNoteAdded: (state, action: PayloadAction<NoteWithType[]>) => {
            state.generalMessageFeed = state.generalMessageFeed.map(m => m.id === action.payload[0].id ? {
                ...m,
                isRead: false
            } : m)
            state.haveUnreadNotes = true
        },
        wsMailArrived: (state, action: PayloadAction<CommonLetterType[]>) => {
            if (state.clientId === action.payload[0].clientId) {
                // state.generalMessageFeed = state.generalMessageFeed.push({...action.payload[0], type: LetterType.LETTER})
                state.generalMessageFeed = [...state.generalMessageFeed, {
                    ...action.payload[0],
                    type: LetterType.LETTER
                }]
            }
        },
        wsSMSMailArrived: (state, action: PayloadAction<ISMSLetter[]>) => {
            if (state.clientId === action.payload[0].clientId) {
                //  state.generalMessageFeed.push({...action.payload[0], type: LetterType.SMS})
                state.generalMessageFeed = [...state.generalMessageFeed, { ...action.payload[0], type: LetterType.SMS }]
            }
        },
        wsMailSendFailed: (state, action: PayloadAction<CommonLetterType>) => {
            if (state.clientId === action.payload.clientId) {
                state.plannedLetters = state.plannedLetters.filter(l => l.id !== action.payload.id)
                state.generalMessageFeed = state.generalMessageFeed.filter(l => l.id !== action.payload.id)
                //   state.failedLetters.push(action.payload)
               // state.failedLetters = [...state.failedLetters, action.payload]
                state.failedLetters = [...state.failedLetters, action.payload]
            }
        },

        wsMailSent: (state, action: PayloadAction<CommonLetterType[]>) => {
            let newClientMessagesFeed: MessagesFeedType = []
            if (state.clientId === action.payload[0].clientId) {
                if (state.generalMessageFeed.find(i => i.id === action.payload[0].id)) {
                    newClientMessagesFeed = state.generalMessageFeed.map((i) => {
                        if (i.id === action.payload[0].id) {
                            return { ...action.payload[0], type: LetterType.LETTER }
                        } else return i
                    })
                    state.generalMessageFeed = newClientMessagesFeed
                    state.plannedLetters = state.plannedLetters.filter(l => l.id !== action.payload[0].id)
                } else {
                    if (state.filter === 'ALL' || state.filter === 'LETTER' || state.filter === 'DEFAULT') {
                        //state.generalMessageFeed = state.generalMessageFeed.push({ ...action.payload[0], type: LetterType.LETTER })
                        state.generalMessageFeed = [...state.generalMessageFeed, {
                            ...action.payload[0],
                            type: LetterType.LETTER
                        }]
                    }
                    state.plannedLetters = state.plannedLetters.filter(l => l.id !== action.payload[0].id)
                }
            }
        },

        wsSMSMailSent: (state, action: PayloadAction<ISMSLetter[]>) => {
            let newClientMessagesFeed: MessagesFeedType = []
            if (state.generalMessageFeed.find(i => i.id === action.payload[0].id)) {
                newClientMessagesFeed = state.generalMessageFeed.map((i) => {
                    if (i.id === action.payload[0].id) {
                        return { ...action.payload[0], type: LetterType.SMS }
                    } else return i
                })
                state.generalMessageFeed = newClientMessagesFeed
                state.plannedLetters = state.plannedLetters.filter(l => l.id !== action.payload[0].id)
            } else {
                if (state.filter === 'ALL' || state.filter === 'SMS' || state.filter === 'DEFAULT') {
                    // state.generalMessageFeed.push({...action.payload[0], type: LetterType.SMS})
                    state.generalMessageFeed = [...state.generalMessageFeed, {
                        ...action.payload[0],
                        type: LetterType.SMS
                    }]
                }
                state.plannedLetters = state.plannedLetters.filter(l => l.id !== action.payload[0].id)
            }
        },

        wsLettersDelayed: (state, action: PayloadAction<any>) => {
            const { payload } = action
            if (state.clientId === payload.letters[0].clientId) {
                //state.plannedLetters = state.plannedLetters.filter(el => !payload.letters.some((l: CommonLetterType) => el.id === l.id))
                state.failedLetters = state.failedLetters.filter(el => !payload.letters.some((l: CommonLetterType) => el.id === l.id))
                state.plannedLetters = [...state.plannedLetters, ...payload.letters]
            }
        },
        wsLettersHalted: (state, action: PayloadAction<any>) => {
            const { payload } = action
            if (state.clientId === payload.letters[0].clientId) {
                //state.plannedLetters = state.plannedLetters.filter(el => !payload.letters.some((l: CommonLetterType) => el.id === l.id))
                state.plannedLetters = state.plannedLetters.filter(el => !payload.letters.some((l: CommonLetterType) => el.id === l.id))
                state.failedLetters = [...state.failedLetters, ...payload.letters]
            }
        },
        wsSMSLetterDelayed: (state, action: PayloadAction<any>) => {
            const { payload } = action
            if (state.clientId === payload.letters[0].clientId) {
                state.plannedLetters = state.plannedLetters.filter(el => !payload.letters.some((l: ISMSLetter) => el.id === l.id))
                state.failedLetters = [...state.failedLetters, ...payload.letters]
            }
        },
        wsClientLogUpdated: (state, action: PayloadAction<ClientLogUpdated>) => {
            if (action.payload.client.id === state.clientId) {
                if (state.filter === 'ALL' || state.filter === 'ACTION') {
                    //state.generalMessageFeed.push({...action.payload.item, type: LetterType.ACTION})
                    state.generalMessageFeed = [...state.generalMessageFeed, {
                        ...action.payload.item,
                        type: LetterType.ACTION
                    }]
                } else {
                    // state.histories.unshift({...action.payload.item})
                    state.histories = [{ ...action.payload.item }, ...state.histories]
                }
            }
        },
        addNewCallToGeneralMessageFeed: (state, action: PayloadAction<CallType>) => {
            state.generalMessageFeed = [action.payload, ...state.generalMessageFeed]
        },
        updateNewCallInGeneralMessageFeed: (state, action: PayloadAction<CallType>) => {
            state.generalMessageFeed = [...state.generalMessageFeed.map(message => {
                if (message.type === action.payload.type && message.id === action.payload.id) {
                    return action.payload
                }
                return message
            })]
        }
    },

    extraReducers: (builder) => {
        builder.addCase(fetchLetterInfo.fulfilled, (state, { payload }) => {
            state.clientLetter = payload
        })
        builder.addCase(declineManagerLetter.fulfilled, (state, { payload }) => {
            state.generalMessageFeed = state.generalMessageFeed.filter(m => m.id !== payload)
            state.plannedLetters = state.plannedLetters.filter(l => l.id !== payload)
        })
        builder.addCase(readAllClientLetters.fulfilled, (state) => {
            state.generalMessageFeed = state.generalMessageFeed.map(m => m.type === LetterType.LETTER ? {
                ...m,
                isRead: true
            } : m)
        })
        builder.addCase(createClientNote.fulfilled, (state, { payload }) => {
            if (state.filter === 'NOTE' || state.filter === 'ALL' || state.filter === 'DEFAULT') {
                // state.generalMessageFeed.push({...payload[0], type: LetterType.NOTE})
                state.generalMessageFeed = [...state.generalMessageFeed, { ...payload[0], type: LetterType.NOTE }]
            }
        })
        builder.addCase(deleteClientNote.fulfilled, (state, { payload }) => {
            state.generalMessageFeed = state.generalMessageFeed.filter(m => m.id !== payload)
        })
        builder.addCase(fetchClientMessageFeed.fulfilled, (state, { payload }) => {
            if (payload.isFilter) {
                state.generalMessageFeed = payload.data.items
                state.totalCount = payload.data.totalCount
                state.currentPage = payload.page
                state.filter = payload.filter
            } else {
                state.generalMessageFeed = [...state.generalMessageFeed, ...payload.data.items]
                state.totalCount = payload.data.totalCount
                state.currentPage = payload.page
                state.filter = payload.filter
            }
        })
        builder.addCase(fetchAllClientMessageFeed.fulfilled, (state, { payload }) => {
            state.lettersByHeader = payload
        })
    }
})

export const { actions: lettersActions } = lettersSlice
export const { reducer: lettersReducer } = lettersSlice
