import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { snackActions } from '../../snackSlice'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { fetchProductNotesForProject } from './fetchProductNotesForProject'


type RequestProps = {
  projectId: number,
  productNotesId: number
}

export const deleteProductNotesInProject = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'productNotes/delete_product_notes_in_project',
  async (data, { dispatch, rejectWithValue, getState, extra }) => {
    const { projectId, productNotesId } = data
    const params = getState().productNotes.productNotesTablePaginationParams

    try {
      await extra.api().delete(`project/${projectId}/product-notes/${productNotesId}/delete`)
      dispatch(fetchProductNotesForProject({ projectId, params }))

      dispatch(snackActions.setSnack({
        label: 'Заметки удалены',
        typeLabel: 'success'
      }))
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'При удалении произошла ошибка')
      return rejectWithValue({})
    } finally {
    }
  }
)