import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { ChainType } from '../types/chains'

interface RequestProps {
  chainId: number,
  isPublic?: boolean
}

type FullFilledResponse = {
  data: ChainType
  chainId: number
  isPublic?: boolean
}

export const fetchChainInfo = createAsyncThunk<FullFilledResponse,
  RequestProps,
  ThunkConfig<any>>(
  'chains/fetch_chain_info',
  async (data, thunkApi) => {
    const { chainId, isPublic } = data
    const { dispatch, rejectWithValue, extra } = thunkApi

    try {
      const response = await extra.api().get<ChainType>(`chain-letters/${chainId}`)
      return { data: response.data, chainId, isPublic }
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить информацию о цепочке')
      return rejectWithValue({})
    } finally {
    }
  }
)