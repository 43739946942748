import { ChangeEvent, memo, useEffect, useRef } from 'react'

import { createStyles, makeStyles } from '@material-ui/core/styles'

import { getAllBaseFiles } from '../../Components/Outputs/Actions/filesToBase/filesToBase'
import { FileType } from '../../Components/Inputs/FilesAttach/FilesAttach'


type UploadButtonPropsType = {
    setAttachedFiles: (attachedFiles: any[]) => void
    value: FileType[]
    setRef?: (ref: any) => void
    ref?: any
    children?: JSX.Element
    disabled?: boolean
    inputId: string
}

export const UploadButton = memo(({setAttachedFiles, value, setRef, ref, inputId, children, disabled}: UploadButtonPropsType) => {

    const refInstance = useRef<any>()

    useEffect(() => {
        setRef && setRef(refInstance)
    }, [refInstance])

    const classes = useStyles()

    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        const filenameInFiles = value.map(el => el.name)
        let test = e.target.files && Object.values(e.target.files)
        getAllBaseFiles(
            test,
            (files: Array<FileType>) => {
                let newFiles = [...value]
                files.forEach(el => {
                    if (filenameInFiles.indexOf(el.name) < 0) {
                        newFiles.push(el)
                    }
                })
                setAttachedFiles(newFiles)
            },
            []
        )
        refInstance.current.value = ''
    }


    return (
        <div className={classes.root}>
            <input
                disabled={disabled}
                ref={refInstance}
                className={classes.input}
                id={inputId}
                type="file"
                multiple
                onChange={handleFileUpload}
            />
            <label htmlFor={inputId}>
                {children}
            </label>
        </div>
    )
})

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: 30,
            height: 30
        },
        input: {
            display: 'none'
        }
    })
)
