import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { prepareFilterParams } from '../../../../../Api/API' // todo change import
import { FetchProductNotesResponse } from '../types/productNotes'
import { productNotesActions } from '../slice/productNotesSlice'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'


type RequestProps = {
  projectId: number,
  params?: PaginationParamsType
  isFree?: boolean
}


export const fetchProductNotesForProject = createAsyncThunk<FetchProductNotesResponse,
  RequestProps,
  ThunkConfig<any>>(
  'productNotes/fetch_product_notes_for_project',
  async (data, { dispatch, rejectWithValue, getState, extra }) => {
    const { projectId, params, isFree } = data
    const productNotes = getState().productNotes.productNotesTablePaginationParams
    !productNotes && dispatch(productNotesActions.isFetchData(true))
    try {
      const response = await extra.api()
        .get<FetchProductNotesResponse>(`project/${projectId}/product-notes?${prepareFilterParams({
          ...params,
          isFree
        })}`)
      params && dispatch(productNotesActions.setParams(params))

      return response.data

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось загрузить заметки о продукте для проекта')
      return rejectWithValue({})
    } finally {
      dispatch(productNotesActions.isFetchData(false))
    }
  }
)