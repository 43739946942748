import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'

import { IClientForProject } from '../../../../../types'
import * as queryString from 'query-string'
import { appActions } from '../../appSlice'
import { clientsActions } from '../slice/clientsSlice'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'


interface RequestProps {
  projectId: number,
  filterName?: string,
  filterValue?: string,
  newClientsIds?: number[]
}

type ReturnFullFilledResponse = {
  leadList: IClientForProject[]
  lastProjectId:number
}

export const fetchClientsByProject = createAsyncThunk<
  ReturnFullFilledResponse,
  RequestProps,
  ThunkConfig<any>>(
  'client/fetch_clients_by_project',
  async (data, thunkApi) => {
    const { dispatch, rejectWithValue, extra } = thunkApi

    const { projectId, filterName, filterValue = '', newClientsIds } = data
    const params: PaginationParamsType = {
      page: 1,
      per_page: 15
    }
    if (filterName && filterValue !== '') {
      params['filter[' + filterName + ']'] = filterValue
    }
    const query = queryString.stringify(params)

    dispatch(appActions.setStatus('loading'))
    try {
      const response = await extra.api().get(`clients/project/${projectId}?${query}`)
      //Todo что за логика ??? вообще непонятно
      newClientsIds && dispatch(clientsActions.setNewClientsIds(newClientsIds))
      newClientsIds && dispatch(clientsActions.setNewClientsIds(undefined))
      if (response.data.length === 0) dispatch(clientsActions.setHasClientsInProject(false))
      return { leadList: response.data, lastProjectId: projectId }
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить список лидов')
      return rejectWithValue(true)
    } finally {
      dispatch(appActions.setStatus('success'))
    }
  }
)