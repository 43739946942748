import {createAsyncThunk} from '@reduxjs/toolkit'
import {ThunkConfig} from '../../../config/StateSchema'
import {tokenCheck} from './tokenCheck'


export const forceLogin = createAsyncThunk<{},
  string,
  ThunkConfig<any>>(
  'auth/force_login',
  async (token, { dispatch }) => {
    localStorage.setItem('apikey', token)
    //addToken(token)
    await dispatch(tokenCheck())
  }
)

