import {useSelector} from 'react-redux'
import {Navigate, useLocation} from 'react-router-dom'
import {RoutePath} from '../routeConfig/routeConfig'
import {getIsAuth} from '../../StoreProvider/slices/authSlice/selectors'


export function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useSelector(getIsAuth);
  console.log('auth', auth)
  let location = useLocation();

  if (!auth) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={RoutePath.login} state={{ from: location }} replace />;
  }

  return children;
}