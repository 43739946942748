import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { tracksActions } from '../slice/tracksSlice'
import { clientsActions } from '../../clientsSlice'
import { TrackType } from '../types/tracks'


export const checkTrackStatus = createAsyncThunk<TrackType[],
  number,
  ThunkConfig<any>>(
  'tracks/check_track_status',
  async (track_id, { dispatch, rejectWithValue, extra }) => {

    dispatch(tracksActions.setIsLoad(true))
    try {
      const res = await extra.api().patch(`track/${track_id}/update`)
      dispatch(clientsActions.setTrackItem(res.data))
      dispatch(snackActions.setSnack({
        label: 'Статус трека успешно проверен',
        typeLabel: 'success'
      }))

      return res.data

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'При проверке статуса трека произошла ошибка')
      return rejectWithValue({})
    } finally {
      dispatch(tracksActions.setIsLoad(false))
    }
  }
)