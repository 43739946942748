import React from 'react'
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack'
import { Link, useLocation } from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close'
import { useSelector } from 'react-redux'
import { Box, Typography } from '@material-ui/core'
import { Location } from '@remix-run/router'
import { useAppDispatch } from '../../providers/StoreProvider/hooks/useAppDispatch'
import { appSelectors, ClientDataTypeForNotification } from '../../providers/StoreProvider/slices/appSlice'
import { getSnackBar, snackActions } from '../../providers/StoreProvider/slices/snackSlice'


export const MyApp = () => {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    //const clientData = useSelector(selectClientDataForNotification)
    const clientData = useSelector(appSelectors.getClientDataForNotification)
    //const snackbar = useSelector<AppStateType, SnackbarType[]>(state => state.snackReducer.snackbar)
    const snackbar = useSelector(getSnackBar)
    const dispatch = useAppDispatch()
    const location = useLocation()

    if (snackbar.length > 0) {
        let clearIds: Array<any> = []
        snackbar.forEach((val: any) => {
            clearIds.push(val.id)
        })
        //dispatch(clearSnackbar(clearIds))
        dispatch(snackActions.clearSnackBar(clearIds))
        snackbar.forEach((val: any) => {
            enqueueSnackbar(
                val.message,
                {
                    key: val.id,
                    variant: val.type,
                    // action: val.action
                    action: getAction(val.action, val.message, location, val.id, closeSnackbar, clientData)
                })
        })
    }
    return <></>
}

const getAction = (
    action: SnackbarAction, message: string, location: Location, key: string,
    closeSnackbar: (key?: SnackbarKey | undefined) => void, clientData: ClientDataTypeForNotification | null
) => {
    switch (message) {
        case 'У вас нет почты для общения с клиентами в данном проекте':
            return (
                <>
                    <Link
                        style={{ color: '#fff', fontWeight: 500, textDecoration: 'underline' }}
                        to={'/project/' + location.pathname.split('/')[2] + '/settings?tab=users'}
                    >
                        Настроить почту
                    </Link>
                </>
            )

        case 'Произошла ошибка при отправлении письма':
            return (
                <>
                    <Link
                        style={{ color: '#fff', fontWeight: 500, textDecoration: 'underline' }}
                        to={'/project/' + location.pathname.split('/')[2] + '/settings?tab=users'}
                    >
                        Проверить настройки почты
                    </Link>
                    <CloseIcon onClick={() => {
                        closeSnackbar(key)
                    }}/>
                </>
            )
        case 'Проблема с почтой':
            return (
                <>
                    <Link
                        style={{ color: '#fff', fontWeight: 500, textDecoration: 'underline' }}
                        to={'/project/' + location.pathname.split('/')[2] + '/settings?tab=email_dealing'}
                    >
                        Проверить настройки почты
                    </Link>
                    <CloseIcon onClick={() => {
                        closeSnackbar(key)
                    }}/>
                </>
            )

        case 'У вас новое сообщение от':
            const link = clientData?.clientStatus === 'NEW'
                ? `/project/${clientData?.projectIdForNotification}/raw_leads`
                : `/project/${clientData?.projectIdForNotification}/leads_manage?client=${clientData?.clientIdForNotification}`
            return (
                <>
                    <Link
                        style={{ color: '#fff', fontWeight: 500, textDecoration: 'underline' }}
                        to={link}
                    >
                        {clientData?.clientFirstNameForNotification} {clientData?.clientLastNameForNotification}
                    </Link>
                    <CloseIcon
                        style={{ padding: 3 }}
                        onClick={() => {
                            closeSnackbar(key)
                        }}
                    />
                </>
            )
        case 'Восстановление клиента успешно. ':
            return (
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography style={{ marginRight: 3 }}>Клиент:</Typography>
                    <Link
                        style={{ color: '#fff', fontWeight: 500, textDecoration: 'underline' }}
                        to={`/project/${clientData?.projectIdForNotification}/leads_manage?client=${clientData?.clientIdForNotification}`}
                    >
                        {clientData?.clientFirstNameForNotification} {clientData?.clientLastNameForNotification}
                    </Link>
                    <Typography style={{ color: '#fff', fontWeight: 500 }}>.
                        Восстановил: {clientData?.restorer}</Typography>
                    <CloseIcon
                        style={{ padding: 4 }}
                        onClick={() => {
                            closeSnackbar(key)
                        }}
                    />
                </Box>
            )
        case 'Добавление в архив успешно. ':
            return (
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography style={{ marginRight: 10 }}>
                        Добавил в архив: {clientData?.decliner}.
                        Клиент: {clientData?.clientFirstNameForNotification} {clientData?.clientLastNameForNotification}
                    </Typography>
                    <Link
                        style={{ color: '#fff', fontWeight: 500, textDecoration: 'underline' }}
                        to={'/project/' + clientData?.projectIdForNotification + '/archive_clients/'}
                    >
                        Показать весь список
                    </Link>
                    <CloseIcon
                        style={{ padding: 4 }}
                        onClick={() => {
                            closeSnackbar(key)
                        }}
                    />
                </Box>
            )

        case 'name : Проект с таким именем существует (в архиве)':
            return (
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Link
                        style={{ color: '#fff', fontWeight: 500, textDecoration: 'underline' }}
                        to={'/deleted_projects/'}
                    >
                        Перейти в список архивных проектов
                    </Link>
                </Box>
            )

        default:
            return <CloseIcon onClick={() => {
                closeSnackbar(key)
            }}/>
    }
}

