import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { fetchProjects } from './fetchProjects'
import { snackActions } from '../../snackSlice'
import { appActions } from '../../appSlice'
import { defaultPaginationParams } from '../slice/projectsSlice'


type RequestProps = {
  projectName: string,
  hasProductNotes: boolean
}

export const createProject = createAsyncThunk<{ projectId: number },
  RequestProps,
  ThunkConfig<any>>(
  'projects/create_project',
  async (data, { dispatch, rejectWithValue, getState, extra }) => {

    const { projectName, hasProductNotes } = data

    const params = getState().projects.projectPagination
    dispatch(appActions.setIsLoad(true))
    try {
      const res = await extra.api().post(`project`, { name: projectName, hasProductNotes })
      await dispatch(fetchProjects({
        params: !params.page ? defaultPaginationParams : params
      }))

      dispatch(snackActions.setSnack({
        label: 'Проект успешно создан',
        typeLabel: 'success'
      }))
      return { projectId: res.data.projectId }

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось создать проект')
      return rejectWithValue({})
    } finally {
      dispatch(appActions.setIsLoad(false))
    }
  }
)