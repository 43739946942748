import { useSelector } from 'react-redux'

import { Box, createStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import AccessByRole from '../../Access/AccessByRole/AccessByRole'
import { permissionsValues } from '../../../z_old_site/crm-default-values/access-by-role'
import { CustomLinkListItem } from '../../../ui-kit'
import { authSelectors } from '../../../providers/StoreProvider/slices/authSlice'
import { RoutePath } from "../../../providers/router/routeConfig/routeConfig"
import { StatisticLinks } from './StatisticLinks'

export const HeaderLinks = () => {

    const userPermissions = useSelector(authSelectors.getAuthUserProjectRights)

    const classes = useStyles()

    return (
        <Box className={classes.links_container}>
            <CustomLinkListItem to={RoutePath.main}>
                <span>Главная страница</span>
            </CustomLinkListItem>
            {/*<Link to='/'>*/}
            {/*  <span>Главная страница</span>*/}
            {/*</Link>*/}

            <CustomLinkListItem to={RoutePath.projects}>
                <span>Все проекты</span>
            </CustomLinkListItem>

            {/*<Link to='/projects'>*/}
            {/*  <span>Все проекты</span>*/}
            {/*</Link>*/}

            <AccessByRole byRole={[...permissionsValues.my_fragment_letter]}>
                <CustomLinkListItem to={`${RoutePath.templates}1`}>
                    <span>Фрагменты/Шаблоны/Цепочки</span>
                </CustomLinkListItem>
            </AccessByRole>
            {/*<AccessByRole byRole={[...permissionsValues.my_fragment_letter]}>*/}
            {/*  <Link to='/templates/1'>*/}
            {/*    <span>Фрагменты/Шаблоны/Цепочки</span>*/}
            {/*  </Link>*/}
            {/*</AccessByRole>*/}

            {userPermissions && userPermissions.canViewArchivedProjects && (
                <CustomLinkListItem to={RoutePath.deleted_projects}>
                    <span>Архивные проекты</span>
                </CustomLinkListItem>
            )}
            {/*{ userProjectsPermissions?.canViewArchivedProjects && (*/}
            {/*  <Link to='/deleted_projects'>*/}
            {/*    <span>Архивные проекты</span>*/}
            {/*  </Link>*/}
            {/*)}*/}

            {userPermissions && userPermissions.canViewGlobalPhones && (
                <CustomLinkListItem to="/phones">
                    <span>Телефоны</span>
                </CustomLinkListItem>
            )}
            {/*{ userProjectsPermissions?.canViewGlobalPhones && (*/}
            {/*  <Link to='/phones'>*/}
            {/*    <span>Телефоны</span>*/}
            {/*  </Link>*/}
            {/*)}*/}

            <CustomLinkListItem to={RoutePath.leads}>
                <span>Лиды</span>
            </CustomLinkListItem>
            {/*<Link to='/leads'>*/}
            {/*  <span>Лиды</span>*/}
            {/*</Link>*/}

            <CustomLinkListItem to={RoutePath.chats}>
                <span>Чаты</span>
            </CustomLinkListItem>
            <StatisticLinks/>
        </Box>
    )
}

const useStyles = makeStyles(() =>
    createStyles({
        links_container: {
            display: 'flex',
            '& .MuiListItem-root': {
                width: 'auto'
            }
        }
    })
)