export const cutTags = (str: string): string => {
  return str ?
    str.replaceAll(/On (Sun|Mon|Tu|Tue|Tues|Wed|Th|Thu|Thur|Thurs|Fri|Sat), (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Sept|Oct|Nov|Dec) [0-9]{1,2}, [0-9]{2,4} at [0-9]{1,2}:[0-9]{2} (.*?) wrote:.*/gims, '')
    : ''
}


export const createMarkUp = (markUpText: string) => {
  return {
    __html: markUpText
  }
}

export const stripTags = (mess: string) => {
  let result = mess.replace(/<body>(.*?)<\/body>/gism, '$1').replace(/<html>(.*?)<\/html>/gism, '$1').replace(/<head>(.*?)<\/head>/gism, '$1')
  return cutCitation(result)
}

export const cutCitation = (str: string) => {
  return str.replaceAll(/(пн|вт|ср|чт|пт|сб|вс), [0-9]{1,2} (янв|фев|мар|апр|май|июн|июл|авг|сен|окт|ноя|дек). [0-9]{2,4} г. в [0-9]{1,2}:[0-9]{2}, (.*)>/gims, '')
    .replaceAll(/On (Sun|Mon|Tu|Tue|Tues|Wed|Th|Thu|Thur|Thurs|Fri|Sat), (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Sept|Oct|Nov|Dec) [0-9]{1,2}, [0-9]{2,4} at [0-9]{1,2}:[0-9]{2} (.*?) wrote:.*/gims, '')
    .replaceAll(/(Sun|Mo|Mon|Tu|Tue|Tues|We|Wed|Th|Thu|Thur|Thurs|Fr|Fri|Sat), [0-9]{1,2} (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Sept|Oct|Nov|Dec) [0-9]{2,4} in [0-9]{1,2}:[0-9]{2}, (.*)>/gims, '')
    // .replaceAll(/(Понедельник|Вторник|Среда|Четверг|Пятница|Суббота|Воскресенье), [0-9]{2,} (января|февраля|марта|апреля|мая|июня|июля|августа|сентября|октября|ноября|декабря) [0-9]{2,4}, [0-9]{1,2}:[0-9]{1,2} \+[0-9]{1,2}:[0-9]{1,2} от [A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}:/gims, '')
    //.replaceAll(/(Sun|Mon|Tue|Wed|Thu|Fri|Sat), (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Sept|Oct|Nov|Dec) [0-9]{1,2}th [0-9]{2,4} in [0-9]{2}:[0-9]{2}, (.*?)>/gims, '')
}

export const checkOriginMessage = (mess: string): string => {
  const strWithoutCitation = cutCitation(mess)
  const citation = mess.replace(strWithoutCitation, '')
  if (strWithoutCitation === mess) {
    return mess
  } else {
    return `${strWithoutCitation} <div style='margin: 5px 0 10px 0; padding: 5px; border-left: 1px solid rgba(0,0,0,.1)'>${citation}</div> `
  }
}


