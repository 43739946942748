import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { DefaultValueType, DefaultValuePropType } from '../types/projectLettersSettings'


type FullFilledResponse = {
  data: DefaultValueType
  projectId: number
  name: DefaultValuePropType
}

export const fetchProjectFooter = createAsyncThunk<FullFilledResponse,
  number,
  ThunkConfig<any>>(
  'projectLettersSettings/fetch_project_footer',
  async (projectId, { dispatch, rejectWithValue, getState, extra }) => {

    try {
      const response = await extra.api().get<string>(`project/${projectId}/letters-setting/footer`)
      const defaultValue = getState().projectLettersSettings.defaultValue
      const data: DefaultValueType = {
        [projectId]: {
          ...defaultValue[projectId],
          footer: response.data
        }
      }

      return { data, projectId, name: 'footer' }
    } catch (e) {

      //todo check error
      handleServerNetworkError(e, dispatch, 'Не удалось загрузить нижнюю часть письма')
      return rejectWithValue({})
    }

  }
)