import { styled, withStyles } from '@material-ui/core/styles'
import { Tooltip, TooltipProps } from '@material-ui/core'
import { tooltipClasses } from '@mui/material'

export const CustomTooltip = withStyles({
  tooltip: {
    color: '#222',
    backgroundColor: '#ffffff',
    boxShadow: '0px 5px 10px -2px rgba(34, 60, 80, 0.2);'
    // border: '0.5px solid rgba(0,0,0,.1)'
  },
  arrow: {
    color: '#ffffff'
    // border: '0.5px solid rgba(0,0,0,.1)'
  }
})(Tooltip)

export const ErrorCustomTooltip = withStyles({
  tooltip: {
    color: 'red',
    backgroundColor: '#fff',
    border: '0.5px solid red',
    maxWidth: 250
  },
  arrow: {
    color: 'red'
  }
})(Tooltip)


export const CustomWidthTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} children={children} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    color: 'red',
    backgroundColor: '#fff',
    border: '0.5px solid red'
  }
})



