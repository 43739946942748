import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { ThunkConfig } from '../../../config/StateSchema'
import { ISMSLetter } from '../../../../../Api/lettersApi' // todo change import
import { lettersActions } from '../../lettersSlice'
import { snackActions } from '../../snackSlice'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'


export const deletePlannedSmsInQueue = createAsyncThunk<{},
  number,
  ThunkConfig<any>>(
  'sms/delete_planned_sms_in_queue',
  async (smsId, { dispatch, rejectWithValue, extra }) => {
    try {
      const res = await extra.api().delete<ISMSLetter,
        AxiosResponse<ISMSLetter>>(`manager-sms/${smsId}/delete`)
      dispatch(lettersActions.deletePlannedLetterFromQueue(res.data))
      dispatch(snackActions.setSnack({
        label: 'смс успешно удалено из очереди',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Произошла ошибка при удалении смс из очереди')
      return rejectWithValue({})
    }
  }
)