import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { GlobalPhonesResponse } from '../types/projectPhones'
import { prepareFilterParams } from '../../../../../Api/API'
import { projectPhonesActions } from '../slice/projectPhonesSlice'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'



export const fetchGlobalPhones = createAsyncThunk<GlobalPhonesResponse,
  PaginationParamsType | undefined,
  ThunkConfig<any>>(
  'projectPhones/fetch_global_phones',
  async (params, { dispatch, rejectWithValue, getState, extra }) => {
    const globalPhones = getState().projectPhones.globalPhonesList
    !globalPhones && dispatch(projectPhonesActions.setIsLoad(true))
    try {
      const response = await extra.api().get<GlobalPhonesResponse>(`phones-list?${prepareFilterParams(params)}`)
      params && dispatch(projectPhonesActions.setParams(params))
      console.log('fetchGlobalPhones', response)
      return response.data

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось загрузить список телефонов')
      return rejectWithValue({})
    } finally {
      dispatch(projectPhonesActions.setIsLoad(true))
    }
  }
)