import React, { FC, PropsWithChildren, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ChatType } from '../../../types'
import { Chat } from '../../../Containers/UsersChat/Chat'
import { ActiveChatList } from '../../../Containers/ActiveChatsList/ActiveChatList'
import { chatSelectors, chatThunks } from '../../StoreProvider/slices/chatsSlice'
import { useAppDispatch } from '../../StoreProvider/hooks/useAppDispatch'


export const CRMChatsProvider: FC<PropsWithChildren> = ({ children }) => {

  const actionClientId = useSelector(chatSelectors.getActionClientId)
  const actionChatId = useSelector(chatSelectors.getActionChatId)
  const actionChatType = useSelector(chatSelectors.getActionChatType)
  const noChatForAction = useSelector(chatSelectors.getNoChatForAction)

  const dispatch = useAppDispatch()

  /** Нужен для запроса за чатом, если такого чата нет в списке открытых */
  useEffect(() => {
    if (actionChatId && noChatForAction && actionChatType) {
      if (actionChatType === ChatType.CLIENT) {
        actionClientId && dispatch(chatThunks.fetchClientChat({ clientId: actionClientId, isWsEvent: true }))
      } else if (actionChatType === ChatType.PRIVATE) {
        dispatch(chatThunks.fetchPrivateChat({ chatId: actionChatId, isWsEvent: true }))
      } else if (actionChatType === ChatType.GENERAL) {
        dispatch(chatThunks.fetchGeneralChat(true))
      }
    }
  }, [actionChatId, actionChatType, actionClientId, dispatch, noChatForAction])

  return (
    <>
      <Chat />
      <ActiveChatList />
      {children}
    </>
  )
}