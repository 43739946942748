const rolesApplications = {
  admin: 'ROLE_ADMIN',
  user: 'ROLE_USER'
};

const allRolesApplications = ['ROLE_ADMIN', 'ROLE_USER'];

const rolesProject = {
  project_m: 'PROJECT_MANAGER',
  oreder_m: 'ORDER_MANAGER',
  client_m: 'CLIENT_MANAGER'
};

const allRolesProject = ['PROJECT_MANAGER', 'ORDER_MANAGER', 'CLIENT_MANAGER', 'ASSISTANT_OPERATOR'];

export const permissionsValues = {
  show_projects: [...allRolesApplications, ...allRolesProject],
  archive_projects: [rolesProject.project_m, rolesApplications.admin],

  create_project: [...allRolesApplications, rolesApplications.admin],
  edit_project: [rolesProject.project_m, rolesApplications.admin],
  delete_project: [rolesProject.project_m, rolesApplications.admin],
  copy_project: [rolesProject.project_m, rolesApplications.admin],
  settings_project: [rolesProject.project_m, rolesApplications.admin],

  new_leads: [rolesProject.client_m, rolesProject.project_m],
  archive_clients: [rolesProject.client_m, rolesProject.project_m],
  voip_project: [rolesProject.client_m, rolesProject.project_m],
  voip_client: [rolesProject.client_m, rolesProject.project_m],
  voip_call: [rolesProject.client_m, rolesProject.project_m],
  my_leads: [
    rolesProject.client_m,
    rolesProject.project_m,
    rolesApplications.admin
  ],
  // manage_leads: [rolesProject.client_m, rolesProject.project_m],
  raw_leads: [
    rolesProject.client_m, 
    // rolesProject.project_m, 
    rolesApplications.admin
  ],
  manage_leads: [
    rolesProject.client_m, 
    rolesProject.project_m, 
    rolesApplications.admin, 
    // rolesApplications.user
  ],
  show_tasks: [...allRolesProject, ...allRolesApplications],
  show_task: [...allRolesProject, ...allRolesApplications],
  create_task: [...allRolesApplications, rolesApplications.admin],
  edit_task: [...allRolesApplications, rolesApplications.admin],
  delete_task: [...allRolesApplications, rolesApplications.admin],
  archive_task: [...rolesProject.project_m, rolesApplications.admin],
  show_manager_table: [...rolesProject.project_m, rolesApplications.admin],

  my_leads_show: [rolesProject.client_m, rolesProject.project_m],
  show_lead: [rolesProject.client_m, rolesProject.project_m],
  my_client_add_to_task: [rolesProject.client_m, rolesProject.project_m],
  my_client_show_someone_else_client: [
    rolesProject.client_m,
    rolesProject.project_m
  ],
  my_client_edit: [rolesProject.client_m, rolesProject.project_m],
  my_client_reply: [rolesProject.client_m, rolesProject.project_m],
  my_client_template: [rolesProject.client_m, rolesProject.project_m],
  my_client_chain: [rolesProject.client_m, rolesProject.project_m],
  my_client_stop_letter: [rolesProject.client_m, rolesProject.project_m],
  my_client_call_phone: [rolesProject.client_m, rolesProject.project_m],
  my_client_send_sms: [rolesProject.client_m, rolesProject.project_m],
  my_client_upload_tracknumber: [...allRolesApplications],

  my_fragment_letter: [...allRolesApplications],
  admin_page_link: [rolesApplications.admin]
};
