import { FC, ReactNode } from 'react'
import { Alert, AlertTitle } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'

type AlertSeverityType = 'success' | 'info' | 'warning' | 'error'

type MuiAlertPropsType = {
  severity: AlertSeverityType
  className?: string
  title?: string
  messages?: string[]
  children?: ReactNode
}

export const MuiAlert: FC<MuiAlertPropsType> = ({
  severity,
  className,
  title,
  children
}) => {
  const classes = useStyles()
  return (
    <Alert severity={severity} className={`${classes.root} ${className}`}>
      <AlertTitle>{title}</AlertTitle>
      {children}
    </Alert>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {}
  })
)