import { FC, memo, MouseEventHandler, ReactNode, useCallback } from 'react'
import { Box, Typography } from '@material-ui/core'
import { useStyles } from '../styles'
import { useSelector } from 'react-redux'
import { ToastVariant } from '../../../../Redux/notificationsReducer'
import { useAppDispatch } from '../../../StoreProvider/hooks/useAppDispatch'
import { Action } from 'redux'
import { notificationsSelectors } from '../../../StoreProvider/slices/notitficationsSlice'

interface ToastWithTitleProps {
  className: string;
  title: string;
  onToggle: MouseEventHandler<HTMLButtonElement>;
  actionCallback?: Action<any>;
  variant: ToastVariant;
  children?: ReactNode;
}

export const ToastWithTitle: FC<ToastWithTitleProps> = memo(({
                                                               title,
                                                               className,
                                                               onToggle,
                                                               actionCallback,
                                                               children,
                                                               variant
                                                             }) => {

  const dispatch = useAppDispatch()
  const disableActionCallback = useSelector(notificationsSelectors.getNotificationDisableActionCallback)
  const cls = useStyles({ variant, disableActionCallback })

  const actionCallbackHandler = useCallback((): void => {
    actionCallback && dispatch(actionCallback)
  }, [actionCallback, dispatch])

  return (
    <Box className={className} onClick={onToggle}>
      <Box className={cls.toastHeader}>
        <Typography variant='caption'>{title}</Typography>
      </Box>
      <Box className={cls.toastBody}>
        <Typography variant='caption'>{children}</Typography>
      </Box>

      {actionCallback && (
        <Box className={cls.toastActions}>
          <Box className={cls.toastAction}>
            <Typography variant='caption' onClick={actionCallbackHandler}>
              Перейти
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  )
})