import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthSchema } from '../types/auth'
import { tokenCheck } from '../services'


const initialState: AuthSchema = {
  id: null,
  isAuth: false,
  isTryAuth: false,
  name: null,
  date: {
    date: '',
    timezone: '',
    timezone_type: 0
  },
  authStatus: false,
  notificationRoom: null,
  projectsRoles: undefined,
  roles: [],
  infoEnabled: [],
  loginMessages: null,
  rights: null,
  adminUrl: ''
}
// Todo нужен ли форс логин и addToken ? (смотреть authReducer)
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload
    },
    setIsTryAuth: (state, action: PayloadAction<boolean>) => {
      state.isTryAuth = action.payload
    },
    setLoginMessage: (state, action: PayloadAction<string | null>) => {
      state.loginMessages = action.payload
    },
    // setError: (state, action: PayloadAction<string | null>) => {
    //   state.loginMessages = action.payload
    //   state.isTryAuth = true
    //   state.isAuth = false
    // },
    setAuthStatus: (state, action: PayloadAction<boolean>) => {
      state.authStatus = action.payload
    }

  },
  extraReducers: (builder) => {
    builder.addCase(tokenCheck.fulfilled, (state, action) => {
      state.name = action.payload.name
      state.date = action.payload.date
      state.notificationRoom = action.payload.notificationRoom
      state.projectsRoles = action.payload.projectsRoles
      state.infoEnabled = action.payload.projectsRoles
      state.roles = action.payload.roles
      state.id = action.payload.id
      state.rights = action.payload.rights
      state.adminUrl = action.payload.adminUrl
    })
    builder.addCase(tokenCheck.rejected, (state, { payload }) => {
      if(payload) {
        state.isTryAuth = payload
        state.isAuth = payload
        state.authStatus = payload
      }
    })
  }

})

export const { actions: authActions } = authSlice
export const { reducer: authReducer } = authSlice
