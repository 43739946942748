import { createSlice } from '@reduxjs/toolkit'
import { MacrosSchema } from '../types/macros'
import { fetchMacros, fetchMacrosPreview, fetchMacrosPreviewList } from '../services'


const initialState: MacrosSchema = {
  isLoad: false,
  macrosPreview: null,
  macrosPreviewList: {}
}


export const macrosSlice = createSlice({
  name: 'macros',
  initialState,
  reducers: {
    setMacrosIsLoad: (state, { payload }) => {
      state.isLoad = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMacrosPreviewList.fulfilled, (state, { payload }) => {
      state.macrosPreviewList = payload
    })
    builder.addCase(fetchMacros.fulfilled, (state, { payload }) => {
      state.macrosList = payload
      state.isLoad = true
    })
    builder.addCase(fetchMacrosPreview.fulfilled, (state, { payload }) => {
      state.macrosPreview = payload
    })
    builder.addCase(fetchMacrosPreview.rejected, (state) => {
      state.macrosPreview = ''
    })
  }

})

export const { actions: macrosActions } = macrosSlice
export const { reducer: macrosReducer } = macrosSlice
