import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { OperatorsStatistic, ProjectSalesFunnelStatistic } from '../types/operators'


export const fetchStatisticFromForwardingRequests = createAsyncThunk<
    OperatorsStatistic<ProjectSalesFunnelStatistic[]>[],
    any,
    ThunkConfig<any>>(
    'operators/fetch_statistic_from_forwarding_requests',
    async (_, { dispatch, rejectWithValue, extra }) => {

        try {

            const response = await extra.api().get(`track-problems/statistics`)
            return response.data

        } catch (e) {
            handleServerNetworkError(e, dispatch, 'Не удалось загрузить информацию')
            return rejectWithValue(e)
        }

    }
)