import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'


type FullFilledResponse = {
  isAcceptable: boolean
  projectId: number
}

export const isAcceptableProject = createAsyncThunk<
  FullFilledResponse,
  number,
  ThunkConfig<any>>(
  'projects/is_acceptable_project',
  async (projectId, { dispatch, rejectWithValue, extra }) => {
    try {
      const res = await extra.api().get(`project/${projectId}/acceptable`)

      return { isAcceptable: res.data.isAcceptable, projectId }

    } catch (e) {
      //todo check error
      handleServerNetworkError(e, dispatch, 'Не удалось получить список проектов')
      return rejectWithValue({})
    }
  }
)