import { createSlice } from '@reduxjs/toolkit'
import { NotesSchema } from '../types/notes'
import { fetchProjectNotes } from '../services'


const initialState: NotesSchema = {
  projectNotes: [],
  projectId: null
}


export const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    // todo проверить санку
    // setProjectNotesAction: (state, action: PayloadAction<{
    //                           projectNotes: ProjectNotesType[],
    //                           projectId: number | null
    //                         }>
    // ) => {
    //   state.projectId = action.payload.projectId
    //   state.projectNotes = action.payload.projectNotes
    // }
    clearProjectNotes: (state) => {
      state.projectNotes = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectNotes.fulfilled, (state, { payload }) => {
      state.projectNotes = payload.projectNotes
      state.projectId = payload.projectId
    })
  }

})

export const { actions: notesActions } = notesSlice
export const { reducer: notesReducer } = notesSlice
