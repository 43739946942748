import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppSchema, ClientDataTypeForNotification, StatusType } from '../types/app'
import { DeclineClientType, NoteAdded, RestoreClientType } from '../../../../../Redux/socket-io-handler'
import { CommonLetterType } from "../../lettersSlice";

export type TargetType = 'mail_arrived' | 'client_restored' | 'client_declined'

const defaultClientDataForNotification: ClientDataTypeForNotification = {
  clientIdForNotification: 0,
  decliner: '',
  clientFirstNameForNotification: '',
  projectIdForNotification: 0,
  clientLastNameForNotification: '',
  restorer: '',
  clientEmailForNotification: '',
  clientStatus: ''
}

const initialState: AppSchema = {
  isLoad: false,
  isError: false,
  status: 'idle',
  error: null,
  projectsNotesError: null,
  isCollapsedMenu: true,
  isFetchClientData: false,
  wsType: null,
  isMaxNumberOfWsReconnections: false,
  delayedLetterInfo: null,
  clientDataForNotification: defaultClientDataForNotification,
  noteInfo: undefined,
  showInfo: false,
  projectId: null,
  clientDataForNoteNotification: undefined,
  isRedirect: false,
  isLoadTable: false
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<StatusType>) => {
      state.status = action.payload
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload
    },
    setProjectNotesError:(state, action: PayloadAction<string | null>) => {
      state.projectsNotesError = action.payload
    },
    setIsLoad:(state, action: PayloadAction<boolean>) => {
      state.isLoad = action.payload
    },
    setIsError: (state, action: PayloadAction<boolean>) => {
      state.isError = action.payload
    },
    setIsLoadTable:(state, action: PayloadAction<boolean>) => {
      state.isLoadTable = action.payload
    },
    setIsFetchClientData:(state, action: PayloadAction<boolean>) => {
      state.isFetchClientData = action.payload
    },
    setIsCollapsedMenu: (state) => {
      state.isCollapsedMenu = !state.isCollapsedMenu
    },
    setIsSending: (state, action: PayloadAction<boolean | undefined>) => {
      state.isSending = action.payload
    },
    checkWsConnectionStatus: (state, action: PayloadAction<string>) => {
      state.wsType = action.payload
    },
    checkWsMaxReconnectCount: (state, action: PayloadAction<boolean>) => {
      state.isMaxNumberOfWsReconnections = action.payload
    },
    setWsDelayedLetterInfo: (state, action: PayloadAction<string[] | null>) => {
      state.delayedLetterInfo = action.payload
    },
    setClientDataForNotification: (
      state,
      action: PayloadAction<{
        data:CommonLetterType[] | RestoreClientType | DeclineClientType,
        target: TargetType
      }>
    ) => {
      const {data, target} = action.payload
      if(target === 'mail_arrived' && Array.isArray(data)) {
        state.clientDataForNotification = {
          clientIdForNotification: data[0].clientId ? data[0].clientId : 0,
          clientEmailForNotification: typeof data[0].sender === 'string' && data[0].sender ? data[0].sender : '',
          clientFirstNameForNotification: data[0].clientFirstName ? data[0].clientFirstName : '',
          clientLastNameForNotification: data[0].clientLastName ? data[0].clientLastName : '',
          projectIdForNotification: data[0].projectId ? data[0].projectId : 0,
          clientStatus: data[0].clientStatus ? data[0].clientStatus : ''
        }
      } else if (target === 'client_restored' && 'client' in data && 'restorer' in data) {
        state.clientDataForNotification = {
          projectIdForNotification: data.projectId,
          clientFirstNameForNotification: data.client.firstName,
          clientLastNameForNotification: data.client.lastName,
          restorer: data.restorer,
          clientIdForNotification: data.client.id
        }
      } else if (target === 'client_declined' && 'client' in data && 'decliner' in data) {
        state.clientDataForNotification = {
          projectIdForNotification: data.projectId,
          clientFirstNameForNotification: data.client.firstName ? data.client.firstName : '',
          clientLastNameForNotification: data.client.lastName ? data.client.lastName : '',
          decliner: data.decliner
        }
      }
    },
    wsNoteAdded: (state, action: PayloadAction<NoteAdded>) => {
      state.noteInfo = action.payload.note
      state.showInfo = true
      state.clientDataForNoteNotification = action.payload.client
      state.projectId = action.payload.projectId
    },
    setIsShowNotice: (state) => {
      state.showInfo = false
    },
    setIsRedirect: (state, action: PayloadAction<boolean>) => {
      state.isRedirect = action.payload
    }
  },
});

export const { actions: appActions } = appSlice;
export const { reducer: appReducer } = appSlice;
