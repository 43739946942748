import { PhoneType } from '../Redux/projectPhonesReducer';
import { instance, prepareFilterParams } from './API'
import { PaginationParamsType } from '../ui-kit/Table/types/query'


type Manager = {username: string}

export enum ProviderStatus {
    NEW = 'new',
    ACTIVE = 'active',
    INACTIVE = 'inactive'
}

export type GlobalPhone = {
    reason?: string
    balance: number
    id: number
    phone: string
    projectId: number
    projectName: string
    providerStatus: ProviderStatus
    managers: Manager[]
    rights: {
        canEdit: boolean
        canDelete: boolean
    }
    externalService: {
        id: number
        name: string
    }
}

export type GlobalPhonesResponse = {
    items: GlobalPhone[]
    totalCount: number
}

export type ProviderStatusInfo = {
    projectPhone: string
    response : {
        incomingPhoneNumbers: ''
        phoneNumber: '',
        status: ProviderStatus
    }
}

export const projectPhonesAPI = {
    getProjectPhonesForSelect(projectId: number) {
        return instance.get<Array<PhoneType>>('project/' + projectId + '/phones-for-select')
            .then(response => {
                return response;
            })
            .catch(err => {
                if (err.response) {
                    return err.response;
                } else if (err.request) {
                } else {
                }
                return null;
            });
    },

    createPhone(projectId: number, phone: string) {
        return instance.post<Array<PhoneType>>('project/' + projectId + '/phones-for-select',{
            number: phone,
            project: projectId
        })
        .then(response => {
            return response;
        })
        .catch(err => {
            if (err.response) {
                return err.response;
            } else if (err.request) {
            } else {
            }
            return null;
        });
    },

    updatePhone(phoneId: number, phone: string, projectId: number) {
        return instance.patch<Array<PhoneType>>('project/phones-for-select/'+phoneId, {
            number: phone,
            project: projectId
        })
        .then(response => {
            return response;
        })
        .catch(err => {
            if (err.response) {
                return err.response;
            } else if (err.request) {
            } else {
            }
            return null;
        });
    },

    deletePhone(phoneId: number) {
        return instance.delete<Array<PhoneType>>('project/phones-for-select/'+phoneId)
        .then(response => {
            return response;
        })
        .catch(err => {
            if (err.response) {
                return err.response;
            } else if (err.request) {
            } else {
            }
            return null;
        });
    },

    // ToDo 
    addPhoneToProjectUser(managerId: number, phoneId: number) {
        return instance.patch<Array<PhoneType>>('project-user/'+managerId+'/add-phone', {
            phone: phoneId,
            project: 22
        })
        .then(response => {
            return response;
        })
        .catch(err => {
            if (err.response) {
                return err.response;
            } else if (err.request) {
            } else {
            }
            return null;
        });
    },

    deleteProjectUserPhone(projectId: number, userId: number, mailboxId: number, phoneAfterDel?: number) {
        const data = {
            phone: mailboxId,
            phoneAfterDelete: phoneAfterDel
        }
        return instance.delete(`project-user/${userId}/delete-phone`, {data})
    },

    getGlobalPhones(params?: PaginationParamsType) {
        return instance.get<GlobalPhonesResponse>(`phones-list?` + prepareFilterParams(params))
    },

    checkProviderStatus(phoneId: number) {
        return instance.get<ProviderStatusInfo>(`check_available_number/${phoneId}`)
    }

}