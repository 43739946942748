import { createAsyncThunk } from "@reduxjs/toolkit";
import { PaginationParamsType } from "../../../../../ui-kit/Table/types/query";
import { ThunkConfig } from "../../../config/StateSchema";
import { prepareFilterParams } from "../../../../../Api/API";
import { handleServerNetworkError } from "../../../../../helpers/error-helpers";
import { OperatorsStatistic, ProjectFromStatistics } from "../types/operators";


export const fetchOperatorsProjectStatistic = createAsyncThunk<OperatorsStatistic<ProjectFromStatistics[]>[],
    PaginationParamsType | undefined,
    ThunkConfig<any>>(
    'operators/fetch_common_operators_statistic',
    async (params, { dispatch, rejectWithValue, extra }) => {
        try {
            const response = await extra.api().get(`clients/statistics-by-operator?${prepareFilterParams(params)}`)
            return response.data
        } catch (e) {
            handleServerNetworkError(e, dispatch, 'Не удалось загрузить список операторов')
            return rejectWithValue({})
        }
    }
)

