import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'

// Todo в старом редюсере логика не юзается
export const deleteWidget = createAsyncThunk<{},
  number,
  ThunkConfig<any>>(
  'widgets/delete_widget',
  async (id, { dispatch, rejectWithValue, extra }) => {

    try {
      const res = await extra.api().delete(`user-widgets/${id}`)

      console.log(res)
    } catch (e) {
      return rejectWithValue({})
    } finally {
    }
  }
)