import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { snackActions } from '../../snackSlice'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'


interface RequestProps {
  params: any, // Todo types
}

export const editClientData = createAsyncThunk<any,
  RequestProps,
  ThunkConfig<any>>(
  'client/edit_client_data',
  async (data, thunkApi) => {

    const { dispatch, rejectWithValue, getState, extra } = thunkApi
    const clientId = getState().clients.clientId
    const { params } = data
    const { client_id } = params
    try {
      await extra.api().patch(`client/${client_id}`, params)
      dispatch(snackActions.setSnack({
        label: 'Информация была успешно обновлена',
        typeLabel: 'success'
      }))
      return { clientId, params }
    } catch (e: any) {
      handleServerNetworkError(e, dispatch, 'Не удалось обновить информацию')
      return rejectWithValue({})
    }
  }
)