import { ReducersMapObject } from 'redux'
import { NavigateOptions, To } from 'react-router-dom'
import { configureStore } from '@reduxjs/toolkit'

import { createAxiosInstance } from './createAxiosInstance'

import { StateSchema, ThunkExtraArg } from './StateSchema'
import { projectLettersSettingsReducer } from '../slices/projectLettersSettingsSlice'
import { projectLetterSettingReducer } from '../slices/projectLetterSettingSlice'
import { projectSpreedSheetReducer } from '../slices/projectSpreadSheetSlice'
import { archiveProjectsReducer } from '../slices/archiveProjectsSlice'
import { externalServiceReducer } from '../slices/externalServiceSlice'
import { archiveClientsReducer } from '../slices/archiveClientsSlice'
import { mailboxForDealReducer } from '../slices/mailboxForDealSlice'
import { notificationsReducer } from '../slices/notitficationsSlice'
import { managerLetterReducer } from '../slices/managerLetterSlice'
import { projectPhonesReducer } from '../slices/projectPhonesSlice'
import { productNotesReducer } from '../slices/productNotesSlice'
import { themeOptionsReducer } from '../slices/themeOptionsSlice'
import { projectUserReducer } from '../slices/projectUserSlice'
import { fragmentsReducer } from '../slices/fragmentsSlice'
import { templatesReducer } from '../slices/tempaltesSlice'
import { operatorsReducer } from "../slices/operatorsSlice";
import { projectsReducer } from '../slices/projectsSlice'
import { clientsReducer } from '../slices/clientsSlice'
import { widgetsReducer } from '../slices/widgetsSlice'
import { lettersReducer } from '../slices/lettersSlice'
import { macrosReducer } from '../slices/macrosSlice'
import { chainsReducer } from '../slices/chainsSlice'
import { tracksReducer } from '../slices/tracksSlice'
//import { twilioReducer } from '../slices/twilioSlice'
import { emailsReducer } from '../slices/emailSlice'
import { chatsReducer } from '../slices/chatsSlice'
import { snackReducer } from '../slices/snackSlice'
import { notesReducer } from '../slices/notesSlice'
import { proxyReducer } from '../slices/proxySlice'
import { callsReducer } from '../slices/callSlice'
import { authReducer } from '../slices/authSlice'
import { appReducer } from '../slices/appSlice'
import { smsReducer } from '../slices/smsSlice'


export function createReduxStore(
    initialState?: StateSchema,
    navigate?: (to: To, options?: NavigateOptions) => void
) {

    const rootReducers: ReducersMapObject<StateSchema> = {
        app: appReducer,
        archiveClients: archiveClientsReducer,
        archiveProjects: archiveProjectsReducer,
        auth: authReducer,
        chains: chainsReducer,
        chats: chatsReducer,
        clients: clientsReducer,
        emails: emailsReducer,
        externalService: externalServiceReducer,
        fragments: fragmentsReducer,
        letters: lettersReducer,
        macros: macrosReducer,
        mailboxForDeal: mailboxForDealReducer,
        managerLetter: managerLetterReducer,
        notes: notesReducer,
        notifications: notificationsReducer,
        productNotes: productNotesReducer,
        projectLetterSetting: projectLetterSettingReducer,
        projectLettersSettings: projectLettersSettingsReducer,
        projects: projectsReducer,
        projectPhones: projectPhonesReducer,
        projectSpreedSheet: projectSpreedSheetReducer,
        projectUser: projectUserReducer,
        proxy: proxyReducer,
        sms: smsReducer,
        snack: snackReducer,
        templates: templatesReducer,
        themeOptions: themeOptionsReducer,
        tracks: tracksReducer,
        //twilio: twilioReducer,
        widgets: widgetsReducer,
        calls: callsReducer,
        operators: operatorsReducer

        // Todo Add old reducers !!!!!
    }

    const extraArg: ThunkExtraArg = {
        api: createAxiosInstance,
        navigate
    }

    return configureStore({
        reducer: rootReducers,
        devTools: true,
        preloadedState: initialState,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: false,
            thunk: {
                extraArgument: extraArg
            }
        })
    })
}

export type AppDispatch = ReturnType<typeof createReduxStore>['dispatch']