import { Box } from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import { StyledSendButton, useStyles } from '../styles'
import SendIcon from '@material-ui/icons/Send'
import { ChangeEvent, FC, KeyboardEvent, memo, useEffect, useRef, useState } from 'react'
import { ChatType, ClientChat, GeneralChat, PrivateChat } from '../../../types'
import moment from 'moment'
import { MessageRequest } from '../Chat'

//import { CustomEvents } from '../../enums/enums'
//import { openFile } from '../../Utils/openFileInNewWindow'
import { Mentions } from 'antd'
import { MentionsRef } from 'antd/es/mentions'
import { useAppDispatch } from '../../../providers/StoreProvider/hooks/useAppDispatch'
import { chatsActions, chatThunks } from '../../../providers/StoreProvider/slices/chatsSlice'
import { IChatMessage } from '../../../types/chat'

interface IProps {
  mentionMembers: any[]
  fileSelected?: File
  setFileSelected: (file?: File) => void
  userId: number | null
  userName: string | null
  currentChat?: ClientChat | GeneralChat | PrivateChat
}


export const ChatActions: FC<IProps> = memo(({
                                               mentionMembers,
                                               fileSelected,
                                               setFileSelected,
                                               currentChat,
                                               userId,
                                               userName
                                             }) => {

  const [messValue, setMessValue] = useState<string>('')

  const getMessage = (): string => {
    let message = ''
    if (currentChat) {
      const isOrderManager = currentChat.members.find(i => i.id === userId && i.roles && i.roles.includes('ORDER_MANAGER'))
      currentChat.members.forEach(i => {
        if (i.roles && i.roles.includes( !isOrderManager ? 'ORDER_MANAGER' : 'CLIENT_MANAGER')) {
          message += `@${i.username}`
        }
      })
    }
    return message
  }
    
  useEffect(() => {
    setMessValue(getMessage())
  }, [currentChat?.members])

  const fileInputRef = useRef<HTMLInputElement>(null)
  const mentionsInputRef = useRef<MentionsRef | null>(null)

  const classes = useStyles({})
  const dispatch = useAppDispatch() 

  const sendMessage = (): void => {
    const postTimestamp = Math.round((Date.now() / 1000) + 60)
    const postDate = moment(Date.now()).tz('America/Chicago').format('hh:mm:ss DD:MM:YYYY')
    // Todo хардкодный объект ( заглушка сообщения )
    const newMessage: IChatMessage = {
      author: {
        id: userId ? userId : 0,
        username: userName ? userName : ''
      },
      isMyMessage: true,
      isSent: false,
      isRead: true,
      pinned: false,
      needNotification: false,
      chatId: currentChat ? currentChat.id : 0,
      id: 0,
      message: messValue,
      files: fileSelected ? [fileSelected] : [],
      postDate: postDate,
      postTimestamp: postTimestamp,
      chatParameters: {
        clientId: 0,
        type: currentChat ? currentChat?.type : ChatType.GENERAL,
        chatId: currentChat ? currentChat.id : 0,
        chatName: null,
        clientFirstName: null,
        clientLastName: null
      }
    }
    dispatch(chatsActions.wsChatMessageAdded(newMessage))
    // const event = new CustomEvent(CustomEvents.USER_CHAT_MESSAGE) /* Не удалять*/
    // document.dispatchEvent(event)
    const data: MessageRequest = {
      message: messValue,
      files: fileSelected ? [fileSelected] : []
    }
    if (currentChat) {
      if (currentChat.type === ChatType.CLIENT) {
        dispatch(chatThunks.sendMessageInChat({
          chatType: currentChat.type,
          message: data,
          clientId: currentChat.clientId
        }))
      } else if (currentChat.type === ChatType.PRIVATE) {
        dispatch(chatThunks.sendMessageInChat({
          chatType: currentChat.type,
          message: data,
          chatId: currentChat.id
        }))
      } else dispatch(chatThunks.sendMessageInChat({ chatType: currentChat.type, message: data }))
    }
    handleSuccess()
  }

  const handleSuccess = (): void => {
    setMessValue('')
    setFileSelected(undefined)
    mentionsInputRef.current?.focus()
  }

  // const pasteMentionValue = (mention: string) => {
  //   if (mentionsInputRef && mentionsInputRef.current) {
  //     let arr = mentionsInputRef.current?.textarea?.value.split('')
  //     if (mentionsInputRef.current?.textarea?.selectionStart !== null &&
  //       mentionsInputRef.current?.textarea?.selectionEnd &&
  //       mentionsInputRef.current?.textarea?.selectionEnd !== mentionsInputRef.current?.textarea?.selectionStart
  //     ) {
  //       arr?.splice(mentionsInputRef.current?.textarea?.selectionStart,
  //         mentionsInputRef.current?.textarea?.selectionEnd - mentionsInputRef.current?.textarea?.selectionStart,
  //         mention
  //       )
  //       arr && setMessValue(arr.join(''))
  //       return
  //     }
  //     arr?.splice(mentionsInputRef.current?.textarea?.selectionStart ? mentionsInputRef.current?.textarea?.selectionStart : 0, 0, mention)
  //     arr && setMessValue(arr.join(''))
  //   }
  // }

  const handleHotKeyPress = (
    e: KeyboardEvent<HTMLInputElement>
      | KeyboardEvent<HTMLTextAreaElement>
  ): void => {
    if (e.ctrlKey && e.key === 'Enter') sendMessage()
  }

  // const openMentions = () => {
  //  pasteMentionValue('@')
  //  mentionsInputRef?.current?.textarea?.focus()
  // }

  const handleChange = (value: string): void => {
    setMessValue(value)
  }

  const handleImageChange = function(e: ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files
    if (!fileList) return
    setFileSelected(fileList[0])
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  return (
    <Box className={classes.chat_actions_wrapper}>
      <Box className={classes.chat_actions_container}>
        <div style={{ width: 30, height: 30 }}>
          <input
            style={{ display: 'none' }}
            ref={fileInputRef}
            id='chat-icon-file'
            type='file'
            multiple={false}
            onChange={handleImageChange}
          />
          <label htmlFor='chat-icon-file'>
            <AttachFileIcon className={classes.attach_icon} />
          </label>
        </div>
        {/*<AlternateEmailIcon*/}
        {/*  className={classes.mentionIcon}*/}
        {/*  onClick={openMentions}*/}

        {/*/>*/}
        <Mentions autoSize
                  options={mentionMembers}
                  style={{ width: '100%' }}
                  value={messValue}
                  onChange={handleChange}
                  onKeyDown={handleHotKeyPress}
                  placeholder='Новое сообщение'
                  ref={mentionsInputRef}
                  dropdownClassName={classes.dropDown}
        />


        <StyledSendButton
          disabled={messValue === '' && !fileSelected}
          onClick={sendMessage}
        >
          <SendIcon />
        </StyledSendButton>
      </Box>
    </Box>
  )
})
