import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { FetchTemplatesEmailResponse } from '../types/emails'
import { appActions } from '../../appSlice'

type FullFilledResponse = {
  data: FetchTemplatesEmailResponse
}

export const fetchTemplatesForEmail = createAsyncThunk<
  FullFilledResponse,
  any,
  ThunkConfig<any>
>(
  'emails/fetch_templates_for_email',
  async (_, thunkApi) => {
    const { dispatch, rejectWithValue, extra } = thunkApi
    try {
      const response = await extra.api().get<FetchTemplatesEmailResponse>('mailbox-templates-for-select')
      return { data: response.data }
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось получить emails')
      return rejectWithValue({})
    }
    finally {
      dispatch(appActions.setIsLoad(false))
    }
  }
)