import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { snackActions } from '../../snackSlice'
import { mailboxForDealActions } from '../slice/mailboxForDealSlice'

export const checkIsWorkingEmailForDealing = createAsyncThunk<{},
  number,
  ThunkConfig<any>>(
  'mailboxForDeal/check_is_working_email_for_deal',
  async (id, { dispatch, rejectWithValue, extra }) => {
    dispatch(mailboxForDealActions.setSendCheckEmailButtonStatus(false))
    try {
      await extra.api().patch(`mailbox-for-deal/${id}/performance-check`)
      dispatch(snackActions.setSnack({
        label: 'Email рабочий',
        typeLabel: 'success'
      }))

    } catch (e: any) {
      console.log('ERROR', {...e})
      dispatch(snackActions.setSnack({
        label: e.response.data.error,
        typeLabel: 'error'
      }))
      return rejectWithValue({})
    } finally {
       dispatch(mailboxForDealActions.setSendCheckEmailButtonStatus(true)); //todo wtf ?  do this
      dispatch(mailboxForDealActions.setSendCheckEmailButtonStatus(null)); // todo wtf ?
    }
  }
)