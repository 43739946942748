import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'

interface RequestProps {
  messageId: number,
  pinned: boolean
}

export const pinUnpinMessageInCurrentChat = createAsyncThunk<{},
  RequestProps,
  ThunkConfig<any>>(
  'chats/pin_unpin_message-in_current_chat',
  async (data, thunkApi) => {
    const { messageId, pinned } = data
    const { dispatch, extra } = thunkApi
    try {
      await extra.api().patch(`chat-message/${messageId}/pin`, { pinned })
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Произошла ошибка')
    }
  }
)