import { createSlice } from '@reduxjs/toolkit'
import { ThemeOptionsSchema } from '../types/themeOptions'


const initialState: ThemeOptionsSchema = {
  // Sidebar
  sidebarShadow: false,
  sidebarFixed: true,
  sidebarToggleMobile: true,
  sidebarFooter: true,
  sidebarUserbox: false,
  sidebarToggle: true,
  sidebarHover: false,
  // Header
  headerFixed: true,
  headerShadow: true,
  headerSearchHover: false,
  // Main content
  contentBackground: '',
  themeConfiguratorToggle: false,
  // Footer
  footerFixed: false,
  footerShadow: false,
  // Page title
  pageTitleStyle: '',
  pageTitleBackground: '',
  pageTitleShadow: false,
  pageTitleBreadcrumb: true,
  pageTitleIconBox: true,
  pageTitleDescription: true
}


export const themeOptionsSlice = createSlice({
  name: 'theme_options',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {

  }

})

export const { actions: themeOptionsActions } = themeOptionsSlice
export const { reducer: themeOptionsReducer } = themeOptionsSlice
