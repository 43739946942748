import { memo, MouseEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { Box } from '@material-ui/core'
import { ToastVariant } from '../../../../Redux/notificationsReducer'
import { ToastNoTitle, ToastWithTitle } from './index'
import { useStyles } from '../styles'
import { useAppDispatch } from '../../../StoreProvider/hooks/useAppDispatch'
import { notificationsActions, notificationsThunks } from '../../../StoreProvider/slices/notitficationsSlice'
import { Action } from 'redux'


interface ToastNotificationProps {
    variant: ToastVariant
    id: string
    title?: string
    expirable?: boolean
    actionCallback?: Action;
    children?: ReactNode
}

const deleteNotificationDelay = 500
const expireNotificationDelay = 8000

export const ToastNotification = memo(({
                                           title,
                                           expirable = true,
                                           actionCallback,
                                           id,
                                           variant,
                                           children
                                       }: ToastNotificationProps) => {

    const [isSlideOut, setIsSlideOut] = useState<boolean>(false)
    const timer = useRef<NodeJS.Timeout>()
    const cls = useStyles({})

    const dispatch = useAppDispatch()

    const toastClassname = `${cls.toastRoot} ${cls[variant]} ${isSlideOut && cls.slideOut}`

    const onToggle = useCallback(
        (e: MouseEvent<HTMLButtonElement>): void => {
            e.stopPropagation()
            setIsSlideOut(true)
            setTimeout(() => {
                dispatch(notificationsActions.deleteNotification(id))
            }, deleteNotificationDelay)

        }, [dispatch, id])

    const onClickHandler = useCallback((): void => {
        setIsSlideOut(false)
        dispatch(notificationsActions.stopTimeout(id))
        if (timer.current) clearTimeout(timer.current)
    }, [id, dispatch])


    useEffect(() => {
        if (expirable) {
           dispatch(notificationsThunks.deleteNotificationDelayed({ id }))

            timer.current = setTimeout(() => {
                setIsSlideOut(true)
            }, expireNotificationDelay)
        }

        return (): void => {
            if (expirable && timer.current) clearTimeout(timer.current)
        }
    }, [dispatch, id, expirable])

    return (
        <Box onClick={onClickHandler}>
            {title ? (
                <ToastWithTitle
                    className={toastClassname}
                    title={title}
                    onToggle={onToggle}
                    actionCallback={actionCallback}
                    variant={variant}
                >
                    {children}
                </ToastWithTitle
                >
            ) : (
                <ToastNoTitle
                    className={toastClassname}
                    onToggle={onToggle}
                    actionCallback={actionCallback}
                    variant={variant}
                >
                    {children}
                </ToastNoTitle>
            )}
        </Box>
    )
})