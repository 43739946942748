import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'
import { fetchProjects } from './fetchProjects'


export const addProjectToArchive = createAsyncThunk<{},
  number[],
  ThunkConfig<any>>(
  'projects/add_project_to_archive',
  async (ids, { dispatch, rejectWithValue, getState, extra }) => {

   const params = getState().projects.projectPagination
    try {
      await extra.api().delete(`projects?ids=${ids[0]}`)
      await dispatch(fetchProjects({params: params}))

      dispatch(snackActions.setSnack({
        label: 'Проект был успешно добавлен в архив',
        typeLabel: 'success'
      }))

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось удалить проект')
      return rejectWithValue({})
    }
  }
)