import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { snackActions } from '../../snackSlice'


export const deleteClientNote = createAsyncThunk<number,
  number,
  ThunkConfig<any>>(
  'letters/delete_client_note',
  async (noteId, thunkApi) => {
    const { dispatch, rejectWithValue, extra } = thunkApi
    try {
      await extra.api().delete(`client-note/${noteId}`)
      dispatch(snackActions.setSnack({
        label: 'Заметка успешно удалена',
        typeLabel: 'success'
      }))

      return noteId

    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось удалить заметку')
      return rejectWithValue({})
    }
  }
)