export function checkAccess(match, byRole, project_id, roles, projectsRoles, forTestingData) {
  let matchProjectId = false
  if (match!== undefined && match.params && match.params.id_project) {
    matchProjectId = match.params.id_project;
  } else {
    matchProjectId = false
  }
  

  let id_project = null;
  let allRoles = null;
  let access = false;
  if (matchProjectId) id_project = matchProjectId;
  if (project_id) id_project = project_id;

  forTestingData && console.log('id_project', id_project);
  forTestingData && console.log('match', match);
  forTestingData && console.log('byRole', byRole);
  forTestingData && console.log('roles', roles);
  forTestingData && console.log('projectsRoles for project', projectsRoles[id_project]);
  forTestingData && !projectsRoles[id_project] && console.log('Все projectsRoles['+id_project+']', projectsRoles);

  // ToDo разобраться почему данные в projectsRoles приходят с бэка по разному
  const projectsRolesForProject = projectsRoles ? projectsRoles.filter(i=>i.project === id_project)[0] : undefined
  forTestingData && console.log('projectsRolesForProject', projectsRolesForProject);

  if (id_project && projectsRolesForProject && roles!==undefined) {
      if (Array.isArray(projectsRolesForProject.roles)) {
        allRoles = [...roles, ...projectsRolesForProject.roles];

      } else {
        allRoles = [...roles, ...Object.values(projectsRolesForProject.roles)];
      }
  } else {

    if (roles!==undefined) {
      allRoles = [...roles];
    } else {
      allRoles = [];
    }
    
  }

  forTestingData && console.log('allRoles', allRoles);
  forTestingData && console.log('byRole должен содержаться в  allRoles');


  // eslint-disable-next-line array-callback-return
  allRoles.some(r => {
    if (byRole.includes(r)) {
      forTestingData && console.log('ВХОЖДЕНИЕ ПО ', r)
      access = true;
    }
  });
  return access;
}
