import { FC, PropsWithChildren, useCallback } from 'react'

import { Popover } from '@material-ui/core'
import { anchorOrigin, PopoverNameType, transformOrigin } from './types'
import { PopoverProps } from '@material-ui/core/Popover/Popover'


type MuiPopoverProps = Omit<PopoverProps, 'open'> & {
  anchorEl?: HTMLButtonElement | SVGSVGElement | HTMLElement | null
  onClose: () => void
  popoverId: string
  name: PopoverNameType
  actionIcon?: JSX.Element | null
  open?: boolean
  className?: string
}

export const MuiPopover: FC<PropsWithChildren<MuiPopoverProps>> = ({
  anchorEl,
  open,
  onClose,
  popoverId,
  name,
  actionIcon,
  children,
  className,
  ...restProps
}) => {

  const openPopover = Boolean(anchorEl)
  const id = openPopover ? popoverId : undefined

  const handleClose = useCallback((): void => {
    onClose()
  }, [onClose])

  return (
    <>
      {actionIcon}
      <Popover id={id}
               open={open ? open : openPopover}
               anchorEl={anchorEl}
               onClose={handleClose}
               className={className}
               anchorOrigin={anchorOrigin[name]}
               transformOrigin={transformOrigin[name]}
               {...restProps}
      >
        {children}
      </Popover>
    </>
  )
}

