import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import Draggable from 'react-draggable'
import { Badge, Box, CircularProgress, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import RemoveIcon from '@material-ui/icons/Remove'
import CropSquareIcon from '@material-ui/icons/CropSquare'
import FilterNoneIcon from '@material-ui/icons/FilterNone'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'


import { PinnedMessages } from './PinnedMessages/PinnedMessages'
import { ChatActions } from './ChatActions/ChatActions'
import { Messages } from './Messages/Messages'
import { useAppDispatch } from '../../providers/StoreProvider/hooks/useAppDispatch'
import { chatsActions, chatSelectors, chatThunks } from '../../providers/StoreProvider/slices/chatsSlice'
import { authSelectors } from '../../providers/StoreProvider/slices/authSlice'
import { ChatType, ClientChat, GeneralChat, PrivateChat } from '../../types'
import { CustomTooltip, Icon, SvgSelector } from '../../ui-kit'
import { IChatMessage } from '../../types/chat'
import { useStyles } from './styles'

export type MessageType = {
  id: number,
  message: string,
  isMyLetter: boolean,
  postDate: string,
  attachedFiles?: any[]
  pinned: boolean
}

export type AttachFileType = {
  data: string
  description: string
  name: string
}

export type MessageRequest = {
  message: string,
  files: File[]
}

export const Chat = () => {

  const activeChat = useSelector(chatSelectors.getActiveChat)
  const isOpenChat = useSelector(chatSelectors.getIsOpenChat)
  const userName = useSelector(authSelectors.getAuthUsername)
  const userId = useSelector(authSelectors.getAuthUserId)


  const [currentChat, setCurrentChat] = useState<ClientChat | GeneralChat | PrivateChat>()
  const [pinnedMessages, setPinnedMessages] = useState<IChatMessage[]>([])
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<number>(0)
  const [fileSelected, setFileSelected] = useState<File>()
  const [scrollEl, setScrollEl] = useState<HTMLElement>()
  const [collapsed, setCollapsed] = useState<boolean>(true)

  const [isInitialRender, setIsInitialRender] = useState<boolean>(true)
  const [isReadAll, setIsReadAll] = useState<boolean>(false)

  const havePinned = pinnedMessages.length > 0

  const classes = useStyles({ havePinned, isOpenChat, collapsed, currentChat })

  const dispatch = useAppDispatch()
  const location = useLocation()

  const mentionMembers = useMemo(() => {
    if (currentChat) {
      return Object.values(currentChat.members).map(el => ({
        value: el.username,
        label: el.username
      }))
    } else return []
  }, [currentChat])

  const handleRemoveChat = (id?: number): void => {
    id && dispatch(chatsActions.deleteActiveChat({ id, isOpen: false }))
  }

  const handleCollapseChat = (): void => {
    dispatch(chatsActions.openChat(false))
  }

  const getChatTitle = (chat?: ClientChat | GeneralChat | PrivateChat): string => {
    if (chat) {
      if (chat.type === ChatType.CLIENT) return `${chat.clientFirstName} ${chat.clientLastName}`
      if (chat.type === ChatType.PRIVATE) return chat.name
      else return 'Общий'
    } else return ''
  }

  const handleRemoveFilePreview = (): void => setFileSelected(undefined)
  const handleChangeSize = () => setCollapsed(prev => !prev)

  const readAllChatMessages = useCallback((): void => {
    setIsReadAll(true)
    if (currentChat && currentChat.type === 'CLIENT') {
      dispatch(chatThunks.readAllMessagesInClientChat({
        clientId: currentChat.clientId,
        chatId: currentChat.id
      }))
    }
    if (currentChat && currentChat.type === 'PRIVATE') {
      dispatch(chatThunks.readAllMessagesInPrivateChat(currentChat.id))
    } else dispatch(chatThunks.readAllMessagesInGeneralChat())
    if (scrollEl) scrollEl.scrollTop = scrollEl?.children[0].clientHeight
  }, [currentChat, dispatch, scrollEl])


  const clearChart = () => {
    if (currentChat?.type === ChatType.CLIENT && currentChat.type && currentChat.clientId) {
      dispatch(chatThunks.deleteChat({ chatId: currentChat.id, type: currentChat.type, clientId: currentChat.clientId }))
    }
  }

  useEffect(() => {
    if (activeChat) {
      setCurrentChat(activeChat)
      setPinnedMessages(activeChat.messages
        .filter(el => el.pinned)
        .sort((a, b) => b.postTimestamp - a.postTimestamp)
      )
      setUnreadMessagesCount(activeChat.messages.filter(m => !m.isRead).length)
    }

    return () => {
      setCurrentChat(undefined)
      setPinnedMessages([])
      setIsInitialRender(true)
      setIsReadAll(false)
    }
  }, [activeChat])

  return (
    <Draggable handle='#handle'
               bounds='parent'
    >
      <Box className={classes.chat_container}>
        <Box className={classes.header} id='handle'>
          <CustomTooltip title='свернуть' placement='top'>
            <RemoveIcon className={classes.icon}
                        onClick={handleCollapseChat} />
          </CustomTooltip>
          {currentChat?.type === ChatType.CLIENT && (
            <CustomTooltip title='перейти к клиенту' placement='top'>
              <Link to={`/project/${currentChat.projectId}/leads_manage?client=${currentChat.clientId}`}
                    state={{from: `${location.pathname}${location.search}`}}
              >
                <AccountCircleIcon className={classes.icon} />
              </Link>
            </CustomTooltip>
          )}
          <Typography className={classes.typography}>
            {getChatTitle(currentChat)}
          </Typography>
          {collapsed ?
            <CustomTooltip title='развернуть' placement='top'>
              <CropSquareIcon
                onClick={handleChangeSize}
                className={classes.icon}
              />
            </CustomTooltip>
            :
            <CustomTooltip title='свернуть' placement='top'>
              <FilterNoneIcon
                onClick={handleChangeSize}
                className={classes.icon}
              />
            </CustomTooltip>
          }
          <CustomTooltip title='закрыть' placement='top'>
            <CloseIcon className={classes.icon}
                       onClick={() => handleRemoveChat(currentChat?.id)} />
          </CustomTooltip>

          <PinnedMessages pinnedMessages={pinnedMessages} />
        </Box>
        {!currentChat ? (
          <Box style={{ width: '100%', height: 410, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress size={20} />
          </Box>
        ) : (
          <Messages messages={currentChat?.messages}
                    pinnedMessages={pinnedMessages}
                    unreadMessagesCount={unreadMessagesCount}
                    scrollEl={scrollEl}
                    setScrollEl={setScrollEl}
                    currentChat={currentChat}
                    setIsInitialRender={setIsInitialRender}
                    isInitialRender={isInitialRender}
                    isReadAll={isReadAll}
          />
        )}


        {fileSelected && (
          <Box className={classes.filePreview}>
            <Box className={classes.filePreviewImageBlock}>
              <SvgSelector id='send-attach' />
            </Box>
            {`${fileSelected.name}`}
            <Icon name='removeWithoutCircle'
                  size={10}
                  className={classes.removePreview_icon}
                  onClick={handleRemoveFilePreview}
            />
          </Box>
        )}
        <ChatActions
          mentionMembers={mentionMembers}
          fileSelected={fileSelected}
          setFileSelected={setFileSelected}
          currentChat={currentChat}
          userId={userId}
          userName={userName}
        />
        {unreadMessagesCount > 0 ? (
          <Box className={classes.readAllBtn} onClick={readAllChatMessages}>
            <Box className={classes.badgeBox}>
              <Badge badgeContent={unreadMessagesCount}
                     color='primary'
                     className={classes.badge}
                     overlap='rectangular' />
            </Box>
            <Icon name='arrowDown' size={16} className={classes.readAllIcon} />
          </Box>
        ) : null}
      </Box>
    </Draggable>
  )
}



