import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { handleServerNetworkError } from '../../../../../helpers/error-helpers'
import { prepareFilterParams } from '../../../../../Api/API'
import { FetchFragmentsListResponse } from '../types/fragments'
import { fragmentsActions } from '../slice/fragmentsSlice'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'


export const fetchLetterFragments = createAsyncThunk<FetchFragmentsListResponse,
  PaginationParamsType | undefined,
  ThunkConfig<any>>(
  'fragments/fetch_letter_fragments',
  async (params, thunkApi) => {
    const { dispatch, rejectWithValue, getState, extra } = thunkApi

    const fragmentsList = getState().fragments.letterFragmentList
    !fragmentsList && dispatch(fragmentsActions.setIsFetchData(true))

      const query = params ? prepareFilterParams(params) : ''
    try {
      const response = await extra.api().get(`letter-fragments?${query}`)
      // params && dispatch(fragmentsActions.setParams(params))
      return response.data
    } catch (e) {
      handleServerNetworkError(e, dispatch, 'Не удалось загрузить фрагменты писем')
      return rejectWithValue({})

    } finally {
      dispatch(fragmentsActions.setIsFetchData(false))
    }
  }
)