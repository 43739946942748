import  { useEffect, useState } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { SyncLoader } from 'react-spinners'
import { useAppDispatch } from '../../providers/StoreProvider/hooks/useAppDispatch'
import { authThunks } from '../../providers/StoreProvider/slices/authSlice'

export const ForceLogin = () => {

  const [searchParams] = useSearchParams()
  const [token, setToken] = useState<string>()
  const dispatch = useAppDispatch()

  const searchToken = searchParams.get('token')

  useEffect(() => {
    if (searchToken) {
      dispatch(authThunks.forceLogin(searchToken))
      setToken(searchToken)
    }
  }, [])

  if (!token) {
    return <SyncLoader />
  }

  return <Navigate to={'/'} />
}



// const ForceLogin= () => {
//   const history = useHistory()
//   const [token, setToken] = useState<string>()
//   const dispatch = useDispatch()
//
//   useEffect(() => {
//     const paramsMap = getParamsMap(history.location.search)
//     if (paramsMap.token) {
//       const token = paramsMap.token as string
//       dispatch(forceLogin(token))
//       setToken(paramsMap.token)
//     }
//   }, [])
//
//   if (!token) {
//     return <SyncLoader />
//   }
//
//   return <Redirect to={'/'} />;
// }
//
// export default  ForceLogin