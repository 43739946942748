export enum ResponseStatusCodes {
    success = 200,
    badRequest = 400,
    notAuthorized = 401,
    noRights = 403,
    notFoundOrNotValid = 404,
    unSupportedMediaType = 415,
    noMailBoxForDealInProject = 422
}


export enum EventNames {
    TRACK_UPDATED = 'TRACK_UPDATED',
    MAIL_ARRIVED = 'MAIL_ARRIVED',
    MAIL_SENT = 'MAIL_SENT',
    CLIENT_SNOOZED = 'CLIENT_SNOOZED',
    CLIENT_UNSNOOZED = 'CLIENT_UNSNOOZED',
    NEW_CLIENT_ADDED = 'NEW_CLIENT_ADDED',
    TRUSTED_CLIENT_ADDED = 'TRUSTED_CLIENT_ADDED',
    NEW_CLIENT_ACCEPTED = 'NEW_CLIENT_ACCEPTED',
    TRUSTED_CLIENT_ACCEPTED = 'TRUSTED_CLIENT_ACCEPTED',
    MAIL_SENDING_FAILED = 'MAIL_SENDING_FAILED',
    MAILBOX_HAS_PROBLEMS = 'MAILBOX_HAS_PROBLEMS',
    LETTERS_DELAYED = 'LETTERS_DELAYED',
    LETTERS_HALTED = 'LETTERS_HALTED',
    CLIENT_RESTORED = 'CLIENT_RESTORED',
    CLIENT_DECLINED = 'CLIENT_DECLINED',
    CLIENT_SALES_FUNNEL_STEP_UPDATED = 'CLIENT_SALES_FUNNEL_STEP_UPDATED',
    CLIENT_NOTE_ADDED = 'CLIENT_NOTE_ADDED',
    TRACK_ADDED = 'TRACK_ADDED',
    CLIENT_LOG_UPDATED = 'CLIENT_LOG_UPDATED',
    SMS_SENT = 'SMS_SENT',
    SMS_ARRIVED = 'SMS_ARRIVED',
    SMS_HALTED = 'SMS_HALTED',
    MESSAGE_ADDED = 'MESSAGE_ADDED',
    MESSAGE_PINNED = 'MESSAGE_PINNED',
    CHAT_CREATED = 'CHAT_CREATED',
    CHAT_DELETED = 'CHAT_DELETED',
    MAILBOXES_FOR_DEAL_UPDATED = 'MAILBOXES_FOR_DEAL_UPDATED',
    MAILBOXES_FOR_NEW_CLIENTS_UPDATED = 'MAILBOXES_FOR_NEW_CLIENTS_UPDATED',
}




export enum CustomEvents {
    USER_CHAT_MESSAGE = 'user-chat-message',
    USER_CHAT_MESSAGE_PIN = 'user-chat-message-pin',
    CLIENT_CHAT_MESSAGE = 'client-chat-message',
}


export enum LetterSendStatusDescription {
    HALTED = 'Цепочка была остановлена из-за ошибки отправки одного письма',
    FAIL = 'Письмо не было отправлено',
    STOPPED = 'Цепочка была остановлена по ответу клиента',
    DELETED = 'Письмо было удалено пользователем',
    DECLINED = 'Отправка письма была отменена вручную'
}