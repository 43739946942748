import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../config/StateSchema'
import { snackActions } from '../../snackSlice'
import { mailboxForDealActions } from '../slice/mailboxForDealSlice'
import { ResponseStatusCodes } from '../../../../../enums/enums'
import { EmailTargetType } from '../../emailSlice'
import { EmailForDealingType, EmailForNewClientsType } from '../../emailSlice/types/emails'
import { PaginationParamsType } from '../../../../../ui-kit/Table/types/query'
import { fetchEmails } from '../../emailSlice/services'

type RequestType = {
  target: EmailTargetType
  mailboxData: EmailForDealingType[] | EmailForNewClientsType[]
  params: PaginationParamsType
  projectId: number
}

export const createEmailsForDealPack = createAsyncThunk<{},
  RequestType,
  ThunkConfig<any>>(
    'mailboxForDeal/create_emails_for_deal_pack',
    async (data, { dispatch, rejectWithValue, extra }) => {
      try {
        const { target, mailboxData, params, projectId } = data
        const res = await extra.api().post(`mailboxes-for-deal`, {
          mailboxes: mailboxData
        })
        if (res.status === ResponseStatusCodes.success) {
          dispatch(mailboxForDealActions.setIsSuccess(true))
          dispatch(snackActions.setSnack({
            label: 'Импорт почты из файла был успешно произведен',
            typeLabel: 'success'
          }))

          /** Обновляем данные вместе с параметрами пагинации */
          dispatch(fetchEmails({
            target: target,
            projectId: projectId,
            params: params
          }))

          /** Обнуляем ошибки */
          dispatch(mailboxForDealActions.setErrorsForDeal([]))

          dispatch(mailboxForDealActions.setIsSuccess(false))
        } else {
          alert('error')
          dispatch(mailboxForDealActions.setErrorsForDeal(res.data))
          dispatch(mailboxForDealActions.setIsSuccess(true))
          dispatch(mailboxForDealActions.setIsSuccess(false))
        }

      } catch (e: any) {
        // handleServerNetworkError(e, dispatch, 'При импорте почты из файла произошла ошибка')
        // dispatch(actions.setIsSuccess(true)); wtf??? todo do this
        // dispatch(actions.setIsSuccess(false)); wtf???  todo do this

        /** Добавляем ошибки */
        dispatch(mailboxForDealActions.setErrorsForDeal(e.response.data))

        return rejectWithValue({})
      }
    }
  )