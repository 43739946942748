import React, {Suspense, useCallback} from 'react'
import {Route, Routes, useLocation, useOutlet} from 'react-router-dom'

import {AppRoutesProps, routeConfig} from '../routeConfig/routeConfig'
import {Loader} from '../../../ui-kit'
import {RequireAuth} from './RequireAuth'
import {Box} from '@material-ui/core'
import {NavigationPanel} from '../../../Components/NavigationPanel/NavigationPanel'
import {makeStyles} from '@material-ui/core/styles'
import SnackbarsTracker from '../../../Containers/Snackbar/Snackbar'
import { ErrorBoundary } from '../../ErrorBoundary'

export const AppRouter = () => {

    const renderWithWrapper = useCallback((route: AppRoutesProps) => {
        const element = (
            <Suspense fallback={<Loader/>}>
                {route.element}
            </Suspense>
        )
        return (
            <Route
                key={route.path}
                path={route.path}
                element={route.authOnly ? <RequireAuth>{element}</RequireAuth> : element}
            />
        )
    }, [])


    return (
        <Routes>
            <Route element={<Layout/>}>
                {Object.values(routeConfig).map(renderWithWrapper)}
            </Route>
        </Routes>
    )
}


export const Layout = () => {
    const outlet = useOutlet()
    const location = useLocation()
    const pathName = location.pathname.split('/')
    const pathNameEnd = pathName[pathName.length - 1]

    const cls = useStyles({pathNameEnd})

    return (
        <Suspense fallback=''>
            <ErrorBoundary>
                <SnackbarsTracker/>
                <Box className={cls.app}>
                    <NavigationPanel/>
                    <Box className={cls.main}>
                        <Box className={cls.content}>
                            {outlet}
                        </Box>
                    </Box>
                </Box>
            </ErrorBoundary>
        </Suspense>
    )
}

const useStyles = makeStyles<{}, { pathNameEnd: string }>({
    app: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        transition: 'all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1)'
    },
    main: {
        position: 'relative',
        minHeight: '100vh'
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        transition: 'all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1)',
        //padding: '35px 16px 30px 16px'
        padding: ({pathNameEnd}) => pathNameEnd === 'leads_manage'
            ? '35px 0px 0px 0px'
            : '35px 16px 30px 16px'
    }
})
